/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  MembershipSummaryTopupListResponse,
  TopupsQueryParams,
} from '../../models';
import { customInstance } from '../../config';

export const topupsQuery = (
  params?: TopupsQueryParams,
  signal?: AbortSignal,
) => {
  return customInstance<MembershipSummaryTopupListResponse>({
    url: `/web/v2/topups`,
    method: 'get',
    params,
    signal,
  });
};

export const getTopupsQueryQueryKey = (params?: TopupsQueryParams) =>
  [`/web/v2/topups`, ...(params ? [params] : [])] as const;

export const getTopupsQueryQueryOptions = <
  TData = Awaited<ReturnType<typeof topupsQuery>>,
  TError = unknown,
>(
  params?: TopupsQueryParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof topupsQuery>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<Awaited<ReturnType<typeof topupsQuery>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTopupsQueryQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof topupsQuery>>> = ({
    signal,
  }) => topupsQuery(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type TopupsQueryQueryResult = NonNullable<
  Awaited<ReturnType<typeof topupsQuery>>
>;
export type TopupsQueryQueryError = unknown;

export const useTopupsQuery = <
  TData = Awaited<ReturnType<typeof topupsQuery>>,
  TError = unknown,
>(
  params?: TopupsQueryParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof topupsQuery>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTopupsQueryQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
