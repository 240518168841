/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export interface UpdateSplittableTransactionRequest {
  /** This is populated when user identifies transaction as ticket/subscription */
  program_id: string;
  /** This is populated when user identifies transaction as ticket/subscription */
  product_id: string;
}
