/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  ExpensesGetResponse,
  ExpenseListParams,
  ExpenseWithMeta,
  Expense,
  ReservedExpensesGetResponse,
  ReservedExpenseListParams,
  ExpensesImportCsvBody,
  ExpensePatchRequest,
  Journey,
  Policy,
  ExpenseValidationRequest,
  BulkExpenseReviewPartialSuccessWithMeta,
  BulkExpenseReviewRequest,
  ExpenseRefundUpdateRequest,
  BulkExpenseRefundPartialSuccessWithMeta,
  BulkExpenseRefundUpdateRequest,
  ExpenseExportResponse,
  ExpenseExportRequest,
} from '../../models';
import type { Error } from '../../models/component-common';
import { customInstance } from '../../config';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
  T,
>() => T extends Y ? 1 : 2
  ? A
  : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<
    { [Q in P]: T[P] },
    { -readonly [Q in P]: T[P] },
    P
  >;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object
        ? NonReadonly<NonNullable<T[P]>>
        : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

export const expenseList = (
  params?: ExpenseListParams,
  signal?: AbortSignal,
) => {
  return customInstance<ExpensesGetResponse>({
    url: `/web/v2/expenses`,
    method: 'get',
    params,
    signal,
  });
};

export const getExpenseListQueryKey = (params?: ExpenseListParams) =>
  [`/web/v2/expenses`, ...(params ? [params] : [])] as const;

export const getExpenseListQueryOptions = <
  TData = Awaited<ReturnType<typeof expenseList>>,
  TError = unknown,
>(
  params?: ExpenseListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof expenseList>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<Awaited<ReturnType<typeof expenseList>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getExpenseListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof expenseList>>> = ({
    signal,
  }) => expenseList(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ExpenseListQueryResult = NonNullable<
  Awaited<ReturnType<typeof expenseList>>
>;
export type ExpenseListQueryError = unknown;

export const useExpenseList = <
  TData = Awaited<ReturnType<typeof expenseList>>,
  TError = unknown,
>(
  params?: ExpenseListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof expenseList>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getExpenseListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const expensesCreate = (expense: NonReadonly<Expense>) => {
  return customInstance<ExpenseWithMeta>({
    url: `/web/v2/expenses`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: expense,
  });
};

export const getExpensesCreateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesCreate>>,
    TError,
    { data: NonReadonly<Expense> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof expensesCreate>>,
  TError,
  { data: NonReadonly<Expense> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof expensesCreate>>,
    { data: NonReadonly<Expense> }
  > = (props) => {
    const { data } = props ?? {};

    return expensesCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExpensesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof expensesCreate>>
>;
export type ExpensesCreateMutationBody = NonReadonly<Expense>;
export type ExpensesCreateMutationError = unknown;

export const useExpensesCreate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesCreate>>,
    TError,
    { data: NonReadonly<Expense> },
    TContext
  >;
}) => {
  const mutationOptions = getExpensesCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const reservedExpenseList = (
  params?: ReservedExpenseListParams,
  signal?: AbortSignal,
) => {
  return customInstance<ReservedExpensesGetResponse>({
    url: `/web/v2/reserved_expenses`,
    method: 'get',
    params,
    signal,
  });
};

export const getReservedExpenseListQueryKey = (
  params?: ReservedExpenseListParams,
) => [`/web/v2/reserved_expenses`, ...(params ? [params] : [])] as const;

export const getReservedExpenseListQueryOptions = <
  TData = Awaited<ReturnType<typeof reservedExpenseList>>,
  TError = Error,
>(
  params?: ReservedExpenseListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof reservedExpenseList>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof reservedExpenseList>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getReservedExpenseListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof reservedExpenseList>>
  > = ({ signal }) => reservedExpenseList(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ReservedExpenseListQueryResult = NonNullable<
  Awaited<ReturnType<typeof reservedExpenseList>>
>;
export type ReservedExpenseListQueryError = Error;

export const useReservedExpenseList = <
  TData = Awaited<ReturnType<typeof reservedExpenseList>>,
  TError = Error,
>(
  params?: ReservedExpenseListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof reservedExpenseList>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getReservedExpenseListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const expensesImportCsv = (
  expensesImportCsvBody: ExpensesImportCsvBody,
) => {
  const formData = new FormData();
  if (expensesImportCsvBody.file !== undefined) {
    formData.append('file', expensesImportCsvBody.file);
  }

  return customInstance<void>({
    url: `/web/v2/expenses/import/csv`,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  });
};

export const getExpensesImportCsvMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesImportCsv>>,
    TError,
    { data: ExpensesImportCsvBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof expensesImportCsv>>,
  TError,
  { data: ExpensesImportCsvBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof expensesImportCsv>>,
    { data: ExpensesImportCsvBody }
  > = (props) => {
    const { data } = props ?? {};

    return expensesImportCsv(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExpensesImportCsvMutationResult = NonNullable<
  Awaited<ReturnType<typeof expensesImportCsv>>
>;
export type ExpensesImportCsvMutationBody = ExpensesImportCsvBody;
export type ExpensesImportCsvMutationError = unknown;

export const useExpensesImportCsv = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesImportCsv>>,
    TError,
    { data: ExpensesImportCsvBody },
    TContext
  >;
}) => {
  const mutationOptions = getExpensesImportCsvMutationOptions(options);

  return useMutation(mutationOptions);
};
export const expenseGet = (expenseId: string, signal?: AbortSignal) => {
  return customInstance<ExpenseWithMeta>({
    url: `/web/v2/expenses/${expenseId}`,
    method: 'get',
    signal,
  });
};

export const getExpenseGetQueryKey = (expenseId: string) =>
  [`/web/v2/expenses/${expenseId}`] as const;

export const getExpenseGetQueryOptions = <
  TData = Awaited<ReturnType<typeof expenseGet>>,
  TError = unknown,
>(
  expenseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof expenseGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<Awaited<ReturnType<typeof expenseGet>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getExpenseGetQueryKey(expenseId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof expenseGet>>> = ({
    signal,
  }) => expenseGet(expenseId, signal);

  return { queryKey, queryFn, enabled: !!expenseId, ...queryOptions };
};

export type ExpenseGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof expenseGet>>
>;
export type ExpenseGetQueryError = unknown;

export const useExpenseGet = <
  TData = Awaited<ReturnType<typeof expenseGet>>,
  TError = unknown,
>(
  expenseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof expenseGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getExpenseGetQueryOptions(expenseId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const expensesPatch = (
  expenseId: string,
  expensePatchRequest: ExpensePatchRequest,
) => {
  return customInstance<ExpenseWithMeta>({
    url: `/web/v2/expenses/${expenseId}`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: expensePatchRequest,
  });
};

export const getExpensesPatchMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesPatch>>,
    TError,
    { expenseId: string; data: ExpensePatchRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof expensesPatch>>,
  TError,
  { expenseId: string; data: ExpensePatchRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof expensesPatch>>,
    { expenseId: string; data: ExpensePatchRequest }
  > = (props) => {
    const { expenseId, data } = props ?? {};

    return expensesPatch(expenseId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExpensesPatchMutationResult = NonNullable<
  Awaited<ReturnType<typeof expensesPatch>>
>;
export type ExpensesPatchMutationBody = ExpensePatchRequest;
export type ExpensesPatchMutationError = unknown;

export const useExpensesPatch = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesPatch>>,
    TError,
    { expenseId: string; data: ExpensePatchRequest },
    TContext
  >;
}) => {
  const mutationOptions = getExpensesPatchMutationOptions(options);

  return useMutation(mutationOptions);
};
export const expensesUpdate = (
  expenseId: string,
  expense: NonReadonly<Expense>,
) => {
  return customInstance<ExpenseWithMeta>({
    url: `/web/v2/expenses/${expenseId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: expense,
  });
};

export const getExpensesUpdateMutationOptions = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesUpdate>>,
    TError,
    { expenseId: string; data: NonReadonly<Expense> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof expensesUpdate>>,
  TError,
  { expenseId: string; data: NonReadonly<Expense> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof expensesUpdate>>,
    { expenseId: string; data: NonReadonly<Expense> }
  > = (props) => {
    const { expenseId, data } = props ?? {};

    return expensesUpdate(expenseId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExpensesUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof expensesUpdate>>
>;
export type ExpensesUpdateMutationBody = NonReadonly<Expense>;
export type ExpensesUpdateMutationError = Error;

export const useExpensesUpdate = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesUpdate>>,
    TError,
    { expenseId: string; data: NonReadonly<Expense> },
    TContext
  >;
}) => {
  const mutationOptions = getExpensesUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const expenseGetJourney = (expenseId: string, signal?: AbortSignal) => {
  return customInstance<Journey | void>({
    url: `/web/v2/expenses/${expenseId}/km_allowance/journey`,
    method: 'get',
    signal,
  });
};

export const getExpenseGetJourneyQueryKey = (expenseId: string) =>
  [`/web/v2/expenses/${expenseId}/km_allowance/journey`] as const;

export const getExpenseGetJourneyQueryOptions = <
  TData = Awaited<ReturnType<typeof expenseGetJourney>>,
  TError = unknown,
>(
  expenseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof expenseGetJourney>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof expenseGetJourney>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getExpenseGetJourneyQueryKey(expenseId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof expenseGetJourney>>
  > = ({ signal }) => expenseGetJourney(expenseId, signal);

  return { queryKey, queryFn, enabled: !!expenseId, ...queryOptions };
};

export type ExpenseGetJourneyQueryResult = NonNullable<
  Awaited<ReturnType<typeof expenseGetJourney>>
>;
export type ExpenseGetJourneyQueryError = unknown;

export const useExpenseGetJourney = <
  TData = Awaited<ReturnType<typeof expenseGetJourney>>,
  TError = unknown,
>(
  expenseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof expenseGetJourney>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getExpenseGetJourneyQueryOptions(expenseId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const expenseGetPolicy = (expenseId: string, signal?: AbortSignal) => {
  return customInstance<Policy | void>({
    url: `/web/v2/expenses/${expenseId}/applied_policy`,
    method: 'get',
    signal,
  });
};

export const getExpenseGetPolicyQueryKey = (expenseId: string) =>
  [`/web/v2/expenses/${expenseId}/applied_policy`] as const;

export const getExpenseGetPolicyQueryOptions = <
  TData = Awaited<ReturnType<typeof expenseGetPolicy>>,
  TError = unknown,
>(
  expenseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof expenseGetPolicy>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof expenseGetPolicy>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getExpenseGetPolicyQueryKey(expenseId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof expenseGetPolicy>>
  > = ({ signal }) => expenseGetPolicy(expenseId, signal);

  return { queryKey, queryFn, enabled: !!expenseId, ...queryOptions };
};

export type ExpenseGetPolicyQueryResult = NonNullable<
  Awaited<ReturnType<typeof expenseGetPolicy>>
>;
export type ExpenseGetPolicyQueryError = unknown;

export const useExpenseGetPolicy = <
  TData = Awaited<ReturnType<typeof expenseGetPolicy>>,
  TError = unknown,
>(
  expenseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof expenseGetPolicy>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getExpenseGetPolicyQueryOptions(expenseId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const expensesValidationUpdate = (
  expenseId: string,
  expenseValidationRequest: ExpenseValidationRequest,
) => {
  return customInstance<ExpenseWithMeta>({
    url: `/web/v2/expenses/${expenseId}/review`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: expenseValidationRequest,
  });
};

export const getExpensesValidationUpdateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesValidationUpdate>>,
    TError,
    { expenseId: string; data: ExpenseValidationRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof expensesValidationUpdate>>,
  TError,
  { expenseId: string; data: ExpenseValidationRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof expensesValidationUpdate>>,
    { expenseId: string; data: ExpenseValidationRequest }
  > = (props) => {
    const { expenseId, data } = props ?? {};

    return expensesValidationUpdate(expenseId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExpensesValidationUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof expensesValidationUpdate>>
>;
export type ExpensesValidationUpdateMutationBody = ExpenseValidationRequest;
export type ExpensesValidationUpdateMutationError = unknown;

export const useExpensesValidationUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesValidationUpdate>>,
    TError,
    { expenseId: string; data: ExpenseValidationRequest },
    TContext
  >;
}) => {
  const mutationOptions = getExpensesValidationUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const expensesBulkValidationUpdate = (
  bulkExpenseReviewRequest: BulkExpenseReviewRequest,
) => {
  return customInstance<void | BulkExpenseReviewPartialSuccessWithMeta>({
    url: `/web/v2/expenses/bulk_review`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: bulkExpenseReviewRequest,
  });
};

export const getExpensesBulkValidationUpdateMutationOptions = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesBulkValidationUpdate>>,
    TError,
    { data: BulkExpenseReviewRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof expensesBulkValidationUpdate>>,
  TError,
  { data: BulkExpenseReviewRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof expensesBulkValidationUpdate>>,
    { data: BulkExpenseReviewRequest }
  > = (props) => {
    const { data } = props ?? {};

    return expensesBulkValidationUpdate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExpensesBulkValidationUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof expensesBulkValidationUpdate>>
>;
export type ExpensesBulkValidationUpdateMutationBody = BulkExpenseReviewRequest;
export type ExpensesBulkValidationUpdateMutationError = Error;

export const useExpensesBulkValidationUpdate = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesBulkValidationUpdate>>,
    TError,
    { data: BulkExpenseReviewRequest },
    TContext
  >;
}) => {
  const mutationOptions =
    getExpensesBulkValidationUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const expensesRefundUpdate = (
  expenseId: string,
  expenseRefundUpdateRequest: ExpenseRefundUpdateRequest,
) => {
  return customInstance<ExpenseWithMeta>({
    url: `/web/v2/expenses/${expenseId}/refund_status`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: expenseRefundUpdateRequest,
  });
};

export const getExpensesRefundUpdateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesRefundUpdate>>,
    TError,
    { expenseId: string; data: ExpenseRefundUpdateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof expensesRefundUpdate>>,
  TError,
  { expenseId: string; data: ExpenseRefundUpdateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof expensesRefundUpdate>>,
    { expenseId: string; data: ExpenseRefundUpdateRequest }
  > = (props) => {
    const { expenseId, data } = props ?? {};

    return expensesRefundUpdate(expenseId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExpensesRefundUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof expensesRefundUpdate>>
>;
export type ExpensesRefundUpdateMutationBody = ExpenseRefundUpdateRequest;
export type ExpensesRefundUpdateMutationError = unknown;

export const useExpensesRefundUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesRefundUpdate>>,
    TError,
    { expenseId: string; data: ExpenseRefundUpdateRequest },
    TContext
  >;
}) => {
  const mutationOptions = getExpensesRefundUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const expensesBulkRefundUpdate = (
  bulkExpenseRefundUpdateRequest: BulkExpenseRefundUpdateRequest,
) => {
  return customInstance<void | BulkExpenseRefundPartialSuccessWithMeta>({
    url: `/web/v2/expenses/bulk_refund`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: bulkExpenseRefundUpdateRequest,
  });
};

export const getExpensesBulkRefundUpdateMutationOptions = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesBulkRefundUpdate>>,
    TError,
    { data: BulkExpenseRefundUpdateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof expensesBulkRefundUpdate>>,
  TError,
  { data: BulkExpenseRefundUpdateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof expensesBulkRefundUpdate>>,
    { data: BulkExpenseRefundUpdateRequest }
  > = (props) => {
    const { data } = props ?? {};

    return expensesBulkRefundUpdate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExpensesBulkRefundUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof expensesBulkRefundUpdate>>
>;
export type ExpensesBulkRefundUpdateMutationBody =
  BulkExpenseRefundUpdateRequest;
export type ExpensesBulkRefundUpdateMutationError = Error;

export const useExpensesBulkRefundUpdate = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesBulkRefundUpdate>>,
    TError,
    { data: BulkExpenseRefundUpdateRequest },
    TContext
  >;
}) => {
  const mutationOptions = getExpensesBulkRefundUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const expensesSubmit = (expenseId: string) => {
  return customInstance<ExpenseWithMeta>({
    url: `/web/v2/expenses/${expenseId}/submit`,
    method: 'post',
  });
};

export const getExpensesSubmitMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesSubmit>>,
    TError,
    { expenseId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof expensesSubmit>>,
  TError,
  { expenseId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof expensesSubmit>>,
    { expenseId: string }
  > = (props) => {
    const { expenseId } = props ?? {};

    return expensesSubmit(expenseId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExpensesSubmitMutationResult = NonNullable<
  Awaited<ReturnType<typeof expensesSubmit>>
>;

export type ExpensesSubmitMutationError = unknown;

export const useExpensesSubmit = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesSubmit>>,
    TError,
    { expenseId: string },
    TContext
  >;
}) => {
  const mutationOptions = getExpensesSubmitMutationOptions(options);

  return useMutation(mutationOptions);
};
export const expensesExport = (expenseExportRequest: ExpenseExportRequest) => {
  return customInstance<ExpenseExportResponse>({
    url: `/web/v2/expenses/export`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: expenseExportRequest,
  });
};

export const getExpensesExportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesExport>>,
    TError,
    { data: ExpenseExportRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof expensesExport>>,
  TError,
  { data: ExpenseExportRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof expensesExport>>,
    { data: ExpenseExportRequest }
  > = (props) => {
    const { data } = props ?? {};

    return expensesExport(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExpensesExportMutationResult = NonNullable<
  Awaited<ReturnType<typeof expensesExport>>
>;
export type ExpensesExportMutationBody = ExpenseExportRequest;
export type ExpensesExportMutationError = unknown;

export const useExpensesExport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesExport>>,
    TError,
    { data: ExpenseExportRequest },
    TContext
  >;
}) => {
  const mutationOptions = getExpensesExportMutationOptions(options);

  return useMutation(mutationOptions);
};
