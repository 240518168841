/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryOrganisationIdParameter } from './component-parameters/queryOrganisationIdParameter';
import type { QueryMembershipIdsParameter } from './component-parameters/queryMembershipIdsParameter';
import type { QuerySortDefinitionParameter } from './component-parameters/querySortDefinitionParameter';
import type { QueryCardStatusParameter } from './queryCardStatusParameter';

export type CardsGetParams = {
  /**
   * ID of the organisation
   */
  organisation_id?: QueryOrganisationIdParameter;
  /**
   * ID of the memberships
   */
  membership_ids?: QueryMembershipIdsParameter;
  /**
   * Result sort order
   */
  sort?: QuerySortDefinitionParameter;
  /**
   * Filter on status of a card
   */
  status?: QueryCardStatusParameter;
};
