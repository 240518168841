/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

/**
 * CREDIT_CARD: A credit card. E.g. Visa or Mastercard
 */
export type PaymentMethodType =
  typeof PaymentMethodType[keyof typeof PaymentMethodType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentMethodType = {
  CREDIT_CARD: 'CREDIT_CARD',
  VIRTUAL_IBAN: 'VIRTUAL_IBAN',
} as const;
