import { Button } from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { PageHeader } from '@libs/ui/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAuthState } from '../context';
import { getPath, Routes } from '../routes';
import { AuthLayout } from './auth/components/auth-layout';

export const ServiceUnavailablePage = () => {
  const { t } = useTranslation();
  const { signout } = useAuthState();
  const navigate = useNavigate();

  return (
    <AuthLayout title={t(i18nKeys.common.errors.service_unavailable.title)}>
      <PageHeader
        size="Large"
        title={t(i18nKeys.common.errors.service_unavailable.introduction)}
      />
      <Button
        mt="2rem"
        variant="primary"
        size="body2"
        onClick={() => {
          navigate(getPath(Routes.DASHBOARD));
        }}
        data-cy="service-unavailable-button-retry"
      >
        {t(i18nKeys.common.errors.service_unavailable.cta)}
      </Button>
      <Button
        ml="2rem"
        mt="2rem"
        variant="border"
        size="body2"
        onClick={() => {
          signout();
          navigate(getPath(Routes.AUTH_LOGIN));
        }}
        data-cy="service-unavailable-button-logout"
      >
        {t(i18nKeys.header.logout)}
      </Button>
    </AuthLayout>
  );
};
