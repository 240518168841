/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { ExpenseAllOfBudgetType } from './expenseAllOfBudgetType';
import type { ExpenseAllOfPaymentSource } from './expenseAllOfPaymentSource';
import type { ExpenseCategoryCode } from './expenseCategoryCode';
import type { ValidationStatus } from './validationStatus';
import type { PayrollStatus } from './payrollStatus';
import type { ExpenseAllOfType } from './expenseAllOfType';

export type ExpenseAllOf = {
  readonly id?: string;
  budget_id?: string;
  readonly budget_type?: ExpenseAllOfBudgetType;
  readonly payment_source?: ExpenseAllOfPaymentSource;
  category_code?: ExpenseCategoryCode;
  expense_code?: string;
  expense_occurred_at?: string;
  validation_status?: ValidationStatus;
  amount?: number;
  distance?: number;
  justification?: string;
  payroll_status?: PayrollStatus;
  provider_name?: string;
  validation_comment?: string;
  is_home_work?: boolean;
  title?: string;
  type?: ExpenseAllOfType;
  membership_id?: string;
};
