/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

/**
 * status of an imccl restriction applied to a card
 */
export type ImcclRestrictionCardStatus =
  typeof ImcclRestrictionCardStatus[keyof typeof ImcclRestrictionCardStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImcclRestrictionCardStatus = {
  CURRENT: 'CURRENT',
  PENDING: 'PENDING',
  ARCHIVED: 'ARCHIVED',
} as const;
