import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Link as ChakraLink,
  Link,
  SimpleGrid,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
} from '@chakra-ui/react';
import { organisationUpdate, useOrganisationGet } from '@libs/api/endpoints';
import { OnboardingStatus, Organisation } from '@libs/api/models';
import { FORMAT_DATE } from '@libs/core/constants';
import { useLanguage } from '@libs/core/i18n';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { validateSchema } from '@libs/core/utils/validation';
import { ONBOARDING_EVENTS } from '@libs/dashboard-core/constants/analytics';
import { getEnvByLanguage } from '@libs/dashboard-core/utils/environment';
import {
  Assistant,
  Card,
  CheckboxField,
  FormSubmitData,
  handleFormSubmit,
} from '@libs/ui/components';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OnboardingLayout } from '../components/onboarding-layout';
import { CompanyDetailsSteps } from '../onboarding-wizard.constants';
import { OnboardingOrganisationConfirmFormData } from '../onboarding-wizard.types';
import { validationOrganisationConfirmSchema } from '../onboarding-wizard.validations';

type OrganisationConfirmFormProps = {
  organisationId: string;
  setCompanyDetailsStep: Dispatch<SetStateAction<CompanyDetailsSteps>>;
};

export const OrganisationConfirmForm = ({
  organisationId,
  setCompanyDetailsStep,
}: OrganisationConfirmFormProps) => {
  const { t } = useTranslation();
  const {
    data: organisation,
    isLoading,
    queryKey,
  } = useOrganisationGet(organisationId);
  const queryClient = useQueryClient();
  const { language } = useLanguage();
  const { mutateAsync: submit } = useMutation(
    ({ values }: FormSubmitData<OnboardingOrganisationConfirmFormData>) => {
      return organisationUpdate(organisation.id, {
        ...(organisation as Organisation),
        terms_and_conditions_accepted: true,
        onboarding_status: OnboardingStatus.STARTED,
      });
    },
    {
      onSuccess: (organisation) => {
        queryClient.setQueryData(queryKey, organisation);
        setCompanyDetailsStep(CompanyDetailsSteps.REVIEW);
        window.analytics.track(ONBOARDING_EVENTS.ONBOARDING_STARTED);
      },
    },
  );

  if (isLoading) {
    return (
      <OnboardingLayout
        title={t(i18nKeys.onboarding.confirm_company.title)}
        introduction={
          <>
            {t(i18nKeys.onboarding.confirm_company.introduction)}{' '}
            <ChakraLink href={`mailto:${process.env.NX_EMAIL_SUPPORT}`}>
              {t(i18nKeys.common.contact_us)}
            </ChakraLink>
          </>
        }
      >
        <Box bgColor="white" borderRadius="1rem" mt="3rem" p="2rem">
          <Box>
            <Stack spacing="1rem">
              <Skeleton height="3.5rem" maxW="20rem" />
              <Skeleton height="2.2rem" maxW="6rem" />
            </Stack>
          </Box>
          <SimpleGrid columns={[1, null, 2]} spacingX={10} mt="1rem">
            <Box>
              <Skeleton height="2rem" maxW="15rem" mb="1rem" />
              <SkeletonText noOfLines={4} spacing="4" />
            </Box>
            <Box>
              <Skeleton height="2rem" maxW="15rem" mb="1rem" />
              <SkeletonText noOfLines={1} spacing="4" />
            </Box>
          </SimpleGrid>
        </Box>
      </OnboardingLayout>
    );
  }

  const termsAndConditionsLink = getEnvByLanguage(
    language,
    'NX_TERMS_AND_CONDITIONS_URL',
  );
  const privacyPolicyLink = getEnvByLanguage(language, 'NX_PRIVACY_POLICY_URL');
  const treezorTermsAndConditionsUrl = getEnvByLanguage(
    language,
    'NX_TREEZOR_TERMS_AND_CONDITIONS_URL',
  );

  return (
    <OnboardingLayout
      title={t(i18nKeys.onboarding.confirm_company.title)}
      introduction={
        <>
          {t(i18nKeys.onboarding.confirm_company.introduction)}{' '}
          <ChakraLink href={`mailto:${process.env.NX_EMAIL_SUPPORT}`}>
            {t(i18nKeys.common.contact_us)}
          </ChakraLink>
        </>
      }
    >
      <Flex direction="column">
        <Card>
          <Box>
            <Heading size="Title1">{organisation.legal_name}</Heading>
            <Badge variant="info" bgColor="ink.light">
              {organisation.vat}
            </Badge>
          </Box>
          <SimpleGrid columns={[1, null, 2]} spacingX={10}>
            <Box>
              <Heading size="Body2" mt="1rem">
                {t(i18nKeys.common.legal_address)}
              </Heading>
              <Text size="Body2" color="ink.dark">
                {organisation.address?.street || '-'}
                <br />
                {organisation.address?.postal_code || '-'}{' '}
                {organisation.address?.city}
                <br />
                {organisation.address?.country_code || '-'}
              </Text>
            </Box>
            <Box>
              <Heading size="Body2" mt="1rem">
                {t(i18nKeys.common.legal_registration_date)}
              </Heading>
              <Text size="Body2" color="ink.dark" pb={4}>
                {dayjs(organisation.legal?.registration_date).format(
                  FORMAT_DATE,
                ) || '-'}
              </Text>
            </Box>
          </SimpleGrid>
        </Card>
        <Formik
          initialValues={{
            termsAndConditions: false,
            termsAndConditionsTreezor: false,
          }}
          validate={validateSchema(validationOrganisationConfirmSchema)}
          onSubmit={handleFormSubmit(submit)}
        >
          {({ isSubmitting }) => (
            <Form noValidate>
              <CheckboxField
                checkboxProps={{ size: 'sm' }}
                name="termsAndConditions"
              >
                {t(
                  i18nKeys.onboarding.confirm_company
                    .agree_terms_and_conditions,
                )}{' '}
                <Link as="a" href={termsAndConditionsLink} target="_blank">
                  {t(i18nKeys.common.terms_and_conditions)}
                </Link>
                {' & '}
                <Link as="a" href={privacyPolicyLink} target="_blank">
                  {t(i18nKeys.common.privacy_policy)}
                </Link>
              </CheckboxField>
              <CheckboxField
                name="termsAndConditionsTreezor"
                checkboxProps={{ size: 'sm' }}
              >
                {t(
                  i18nKeys.onboarding.confirmation_dialog
                    .agree_terms_and_conditions,
                )}{' '}
                <Link
                  as="a"
                  href={treezorTermsAndConditionsUrl}
                  target="_blank"
                >
                  {t(i18nKeys.common.terms_and_conditions)}
                </Link>
              </CheckboxField>
              <Flex justify="flex-end" p="4rem 0 8rem">
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  variant="primary"
                  size="body1"
                  data-cy="organisation-confirm-form-button-submit"
                >
                  {t(i18nKeys.common.confirm)}
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
      <Assistant>
        <Assistant.Card
          title={t(i18nKeys.onboarding.confirm_company.assistant.title)}
        >
          <Text my={2}>
            {t(i18nKeys.onboarding.confirm_company.assistant.content)}
          </Text>
        </Assistant.Card>
      </Assistant>
    </OnboardingLayout>
  );
};
