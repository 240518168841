/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { RegistrationState } from './registrationState';

export interface Selfie {
  picture_url?: string;
  registrations?: RegistrationState[];
}
