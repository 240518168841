/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { ProgramGroupLimitAllOf } from './programGroupLimitAllOf';
import type { Audit } from './audit';

export type ProgramGroupLimit = ProgramGroupLimitAllOf & Audit;
