/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { KmAllowance } from './kmAllowance';
import type { AutomaticExpenseApprovalLimits } from './automaticExpenseApprovalLimits';

export interface OrganisationConfiguration {
  km_allowance?: KmAllowance;
  automatic_expense_approval_limits?: AutomaticExpenseApprovalLimits;
}
