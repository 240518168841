/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Gender } from './component-common/gender';
import type { MembershipRole } from './membershipRole';
import type { RegistrationStatus } from './registrationStatus';
import type { Address } from './address';
import type { OnboardingActions } from './onboardingActions';
import type { Platform } from './platform';
import type { SocialSecretaryData } from './socialSecretaryData';

export type RegistrationAllOf = {
  membership_id?: string;
  is_suspended?: boolean;
  readonly user_id?: string;
  readonly organisation_id?: string;
  readonly group_id?: string;
  readonly default_program_id?: string;
  internal_payroll_id?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  gender?: Gender;
  role?: MembershipRole;
  organisation_name?: string;
  group_name?: string;
  default_group?: boolean;
  team_id?: string;
  team_name?: string;
  status?: RegistrationStatus;
  address?: Address;
  start_on?: string;
  date_of_birth?: string;
  place_of_birth?: string;
  country_of_birth?: string;
  job_title?: string;
  language?: string;
  nationality?: string;
  readonly use_physical_card?: boolean;
  canceled_on?: string;
  onboarding_actions?: OnboardingActions;
  planned_suspension_date?: string;
  planned_reactivation_date?: string;
  readonly bank_reference?: string;
  readonly platforms?: Platform[];
  social_secretary?: SocialSecretaryData;
};
