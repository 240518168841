/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Phone } from './component-common/phone';
import type { CountryCode } from './component-common/countryCode';

export interface RegistrationPatch {
  phone_number: Phone;
  phone_number_country_code?: CountryCode;
}
