import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useIcon } from '@libs/core/theme/utils';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectInputField } from '../form/select-field';
import { Loader } from '../loader';
import { PagerButton } from './pager-button';

export type PagerProps = BoxProps & {
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  setPageSize?: React.Dispatch<React.SetStateAction<number>>;
  page_size?: number;
  page_bound?: number;
  page_index?: number;
  total_pages: number;
  total_items?: number;
  pageNumber: number;
  isFetching?: boolean;
};

export const Pager = ({
  page_index,
  total_pages,
  total_items,
  page_size = PAGE_SIZE,
  page_bound = 3,
  setPageNumber,
  setPageSize,
  pageNumber,
  isFetching,
  ...rest
}: PagerProps) => {
  const { t } = useTranslation();
  const ArrowLeft = useIcon('ArrowLeft');
  const ArrowRight = useIcon('ArrowRight');

  const initUpperBound = Math.ceil(page_index / page_bound) * page_bound;
  const upperPageBound =
    initUpperBound >= total_pages ? total_pages : initUpperBound;
  const lowerPageBound =
    initUpperBound > total_pages && total_pages > page_bound
      ? total_pages - page_bound
      : initUpperBound - page_bound;

  useEffect(() => {
    setPageNumber(pageNumber);
  }, [setPageNumber, pageNumber]);

  if (total_pages === 1 || page_index > total_pages) {
    return null;
  }

  return (
    <Flex
      name="pager"
      align="center"
      direction={{
        base: 'column-reverse',
        md: 'row',
      }}
      justify={{
        base: 'center',
        md: total_items ? 'space-between' : 'flex-end',
      }}
      {...rest}
    >
      {total_items && (
        <Flex
          width={{
            base: '100%',
            md: '50%',
          }}
          justify={{
            base: 'center',
            md: 'flex-start',
          }}
          align="baseline"
        >
          <Text size="Small" color="ink.medium" mt={{ base: '1rem', md: '0' }}>
            {t(i18nKeys.pager.rows_per_page)}:{' '}
          </Text>
          <Box maxWidth="10rem" mx="1rem">
            <SelectInputField
              size="sm"
              value={page_size}
              name="pageSize"
              // eslint-disable-next-line  @typescript-eslint/no-explicit-any
              onChange={(e: any) => setPageSize(Number(e.target?.value))}
              mb="3rem"
              mx="1rem"
            >
              {PAGE_SIZE_OPTIONS.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </SelectInputField>
          </Box>
          <Text size="Small" color="ink.medium" mt={{ base: '1rem', md: '0' }}>
            {t(i18nKeys.pager.entries, {
              lower: ((pageNumber - 1) * page_size + 1).toString(),
              upper:
                pageNumber === total_pages
                  ? total_items.toString()
                  : (pageNumber * page_size).toString(),

              total: total_items.toString(),
            })}
          </Text>
        </Flex>
      )}
      <Box>
        {pageNumber > 1 && (
          <PagerButton
            ml="0"
            mr=".5rem"
            onClick={() => {
              setPageNumber(pageNumber - 1);
            }}
          >
            <Box w="1.4rem" h="1.4rem">
              <ArrowLeft />
            </Box>
          </PagerButton>
        )}
        {pageNumber > page_bound && (
          <PagerButton
            display={{ base: 'none', sm: 'inline-flex' }}
            onClick={() => {
              setPageNumber(1);
            }}
          >
            1
          </PagerButton>
        )}
        {pageNumber > page_bound && (
          <PagerButton
            background="transparant"
            mx="0"
            px="1rem"
            display={{ base: 'none', sm: 'inline-flex' }}
            onClick={() => {
              setPageNumber(upperPageBound - page_bound);
            }}
          >
            ...
          </PagerButton>
        )}
        {[...Array(total_pages + 1).keys()].map((pageNb) => (
          <React.Fragment key={`page${pageNb}`}>
            {pageNumber === pageNb && (
              <PagerButton isActive>
                {isFetching ? (
                  <Loader
                    spinnerProps={{
                      size: 'md',
                      color: 'spinner.empty',
                      emptyColor: 'main.secondary.light',
                    }}
                  />
                ) : (
                  pageNb
                )}
              </PagerButton>
            )}
            {pageNb < upperPageBound + 1 &&
              pageNb > lowerPageBound &&
              pageNumber !== pageNb &&
              pageNb < total_pages && (
                <PagerButton onClick={() => setPageNumber(pageNb)}>
                  {pageNb}
                </PagerButton>
              )}
          </React.Fragment>
        ))}
        {(pageNumber < total_pages - page_bound + 1 ||
          lowerPageBound + page_bound !== total_pages) &&
          total_pages > page_bound && (
            <PagerButton
              px="1rem"
              mx="0"
              background="transparant"
              display={{ base: 'none', sm: 'inline-flex' }}
              onClick={() => {
                setPageNumber(upperPageBound + 1);
              }}
            >
              ...
            </PagerButton>
          )}
        {pageNumber !== total_pages &&
          (upperPageBound === total_pages ||
            pageNumber < upperPageBound ||
            total_pages >= upperPageBound - page_bound - 1) && (
            <PagerButton
              display={{ base: 'none', sm: 'inline-flex' }}
              onClick={() => {
                setPageNumber(total_pages);
              }}
            >
              {total_pages}
            </PagerButton>
          )}
        {pageNumber < total_pages && (
          <PagerButton
            ml=".5rem"
            mr="0"
            onClick={() => {
              setPageNumber(pageNumber + 1);
            }}
          >
            <Box w="1.4rem" h="1.4rem">
              <ArrowRight />
            </Box>
          </PagerButton>
        )}
      </Box>
    </Flex>
  );
};
