/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryProgramGroupLimitIdsParameter } from './component-parameters/queryProgramGroupLimitIdsParameter';

export type ProgramGroupsLimitDisplayPatchParams = {
  /**
   * ID of the program_group_limit_ids
   */
  program_group_limit_ids?: QueryProgramGroupLimitIdsParameter;
};
