/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryMembershipIdsParameter } from './component-parameters/queryMembershipIdsParameter';
import type { QueryPageNumberParameter } from './component-parameters/queryPageNumberParameter';
import type { QueryPageSizeParameter } from './component-parameters/queryPageSizeParameter';
import type { QuerySortDefinitionParameter } from './component-parameters/querySortDefinitionParameter';
import type { QueryDatetimeFromParameter } from './component-parameters/queryDatetimeFromParameter';
import type { QueryDatetimeToParameter } from './component-parameters/queryDatetimeToParameter';
import type { QuerySortByParameter } from './component-parameters/querySortByParameter';

export type ReservedExpenseListParams = {
  /**
   * ID of the memberships
   */
  membership_ids?: QueryMembershipIdsParameter;
  /**
   * Number of the page to fetch
   */
  page_number?: QueryPageNumberParameter;
  /**
   * Size of the page to fetch
   */
  page_size?: QueryPageSizeParameter;
  /**
   * Result sort order
   */
  sort?: QuerySortDefinitionParameter;
  /**
   * Start of date range (including)
   */
  date_from?: QueryDatetimeFromParameter;
  /**
   * End of date range (including)
   */
  range_to?: QueryDatetimeToParameter;
  /**
   * filter by specific field (for the range date)
   */
  sort_by?: QuerySortByParameter;
};
