import { Button } from '@chakra-ui/button';
import { Box, Flex, Heading, Text } from '@chakra-ui/layout';
import { useTheme } from '@chakra-ui/system';
import {
  getMerchantCategoryRestrictionGroupsGetPresenceQueryKey,
  getMerchantIdRestrictionGroupsGetPresenceQueryKey,
  merchantCategoryRestrictionGroupsGetPresence,
  merchantIdRestrictionGroupsGetPresence,
  useMerchantCategoryRestrictionGroupMerchantsListDelete,
  useMerchantCategoryRestrictionGroupMerchantsListUpdate,
  useMerchantIdRestrictionGroupMerchantsListDelete,
  useMerchantIdRestrictionGroupMerchantsListUpdate,
} from '@libs/api/endpoints';
import { MerchantCategoryRestrictionGroupsGetParams } from '@libs/api/models';
import { QueryStatus } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useIcon } from '@libs/core/theme/utils';
import { Loader, Tag, Tooltip } from '@libs/ui/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { RestrictionGroupType } from '../restriction-group.type';

type RestrictionGroupDetailsSearchResultProps = {
  searchQuery: string[];
  restrictionGroupId: string;
  type: RestrictionGroupType;
  RestrictionGroupQueryKey: (
    | string
    | MerchantCategoryRestrictionGroupsGetParams
  )[];
};

export const RestrictionGroupDetailsSearchResult = ({
  searchQuery,
  type,
  restrictionGroupId,
  RestrictionGroupQueryKey,
}: RestrictionGroupDetailsSearchResultProps) => {
  const [selectedId, setSelectedId] = useState<string>(null);
  const [isRemoveAllLoading, setRemoveAllLoading] = useState<boolean>(false);
  const [isAddAllLoading, setAddAllLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const AddIcon = useIcon('Add');
  const CloseIcon = useIcon('Close');
  const theme = useTheme();
  const queryClient = useQueryClient();

  const presenceQueryKey =
    type === RestrictionGroupType.MCC
      ? getMerchantCategoryRestrictionGroupsGetPresenceQueryKey(
          restrictionGroupId,
          {
            merchants: searchQuery.toString(),
          },
        )
      : getMerchantIdRestrictionGroupsGetPresenceQueryKey(restrictionGroupId, {
          merchants: searchQuery.toString(),
        });
  const { data, isLoading } = useQuery(
    presenceQueryKey,
    () =>
      type === RestrictionGroupType.MCC
        ? merchantCategoryRestrictionGroupsGetPresence(restrictionGroupId, {
            merchants: searchQuery.toString(),
          })
        : merchantIdRestrictionGroupsGetPresence(restrictionGroupId, {
            merchants: searchQuery.toString(),
          }),
    { enabled: searchQuery.length > 0, keepPreviousData: true },
  );

  const updateSuccessHandler = () => {
    // update presence cache on success
    queryClient.invalidateQueries(presenceQueryKey);
    queryClient.invalidateQueries(RestrictionGroupQueryKey);
    queryClient.setQueryData(
      presenceQueryKey,
      data.map(({ merchant, presence }) =>
        merchant === selectedId
          ? { merchant, presence: !presence }
          : { merchant, presence },
      ),
    );
    setSelectedId(null);
    setAddAllLoading(false);
    setRemoveAllLoading(false);
  };
  const updateErrorHandler = (err) => {
    setSelectedId(null);
    setAddAllLoading(false);
    setRemoveAllLoading(false);
  };

  // Add to list mutation
  const {
    mutateAsync: updateMidRestrictionGroupList,
    status: updateMidRestrictionGroupListStatus,
    isLoading: isUpdateMidRestrictionGroupListLoading,
  } = useMerchantIdRestrictionGroupMerchantsListUpdate({
    mutation: {
      onSuccess: updateSuccessHandler,
      onError: updateErrorHandler,
    },
  });
  const {
    mutateAsync: updateMccList,
    status: updateMccListStatus,
    isLoading: isUpdateMccListLoading,
  } = useMerchantCategoryRestrictionGroupMerchantsListUpdate({
    mutation: {
      onSuccess: updateSuccessHandler,
      onError: updateErrorHandler,
    },
  });

  // Delete from list mutation
  const {
    mutateAsync: deleteMidRestrictionGroupList,
    status: deleteMidRestrictionGroupListStatus,
    isLoading: isDeleteMidRestrictionGroupListLoading,
  } = useMerchantIdRestrictionGroupMerchantsListDelete({
    mutation: {
      onSuccess: updateSuccessHandler,
      onError: updateErrorHandler,
    },
  });
  const {
    mutateAsync: deleteMccList,
    status: deleteMccListStatus,
    isLoading: isDeleteMccListLoading,
  } = useMerchantCategoryRestrictionGroupMerchantsListDelete({
    mutation: {
      onSuccess: updateSuccessHandler,
      onError: updateErrorHandler,
    },
  });

  const updateRestrictionList = (
    type: RestrictionGroupType,
    merchants: string,
  ) => {
    if (type === RestrictionGroupType.MCC) {
      updateMccList({
        mccRestrictionId: restrictionGroupId,
        params: { merchants },
      });
    } else {
      updateMidRestrictionGroupList({
        midRestrictionId: restrictionGroupId,
        params: { merchants },
      });
    }
  };
  const deleteRestrictionList = (
    type: RestrictionGroupType,
    merchants: string,
  ) => {
    if (type === RestrictionGroupType.MCC) {
      deleteMccList({
        mccRestrictionId: restrictionGroupId,
        params: { merchants },
      });
    } else {
      deleteMidRestrictionGroupList({
        midRestrictionId: restrictionGroupId,
        params: { merchants },
      });
    }
  };

  const edit = (id: string, currentState: boolean) => {
    setSelectedId((s) => (s === id ? null : id));
    if (currentState) {
      deleteRestrictionList(type, id);
    } else {
      updateRestrictionList(type, id);
    }
  };

  const getIdsToEdit = () =>
    searchQuery.reduce(
      (acc, it) => {
        return (
          (data &&
            (data.filter((data) => data.merchant === it && !data.presence)
              .length > 0
              ? {
                  ...acc,
                  not_exist: [...acc.not_exist, it],
                }
              : { ...acc, exist: [...acc.exist, it] })) ||
          acc
        );
      },
      { exist: [], not_exist: [] },
    );
  const removeAll = () => {
    setRemoveAllLoading(true);
    const idsToRemove = getIdsToEdit().exist;
    if (idsToRemove.length > 0) {
      deleteRestrictionList(type, idsToRemove.toString());
    }
  };
  const addAll = () => {
    setAddAllLoading(true);
    const idsToAdd = getIdsToEdit().not_exist;
    if (idsToAdd.length > 0) {
      updateRestrictionList(type, idsToAdd.toString());
    }
  };

  if (searchQuery.length === 0 || isLoading) {
    return null;
  }

  const getLoadingState = (isListed: boolean) => {
    if (type === RestrictionGroupType.MCC) {
      return isListed
        ? deleteMccListStatus === QueryStatus.loading
        : updateMccListStatus === QueryStatus.loading;
    } else {
      return isListed
        ? deleteMidRestrictionGroupListStatus === QueryStatus.loading
        : updateMidRestrictionGroupListStatus === QueryStatus.loading;
    }
  };

  const isEditAllButtonDisabled =
    isDeleteMccListLoading ||
    isDeleteMidRestrictionGroupListLoading ||
    isUpdateMccListLoading ||
    isUpdateMidRestrictionGroupListLoading ||
    isRemoveAllLoading ||
    isAddAllLoading ||
    searchQuery.length < 2;

  return (
    <Box>
      <Heading size="Title3">
        {t(i18nKeys.operator.restriction_groups.details.search.results)}
      </Heading>
      <Text size="Body2" color="ink.dark">
        {t(i18nKeys.operator.restriction_groups.details.search.result_subtitle)}
      </Text>
      <Flex mt=".5rem" wrap="wrap">
        {searchQuery.map((merchantId, index) => {
          // checks if data is loading or is in cache
          const isNew =
            data &&
            data.filter((data) => data.merchant === merchantId).length === 0;

          const merchantIdExists =
            data &&
            data.filter((data) => data.merchant === merchantId && data.presence)
              .length > 0;
          const colorVariant = merchantIdExists ? 'positive' : 'negative';
          const isLoadingEdit = getLoadingState(merchantIdExists);

          // don't render tag if data is loading and not in cache
          return !isNew ? (
            <Tag
              mr="1rem"
              mb=".5rem"
              variant={colorVariant}
              key={`${merchantId}-${index}`}
              rightIcon={
                <Tooltip
                  placement="bottom"
                  bg="ink.white"
                  textAlign="center"
                  label={
                    <Text size="Body2" color="ink.dark">
                      {merchantIdExists
                        ? t(
                            i18nKeys.operator.restriction_groups.details.search
                              .result_remove,
                          )
                        : t(
                            i18nKeys.operator.restriction_groups.details.search
                              .result_add,
                          )}
                    </Text>
                  }
                >
                  <Button
                    variant="transparent"
                    p=".3rem"
                    onClick={() => edit(merchantId, merchantIdExists)}
                    ml="5rem"
                    w="1.5rem"
                    h="1.5rem"
                    fill={`status.${colorVariant}.type`}
                    isDisabled={
                      isLoadingEdit || isAddAllLoading || isRemoveAllLoading
                    }
                  >
                    {(selectedId === merchantId && isLoadingEdit) ||
                    (merchantIdExists && isRemoveAllLoading) ||
                    (!merchantIdExists && isAddAllLoading) ? (
                      <Loader
                        spinnerProps={{
                          size: 'md',
                          color: `status.${colorVariant}.type`,
                          emptyColor: `status.${colorVariant}.light`,
                        }}
                      />
                    ) : (
                      (merchantIdExists && <CloseIcon />) || <AddIcon />
                    )}
                  </Button>
                </Tooltip>
              }
            >
              {merchantId}
            </Tag>
          ) : (
            <Tag
              mr="1rem"
              variant="status"
              key={`${merchantId}-${index}`}
              border={`solid ${theme.borders.xs}`}
              borderColor={theme.colors.ink.medium}
              borderRadius="1.2rem"
              px=".75rem"
              py=".5rem"
              rightIcon={
                <Box
                  px=".2rem"
                  ml="5rem"
                  w="1.5rem"
                  h="1.5rem"
                  position="relative"
                >
                  <Loader spinnerProps={{ size: 'md' }} />
                </Box>
              }
            >
              {merchantId}
            </Tag>
          );
        })}
      </Flex>
      <Flex justify="flex-end" mt="2rem">
        <Button
          variant="primary"
          size="body2"
          onClick={removeAll}
          mr="1rem"
          isDisabled={
            isEditAllButtonDisabled || getIdsToEdit().exist.length < 2
          }
        >
          {t(i18nKeys.common.remove_all)}
        </Button>
        <Button
          variant="primary"
          size="body2"
          onClick={addAll}
          isDisabled={
            isEditAllButtonDisabled || getIdsToEdit().not_exist.length < 2
          }
        >
          {t(i18nKeys.common.add_all)}
        </Button>
      </Flex>
    </Box>
  );
};
