/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { BudgetDistribution } from './budgetDistribution';

export type ProgramGroupLimitAllOf = {
  readonly id?: string;
  readonly group_id?: Uuid;
  limit_amount?: number;
  carry_over?: boolean;
  prorata?: boolean;
  budget_distribution?: BudgetDistribution;
  /** This field will be populated for personalized limit and empty for limit defined at group level
   */
  readonly membership_id?: Uuid;
  display_budget_limit?: boolean;
};
