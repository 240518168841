/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Provider } from './provider';
import type { Service } from './service';

export interface Merchant {
  readonly merchant_id: string;
  merchant_name: string;
  merchant_address?: string;
  merchant_city?: string;
  merchant_country?: string;
  merchant_category_code?: string;
  transaction_reference?: string;
  card_reference?: string;
  provider?: Provider;
  service?: Service;
}
