/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { AmountOfMoney } from './amountOfMoney';

export interface ManualSplitData {
  /** This flag indicates whether the percentage of the split was adjusted due to overspending the budget. Creating a manual expense with this flag set will lead to error. Used only for responses. */
  is_overspent?: boolean;
  /** Percentage of expense covered by an employeer */
  employer_reimbursement_percentage?: number;
  /** Amount covered by an employer, set to the same value as 'amount' in the Expense. Used only for responses. Both values are mapped from the same proto property. May change in the future, in which case additional logic to proto mapping will be needed. */
  employer_contribution_amount?: AmountOfMoney;
  employee_contribution_amount?: AmountOfMoney;
}
