/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type merchantRestrictionGroupsGetPresenceResponseItem = {
  merchant?: string;
  presence?: boolean;
};
