/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryPaymentMethodsParameter } from './queryPaymentMethodsParameter';
import type { QueryProgramIdParameter } from './component-parameters/queryProgramIdParameter';
import type { QueryServiceIdParameter } from './component-parameters/queryServiceIdParameter';
import type { QueryProviderIdParameter } from './component-parameters/queryProviderIdParameter';

export type MembershipProductsGetParams = {
  /**
   * Repeat "payment_method" param multiple times to include multiple payment methods
   */
  payment_method?: QueryPaymentMethodsParameter;
  /**
   * ID of the program
   */
  program_id?: QueryProgramIdParameter;
  /**
   * ID of the service
   */
  service_id?: QueryServiceIdParameter;
  /**
   * ID of the provider
   */
  provider_id?: QueryProviderIdParameter;
};
