import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
} from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { getPath, Routes } from '@libs/dashboard-core/routes';
import { PageHeader } from '@libs/ui/components';
import { Link } from '@libs/ui/components/link';
import { useDrawer } from '@libs/ui/components/table/table.utils';
import { useTranslation } from 'react-i18next';
import { DashboardLayout } from '../../components/dashboard-layout';
import React, { useState } from 'react';
import { RestrictionTable } from './components/restriction-table';
import { useImcclRestrictionAllGet } from '@libs/api-v2/endpoints/imccl-restrictions/imccl-restrictions';
import { RestrictionDrawer } from './components/restriction-drawer';

export const RestrictionPage = () => {
  // Attributes
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDrawer();
  const [restrictionId, setRestrictionId] = useState<string>();

  const {
    // is false when fetching cached values
    isLoading,
    data: restrictions,
    // defaults to true so long as enabled is set to false
    // is true if the query is currently fetching, including background fetching
    isFetching,
    refetch,
  } = useImcclRestrictionAllGet();

  // Render
  return (
    <DashboardLayout>
      <Breadcrumb mb="2rem">
        <BreadcrumbItem>
          <BreadcrumbLink
            variant="breadcrumb"
            as={Link}
            to={getPath(Routes.DASHBOARD)}
          >
            {t(i18nKeys.common.home)}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink variant="breadcrumb" as={Link} to="#">
            {/* {t(i18nKeys.operator.restriction.title)} */}
            Restrictions
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex display={{ base: 'block', lg: 'flex' }} justify="space-between">
        <PageHeader
          size="Large"
          //title={t(i18nKeys.operator.restriction.title)}
          title="Restrictions"
          //introduction={t(i18nKeys.operator.restriction.introduction)}
          introduction="Liste des restrictions"
        />
        <Button
          mt={{ base: '2rem', lg: '0' }}
          type="button"
          variant="primary"
          size="body1"
          onClick={() => {
            setRestrictionId(undefined);
            onOpen();
          }}
        >
          {/* {t(i18nKeys.operator.restrictions.button.create)} */}
          Ajouter une restriction
        </Button>
      </Flex>
      <RestrictionTable
        restrictions={restrictions}
        open={onOpen}
        isFetching={isFetching}
        isLoading={isLoading}
        setRestrictionId={setRestrictionId}
      />
      {isOpen && (
        <RestrictionDrawer
          isOpen={isOpen}
          onClose={onClose}
          refetch={refetch}
          restrictionId={restrictionId}
        />
      )}
    </DashboardLayout>
  );
};
