/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { Role } from './role';
import type { Membership } from './membership';

export interface LoggedUser {
  id: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  language?: string;
  roles: Role[];
  memberships?: Membership[];
}
