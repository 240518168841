/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

/**
 * Only 1 restriction group can be applied at a time
 */
export interface CardUpdateRequest {
  mid_restriction_group_reference?: number;
  mcc_restriction_group_reference?: number;
}

// Je note par rapport
// Raler plus
// Remettre en cause les contraintes en questions (Ford Avec le plus identifié )
// Donner au PM une question =>  Méfiant par défault
// Aider les juniors à Grandir (Alessandro)
// -> Mentoring => Organiser des calls 
// -> Leadership

// Définir les obj
// Parce que je suis 