import { Button } from '@chakra-ui/button';
import { Box, Flex, List, ListItem, Text } from '@chakra-ui/layout';
import { useIcon } from '@libs/core/theme/utils';
import { useMultipleSelection, useSelect } from 'downshift';
import React, { useEffect } from 'react';
import { Tag } from '../tag';

type MultiSelectProps = {
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
  options: string[];
  closeMenuOnSelect?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any[];
  optionNames?: { name: string; id: string }[];
};

export const MultiSelect = ({
  setSelectedItems,
  options,
  closeMenuOnSelect = false,
  defaultValue = [],
  optionNames,
}: MultiSelectProps) => {
  const CloseIcon = useIcon('Close');

  const {
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
  } = useMultipleSelection({ initialSelectedItems: defaultValue });

  const getFilteredItems = () =>
    options.filter((item) => selectedItems.indexOf(item) < 0);

  const {
    closeMenu,
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    selectedItem: null,
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    items: getFilteredItems(),
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: !closeMenuOnSelect, // keep the menu open after selection.
          };
      }
      return changes;
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (selectedItem) {
            addSelectedItem(selectedItem);
          }
          break;
        default:
          break;
      }
    },
  });

  useEffect(() => {
    if (selectedItems.length === options.length) {
      closeMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeMenu, options.length, selectedItems.length]);

  useEffect(() => {
    setSelectedItems(selectedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  return (
    <Box position="relative">
      <Flex
        align="center"
        wrap="wrap"
        minH="4.8rem"
        maxH="6.8rem"
        overflow="auto"
        bgColor="ink.lighter"
        border=".1rem solid"
        borderColor="ink.light"
        pr="10"
        pl="3"
        cursor="pointer"
        {...getToggleButtonProps(
          getDropdownProps({ preventKeyAction: isOpen }),
        )}
      >
        {selectedItems.map((selectedItem) => {
          const selectedItemName =
            optionNames?.find(({ id }) => id === selectedItem)?.name ||
            selectedItem;
          return (
            <Tag
              key={selectedItem}
              h="3rem"
              p=".5rem .5rem .5rem 1rem"
              mx=".5rem"
              my=".8rem"
              backgroundColor="ink.white"
              _hover={{
                bgColor: 'ink.white',
              }}
              rightIcon={
                <Button
                  bgColor="ink.white"
                  border="none"
                  ml=".75rem"
                  p="0"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeSelectedItem(selectedItem);
                  }}
                  _hover={{ bgColor: 'ink.white' }}
                  _active={{ bgColor: 'ink.white' }}
                  fill="ink.dark"
                >
                  <CloseIcon width="1.5rem" height="1.5rem" />
                </Button>
              }
            >
              <Text size="Small" color="ink.dark">
                {selectedItemName}
              </Text>
            </Tag>
          );
        })}
      </Flex>

      <List
        {...getMenuProps()}
        position="absolute"
        zIndex="2000"
        w="100%"
        h="auto"
        maxH={'150px'}
        display={!isOpen ? 'none' : 'inline-block'}
        bgColor="ink.white"
        overflow="scroll"
        border=".1rem solid"
        borderColor="ink.lighter"
      >
        {isOpen &&
          getFilteredItems().map((item, index) => {
            const itemName =
              optionNames?.find(({ id }) => id === item)?.name || item;
            return (
              <ListItem
                key={item}
                cursor="pointer"
                lineHeight="10"
                py="2.5"
                px="5"
                bgColor={highlightedIndex === index ? 'ink.lighter' : ''}
                {...getItemProps({ item, index })}
              >
                {itemName}
              </ListItem>
            );
          })}
      </List>
    </Box>
  );
};

export default MultiSelect;
