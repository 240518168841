/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QuerySearchParameter } from './component-parameters/querySearchParameter';
import type { QueryLangParameter } from './component-parameters/queryLangParameter';

export type MembershipSearchProvidersGetParams = {
  /**
   * Search query parameter
   */
  search?: QuerySearchParameter;
  /**
   * Language query parameter
   */
  lang?: QueryLangParameter;
};
