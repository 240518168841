/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { Audit } from './audit';
import type { DocumentV1AllOf } from './documentV1AllOf';

export type DocumentV1 = Audit & DocumentV1AllOf;
