/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type ExpenseCategoryCode =
  typeof ExpenseCategoryCode[keyof typeof ExpenseCategoryCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseCategoryCode = {
  car: 'car',
  bike: 'bike',
  motor_scooter: 'motor_scooter',
  kick_scooter: 'kick_scooter',
  public_transport: 'public_transport',
  taxi: 'taxi',
  parking: 'parking',
  car_rent_or_lease_long_term: 'car_rent_or_lease_long_term',
  housing_interest: 'housing_interest',
  fuel_charging_parking_toll: 'fuel_charging_parking_toll',
  company_lease_bike: 'company_lease_bike',
  parking_at_station: 'parking_at_station',
  fuel_or_charging: 'fuel_or_charging',
  equipment_purchase: 'equipment_purchase',
  'e-vehicle_purchase': 'e-vehicle_purchase',
  'e-vehicle_leasing': 'e-vehicle_leasing',
  housing_rent: 'housing_rent',
  unknown: 'unknown',
} as const;
