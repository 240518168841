/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  CardTransactionsGetResponse,
  ErrorResponse,
  CardTransactionsGetParams,
} from '../../models';
import { customInstance } from '../../config';

export const cardTransactionsGet = (
  params?: CardTransactionsGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<CardTransactionsGetResponse>({
    url: `/web/cardTransactions`,
    method: 'get',
    params,
    signal,
  });
};

export const getCardTransactionsGetQueryKey = (
  params?: CardTransactionsGetParams,
) => [`/web/cardTransactions`, ...(params ? [params] : [])];

export type CardTransactionsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof cardTransactionsGet>>
>;
export type CardTransactionsGetQueryError = ErrorResponse;

export const useCardTransactionsGet = <
  TData = Awaited<ReturnType<typeof cardTransactionsGet>>,
  TError = ErrorResponse,
>(
  params?: CardTransactionsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof cardTransactionsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCardTransactionsGetQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof cardTransactionsGet>>
  > = ({ signal }) => cardTransactionsGet(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof cardTransactionsGet>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};
