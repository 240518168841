/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { ExpenseAllOf } from './expenseAllOf';
import type { Audit } from './audit';

export type Expense = ExpenseAllOf & Audit;
