/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type PersonalAssets = typeof PersonalAssets[keyof typeof PersonalAssets];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PersonalAssets = {
  less_three_hundred_twenty: 'less_three_hundred_twenty',
  less_hundred_thirthy: 'less_hundred_thirthy',
  less_two: 'less_two',
  less_four_hundred_sixty: 'less_four_hundred_sixty',
} as const;
