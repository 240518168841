/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { RecurringExpenseInterval } from './recurringExpenseInterval';

export interface ExpenseRuleCreateRequest {
  parent_expense_id: Uuid;
  /** The starting date from which the expense rule to start to be executed, creating expenses (repeated according with the interval attribute). */
  start_date?: string;
  /** The date on which the expense rule will stop being executed (no more expenses will be created). */
  end_date: string;
  interval?: RecurringExpenseInterval;
}
