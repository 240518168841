/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { BudgetType } from './component-common/budgetType';
import type { PaymentSource } from './paymentSource';
import type { ExpenseCategoryCode } from './expenseCategoryCode';
import type { ValidationStatus } from './validationStatus';
import type { PayrollStatus } from './payrollStatus';
import type { ExpenseV1AllOfType } from './expenseV1AllOfType';

export type ExpenseV1AllOf = {
  readonly id?: string;
  budget_id?: string;
  readonly budget_type?: BudgetType;
  readonly payment_source?: PaymentSource;
  category_code?: ExpenseCategoryCode;
  expense_code?: string;
  expense_occurred_at?: string;
  validation_status?: ValidationStatus;
  amount?: number;
  distance?: number;
  justification?: string;
  payroll_status?: PayrollStatus;
  provider_name?: string;
  validation_comment?: string;
  is_home_work?: boolean;
  title?: string;
  type?: ExpenseV1AllOfType;
  membership_id?: string;
};
