/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type ServiceCategoryNameKey =
  typeof ServiceCategoryNameKey[keyof typeof ServiceCategoryNameKey];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceCategoryNameKey = {
  bike: 'bike',
  car: 'car',
  equipment: 'equipment',
  infrastructure: 'infrastructure',
  leasing: 'leasing',
  long_distance: 'long_distance',
  micro_mobility: 'micro_mobility',
  private_chauffeur_service: 'private_chauffeur_service',
  public_transport: 'public_transport',
  renting: 'renting',
  shared_mobility: 'shared_mobility',
  tax_free_allowance: 'tax_free_allowance',
} as const;
