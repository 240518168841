import { Button } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { Flex } from '@chakra-ui/layout';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { usePager } from '@libs/ui/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BudgetCreateDrawer } from './budget-create-drawer';
import { BudgetTable } from './budget-table';
import { useMembershipSummariesGet } from '@libs/api-v2/endpoints';
import { MembershipSummariesTable } from './membership-summaries-table';
import { TopupTable } from './topup-table';
import { Registration } from '@libs/api-v2/models';

type BudgetsTableProps = {
  employee: Registration;
  isOldGen: boolean;
};

export const EmployeeDetailsBudgets = ({
  employee,
  isOldGen,
}: BudgetsTableProps) => {
  const { pageNumber, pageSize, setPageNumber, setPageSize } = usePager();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const {
    isLoading: isLoadingSummary,
    isFetching: isFetchingSummary,
    refetch: refetchSummary,
    data: MembershipSummaries,
  } = useMembershipSummariesGet(employee.membership_id, null, {
    query: { keepPreviousData: true },
  });

  const params = {
    membership_id: employee.membership_id,
    page_number: pageNumber,
    page_size: pageSize,
    // TODO enable sort & filters when endpoints are updated
    // sort: currentSortQuery.length > 0 ? currentSortQuery.toString() : null,
    // is_carry_over_enabled:
    //   (filterQuery.length > 0 &&
    //     filterQuery.find('is_carry_over_enabled').split(':')[1]) ||
    //   null,
    // type:
    //   (filterQuery.length > 0 && filterQuery.find('type').split(':')[1]) ||
    //   null,
  };

  return (
    <Flex direction="column">
      {isOldGen ? (
        <>
          <Button
            variant="primary"
            size="body1"
            alignSelf="flex-end"
            mt="1rem"
            onClick={onOpen}
          >
            {t(i18nKeys.operator.employees.budgets.add_budget)}
          </Button>
          <BudgetTable
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
            budgetsGetParams={params}
            employee={employee}
          />
          {isOpen && (
            <BudgetCreateDrawer
              isOpen={isOpen}
              onClose={onClose}
              employee={employee}
              budgetsGetParams={params}
            />
          )}
        </>
      ) : (
        <Flex width={'full'} direction="column">
          <MembershipSummariesTable
            isLoading={isLoadingSummary}
            isFetching={isFetchingSummary}
            membershipSummaries={MembershipSummaries}
            refetch={refetchSummary}
          />
          <TopupTable
            membershipId={employee?.membership_id}
            includeCanceled={false}
          />
        </Flex>
      )}
    </Flex>
  );
};
