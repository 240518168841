/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Email } from './component-common/email';
import type { Address } from './address';
import type { Phone } from './component-common/phone';
import type { CountryCode } from './component-common/countryCode';
import type { Gender } from './component-common/gender';
import type { MembershipRole } from './membershipRole';
import type { UseCard } from './useCard';
import type { Language } from './component-common/language';

export interface RegistrationCreateRequest {
  organisation_id: string;
  email: Email;
  address?: Address;
  first_name: string;
  last_name: string;
  phone_number?: Phone;
  phone_number_country_code?: CountryCode;
  gender: Gender;
  date_of_birth?: string;
  nationality?: CountryCode;
  place_of_birth?: string;
  country_of_birth?: CountryCode;
  start_on?: string;
  role?: MembershipRole;
  job_title?: string;
  group_id: string;
  use_card?: UseCard;
  internal_payroll_id?: string;
  language?: Language;
}
