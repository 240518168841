/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type IncomeRange = typeof IncomeRange[keyof typeof IncomeRange];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IncomeRange = {
  less_twenty_five: 'less_twenty_five',
  less_fifty_five: 'less_fifty_five',
  over_fifty_five: 'over_fifty_five',
} as const;
