/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type RestrictionGroupStatus =
  typeof RestrictionGroupStatus[keyof typeof RestrictionGroupStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RestrictionGroupStatus = {
  VALIDATED: 'VALIDATED',
  PENDING: 'PENDING',
  CANCELED: 'CANCELED',
} as const;
