/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { MerchantRestrictionGroup } from './merchantRestrictionGroup';
import type { MetaV1 } from './metaV1';

export interface MerchantRestrictionGroupsGetResponse {
  data?: MerchantRestrictionGroup[];
  meta?: MetaV1;
}
