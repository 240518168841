/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

/**
 * REPORTED: Expense need to be reviewed PENDING: Waiting for payment if the expense is approved or refused it should be moved to pending. PAID: Expense has been paid by org/employee/skipr/provider
 */
export type ExpenseRefundStatus =
  typeof ExpenseRefundStatus[keyof typeof ExpenseRefundStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseRefundStatus = {
  NONE: 'NONE',
  REPORTED: 'REPORTED',
  PENDING: 'PENDING',
  PAID: 'PAID',
  CANCELED: 'CANCELED',
} as const;
