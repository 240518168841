import { Checkbox, Text } from '@chakra-ui/react';
import { Organisation } from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { capitalize } from '@libs/core/utils';
import { Pager, Table, usePager } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useDrawer, useTable } from '@libs/ui/components/table/table.utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGroupsGet } from '@libs/api-v2/endpoints/groups/groups';
import { useIcon } from '@libs/core/theme/utils';
import { GroupMembersDrawer } from '../../employee/details/components/group-members-drawer';

type OrganisationDetailsGroupsProps = {
  organisation?: Organisation;
};

export const GroupsTable = ({
  organisation,
}: OrganisationDetailsGroupsProps) => {
  const { t } = useTranslation();
  const { pageNumber, pageSize, setPageNumber, setPageSize } = usePager();
  const { setSearchQuery, useQueryParams } = useTable({
    setPageNumber,
    setPageSize,
  });

  const submitHandler = ({ q }) => {
    setSearchQuery(q);
  };

  const EyeIcon = useIcon('Eye');
  const { drawerHandler, isOpen, onClose, activeDrawerIndex } = useDrawer();

  const {
    // is false when fetching cached values
    isLoading,
    refetch,
    data: groups,
    // defaults to true so long as enabled is set to false
    // is true if the query is currently fetching, including background fetching
    isFetching,
  } = useGroupsGet(
    {
      organisation_id: organisation ? organisation.id : null,
      page_number: pageNumber,
      page_size: pageSize,
    },
    { query: { keepPreviousData: true } },
  );

  useQueryParams({
    pageSize,
    pageNumber,
    setPageSize,
    refetch,
    totalPages: groups?.meta.total_pages,
  });

  const headers: TableHeaders = [
    {
      name: 'title',
      title: t(i18nKeys.common.group),
    },
    {
      name: 'description',
      title: t(i18nKeys.common.groups.description),
    },
    { name: 'default_group', title: t(i18nKeys.common.groups.is_default) },
  ];

  return (
    <>
      <Table onSubmit={submitHandler} headers={headers}>
        <Table.TBody
          isLoading={(isLoading || isFetching) && !groups}
          colSpan={headers.length}
          isEmpty={
            !isLoading && !isFetching && (groups?.data.length === 0 || !groups)
          }
        >
          {groups?.data.map(
            ({ id, name, description, default_group }, index) => (
              <Table.TR key={id} selectable index={id}>
                <Table.TD>
                  <Text size="Body2">{capitalize(name)} </Text>
                </Table.TD>
                <Table.TD>
                  <Text>{name}</Text>
                </Table.TD>
                <Table.TD>
                  <Text>{description}</Text>
                </Table.TD>
                <Table.TD>
                  <Checkbox isChecked={default_group} isDisabled={true} />
                </Table.TD>
                <Table.Action
                  actions={[
                    {
                      onClick: () => drawerHandler(index),
                      icon: <EyeIcon width="2rem" height="2rem" />,
                    },
                  ]}
                />
              </Table.TR>
            ),
          )}
        </Table.TBody>
      </Table>
      {groups && groups?.meta.total_pages > 1 && (
        <Pager
          setPageNumber={setPageNumber}
          total_items={groups.meta.total_items}
          page_index={groups.meta.page_index}
          total_pages={groups.meta.total_pages}
          mt="2rem"
          page_size={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          isFetching={isFetching}
        />
      )}
      {isOpen && (
        <GroupMembersDrawer
          group={groups.data[activeDrawerIndex]}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
};
