/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { OrganisationStatistics } from './organisationStatistics';

export interface OrganisationsStatisticsGetResponse {
  data?: OrganisationStatistics;
}
