import merchant_category_codes_json from './mcc.json';

export type MerchantCategoryCode = {
  mcc: string;
  edited_description: string;
  combined_description: string;
  usda_description: string;
  irs_description: string;
  irs_reportable: string;
  id: number;
};
export const getMerchantCategoryCodes = () =>
  new Map(merchant_category_codes_json.map((entry) => [entry.mcc, entry]));
