/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { TripType } from './tripType';

export type TripCreateRequestTripConfiguration = {
  trip_type_to?: TripType;
  trip_type_back?: TripType;
};
