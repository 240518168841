/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  GroupsGetResponse,
  GroupsGetParams,
  Group,
  GroupCreateRequest,
  GroupUpdateRequest,
  RegistrationsWithoutMetaGetResponse,
  Error404NotFoundResponse,
  GroupMemberUpdateRequest,
  ProgramsGetResponse,
  GroupProgramsGetParams,
} from '../../models';
import type { ErrorResponse, Error } from '../../models/component-common';
import { customInstance } from '../../config';

export const groupsGet = (params?: GroupsGetParams, signal?: AbortSignal) => {
  return customInstance<GroupsGetResponse>({
    url: `/web/v2/groups`,
    method: 'get',
    params,
    signal,
  });
};

export const getGroupsGetQueryKey = (params?: GroupsGetParams) =>
  [`/web/v2/groups`, ...(params ? [params] : [])] as const;

export const getGroupsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof groupsGet>>,
  TError = ErrorResponse,
>(
  params?: GroupsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof groupsGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<Awaited<ReturnType<typeof groupsGet>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupsGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupsGet>>> = ({
    signal,
  }) => groupsGet(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GroupsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof groupsGet>>
>;
export type GroupsGetQueryError = ErrorResponse;

export const useGroupsGet = <
  TData = Awaited<ReturnType<typeof groupsGet>>,
  TError = ErrorResponse,
>(
  params?: GroupsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof groupsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGroupsGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const groupsCreate = (groupCreateRequest: GroupCreateRequest) => {
  return customInstance<Group>({
    url: `/web/v2/groups`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: groupCreateRequest,
  });
};

export const getGroupsCreateMutationOptions = <
  TError = ErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupsCreate>>,
    TError,
    { data: GroupCreateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof groupsCreate>>,
  TError,
  { data: GroupCreateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof groupsCreate>>,
    { data: GroupCreateRequest }
  > = (props) => {
    const { data } = props ?? {};

    return groupsCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type GroupsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof groupsCreate>>
>;
export type GroupsCreateMutationBody = GroupCreateRequest;
export type GroupsCreateMutationError = ErrorResponse;

export const useGroupsCreate = <
  TError = ErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupsCreate>>,
    TError,
    { data: GroupCreateRequest },
    TContext
  >;
}) => {
  const mutationOptions = getGroupsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const groupPut = (
  groupId: string,
  groupUpdateRequest: GroupUpdateRequest,
) => {
  return customInstance<Group>({
    url: `/web/v2/groups/${groupId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: groupUpdateRequest,
  });
};

export const getGroupPutMutationOptions = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupPut>>,
    TError,
    { groupId: string; data: GroupUpdateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof groupPut>>,
  TError,
  { groupId: string; data: GroupUpdateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof groupPut>>,
    { groupId: string; data: GroupUpdateRequest }
  > = (props) => {
    const { groupId, data } = props ?? {};

    return groupPut(groupId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type GroupPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof groupPut>>
>;
export type GroupPutMutationBody = GroupUpdateRequest;
export type GroupPutMutationError = Error;

export const useGroupPut = <TError = Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupPut>>,
    TError,
    { groupId: string; data: GroupUpdateRequest },
    TContext
  >;
}) => {
  const mutationOptions = getGroupPutMutationOptions(options);

  return useMutation(mutationOptions);
};
export const groupDelete = (groupId: string) => {
  return customInstance<void>({
    url: `/web/v2/groups/${groupId}`,
    method: 'delete',
  });
};

export const getGroupDeleteMutationOptions = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupDelete>>,
    TError,
    { groupId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof groupDelete>>,
  TError,
  { groupId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof groupDelete>>,
    { groupId: string }
  > = (props) => {
    const { groupId } = props ?? {};

    return groupDelete(groupId);
  };

  return { mutationFn, ...mutationOptions };
};

export type GroupDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof groupDelete>>
>;

export type GroupDeleteMutationError = Error;

export const useGroupDelete = <TError = Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupDelete>>,
    TError,
    { groupId: string },
    TContext
  >;
}) => {
  const mutationOptions = getGroupDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Fetch all members for a specific group.

 */
export const groupMembersGet = (groupId: string, signal?: AbortSignal) => {
  return customInstance<RegistrationsWithoutMetaGetResponse>({
    url: `/web/v2/groups/${groupId}/members`,
    method: 'get',
    signal,
  });
};

export const getGroupMembersGetQueryKey = (groupId: string) =>
  [`/web/v2/groups/${groupId}/members`] as const;

export const getGroupMembersGetQueryOptions = <
  TData = Awaited<ReturnType<typeof groupMembersGet>>,
  TError = ErrorResponse,
>(
  groupId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof groupMembersGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof groupMembersGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGroupMembersGetQueryKey(groupId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupMembersGet>>> = ({
    signal,
  }) => groupMembersGet(groupId, signal);

  return { queryKey, queryFn, enabled: !!groupId, ...queryOptions };
};

export type GroupMembersGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof groupMembersGet>>
>;
export type GroupMembersGetQueryError = ErrorResponse;

export const useGroupMembersGet = <
  TData = Awaited<ReturnType<typeof groupMembersGet>>,
  TError = ErrorResponse,
>(
  groupId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof groupMembersGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGroupMembersGetQueryOptions(groupId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const groupMemberPost = (
  groupId: string,
  membershipId: string,
  groupMemberUpdateRequest: GroupMemberUpdateRequest,
) => {
  return customInstance<void>({
    url: `/web/v2/groups/${groupId}/members/${membershipId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: groupMemberUpdateRequest,
  });
};

export const getGroupMemberPostMutationOptions = <
  TError = Error | Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupMemberPost>>,
    TError,
    { groupId: string; membershipId: string; data: GroupMemberUpdateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof groupMemberPost>>,
  TError,
  { groupId: string; membershipId: string; data: GroupMemberUpdateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof groupMemberPost>>,
    { groupId: string; membershipId: string; data: GroupMemberUpdateRequest }
  > = (props) => {
    const { groupId, membershipId, data } = props ?? {};

    return groupMemberPost(groupId, membershipId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type GroupMemberPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof groupMemberPost>>
>;
export type GroupMemberPostMutationBody = GroupMemberUpdateRequest;
export type GroupMemberPostMutationError = Error | Error404NotFoundResponse;

export const useGroupMemberPost = <
  TError = Error | Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupMemberPost>>,
    TError,
    { groupId: string; membershipId: string; data: GroupMemberUpdateRequest },
    TContext
  >;
}) => {
  const mutationOptions = getGroupMemberPostMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Remove member from group.

 */
export const groupMemberDelete = (groupId: string, membershipId: string) => {
  return customInstance<void>({
    url: `/web/v2/groups/${groupId}/members/${membershipId}`,
    method: 'delete',
  });
};

export const getGroupMemberDeleteMutationOptions = <
  TError = ErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupMemberDelete>>,
    TError,
    { groupId: string; membershipId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof groupMemberDelete>>,
  TError,
  { groupId: string; membershipId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof groupMemberDelete>>,
    { groupId: string; membershipId: string }
  > = (props) => {
    const { groupId, membershipId } = props ?? {};

    return groupMemberDelete(groupId, membershipId);
  };

  return { mutationFn, ...mutationOptions };
};

export type GroupMemberDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof groupMemberDelete>>
>;

export type GroupMemberDeleteMutationError = ErrorResponse;

export const useGroupMemberDelete = <
  TError = ErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupMemberDelete>>,
    TError,
    { groupId: string; membershipId: string },
    TContext
  >;
}) => {
  const mutationOptions = getGroupMemberDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Fetch all programs applied to a group.

 */
export const groupProgramsGet = (
  groupId: string,
  params?: GroupProgramsGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<ProgramsGetResponse>({
    url: `/web/v2/groups/${groupId}/programs`,
    method: 'get',
    params,
    signal,
  });
};

export const getGroupProgramsGetQueryKey = (
  groupId: string,
  params?: GroupProgramsGetParams,
) =>
  [`/web/v2/groups/${groupId}/programs`, ...(params ? [params] : [])] as const;

export const getGroupProgramsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof groupProgramsGet>>,
  TError = ErrorResponse,
>(
  groupId: string,
  params?: GroupProgramsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof groupProgramsGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof groupProgramsGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGroupProgramsGetQueryKey(groupId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof groupProgramsGet>>
  > = ({ signal }) => groupProgramsGet(groupId, params, signal);

  return { queryKey, queryFn, enabled: !!groupId, ...queryOptions };
};

export type GroupProgramsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof groupProgramsGet>>
>;
export type GroupProgramsGetQueryError = ErrorResponse;

export const useGroupProgramsGet = <
  TData = Awaited<ReturnType<typeof groupProgramsGet>>,
  TError = ErrorResponse,
>(
  groupId: string,
  params?: GroupProgramsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof groupProgramsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGroupProgramsGetQueryOptions(
    groupId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
