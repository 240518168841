/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { AddressV1 } from './addressV1';
import type { Gender } from './component-common/gender';
import type { RegistrationRequestCreateRole } from './registrationRequestCreateRole';

export interface RegistrationRequestCreate {
  organisation_id?: string;
  email?: string;
  address?: AddressV1;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  phone_number_country_code?: string;
  gender?: Gender;
  date_of_birth?: string;
  nationality?: string;
  place_of_birth?: string;
  country_of_birth?: string;
  start_on?: string;
  role?: RegistrationRequestCreateRole;
  job_title?: string;
}
