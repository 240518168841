import { getQueryParam } from '@libs/core/utils';
import React, { useCallback } from 'react';
export interface UseWizard {
  activeStepIndex: number;
  activeHash: string;
  goToStep: (stepIndex: number) => void;
  nextStep: () => void;
  previousStep: () => void;
  steps: UseWizardStep[];
  register: (index: number, step: UseWizardStep) => void;
  unregister: (stepId: string) => void;
}

export type OnChangeHandler = (props: {
  newStepIndex: number;
  previousStepIndex: number;
}) => void;

type GoToStep = (stepIndex: number) => void;

export const WizardContext = React.createContext<UseWizard | null>(null);

export interface UseWizardStep {
  id: string;
  label?: string;
  hash?: string;
  isHidden?: boolean;
  time?: string;
}
export interface UseWizardProps {
  initialStepIndex?: number;
  initialHash?: string;
  onChange?: OnChangeHandler;
  useQueryParams?: boolean;
}

export const useWizardProvider = ({
  initialStepIndex = 0,
  initialHash,
  onChange,
  useQueryParams = false,
}: UseWizardProps) => {
  const [activeStepIndex, setActiveStepIndex] = React.useState(null);
  const [steps, setSteps] = React.useState<UseWizardStep[]>([]);
  const [activeHash, setActiveHash] = React.useState<string>(null);

  const stepQueryParam = getQueryParam('step');

  const changeHash = useCallback(
    (step: UseWizardStep) => {
      if (useQueryParams) {
        // window.location.search = `?step=${step.hash}`;
      } else {
        window.location.hash = step.hash;
      }
    },
    [useQueryParams],
  );

  React.useEffect(() => {
    if (steps.length) {
      if (initialHash) {
        const stepIndex = steps.findIndex(({ hash }) => hash === initialHash);
        setActiveStepIndex(stepIndex !== -1 ? stepIndex : 0);
        return;
      }
      const stepIndex = steps.findIndex(({ hash }) => {
        if (useQueryParams) {
          return window.location.search && hash === stepQueryParam;
        }

        return window.location.hash && hash === window.location.hash.slice(1);
      });

      if (stepIndex < 0) {
        if (typeof activeStepIndex === 'number') {
          const step = steps[activeStepIndex];
          setActiveHash(step.hash);
          changeHash(step);
          return;
        }

        setActiveStepIndex(initialStepIndex);
        return;
      }

      if (onChange) {
        onChange({
          previousStepIndex: initialStepIndex,
          newStepIndex: stepIndex,
        });
      }

      setActiveStepIndex(stepIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps.length]);

  React.useEffect(() => {
    const step = steps.find((_, index) => index === activeStepIndex);

    if (!step) {
      return;
    }
    setActiveHash(step.hash);
    changeHash(step);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStepIndex]);

  const goToStep: GoToStep = React.useCallback(
    (stepIndex: number) => {
      if (activeStepIndex !== stepIndex) {
        if (onChange) {
          onChange({
            previousStepIndex: activeStepIndex,
            newStepIndex: stepIndex,
          });
        }
        setActiveStepIndex(stepIndex);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeStepIndex, setActiveStepIndex],
  );

  const nextStep = React.useCallback(() => {
    setActiveStepIndex((index) => index + 1);
  }, [setActiveStepIndex]);

  const previousStep = React.useCallback(() => {
    setActiveStepIndex((index) => index - 1);
  }, [setActiveStepIndex]);

  const register = React.useCallback(
    (stepIndex: number, step: UseWizardStep) => {
      setSteps((currentSteps) => {
        if (!currentSteps[stepIndex]) {
          return [...currentSteps, step];
        }

        return currentSteps.reduce(
          (acc, currentStep, currentStepIndex) =>
            stepIndex === currentStepIndex
              ? [...acc, step, currentStep]
              : [...acc, currentStep],
          [],
        );
      });
    },
    [setSteps],
  );

  const unregister = React.useCallback((stepId: string) => {
    setSteps((currentSteps) => currentSteps.filter(({ id }) => id !== stepId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    activeStepIndex,
    activeHash,
    goToStep,
    nextStep,
    previousStep,
    steps,
    register,
    unregister,
  };
};

export const useWizard = (): UseWizard => {
  const wizardContext = React.useContext(WizardContext);

  if (wizardContext === null) {
    throw new Error(
      'Wizard Step must be used as a child within a Wizard Component',
    );
  }

  return wizardContext;
};
