/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { MembershipV1Role } from './membershipV1Role';

export interface MembershipV1 {
  id?: string;
  role?: MembershipV1Role;
  organisation_id?: string;
}
