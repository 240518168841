import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Switch } from '@chakra-ui/react';
import { Button } from '@chakra-ui/button';
import { Box, Text } from '@chakra-ui/layout';
import { useRegistrationMembershipResetPost } from '@libs/api-v2/endpoints';
import { QueryStatus } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Drawer } from '@libs/ui/components';
import { Registration } from '@libs/api-v2/models';
import { QueryStatusText } from '../../../components/query-status-text';

type ResetAccountDrawerProps = {
  employee: Registration;
  isOpen: boolean;
  onClose: () => void;
  refetch: any;
};

export const ResetAccountDrawer = ({
  employee,
  isOpen,
  onClose,
  refetch,
}: ResetAccountDrawerProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [shouldSendInvite, setShouldSendInvite] = useState(false);

  const { mutateAsync: resetAccount, status } =
    useRegistrationMembershipResetPost({
      mutation: {
        onError: (error: any) => {
          setError(error.translation?.key || error.detail);
        },
        onSuccess: () => {
          refetch();
          setError(null);
        },
      },
    });

  const handleResetAccount = async () => {
    await resetAccount({
      membershipId: employee.membership_id,
      data: {
        send_invite: shouldSendInvite,
      },
    });
    setShouldSendInvite(false);
  };

  const statusText = {
    [QueryStatus.loading]: `Resetting ${
      shouldSendInvite ? 'and inviting' : ''
    } account...`,
    [QueryStatus.error]: t(i18nKeys.common.errors.http_error),
    [QueryStatus.success]: t(i18nKeys.common.success),
  };

  return (
    <Drawer title="Reset account" isOpen={isOpen} onClose={onClose} size="lg">
      <Drawer.Body>
        <Flex direction={'column'} gap={'2rem'}>
          <Text size="Body2" color="ink.dark" mt="2rem">
            - Reset account is mainly used to fix SSO loops, it will put the
            user back to a "CREATED" state.
            <br />- The user also needs to reactivate their account by following
            the invite flow with the latest invite e-mail.
          </Text>
          <Flex alignItems={'center'} gap={'1rem'}>
            <Text size="Body2" color="ink.dark">
              Also send an invite?
            </Text>
            <Switch
              isChecked={shouldSendInvite}
              onChange={(e) => {
                setShouldSendInvite(e.target.checked);
              }}
            />
          </Flex>
          <Box>
            <Button
              variant="primary"
              size="body1"
              onClick={handleResetAccount}
              isLoading={status === QueryStatus.loading}
              disabled={employee.is_suspended}
            >
              Reset account {shouldSendInvite ? 'and send invite' : ''}
            </Button>
            <QueryStatusText
              error={error}
              status={status}
              statusText={statusText}
            />
          </Box>
        </Flex>
      </Drawer.Body>
    </Drawer>
  );
};
