/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryFeaturedProvidersParameter } from './component-parameters/queryFeaturedProvidersParameter';
import type { QueryPaymentMethodsParameter } from './queryPaymentMethodsParameter';
import type { QueryProviderIdParameter } from './component-parameters/queryProviderIdParameter';
import type { QueryProgramIdParameter } from './component-parameters/queryProgramIdParameter';
import type { QueryQParameter } from './component-parameters/queryQParameter';

export type MembershipServicesGetParams = {
  /**
   * flag to query featured providers only
   */
  featured_providers?: QueryFeaturedProvidersParameter;
  /**
   * Repeat "payment_method" param multiple times to include multiple payment methods
   */
  payment_method?: QueryPaymentMethodsParameter;
  /**
   * ID of the provider
   */
  provider_id?: QueryProviderIdParameter;
  /**
   * ID of the program
   */
  program_id?: QueryProgramIdParameter;
  /**
   * Search query parameter
   */
  q?: QueryQParameter;
};
