/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { AnnualTurnOver } from './annualTurnOver';
import type { NetIncomeRange } from './netIncomeRange';
import type { NumberOfEmployeeRange } from './numberOfEmployeeRange';

export interface Legal {
  form?: string;
  registration_date?: string;
  readonly sector?: string;
  annual_turn_over?: AnnualTurnOver;
  net_income_range?: NetIncomeRange;
  number_of_employees_range?: NumberOfEmployeeRange;
}
