/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { ExpenseRefundStatus } from './expenseRefundStatus';

/**
 * refund statuses of an expense
 */
export type RefundStatusesParameter = ExpenseRefundStatus[];
