import { Button } from '@chakra-ui/button';
import { Box } from '@chakra-ui/layout';
import {
  useMerchantCategoryRestrictionGroupsGet,
  useMerchantIdRestrictionGroupsGet,
} from '@libs/api/endpoints';
import {
  cardsGet,
  getCardsGetQueryKey,
  useCardUpdate,
  useImcclRestrictionAllGet,
} from '@libs/api-v2/endpoints';
import { CardsGetParams, CardUpdateRequest } from '@libs/api-v2/models';
import { QueryStatus } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { capitalize } from '@libs/core/utils';
import { Drawer, SelectField } from '@libs/ui/components';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { QueryStatusText } from '../../../components/query-status-text';
import { CardDrawerProps } from '../../employee.types';

type CardEditDrawerProps = CardDrawerProps & {
  cardsGetQueryParams: CardsGetParams;
  hasImccl: boolean;
  imcclRestrictionId: string;
};

export const CardEditDrawer = ({
  card,
  isOpen,
  onClose,
  cardsGetQueryParams,
  hasImccl,
  imcclRestrictionId,
}: CardEditDrawerProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const queryClient = useQueryClient();

  const params = {
    page_size: 100,
  };
  const { data: midGroups } = useMerchantIdRestrictionGroupsGet(params);
  const { data: mccGroups } = useMerchantCategoryRestrictionGroupsGet(params);
  const { data: imcclGroups } = useImcclRestrictionAllGet();

  const successHandler = () => {
    const queryKey = getCardsGetQueryKey(cardsGetQueryParams);
    queryClient.invalidateQueries(queryKey);
    queryClient.fetchQuery(queryKey, () => cardsGet(cardsGetQueryParams));
    setError(null);
  };

  const { mutateAsync: cardUpdate, status } = useCardUpdate({
    mutation: {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        setError(error);
      },
      onSuccess: successHandler,
    },
  });

  const submitHandler = (values: CardUpdateRequest) => {
    // Make sure to parse values as int
    values.mcc_restriction_group_reference = parseInt(
      String(values.mcc_restriction_group_reference),
      10,
    );
    values.mid_restriction_group_reference = parseInt(
      String(values.mid_restriction_group_reference),
      10,
    );

    cardUpdate({ cardId: card.id, data: values });
  };

  const statusText = {
    [QueryStatus.loading]: t(i18nKeys.operator.cards.edit.status.loading),
    [QueryStatus.error]: t(i18nKeys.operator.cards.edit.status.error),
    [QueryStatus.success]: t(i18nKeys.operator.cards.edit.status.success),
  };

  return (
    <Drawer
      title={t(i18nKeys.operator.cards.edit.title)}
      isOpen={isOpen}
      onClose={onClose}
      size="xxl"
    >
      <Drawer.Body>
        <Box height={600} overflow={'scroll'}>
          <Formik<CardUpdateRequest>
            onSubmit={submitHandler}
            initialValues={{
              mcc_restriction_group_reference:
                card.mcc_restriction_group_reference,
              mid_restriction_group_reference:
                card.mid_restriction_group_reference,
            }}
          >
            {({ isValid, dirty }) => (
              <Form>
                {(!hasImccl || !!card.mcc_restriction_group_reference) && (
                  <SelectField
                    name="mcc_restriction_group_reference"
                    label={t(i18nKeys.operator.cards.mcc_restriction_group)}
                  >
                    {/* empty? */}
                    <option key={0} value={0} />
                    {mccGroups?.data.map((mccGroup) => (
                      <option
                        key={mccGroup.reference}
                        value={mccGroup.reference}
                      >
                        {capitalize(mccGroup.name)}
                      </option>
                    ))}
                  </SelectField>
                )}
                {(!hasImccl || !!card.mid_restriction_group_reference) && (
                  <SelectField
                    name="mid_restriction_group_reference"
                    label={t(i18nKeys.operator.cards.mid_restriction_group)}
                  >
                    {/* empty? */}
                    <option key={0} value={0} />
                    {midGroups?.data.map((midGroup) => (
                      <option
                        key={midGroup.reference}
                        value={midGroup.reference}
                      >
                        {capitalize(midGroup.name)}
                      </option>
                    ))}
                  </SelectField>
                )}
                <SelectField
                  name="imccl_restriction_id"
                  label={'Imccl restriction'}
                  isDisabled={true}
                >
                  {imcclGroups?.restrictions.map((imcclGroup) => (
                    <option key={imcclGroup.id} value={imcclGroup.id}>
                      {capitalize(imcclGroup.name)}
                    </option>
                  ))}
                </SelectField>
                <Button
                  type="submit"
                  variant="primary"
                  size="body2"
                  mt="2rem"
                  mr="2rem"
                  disabled={!isValid || !dirty}
                  isLoading={status === QueryStatus.loading}
                  loadingText={t(i18nKeys.common.submit)}
                >
                  {t(i18nKeys.common.submit)}
                </Button>
                <QueryStatusText
                  error={error}
                  status={status}
                  statusText={statusText}
                />
              </Form>
            )}
          </Formik>
        </Box>
      </Drawer.Body>
    </Drawer>
  );
};
