import { Box, InputRightElement, useTheme } from '@chakra-ui/react';
import { useIcon } from '@libs/core/theme/utils';
import { useField, useFormikContext } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { FormControl } from '../components/form-control';
import {
  InputBar,
  InputButton,
  InputHighLight,
  InputStyledField,
} from '../form.style';
import { BaseProps } from '../form.types';

export type SearchFieldProps = BaseProps & {
  inputRightElement?: React.ReactNode;
  setFieldValue: (
    field: string,
    value: string,
    shouldValidate?: boolean,
  ) => void;
  dirty: boolean;
};

export const SearchField: FC<SearchFieldProps> = (props: SearchFieldProps) => {
  const {
    name,
    placeholder,
    inputRightElement,
    setFieldValue,
    dirty,
    ...formControlProps
  } = props;
  const [field, meta] = useField(name);
  const [showClearSearchButton, toggleClearSearchButton] =
    useState<boolean>(false);
  const { submitForm } = useFormikContext();
  const Search = useIcon('Search');
  const ClearSearch = useIcon('Close');
  const theme = useTheme();

  useEffect(() => {
    if (field.value?.length > 0) {
      toggleClearSearchButton(dirty);
    }
  }, [dirty, field.value]);

  return (
    <FormControl
      id={name}
      meta={meta}
      isEmpty={!field.value}
      {...formControlProps}
    >
      <InputStyledField
        {...field}
        placeholder={placeholder}
        value={field.value || ''}
        type="text"
      />
      <InputRightElement
        children={
          <Box>
            {showClearSearchButton && (
              <InputButton
                onClick={() => {
                  setFieldValue(name, '');
                  toggleClearSearchButton(false);
                  submitForm();
                }}
                m="0"
                p="0"
                variant="transparant"
              >
                <Box
                  width="2.4rem"
                  height="2.4rem"
                  bgColor={theme.colors.ink.light}
                  borderRadius="full"
                  pl=".2rem"
                  pt=".2rem"
                >
                  <ClearSearch fill={theme.colors.ink.white} />
                </Box>
              </InputButton>
            )}
            <InputButton
              type="submit"
              m="0"
              p="0"
              variant="transparant"
              onClick={() => submitForm()}
            >
              <Search />
            </InputButton>
          </Box>
        }
      />
      <InputHighLight />
      <InputBar />
    </FormControl>
  );
};
