/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { FrontendConfigurationEnvironmentVariables } from './frontendConfigurationEnvironmentVariables';

export interface FrontendConfiguration {
  support_email: string;
  support_url: string;
  environment_variables: FrontendConfigurationEnvironmentVariables;
}
