/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { Service } from './service';
import type { PolicyCondition } from './policyCondition';

export interface PolicyConflict {
  policy_id?: Uuid;
  services?: Service[];
  condition?: PolicyCondition;
}
