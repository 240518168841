/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type OrganisationScope =
  typeof OrganisationScope[keyof typeof OrganisationScope];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganisationScope = {
  NEXTGEN: 'NEXTGEN',
  OLDGEN: 'OLDGEN',
  SMARTMOVE: 'SMARTMOVE',
} as const;
