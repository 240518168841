/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  PaymentMethodBeneficiariesResponse,
  BlockPaymentMethodMandateParams,
  PaymentMethodFailedPaymentsResponse,
} from '../../models';
import { customInstance } from '../../config';

/**
 * Lists known beneficiaries of a payment method.
This is applicable to DIRECT_DEBIT payment method

 */
export const getPaymentMethodBeneficiaries = (
  paymentMethodId: string,
  signal?: AbortSignal,
) => {
  return customInstance<PaymentMethodBeneficiariesResponse>({
    url: `/web/v2/payment_methods/${paymentMethodId}/beneficiaries`,
    method: 'get',
    signal,
  });
};

export const getGetPaymentMethodBeneficiariesQueryKey = (
  paymentMethodId: string,
) => [`/web/v2/payment_methods/${paymentMethodId}/beneficiaries`] as const;

export const getGetPaymentMethodBeneficiariesQueryOptions = <
  TData = Awaited<ReturnType<typeof getPaymentMethodBeneficiaries>>,
  TError = unknown,
>(
  paymentMethodId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPaymentMethodBeneficiaries>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPaymentMethodBeneficiaries>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPaymentMethodBeneficiariesQueryKey(paymentMethodId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPaymentMethodBeneficiaries>>
  > = ({ signal }) => getPaymentMethodBeneficiaries(paymentMethodId, signal);

  return { queryKey, queryFn, enabled: !!paymentMethodId, ...queryOptions };
};

export type GetPaymentMethodBeneficiariesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPaymentMethodBeneficiaries>>
>;
export type GetPaymentMethodBeneficiariesQueryError = unknown;

export const useGetPaymentMethodBeneficiaries = <
  TData = Awaited<ReturnType<typeof getPaymentMethodBeneficiaries>>,
  TError = unknown,
>(
  paymentMethodId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPaymentMethodBeneficiaries>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPaymentMethodBeneficiariesQueryOptions(
    paymentMethodId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Block mandate to prevent iban payments

 */
export const blockPaymentMethodMandate = (
  paymentMethodId: string,
  beneficiaryId: string,
  params: BlockPaymentMethodMandateParams,
) => {
  return customInstance<void>({
    url: `/web/v2/payment_methods/${paymentMethodId}/beneficiaries/${beneficiaryId}/blacklist`,
    method: 'post',
    params,
  });
};

export const getBlockPaymentMethodMandateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof blockPaymentMethodMandate>>,
    TError,
    {
      paymentMethodId: string;
      beneficiaryId: string;
      params: BlockPaymentMethodMandateParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof blockPaymentMethodMandate>>,
  TError,
  {
    paymentMethodId: string;
    beneficiaryId: string;
    params: BlockPaymentMethodMandateParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof blockPaymentMethodMandate>>,
    {
      paymentMethodId: string;
      beneficiaryId: string;
      params: BlockPaymentMethodMandateParams;
    }
  > = (props) => {
    const { paymentMethodId, beneficiaryId, params } = props ?? {};

    return blockPaymentMethodMandate(paymentMethodId, beneficiaryId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type BlockPaymentMethodMandateMutationResult = NonNullable<
  Awaited<ReturnType<typeof blockPaymentMethodMandate>>
>;

export type BlockPaymentMethodMandateMutationError = unknown;

export const useBlockPaymentMethodMandate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof blockPaymentMethodMandate>>,
    TError,
    {
      paymentMethodId: string;
      beneficiaryId: string;
      params: BlockPaymentMethodMandateParams;
    },
    TContext
  >;
}) => {
  const mutationOptions = getBlockPaymentMethodMandateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Lists failed transactions for payment method.
This is applicable to DIRECT_DEBIT payment method

 */
export const getPaymentMethodFailedPayments = (
  paymentMethodId: string,
  signal?: AbortSignal,
) => {
  return customInstance<PaymentMethodFailedPaymentsResponse>({
    url: `/web/v2/payment_methods/${paymentMethodId}/failed_payments`,
    method: 'get',
    signal,
  });
};

export const getGetPaymentMethodFailedPaymentsQueryKey = (
  paymentMethodId: string,
) => [`/web/v2/payment_methods/${paymentMethodId}/failed_payments`] as const;

export const getGetPaymentMethodFailedPaymentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPaymentMethodFailedPayments>>,
  TError = unknown,
>(
  paymentMethodId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPaymentMethodFailedPayments>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPaymentMethodFailedPayments>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPaymentMethodFailedPaymentsQueryKey(paymentMethodId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPaymentMethodFailedPayments>>
  > = ({ signal }) => getPaymentMethodFailedPayments(paymentMethodId, signal);

  return { queryKey, queryFn, enabled: !!paymentMethodId, ...queryOptions };
};

export type GetPaymentMethodFailedPaymentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPaymentMethodFailedPayments>>
>;
export type GetPaymentMethodFailedPaymentsQueryError = unknown;

export const useGetPaymentMethodFailedPayments = <
  TData = Awaited<ReturnType<typeof getPaymentMethodFailedPayments>>,
  TError = unknown,
>(
  paymentMethodId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPaymentMethodFailedPayments>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPaymentMethodFailedPaymentsQueryOptions(
    paymentMethodId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
