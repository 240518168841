/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  MerchantRestrictionGroupsGetResponse,
  ErrorResponse,
  MerchantCategoryRestrictionGroupsGetParams,
  MerchantCategoryRestrictionGroupGet200,
  MerchantRestrictionGroup,
  MerchantRestrictionGroupsRequest,
  MerchantCategoryRestrictionGroupMerchantsListUpdateParams,
  MerchantCategoryRestrictionGroupMerchantsListDeleteParams,
  MerchantRestrictionGroupsGetPresenceResponse,
  MerchantCategoryRestrictionGroupsGetPresenceParams,
} from '../../models';
import { customInstance } from '../../config';

export const merchantCategoryRestrictionGroupsGet = (
  params?: MerchantCategoryRestrictionGroupsGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<MerchantRestrictionGroupsGetResponse>({
    url: `/web/merchantCategoryRestrictionGroups`,
    method: 'get',
    params,
    signal,
  });
};

export const getMerchantCategoryRestrictionGroupsGetQueryKey = (
  params?: MerchantCategoryRestrictionGroupsGetParams,
) => [`/web/merchantCategoryRestrictionGroups`, ...(params ? [params] : [])];

export type MerchantCategoryRestrictionGroupsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof merchantCategoryRestrictionGroupsGet>>
>;
export type MerchantCategoryRestrictionGroupsGetQueryError = ErrorResponse;

export const useMerchantCategoryRestrictionGroupsGet = <
  TData = Awaited<ReturnType<typeof merchantCategoryRestrictionGroupsGet>>,
  TError = ErrorResponse,
>(
  params?: MerchantCategoryRestrictionGroupsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof merchantCategoryRestrictionGroupsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMerchantCategoryRestrictionGroupsGetQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof merchantCategoryRestrictionGroupsGet>>
  > = ({ signal }) => merchantCategoryRestrictionGroupsGet(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof merchantCategoryRestrictionGroupsGet>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const merchantCategoryRestrictionGroupGet = (
  mccRestrictionId: string,
  signal?: AbortSignal,
) => {
  return customInstance<MerchantCategoryRestrictionGroupGet200>({
    url: `/web/merchantCategoryRestrictionGroups/${mccRestrictionId}`,
    method: 'get',
    signal,
  });
};

export const getMerchantCategoryRestrictionGroupGetQueryKey = (
  mccRestrictionId: string,
) => [`/web/merchantCategoryRestrictionGroups/${mccRestrictionId}`];

export type MerchantCategoryRestrictionGroupGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof merchantCategoryRestrictionGroupGet>>
>;
export type MerchantCategoryRestrictionGroupGetQueryError = unknown;

export const useMerchantCategoryRestrictionGroupGet = <
  TData = Awaited<ReturnType<typeof merchantCategoryRestrictionGroupGet>>,
  TError = unknown,
>(
  mccRestrictionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof merchantCategoryRestrictionGroupGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMerchantCategoryRestrictionGroupGetQueryKey(mccRestrictionId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof merchantCategoryRestrictionGroupGet>>
  > = ({ signal }) =>
    merchantCategoryRestrictionGroupGet(mccRestrictionId, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof merchantCategoryRestrictionGroupGet>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!mccRestrictionId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const merchantCategoryRestrictionGroupUpdate = (
  mccRestrictionId: string,
  merchantRestrictionGroupsRequest: MerchantRestrictionGroupsRequest,
) => {
  return customInstance<MerchantRestrictionGroup>({
    url: `/web/merchantCategoryRestrictionGroups/${mccRestrictionId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: merchantRestrictionGroupsRequest,
  });
};

export type MerchantCategoryRestrictionGroupUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof merchantCategoryRestrictionGroupUpdate>>
>;
export type MerchantCategoryRestrictionGroupUpdateMutationBody =
  MerchantRestrictionGroupsRequest;
export type MerchantCategoryRestrictionGroupUpdateMutationError = unknown;

export const useMerchantCategoryRestrictionGroupUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof merchantCategoryRestrictionGroupUpdate>>,
    TError,
    { mccRestrictionId: string; data: MerchantRestrictionGroupsRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof merchantCategoryRestrictionGroupUpdate>>,
    { mccRestrictionId: string; data: MerchantRestrictionGroupsRequest }
  > = (props) => {
    const { mccRestrictionId, data } = props ?? {};

    return merchantCategoryRestrictionGroupUpdate(mccRestrictionId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof merchantCategoryRestrictionGroupUpdate>>,
    TError,
    { mccRestrictionId: string; data: MerchantRestrictionGroupsRequest },
    TContext
  >(mutationFn, mutationOptions);
};
export const merchantCategoryRestrictionGroupMerchantsListUpdate = (
  mccRestrictionId: string,
  params: MerchantCategoryRestrictionGroupMerchantsListUpdateParams,
) => {
  return customInstance<MerchantRestrictionGroup>({
    url: `/web/merchantCategoryRestrictionGroups/${mccRestrictionId}/merchants`,
    method: 'put',
    params,
  });
};

export type MerchantCategoryRestrictionGroupMerchantsListUpdateMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof merchantCategoryRestrictionGroupMerchantsListUpdate>
    >
  >;

export type MerchantCategoryRestrictionGroupMerchantsListUpdateMutationError =
  unknown;

export const useMerchantCategoryRestrictionGroupMerchantsListUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof merchantCategoryRestrictionGroupMerchantsListUpdate>
    >,
    TError,
    {
      mccRestrictionId: string;
      params: MerchantCategoryRestrictionGroupMerchantsListUpdateParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<typeof merchantCategoryRestrictionGroupMerchantsListUpdate>
    >,
    {
      mccRestrictionId: string;
      params: MerchantCategoryRestrictionGroupMerchantsListUpdateParams;
    }
  > = (props) => {
    const { mccRestrictionId, params } = props ?? {};

    return merchantCategoryRestrictionGroupMerchantsListUpdate(
      mccRestrictionId,
      params,
    );
  };

  return useMutation<
    Awaited<
      ReturnType<typeof merchantCategoryRestrictionGroupMerchantsListUpdate>
    >,
    TError,
    {
      mccRestrictionId: string;
      params: MerchantCategoryRestrictionGroupMerchantsListUpdateParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
export const merchantCategoryRestrictionGroupMerchantsListDelete = (
  mccRestrictionId: string,
  params: MerchantCategoryRestrictionGroupMerchantsListDeleteParams,
) => {
  return customInstance<MerchantRestrictionGroup>({
    url: `/web/merchantCategoryRestrictionGroups/${mccRestrictionId}/merchants`,
    method: 'delete',
    params,
  });
};

export type MerchantCategoryRestrictionGroupMerchantsListDeleteMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof merchantCategoryRestrictionGroupMerchantsListDelete>
    >
  >;

export type MerchantCategoryRestrictionGroupMerchantsListDeleteMutationError =
  unknown;

export const useMerchantCategoryRestrictionGroupMerchantsListDelete = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof merchantCategoryRestrictionGroupMerchantsListDelete>
    >,
    TError,
    {
      mccRestrictionId: string;
      params: MerchantCategoryRestrictionGroupMerchantsListDeleteParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<typeof merchantCategoryRestrictionGroupMerchantsListDelete>
    >,
    {
      mccRestrictionId: string;
      params: MerchantCategoryRestrictionGroupMerchantsListDeleteParams;
    }
  > = (props) => {
    const { mccRestrictionId, params } = props ?? {};

    return merchantCategoryRestrictionGroupMerchantsListDelete(
      mccRestrictionId,
      params,
    );
  };

  return useMutation<
    Awaited<
      ReturnType<typeof merchantCategoryRestrictionGroupMerchantsListDelete>
    >,
    TError,
    {
      mccRestrictionId: string;
      params: MerchantCategoryRestrictionGroupMerchantsListDeleteParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
export const merchantCategoryRestrictionGroupsGetPresence = (
  mccRestrictionId: string,
  params?: MerchantCategoryRestrictionGroupsGetPresenceParams,
  signal?: AbortSignal,
) => {
  return customInstance<MerchantRestrictionGroupsGetPresenceResponse>({
    url: `/web/merchantCategoryRestrictionGroups/${mccRestrictionId}/presence`,
    method: 'get',
    params,
    signal,
  });
};

export const getMerchantCategoryRestrictionGroupsGetPresenceQueryKey = (
  mccRestrictionId: string,
  params?: MerchantCategoryRestrictionGroupsGetPresenceParams,
) => [
  `/web/merchantCategoryRestrictionGroups/${mccRestrictionId}/presence`,
  ...(params ? [params] : []),
];

export type MerchantCategoryRestrictionGroupsGetPresenceQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof merchantCategoryRestrictionGroupsGetPresence>>
  >;
export type MerchantCategoryRestrictionGroupsGetPresenceQueryError = unknown;

export const useMerchantCategoryRestrictionGroupsGetPresence = <
  TData = Awaited<
    ReturnType<typeof merchantCategoryRestrictionGroupsGetPresence>
  >,
  TError = unknown,
>(
  mccRestrictionId: string,
  params?: MerchantCategoryRestrictionGroupsGetPresenceParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof merchantCategoryRestrictionGroupsGetPresence>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMerchantCategoryRestrictionGroupsGetPresenceQueryKey(
      mccRestrictionId,
      params,
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof merchantCategoryRestrictionGroupsGetPresence>>
  > = ({ signal }) =>
    merchantCategoryRestrictionGroupsGetPresence(
      mccRestrictionId,
      params,
      signal,
    );

  const query = useQuery<
    Awaited<ReturnType<typeof merchantCategoryRestrictionGroupsGetPresence>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!mccRestrictionId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
