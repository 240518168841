/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { TripType } from './tripType';
import type { Address } from './address';

export interface KmAllowanceConfiguration {
  trip_type?: TripType;
  home_address?: Address;
  office_address?: Address;
  start_station_address?: Address;
  end_station_address?: Address;
}
