/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type ReasonRefusalType =
  typeof ReasonRefusalType[keyof typeof ReasonRefusalType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReasonRefusalType = {
  BCE_DOC_MISSING: 'BCE_DOC_MISSING',
  BCE_DOC_NOT_READABLE: 'BCE_DOC_NOT_READABLE',
  BCE_DOC_OUT_OF_DATE: 'BCE_DOC_OUT_OF_DATE',
} as const;
