/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { PresenceItem } from './presenceItem';

export type MerchantRestrictionGroupsGetPresenceResponse = PresenceItem[];
