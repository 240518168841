/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { ExpenseCategoryCode } from './expenseCategoryCode';
import type { QueryPageNumberParameter } from './component-parameters/queryPageNumberParameter';
import type { QueryPageSizeParameter } from './component-parameters/queryPageSizeParameter';

export type MerchantsGetParams = {
  expense_category_code?: ExpenseCategoryCode;
  sort?: string;
  page_number?: QueryPageNumberParameter;
  page_size?: QueryPageSizeParameter;
};
