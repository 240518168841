/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type ForgotPasswordBody = {
  email: string;
  reset_password_url: string;
  redirect_url?: string;
};
