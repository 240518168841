/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type RegistrationV1AllOfRole =
  typeof RegistrationV1AllOfRole[keyof typeof RegistrationV1AllOfRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RegistrationV1AllOfRole = {
  EMPLOYEE: 'EMPLOYEE',
  FLEET_MGR: 'FLEET_MGR',
} as const;
