/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { ProgramProductConfigurationConfigurationAttributes } from './programProductConfigurationConfigurationAttributes';

export interface ProgramProductConfiguration {
  /** For KmAllowance it will be: amount_per_km, max_km_per_trip and office_address */
  configuration_attributes?: ProgramProductConfigurationConfigurationAttributes;
  /** This determines if a user can manually input the km distance of his journey Only for km allowance services */
  is_manual_km_amount_enabled?: boolean | null;
  /** When a manual km amount is set, this will determine if the expense should be auto approved regardless of other configs Only for km allowance services */
  force_manual_approve_for_manual_km_amount?: boolean | null;
}
