import React from 'react';
import { Text } from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Table } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useTranslation } from 'react-i18next';
import { useIcon } from '@libs/core/theme/utils/useIcon';
import { RulesetArrayResponse } from '@libs/api-v2/models';

type RulesetTableProps = {
  rulesets: RulesetArrayResponse;
  isLoading: boolean;
  isFetching: boolean;
  open: () => void;
  setRulesetId;
};

export const RulesetTable = ({
  rulesets,
  isLoading,
  isFetching,
  open,
  setRulesetId,
}: RulesetTableProps) => {
  // Attributes

  const Eye = useIcon('Eye');
  const { t } = useTranslation();

  const headers: TableHeaders = [
    t(i18nKeys.common.full_name),
    t(i18nKeys.common.description),
    '',
  ];

  // Render
  return (
    <Table
      headers={headers}
      // Uncomment when available
      // hasSearch
      // searchPlaceholder={t(i18nKeys.common.table.employee.search_placeholder)}
      // searchQuery={searchQuery}
      // setSearchQuery={setSearchQuery}
    >
      <Table.TBody
        isLoading={(isLoading || isFetching) && !rulesets}
        colSpan={headers.length}
        isEmpty={
          !isLoading &&
          !isFetching &&
          (rulesets?.rules.length === 0 || !rulesets)
        }
      >
        {rulesets?.rules.map((ruleset, index) => {
          return (
            <Table.TR
              key={ruleset.id}
              selectable
              index={ruleset.id}
              position="relative"
              transform="scale(1,1)"
            >
              <Table.TD>
                <Text>{ruleset.name} </Text>
              </Table.TD>
              <Table.TD>
                <Text>{ruleset.description} </Text>
              </Table.TD>
              <Table.Action
                actions={[
                  {
                    icon: <Eye w="2rem" h="2rem" />,
                    onClick: () => {
                      setRulesetId(ruleset.id);
                      open();
                    },
                    tooltipLabel: (
                      <Text size="small">
                        {t(i18nKeys.operator.ruleset.tooltip.edit)}
                      </Text>
                    ),
                  },
                ]}
              />
            </Table.TR>
          );
        })}
      </Table.TBody>
    </Table>
  );
};
