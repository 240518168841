import { Button } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { Flex } from '@chakra-ui/layout';
import { Registration } from '@libs/api-v2/models';
import { useProgramsGet } from '@libs/api-v2/endpoints/programs/programs';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { getPath, Routes } from '@libs/dashboard-core/routes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { CardCreateDrawer } from './card-create-drawer';
import { CardTable } from './card-table';
import { Loader } from '@libs/ui/components';
import { Box, Stack, Text } from '@chakra-ui/react';
import { CardImcclRestrictionsTable } from './card-imccl-restrictions-table';

type EmployeeDetailsCardsProps = {
  employee: Registration;
};

export const EmployeeDetailsCards = ({
  employee,
}: EmployeeDetailsCardsProps) => {
  const { t } = useTranslation();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const navigate = useNavigate();
  const { isLoading, data: programs } = useProgramsGet({
    organisation_id: employee.organisation_id,
  });

  if (isLoading) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }
  const hasImccl =
    programs &&
    programs.data.filter((p) => !!p.imccl_restriction_id).length > 0;

  return (
    <Flex direction="column">
      <Flex mt="1rem" mb="0" alignSelf="flex-end">
        <Button
          variant="border"
          size="body1"
          mr="1rem"
          onClick={() =>
            navigate(
              getPath(Routes.DASHBOARD_TRANSACTIONS) +
                `?membership_id=${employee.membership_id}`,
            )
          }
        >
          {t(i18nKeys.common.transactions)}
        </Button>
        <Button variant="primary" size="body1" onClick={onOpen}>
          {t(i18nKeys.operator.cards.add)}
        </Button>
      </Flex>
      <CardTable employee={employee} hasImccl={hasImccl} />
      {hasImccl && (
        <Stack mt="5rem">
          <Text size="Body1Bold">Imccl restrictions History</Text>
          <CardImcclRestrictionsTable employee={employee} />
        </Stack>
      )}
      {isOpen && (
        <CardCreateDrawer
          employee={employee}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </Flex>
  );
};
