/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  ProgramTemplatesGetResponse,
  ProgramTemplatesGetParams,
  ProgramTemplate,
  ProgramWithMeta,
  Error404NotFoundResponse,
  ProgramsGetResponse,
  ProgramsGetParams,
  Program,
  ProgramGroupGetResponse,
  ProgramGroupsGetParams,
  ProgramGroup,
  ProgramGroupsUpdateRequest,
  ProgramGroupWithMeta,
  ProgramGroupGetParams,
  ProgramGroupWithLimit,
  Error400LimitInvalidPayloadResponse,
  Error409ProgramGroupAlreadyExistsResponse,
  ProgramGroupLimit,
  ProgramGroupCreateParams,
  ProgramGroupData,
  MembershipSummary,
  ProgramGroupLimitSimulationParams,
  ProgramGroupLimitWithMeta,
  ProgramGroupLimitGetParams,
  ProgramGroupLimitUpdateParams,
  Error403LimitForbiddenResponse,
  Error409LimitAlreadyExistsResponse,
  ProgramGroupLimitCreateParams,
  ProgramGroupLimitDeleteParams,
  ModifyDisplayBudgetLimitRequest,
  ProgramGroupLimitDisplayPutParams,
  ProgramGroupsLimitDisplayPatchParams,
  ProductsGetResponse,
  ProgramProductsGetParams,
  ProgramProduct,
  ProgramProductConfiguration,
  ProgramServicesGetResponse,
  ProgramServicesGetParams,
  ProgramServicesUpdateResponse,
  ProgramServicesUpdateRequest,
  ProgramService,
  ProgramServiceUpdateRequest,
} from '../../models';
import type { Error } from '../../models/component-common';
import { customInstance } from '../../config';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
  T,
>() => T extends Y ? 1 : 2
  ? A
  : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<
    { [Q in P]: T[P] },
    { -readonly [Q in P]: T[P] },
    P
  >;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object
        ? NonReadonly<NonNullable<T[P]>>
        : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

/**
 * Returns program templates available for organisation
In case organisation_id is not explicitly provided in the query it will be derived from caller identity
All templates will be prepopulated with organisation id where applicable

 */
export const programTemplatesGet = (
  params?: ProgramTemplatesGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<ProgramTemplatesGetResponse>({
    url: `/web/v2/program_templates`,
    method: 'get',
    params,
    signal,
  });
};

export const getProgramTemplatesGetQueryKey = (
  params?: ProgramTemplatesGetParams,
) => [`/web/v2/program_templates`, ...(params ? [params] : [])] as const;

export const getProgramTemplatesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof programTemplatesGet>>,
  TError = unknown,
>(
  params?: ProgramTemplatesGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programTemplatesGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof programTemplatesGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getProgramTemplatesGetQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof programTemplatesGet>>
  > = ({ signal }) => programTemplatesGet(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ProgramTemplatesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof programTemplatesGet>>
>;
export type ProgramTemplatesGetQueryError = unknown;

export const useProgramTemplatesGet = <
  TData = Awaited<ReturnType<typeof programTemplatesGet>>,
  TError = unknown,
>(
  params?: ProgramTemplatesGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programTemplatesGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProgramTemplatesGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const programTemplateGet = (
  templateId: string,
  signal?: AbortSignal,
) => {
  return customInstance<ProgramTemplate>({
    url: `/web/v2/program_templates/${templateId}`,
    method: 'get',
    signal,
  });
};

export const getProgramTemplateGetQueryKey = (templateId: string) =>
  [`/web/v2/program_templates/${templateId}`] as const;

export const getProgramTemplateGetQueryOptions = <
  TData = Awaited<ReturnType<typeof programTemplateGet>>,
  TError = unknown,
>(
  templateId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programTemplateGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof programTemplateGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getProgramTemplateGetQueryKey(templateId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof programTemplateGet>>
  > = ({ signal }) => programTemplateGet(templateId, signal);

  return { queryKey, queryFn, enabled: !!templateId, ...queryOptions };
};

export type ProgramTemplateGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof programTemplateGet>>
>;
export type ProgramTemplateGetQueryError = unknown;

export const useProgramTemplateGet = <
  TData = Awaited<ReturnType<typeof programTemplateGet>>,
  TError = unknown,
>(
  templateId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programTemplateGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProgramTemplateGetQueryOptions(templateId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Provisions an organisation specific program from provided template payload.
Payload should contain customized program configuration including service selection

 */
export const programTemplateProvision = (
  programTemplate: NonReadonly<ProgramTemplate>,
) => {
  return customInstance<ProgramWithMeta>({
    url: `/web/v2/program_templates/provision`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: programTemplate,
  });
};

export const getProgramTemplateProvisionMutationOptions = <
  TError = Error | void | Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programTemplateProvision>>,
    TError,
    { data: NonReadonly<ProgramTemplate> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof programTemplateProvision>>,
  TError,
  { data: NonReadonly<ProgramTemplate> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programTemplateProvision>>,
    { data: NonReadonly<ProgramTemplate> }
  > = (props) => {
    const { data } = props ?? {};

    return programTemplateProvision(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProgramTemplateProvisionMutationResult = NonNullable<
  Awaited<ReturnType<typeof programTemplateProvision>>
>;
export type ProgramTemplateProvisionMutationBody = NonReadonly<ProgramTemplate>;
export type ProgramTemplateProvisionMutationError =
  | Error
  | void
  | Error404NotFoundResponse;

export const useProgramTemplateProvision = <
  TError = Error | void | Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programTemplateProvision>>,
    TError,
    { data: NonReadonly<ProgramTemplate> },
    TContext
  >;
}) => {
  const mutationOptions = getProgramTemplateProvisionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const programsGet = (
  params?: ProgramsGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<ProgramsGetResponse>({
    url: `/web/v2/programs`,
    method: 'get',
    params,
    signal,
  });
};

export const getProgramsGetQueryKey = (params?: ProgramsGetParams) =>
  [`/web/v2/programs`, ...(params ? [params] : [])] as const;

export const getProgramsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof programsGet>>,
  TError = unknown,
>(
  params?: ProgramsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programsGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<Awaited<ReturnType<typeof programsGet>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProgramsGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof programsGet>>> = ({
    signal,
  }) => programsGet(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ProgramsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof programsGet>>
>;
export type ProgramsGetQueryError = unknown;

export const useProgramsGet = <
  TData = Awaited<ReturnType<typeof programsGet>>,
  TError = unknown,
>(
  params?: ProgramsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProgramsGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const programUpdate = (
  programId: string,
  program: NonReadonly<Program>,
) => {
  return customInstance<Program>({
    url: `/web/v2/programs/${programId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: program,
  });
};

export const getProgramUpdateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programUpdate>>,
    TError,
    { programId: string; data: NonReadonly<Program> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof programUpdate>>,
  TError,
  { programId: string; data: NonReadonly<Program> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programUpdate>>,
    { programId: string; data: NonReadonly<Program> }
  > = (props) => {
    const { programId, data } = props ?? {};

    return programUpdate(programId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProgramUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof programUpdate>>
>;
export type ProgramUpdateMutationBody = NonReadonly<Program>;
export type ProgramUpdateMutationError = unknown;

export const useProgramUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programUpdate>>,
    TError,
    { programId: string; data: NonReadonly<Program> },
    TContext
  >;
}) => {
  const mutationOptions = getProgramUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const programGet = (programId: string, signal?: AbortSignal) => {
  return customInstance<ProgramWithMeta>({
    url: `/web/v2/programs/${programId}`,
    method: 'get',
    signal,
  });
};

export const getProgramGetQueryKey = (programId: string) =>
  [`/web/v2/programs/${programId}`] as const;

export const getProgramGetQueryOptions = <
  TData = Awaited<ReturnType<typeof programGet>>,
  TError = unknown,
>(
  programId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<Awaited<ReturnType<typeof programGet>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProgramGetQueryKey(programId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof programGet>>> = ({
    signal,
  }) => programGet(programId, signal);

  return { queryKey, queryFn, enabled: !!programId, ...queryOptions };
};

export type ProgramGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof programGet>>
>;
export type ProgramGetQueryError = unknown;

export const useProgramGet = <
  TData = Awaited<ReturnType<typeof programGet>>,
  TError = unknown,
>(
  programId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProgramGetQueryOptions(programId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const programGroupsGet = (
  programId: string,
  params?: ProgramGroupsGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<ProgramGroupGetResponse>({
    url: `/web/v2/programs/${programId}/groups`,
    method: 'get',
    params,
    signal,
  });
};

export const getProgramGroupsGetQueryKey = (
  programId: string,
  params?: ProgramGroupsGetParams,
) =>
  [
    `/web/v2/programs/${programId}/groups`,
    ...(params ? [params] : []),
  ] as const;

export const getProgramGroupsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof programGroupsGet>>,
  TError = unknown,
>(
  programId: string,
  params?: ProgramGroupsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programGroupsGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof programGroupsGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getProgramGroupsGetQueryKey(programId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof programGroupsGet>>
  > = ({ signal }) => programGroupsGet(programId, params, signal);

  return { queryKey, queryFn, enabled: !!programId, ...queryOptions };
};

export type ProgramGroupsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof programGroupsGet>>
>;
export type ProgramGroupsGetQueryError = unknown;

export const useProgramGroupsGet = <
  TData = Awaited<ReturnType<typeof programGroupsGet>>,
  TError = unknown,
>(
  programId: string,
  params?: ProgramGroupsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programGroupsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProgramGroupsGetQueryOptions(
    programId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Updates the program_groups for the given program.
Programs groups which exist but whose group is not in the request's payload will be deleted (along with the PGL & summaries).
Program groups which do not exist but whose group is in the request's payload will be created (along with the PGL & summaries).
Warning: This call should not be used to add groups to a program, please use program_group_create instead.

 */
export const programGroupsUpdate = (
  programId: string,
  programGroupsUpdateRequest: ProgramGroupsUpdateRequest,
) => {
  return customInstance<ProgramGroup>({
    url: `/web/v2/programs/${programId}/groups`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: programGroupsUpdateRequest,
  });
};

export const getProgramGroupsUpdateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupsUpdate>>,
    TError,
    { programId: string; data: ProgramGroupsUpdateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof programGroupsUpdate>>,
  TError,
  { programId: string; data: ProgramGroupsUpdateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programGroupsUpdate>>,
    { programId: string; data: ProgramGroupsUpdateRequest }
  > = (props) => {
    const { programId, data } = props ?? {};

    return programGroupsUpdate(programId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProgramGroupsUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof programGroupsUpdate>>
>;
export type ProgramGroupsUpdateMutationBody = ProgramGroupsUpdateRequest;
export type ProgramGroupsUpdateMutationError = unknown;

export const useProgramGroupsUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupsUpdate>>,
    TError,
    { programId: string; data: ProgramGroupsUpdateRequest },
    TContext
  >;
}) => {
  const mutationOptions = getProgramGroupsUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const programGroupGet = (
  programId: string,
  groupId: string,
  params?: ProgramGroupGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<ProgramGroupWithMeta>({
    url: `/web/v2/programs/${programId}/groups/${groupId}`,
    method: 'get',
    params,
    signal,
  });
};

export const getProgramGroupGetQueryKey = (
  programId: string,
  groupId: string,
  params?: ProgramGroupGetParams,
) =>
  [
    `/web/v2/programs/${programId}/groups/${groupId}`,
    ...(params ? [params] : []),
  ] as const;

export const getProgramGroupGetQueryOptions = <
  TData = Awaited<ReturnType<typeof programGroupGet>>,
  TError = unknown,
>(
  programId: string,
  groupId: string,
  params?: ProgramGroupGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programGroupGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof programGroupGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getProgramGroupGetQueryKey(programId, groupId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof programGroupGet>>> = ({
    signal,
  }) => programGroupGet(programId, groupId, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(programId && groupId),
    ...queryOptions,
  };
};

export type ProgramGroupGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof programGroupGet>>
>;
export type ProgramGroupGetQueryError = unknown;

export const useProgramGroupGet = <
  TData = Awaited<ReturnType<typeof programGroupGet>>,
  TError = unknown,
>(
  programId: string,
  groupId: string,
  params?: ProgramGroupGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programGroupGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProgramGroupGetQueryOptions(
    programId,
    groupId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Creates a ProgramGroup for the given program + group.
Will then create the ProgramGroupLimit for said ProgramGroup with the configuration from the request body.
Finally, membership Summaries will be created for the group's users - if any - with a budget starting at the given query_date_from.

 */
export const programGroupCreate = (
  programId: string,
  groupId: string,
  programGroupLimit: NonReadonly<ProgramGroupLimit>,
  params?: ProgramGroupCreateParams,
) => {
  return customInstance<ProgramGroupWithLimit>({
    url: `/web/v2/programs/${programId}/groups/${groupId}`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: programGroupLimit,
    params,
  });
};

export const getProgramGroupCreateMutationOptions = <
  TError =
    | Error400LimitInvalidPayloadResponse
    | Error409ProgramGroupAlreadyExistsResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupCreate>>,
    TError,
    {
      programId: string;
      groupId: string;
      data: NonReadonly<ProgramGroupLimit>;
      params?: ProgramGroupCreateParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof programGroupCreate>>,
  TError,
  {
    programId: string;
    groupId: string;
    data: NonReadonly<ProgramGroupLimit>;
    params?: ProgramGroupCreateParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programGroupCreate>>,
    {
      programId: string;
      groupId: string;
      data: NonReadonly<ProgramGroupLimit>;
      params?: ProgramGroupCreateParams;
    }
  > = (props) => {
    const { programId, groupId, data, params } = props ?? {};

    return programGroupCreate(programId, groupId, data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProgramGroupCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof programGroupCreate>>
>;
export type ProgramGroupCreateMutationBody = NonReadonly<ProgramGroupLimit>;
export type ProgramGroupCreateMutationError =
  | Error400LimitInvalidPayloadResponse
  | Error409ProgramGroupAlreadyExistsResponse;

export const useProgramGroupCreate = <
  TError =
    | Error400LimitInvalidPayloadResponse
    | Error409ProgramGroupAlreadyExistsResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupCreate>>,
    TError,
    {
      programId: string;
      groupId: string;
      data: NonReadonly<ProgramGroupLimit>;
      params?: ProgramGroupCreateParams;
    },
    TContext
  >;
}) => {
  const mutationOptions = getProgramGroupCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const programGroupUpdate = (
  programId: string,
  groupId: string,
  programGroupData: NonReadonly<ProgramGroupData>,
) => {
  return customInstance<ProgramGroup>({
    url: `/web/v2/programs/${programId}/groups/${groupId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: programGroupData,
  });
};

export const getProgramGroupUpdateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupUpdate>>,
    TError,
    { programId: string; groupId: string; data: NonReadonly<ProgramGroupData> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof programGroupUpdate>>,
  TError,
  { programId: string; groupId: string; data: NonReadonly<ProgramGroupData> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programGroupUpdate>>,
    { programId: string; groupId: string; data: NonReadonly<ProgramGroupData> }
  > = (props) => {
    const { programId, groupId, data } = props ?? {};

    return programGroupUpdate(programId, groupId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProgramGroupUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof programGroupUpdate>>
>;
export type ProgramGroupUpdateMutationBody = NonReadonly<ProgramGroupData>;
export type ProgramGroupUpdateMutationError = unknown;

export const useProgramGroupUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupUpdate>>,
    TError,
    { programId: string; groupId: string; data: NonReadonly<ProgramGroupData> },
    TContext
  >;
}) => {
  const mutationOptions = getProgramGroupUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Performs budget simulation for a given limit

 */
export const programGroupLimitSimulation = (
  programId: string,
  groupId: string,
  programGroupLimit: NonReadonly<ProgramGroupLimit>,
  params?: ProgramGroupLimitSimulationParams,
) => {
  return customInstance<MembershipSummary>({
    url: `/web/v2/programs/${programId}/groups/${groupId}/limit/simulation`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: programGroupLimit,
    params,
  });
};

export const getProgramGroupLimitSimulationMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupLimitSimulation>>,
    TError,
    {
      programId: string;
      groupId: string;
      data: NonReadonly<ProgramGroupLimit>;
      params?: ProgramGroupLimitSimulationParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof programGroupLimitSimulation>>,
  TError,
  {
    programId: string;
    groupId: string;
    data: NonReadonly<ProgramGroupLimit>;
    params?: ProgramGroupLimitSimulationParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programGroupLimitSimulation>>,
    {
      programId: string;
      groupId: string;
      data: NonReadonly<ProgramGroupLimit>;
      params?: ProgramGroupLimitSimulationParams;
    }
  > = (props) => {
    const { programId, groupId, data, params } = props ?? {};

    return programGroupLimitSimulation(programId, groupId, data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProgramGroupLimitSimulationMutationResult = NonNullable<
  Awaited<ReturnType<typeof programGroupLimitSimulation>>
>;
export type ProgramGroupLimitSimulationMutationBody =
  NonReadonly<ProgramGroupLimit>;
export type ProgramGroupLimitSimulationMutationError = unknown;

export const useProgramGroupLimitSimulation = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupLimitSimulation>>,
    TError,
    {
      programId: string;
      groupId: string;
      data: NonReadonly<ProgramGroupLimit>;
      params?: ProgramGroupLimitSimulationParams;
    },
    TContext
  >;
}) => {
  const mutationOptions =
    getProgramGroupLimitSimulationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves limit for given program and group.
Optionally it will return personalized limit if membership_id query is populated and personalized limit exists.
It will still return group level limit if no personalized limit available

 */
export const programGroupLimitGet = (
  programId: string,
  groupId: string,
  params?: ProgramGroupLimitGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<ProgramGroupLimitWithMeta>({
    url: `/web/v2/programs/${programId}/groups/${groupId}/limit`,
    method: 'get',
    params,
    signal,
  });
};

export const getProgramGroupLimitGetQueryKey = (
  programId: string,
  groupId: string,
  params?: ProgramGroupLimitGetParams,
) =>
  [
    `/web/v2/programs/${programId}/groups/${groupId}/limit`,
    ...(params ? [params] : []),
  ] as const;

export const getProgramGroupLimitGetQueryOptions = <
  TData = Awaited<ReturnType<typeof programGroupLimitGet>>,
  TError = unknown,
>(
  programId: string,
  groupId: string,
  params?: ProgramGroupLimitGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programGroupLimitGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof programGroupLimitGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getProgramGroupLimitGetQueryKey(programId, groupId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof programGroupLimitGet>>
  > = ({ signal }) => programGroupLimitGet(programId, groupId, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(programId && groupId),
    ...queryOptions,
  };
};

export type ProgramGroupLimitGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof programGroupLimitGet>>
>;
export type ProgramGroupLimitGetQueryError = unknown;

export const useProgramGroupLimitGet = <
  TData = Awaited<ReturnType<typeof programGroupLimitGet>>,
  TError = unknown,
>(
  programId: string,
  groupId: string,
  params?: ProgramGroupLimitGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programGroupLimitGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProgramGroupLimitGetQueryOptions(
    programId,
    groupId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Puts either a group level limit (membership_id query not populated)
or personalized limit (membership_id query populated).
Should be used for existing limits only - POST is being used to create a limit from scratch.
Optional date_from may be used to override default date budget is accrued from for particular limit

 */
export const programGroupLimitUpdate = (
  programId: string,
  groupId: string,
  programGroupLimit: NonReadonly<ProgramGroupLimit>,
  params: ProgramGroupLimitUpdateParams,
) => {
  return customInstance<ProgramGroupLimit>({
    url: `/web/v2/programs/${programId}/groups/${groupId}/limit`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: programGroupLimit,
    params,
  });
};

export const getProgramGroupLimitUpdateMutationOptions = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupLimitUpdate>>,
    TError,
    {
      programId: string;
      groupId: string;
      data: NonReadonly<ProgramGroupLimit>;
      params: ProgramGroupLimitUpdateParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof programGroupLimitUpdate>>,
  TError,
  {
    programId: string;
    groupId: string;
    data: NonReadonly<ProgramGroupLimit>;
    params: ProgramGroupLimitUpdateParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programGroupLimitUpdate>>,
    {
      programId: string;
      groupId: string;
      data: NonReadonly<ProgramGroupLimit>;
      params: ProgramGroupLimitUpdateParams;
    }
  > = (props) => {
    const { programId, groupId, data, params } = props ?? {};

    return programGroupLimitUpdate(programId, groupId, data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProgramGroupLimitUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof programGroupLimitUpdate>>
>;
export type ProgramGroupLimitUpdateMutationBody =
  NonReadonly<ProgramGroupLimit>;
export type ProgramGroupLimitUpdateMutationError = Error;

export const useProgramGroupLimitUpdate = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupLimitUpdate>>,
    TError,
    {
      programId: string;
      groupId: string;
      data: NonReadonly<ProgramGroupLimit>;
      params: ProgramGroupLimitUpdateParams;
    },
    TContext
  >;
}) => {
  const mutationOptions = getProgramGroupLimitUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Creates either a group level limit (membership_id query not populated)
or personalized limit (membership_id query populated).
Should be used for new limits only - PUT is being used to update existing limits.
Optional date_from may be used to override default date budget is accrued from for particular limit

 */
export const programGroupLimitCreate = (
  programId: string,
  groupId: string,
  programGroupLimit: NonReadonly<ProgramGroupLimit>,
  params: ProgramGroupLimitCreateParams,
) => {
  return customInstance<ProgramGroupLimit>({
    url: `/web/v2/programs/${programId}/groups/${groupId}/limit`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: programGroupLimit,
    params,
  });
};

export const getProgramGroupLimitCreateMutationOptions = <
  TError =
    | Error400LimitInvalidPayloadResponse
    | Error403LimitForbiddenResponse
    | Error409LimitAlreadyExistsResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupLimitCreate>>,
    TError,
    {
      programId: string;
      groupId: string;
      data: NonReadonly<ProgramGroupLimit>;
      params: ProgramGroupLimitCreateParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof programGroupLimitCreate>>,
  TError,
  {
    programId: string;
    groupId: string;
    data: NonReadonly<ProgramGroupLimit>;
    params: ProgramGroupLimitCreateParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programGroupLimitCreate>>,
    {
      programId: string;
      groupId: string;
      data: NonReadonly<ProgramGroupLimit>;
      params: ProgramGroupLimitCreateParams;
    }
  > = (props) => {
    const { programId, groupId, data, params } = props ?? {};

    return programGroupLimitCreate(programId, groupId, data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProgramGroupLimitCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof programGroupLimitCreate>>
>;
export type ProgramGroupLimitCreateMutationBody =
  NonReadonly<ProgramGroupLimit>;
export type ProgramGroupLimitCreateMutationError =
  | Error400LimitInvalidPayloadResponse
  | Error403LimitForbiddenResponse
  | Error409LimitAlreadyExistsResponse;

export const useProgramGroupLimitCreate = <
  TError =
    | Error400LimitInvalidPayloadResponse
    | Error403LimitForbiddenResponse
    | Error409LimitAlreadyExistsResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupLimitCreate>>,
    TError,
    {
      programId: string;
      groupId: string;
      data: NonReadonly<ProgramGroupLimit>;
      params: ProgramGroupLimitCreateParams;
    },
    TContext
  >;
}) => {
  const mutationOptions = getProgramGroupLimitCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Deletes personalized limit

 */
export const programGroupLimitDelete = (
  programId: string,
  groupId: string,
  params: ProgramGroupLimitDeleteParams,
) => {
  return customInstance<void>({
    url: `/web/v2/programs/${programId}/groups/${groupId}/limit`,
    method: 'delete',
    params,
  });
};

export const getProgramGroupLimitDeleteMutationOptions = <
  TError = Error | Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupLimitDelete>>,
    TError,
    {
      programId: string;
      groupId: string;
      params: ProgramGroupLimitDeleteParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof programGroupLimitDelete>>,
  TError,
  { programId: string; groupId: string; params: ProgramGroupLimitDeleteParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programGroupLimitDelete>>,
    {
      programId: string;
      groupId: string;
      params: ProgramGroupLimitDeleteParams;
    }
  > = (props) => {
    const { programId, groupId, params } = props ?? {};

    return programGroupLimitDelete(programId, groupId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProgramGroupLimitDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof programGroupLimitDelete>>
>;

export type ProgramGroupLimitDeleteMutationError =
  | Error
  | Error404NotFoundResponse;

export const useProgramGroupLimitDelete = <
  TError = Error | Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupLimitDelete>>,
    TError,
    {
      programId: string;
      groupId: string;
      params: ProgramGroupLimitDeleteParams;
    },
    TContext
  >;
}) => {
  const mutationOptions = getProgramGroupLimitDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};
export const programGroupLimitDisplayPut = (
  programGroupLimitId: string,
  modifyDisplayBudgetLimitRequest: ModifyDisplayBudgetLimitRequest,
  params: ProgramGroupLimitDisplayPutParams,
) => {
  return customInstance<ProgramGroupLimit>({
    url: `/web/v2/program-group-limits/${programGroupLimitId}`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: modifyDisplayBudgetLimitRequest,
    params,
  });
};

export const getProgramGroupLimitDisplayPutMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupLimitDisplayPut>>,
    TError,
    {
      programGroupLimitId: string;
      data: ModifyDisplayBudgetLimitRequest;
      params: ProgramGroupLimitDisplayPutParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof programGroupLimitDisplayPut>>,
  TError,
  {
    programGroupLimitId: string;
    data: ModifyDisplayBudgetLimitRequest;
    params: ProgramGroupLimitDisplayPutParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programGroupLimitDisplayPut>>,
    {
      programGroupLimitId: string;
      data: ModifyDisplayBudgetLimitRequest;
      params: ProgramGroupLimitDisplayPutParams;
    }
  > = (props) => {
    const { programGroupLimitId, data, params } = props ?? {};

    return programGroupLimitDisplayPut(programGroupLimitId, data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProgramGroupLimitDisplayPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof programGroupLimitDisplayPut>>
>;
export type ProgramGroupLimitDisplayPutMutationBody =
  ModifyDisplayBudgetLimitRequest;
export type ProgramGroupLimitDisplayPutMutationError = unknown;

export const useProgramGroupLimitDisplayPut = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupLimitDisplayPut>>,
    TError,
    {
      programGroupLimitId: string;
      data: ModifyDisplayBudgetLimitRequest;
      params: ProgramGroupLimitDisplayPutParams;
    },
    TContext
  >;
}) => {
  const mutationOptions =
    getProgramGroupLimitDisplayPutMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Patch program groups limit display

 */
export const programGroupsLimitDisplayPatch = (
  modifyDisplayBudgetLimitRequest: ModifyDisplayBudgetLimitRequest,
  params?: ProgramGroupsLimitDisplayPatchParams,
) => {
  return customInstance<void>({
    url: `/web/v2/program_group_limits/display_limit`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: modifyDisplayBudgetLimitRequest,
    params,
  });
};

export const getProgramGroupsLimitDisplayPatchMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupsLimitDisplayPatch>>,
    TError,
    {
      data: ModifyDisplayBudgetLimitRequest;
      params?: ProgramGroupsLimitDisplayPatchParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof programGroupsLimitDisplayPatch>>,
  TError,
  {
    data: ModifyDisplayBudgetLimitRequest;
    params?: ProgramGroupsLimitDisplayPatchParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programGroupsLimitDisplayPatch>>,
    {
      data: ModifyDisplayBudgetLimitRequest;
      params?: ProgramGroupsLimitDisplayPatchParams;
    }
  > = (props) => {
    const { data, params } = props ?? {};

    return programGroupsLimitDisplayPatch(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProgramGroupsLimitDisplayPatchMutationResult = NonNullable<
  Awaited<ReturnType<typeof programGroupsLimitDisplayPatch>>
>;
export type ProgramGroupsLimitDisplayPatchMutationBody =
  ModifyDisplayBudgetLimitRequest;
export type ProgramGroupsLimitDisplayPatchMutationError = unknown;

export const useProgramGroupsLimitDisplayPatch = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupsLimitDisplayPatch>>,
    TError,
    {
      data: ModifyDisplayBudgetLimitRequest;
      params?: ProgramGroupsLimitDisplayPatchParams;
    },
    TContext
  >;
}) => {
  const mutationOptions =
    getProgramGroupsLimitDisplayPatchMutationOptions(options);

  return useMutation(mutationOptions);
};
export const programProductsGet = (
  programId: string,
  params?: ProgramProductsGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<ProductsGetResponse>({
    url: `/web/v2/programs/${programId}/products`,
    method: 'get',
    params,
    signal,
  });
};

export const getProgramProductsGetQueryKey = (
  programId: string,
  params?: ProgramProductsGetParams,
) =>
  [
    `/web/v2/programs/${programId}/products`,
    ...(params ? [params] : []),
  ] as const;

export const getProgramProductsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof programProductsGet>>,
  TError = unknown,
>(
  programId: string,
  params?: ProgramProductsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programProductsGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof programProductsGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getProgramProductsGetQueryKey(programId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof programProductsGet>>
  > = ({ signal }) => programProductsGet(programId, params, signal);

  return { queryKey, queryFn, enabled: !!programId, ...queryOptions };
};

export type ProgramProductsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof programProductsGet>>
>;
export type ProgramProductsGetQueryError = unknown;

export const useProgramProductsGet = <
  TData = Awaited<ReturnType<typeof programProductsGet>>,
  TError = unknown,
>(
  programId: string,
  params?: ProgramProductsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programProductsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProgramProductsGetQueryOptions(
    programId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const programProductGet = (
  programId: string,
  productId: string,
  signal?: AbortSignal,
) => {
  return customInstance<ProgramProduct>({
    url: `/web/v2/programs/${programId}/products/${productId}`,
    method: 'get',
    signal,
  });
};

export const getProgramProductGetQueryKey = (
  programId: string,
  productId: string,
) => [`/web/v2/programs/${programId}/products/${productId}`] as const;

export const getProgramProductGetQueryOptions = <
  TData = Awaited<ReturnType<typeof programProductGet>>,
  TError = unknown,
>(
  programId: string,
  productId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programProductGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof programProductGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getProgramProductGetQueryKey(programId, productId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof programProductGet>>
  > = ({ signal }) => programProductGet(programId, productId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(programId && productId),
    ...queryOptions,
  };
};

export type ProgramProductGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof programProductGet>>
>;
export type ProgramProductGetQueryError = unknown;

export const useProgramProductGet = <
  TData = Awaited<ReturnType<typeof programProductGet>>,
  TError = unknown,
>(
  programId: string,
  productId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programProductGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProgramProductGetQueryOptions(
    programId,
    productId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const programProductUpdate = (
  programId: string,
  productId: string,
  programProductConfiguration: ProgramProductConfiguration,
) => {
  return customInstance<ProgramProduct>({
    url: `/web/v2/programs/${programId}/products/${productId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: programProductConfiguration,
  });
};

export const getProgramProductUpdateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programProductUpdate>>,
    TError,
    { programId: string; productId: string; data: ProgramProductConfiguration },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof programProductUpdate>>,
  TError,
  { programId: string; productId: string; data: ProgramProductConfiguration },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programProductUpdate>>,
    { programId: string; productId: string; data: ProgramProductConfiguration }
  > = (props) => {
    const { programId, productId, data } = props ?? {};

    return programProductUpdate(programId, productId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProgramProductUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof programProductUpdate>>
>;
export type ProgramProductUpdateMutationBody = ProgramProductConfiguration;
export type ProgramProductUpdateMutationError = unknown;

export const useProgramProductUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programProductUpdate>>,
    TError,
    { programId: string; productId: string; data: ProgramProductConfiguration },
    TContext
  >;
}) => {
  const mutationOptions = getProgramProductUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const programServicesGet = (
  programId: string,
  params?: ProgramServicesGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<ProgramServicesGetResponse>({
    url: `/web/v2/programs/${programId}/services`,
    method: 'get',
    params,
    signal,
  });
};

export const getProgramServicesGetQueryKey = (
  programId: string,
  params?: ProgramServicesGetParams,
) =>
  [
    `/web/v2/programs/${programId}/services`,
    ...(params ? [params] : []),
  ] as const;

export const getProgramServicesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof programServicesGet>>,
  TError = unknown,
>(
  programId: string,
  params?: ProgramServicesGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programServicesGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof programServicesGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getProgramServicesGetQueryKey(programId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof programServicesGet>>
  > = ({ signal }) => programServicesGet(programId, params, signal);

  return { queryKey, queryFn, enabled: !!programId, ...queryOptions };
};

export type ProgramServicesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof programServicesGet>>
>;
export type ProgramServicesGetQueryError = unknown;

export const useProgramServicesGet = <
  TData = Awaited<ReturnType<typeof programServicesGet>>,
  TError = unknown,
>(
  programId: string,
  params?: ProgramServicesGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programServicesGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProgramServicesGetQueryOptions(
    programId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const programServicesUpdate = (
  programId: string,
  programServicesUpdateRequest: ProgramServicesUpdateRequest,
) => {
  return customInstance<ProgramServicesUpdateResponse>({
    url: `/web/v2/programs/${programId}/services`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: programServicesUpdateRequest,
  });
};

export const getProgramServicesUpdateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programServicesUpdate>>,
    TError,
    { programId: string; data: ProgramServicesUpdateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof programServicesUpdate>>,
  TError,
  { programId: string; data: ProgramServicesUpdateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programServicesUpdate>>,
    { programId: string; data: ProgramServicesUpdateRequest }
  > = (props) => {
    const { programId, data } = props ?? {};

    return programServicesUpdate(programId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProgramServicesUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof programServicesUpdate>>
>;
export type ProgramServicesUpdateMutationBody = ProgramServicesUpdateRequest;
export type ProgramServicesUpdateMutationError = unknown;

export const useProgramServicesUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programServicesUpdate>>,
    TError,
    { programId: string; data: ProgramServicesUpdateRequest },
    TContext
  >;
}) => {
  const mutationOptions = getProgramServicesUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const programServiceGet = (
  programId: string,
  serviceId: string,
  signal?: AbortSignal,
) => {
  return customInstance<ProgramService>({
    url: `/web/v2/programs/${programId}/services/${serviceId}`,
    method: 'get',
    signal,
  });
};

export const getProgramServiceGetQueryKey = (
  programId: string,
  serviceId: string,
) => [`/web/v2/programs/${programId}/services/${serviceId}`] as const;

export const getProgramServiceGetQueryOptions = <
  TData = Awaited<ReturnType<typeof programServiceGet>>,
  TError = unknown,
>(
  programId: string,
  serviceId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programServiceGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof programServiceGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getProgramServiceGetQueryKey(programId, serviceId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof programServiceGet>>
  > = ({ signal }) => programServiceGet(programId, serviceId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(programId && serviceId),
    ...queryOptions,
  };
};

export type ProgramServiceGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof programServiceGet>>
>;
export type ProgramServiceGetQueryError = unknown;

export const useProgramServiceGet = <
  TData = Awaited<ReturnType<typeof programServiceGet>>,
  TError = unknown,
>(
  programId: string,
  serviceId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programServiceGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProgramServiceGetQueryOptions(
    programId,
    serviceId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const programServiceUpdate = (
  programId: string,
  serviceId: string,
  programServiceUpdateRequest: ProgramServiceUpdateRequest,
) => {
  return customInstance<void>({
    url: `/web/v2/programs/${programId}/services/${serviceId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: programServiceUpdateRequest,
  });
};

export const getProgramServiceUpdateMutationOptions = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programServiceUpdate>>,
    TError,
    { programId: string; serviceId: string; data: ProgramServiceUpdateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof programServiceUpdate>>,
  TError,
  { programId: string; serviceId: string; data: ProgramServiceUpdateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programServiceUpdate>>,
    { programId: string; serviceId: string; data: ProgramServiceUpdateRequest }
  > = (props) => {
    const { programId, serviceId, data } = props ?? {};

    return programServiceUpdate(programId, serviceId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProgramServiceUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof programServiceUpdate>>
>;
export type ProgramServiceUpdateMutationBody = ProgramServiceUpdateRequest;
export type ProgramServiceUpdateMutationError = Error;

export const useProgramServiceUpdate = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programServiceUpdate>>,
    TError,
    { programId: string; serviceId: string; data: ProgramServiceUpdateRequest },
    TContext
  >;
}) => {
  const mutationOptions = getProgramServiceUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Changes the status of the program to INACTIVE
and removes related program_groups and program_services.

 */
export const programDeactivate = (programId: string) => {
  return customInstance<void>({
    url: `/web/v2/programs/${programId}/deactivate`,
    method: 'post',
  });
};

export const getProgramDeactivateMutationOptions = <
  TError = Error | Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programDeactivate>>,
    TError,
    { programId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof programDeactivate>>,
  TError,
  { programId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programDeactivate>>,
    { programId: string }
  > = (props) => {
    const { programId } = props ?? {};

    return programDeactivate(programId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProgramDeactivateMutationResult = NonNullable<
  Awaited<ReturnType<typeof programDeactivate>>
>;

export type ProgramDeactivateMutationError = Error | Error404NotFoundResponse;

export const useProgramDeactivate = <
  TError = Error | Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programDeactivate>>,
    TError,
    { programId: string },
    TContext
  >;
}) => {
  const mutationOptions = getProgramDeactivateMutationOptions(options);

  return useMutation(mutationOptions);
};
