/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  DocumentV1,
  DocumentCreateBody,
  DocumentType,
} from '../../models';
import { customInstance } from '../../config';

export const documentCreate = (documentCreateBody: DocumentCreateBody) => {
  return customInstance<DocumentV1>({
    url: `/web/documents`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: documentCreateBody,
  });
};

export type DocumentCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof documentCreate>>
>;
export type DocumentCreateMutationBody = DocumentCreateBody;
export type DocumentCreateMutationError = unknown;

export const useDocumentCreate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof documentCreate>>,
    TError,
    { data: DocumentCreateBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof documentCreate>>,
    { data: DocumentCreateBody }
  > = (props) => {
    const { data } = props ?? {};

    return documentCreate(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof documentCreate>>,
    TError,
    { data: DocumentCreateBody },
    TContext
  >(mutationFn, mutationOptions);
};
export const documentsTypesGet = (signal?: AbortSignal) => {
  return customInstance<DocumentType[]>({
    url: `/web/documents/types`,
    method: 'get',
    signal,
  });
};

export const getDocumentsTypesGetQueryKey = () => [`/web/documents/types`];

export type DocumentsTypesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof documentsTypesGet>>
>;
export type DocumentsTypesGetQueryError = unknown;

export const useDocumentsTypesGet = <
  TData = Awaited<ReturnType<typeof documentsTypesGet>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof documentsTypesGet>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDocumentsTypesGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof documentsTypesGet>>
  > = ({ signal }) => documentsTypesGet(signal);

  const query = useQuery<
    Awaited<ReturnType<typeof documentsTypesGet>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};
