/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  ResetPasswordBody,
  ForgotPasswordBody,
  GetConfigurationByPhoneNumberParams,
  GetConfigurationByEmailParams,
  GetConfigurationBySubjectParams,
  GetConfigurationByTokenParams,
  SetAuthenticationMappingRequest,
} from '../../models';
import type { GetConfigurationResponse } from '../../models/component-auth';
import type { Phone, Email } from '../../models/component-common';
import { customInstance } from '../../config';

/**
 * This path is rate limited to 10 failing calls per minute.
Once threshold is reached, the IP will be blocked for 1 minute.
The limit is shared between all calls of /web/auth and /web/v2/auth

 */
export const resetPassword = (resetPasswordBody: ResetPasswordBody) => {
  return customInstance<void>({
    url: `/web/v2/auth/reset-password`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: resetPasswordBody,
  });
};

export const getResetPasswordMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetPassword>>,
    TError,
    { data: ResetPasswordBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof resetPassword>>,
  TError,
  { data: ResetPasswordBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resetPassword>>,
    { data: ResetPasswordBody }
  > = (props) => {
    const { data } = props ?? {};

    return resetPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof resetPassword>>
>;
export type ResetPasswordMutationBody = ResetPasswordBody;
export type ResetPasswordMutationError = unknown;

export const useResetPassword = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetPassword>>,
    TError,
    { data: ResetPasswordBody },
    TContext
  >;
}) => {
  const mutationOptions = getResetPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * This path is rate limited to 10 failing calls per minute.
Once threshold is reached, the IP will be blocked for 1 minute.
The limit is shared between all calls of /web/auth and /web/v2/auth

 */
export const forgotPassword = (forgotPasswordBody: ForgotPasswordBody) => {
  return customInstance<void>({
    url: `/web/v2/auth/forgot-password`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: forgotPasswordBody,
  });
};

export const getForgotPasswordMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof forgotPassword>>,
    TError,
    { data: ForgotPasswordBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof forgotPassword>>,
  TError,
  { data: ForgotPasswordBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof forgotPassword>>,
    { data: ForgotPasswordBody }
  > = (props) => {
    const { data } = props ?? {};

    return forgotPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ForgotPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof forgotPassword>>
>;
export type ForgotPasswordMutationBody = ForgotPasswordBody;
export type ForgotPasswordMutationError = unknown;

export const useForgotPassword = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof forgotPassword>>,
    TError,
    { data: ForgotPasswordBody },
    TContext
  >;
}) => {
  const mutationOptions = getForgotPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * This path is rate limited to 10 failing calls per minute.
Once threshold is reached, the IP will be blocked for 1 minute.
The limit is shared between all calls of /web/auth and /web/v2/auth

 */
export const getConfigurationByPhoneNumber = (
  phoneNumber: Phone,
  params?: GetConfigurationByPhoneNumberParams,
  signal?: AbortSignal,
) => {
  return customInstance<GetConfigurationResponse>({
    url: `/web/v2/auth/configuration/phone_number/${phoneNumber}`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetConfigurationByPhoneNumberQueryKey = (
  phoneNumber: Phone,
  params?: GetConfigurationByPhoneNumberParams,
) =>
  [
    `/web/v2/auth/configuration/phone_number/${phoneNumber}`,
    ...(params ? [params] : []),
  ] as const;

export const getGetConfigurationByPhoneNumberQueryOptions = <
  TData = Awaited<ReturnType<typeof getConfigurationByPhoneNumber>>,
  TError = unknown,
>(
  phoneNumber: Phone,
  params?: GetConfigurationByPhoneNumberParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConfigurationByPhoneNumber>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getConfigurationByPhoneNumber>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetConfigurationByPhoneNumberQueryKey(phoneNumber, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getConfigurationByPhoneNumber>>
  > = ({ signal }) =>
    getConfigurationByPhoneNumber(phoneNumber, params, signal);

  return { queryKey, queryFn, enabled: !!phoneNumber, ...queryOptions };
};

export type GetConfigurationByPhoneNumberQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConfigurationByPhoneNumber>>
>;
export type GetConfigurationByPhoneNumberQueryError = unknown;

export const useGetConfigurationByPhoneNumber = <
  TData = Awaited<ReturnType<typeof getConfigurationByPhoneNumber>>,
  TError = unknown,
>(
  phoneNumber: Phone,
  params?: GetConfigurationByPhoneNumberParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConfigurationByPhoneNumber>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetConfigurationByPhoneNumberQueryOptions(
    phoneNumber,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Make sure to URL-encode the email!

This path is rate limited to 10 failing calls per minute.
Once threshold is reached, the IP will be blocked for 1 minute.
The limit is shared between all calls of /web/auth and /web/v2/auth

 */
export const getConfigurationByEmail = (
  email: Email,
  params?: GetConfigurationByEmailParams,
  signal?: AbortSignal,
) => {
  return customInstance<GetConfigurationResponse>({
    url: `/web/v2/auth/configuration/email/${email}`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetConfigurationByEmailQueryKey = (
  email: Email,
  params?: GetConfigurationByEmailParams,
) =>
  [
    `/web/v2/auth/configuration/email/${email}`,
    ...(params ? [params] : []),
  ] as const;

export const getGetConfigurationByEmailQueryOptions = <
  TData = Awaited<ReturnType<typeof getConfigurationByEmail>>,
  TError = unknown,
>(
  email: Email,
  params?: GetConfigurationByEmailParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConfigurationByEmail>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getConfigurationByEmail>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetConfigurationByEmailQueryKey(email, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getConfigurationByEmail>>
  > = ({ signal }) => getConfigurationByEmail(email, params, signal);

  return { queryKey, queryFn, enabled: !!email, ...queryOptions };
};

export type GetConfigurationByEmailQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConfigurationByEmail>>
>;
export type GetConfigurationByEmailQueryError = unknown;

export const useGetConfigurationByEmail = <
  TData = Awaited<ReturnType<typeof getConfigurationByEmail>>,
  TError = unknown,
>(
  email: Email,
  params?: GetConfigurationByEmailParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConfigurationByEmail>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetConfigurationByEmailQueryOptions(
    email,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Please note that subject is recognized only for activated SSO users.
It won't be recognized before successful account activation (sso subject linking)

This path is rate limited to 10 failing calls per minute.
Once threshold is reached, the IP will be blocked for 1 minute.
The limit is shared between all calls of /web/auth and /web/v2/auth

 */
export const getConfigurationBySubject = (
  subject: string,
  params?: GetConfigurationBySubjectParams,
  signal?: AbortSignal,
) => {
  return customInstance<GetConfigurationResponse>({
    url: `/web/v2/auth/configuration/subject/${subject}`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetConfigurationBySubjectQueryKey = (
  subject: string,
  params?: GetConfigurationBySubjectParams,
) =>
  [
    `/web/v2/auth/configuration/subject/${subject}`,
    ...(params ? [params] : []),
  ] as const;

export const getGetConfigurationBySubjectQueryOptions = <
  TData = Awaited<ReturnType<typeof getConfigurationBySubject>>,
  TError = unknown,
>(
  subject: string,
  params?: GetConfigurationBySubjectParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConfigurationBySubject>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getConfigurationBySubject>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetConfigurationBySubjectQueryKey(subject, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getConfigurationBySubject>>
  > = ({ signal }) => getConfigurationBySubject(subject, params, signal);

  return { queryKey, queryFn, enabled: !!subject, ...queryOptions };
};

export type GetConfigurationBySubjectQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConfigurationBySubject>>
>;
export type GetConfigurationBySubjectQueryError = unknown;

export const useGetConfigurationBySubject = <
  TData = Awaited<ReturnType<typeof getConfigurationBySubject>>,
  TError = unknown,
>(
  subject: string,
  params?: GetConfigurationBySubjectParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConfigurationBySubject>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetConfigurationBySubjectQueryOptions(
    subject,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Please use get_configuration_by_email since JWT token already carries an email frontend can access and use
Make sure to pass invitation token as X-Authorization header

This path is rate limited to 10 failing calls per minute.
Once threshold is reached, the IP will be blocked for 1 minute.
The limit is shared between all calls of /web/auth and /web/v2/auth

 * @deprecated
 */
export const getConfigurationByToken = (
  params?: GetConfigurationByTokenParams,
  signal?: AbortSignal,
) => {
  return customInstance<GetConfigurationResponse>({
    url: `/web/v2/auth/configuration/token`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetConfigurationByTokenQueryKey = (
  params?: GetConfigurationByTokenParams,
) => [`/web/v2/auth/configuration/token`, ...(params ? [params] : [])] as const;

export const getGetConfigurationByTokenQueryOptions = <
  TData = Awaited<ReturnType<typeof getConfigurationByToken>>,
  TError = unknown,
>(
  params?: GetConfigurationByTokenParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConfigurationByToken>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getConfigurationByToken>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetConfigurationByTokenQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getConfigurationByToken>>
  > = ({ signal }) => getConfigurationByToken(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetConfigurationByTokenQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConfigurationByToken>>
>;
export type GetConfigurationByTokenQueryError = unknown;

/**
 * @deprecated
 */
export const useGetConfigurationByToken = <
  TData = Awaited<ReturnType<typeof getConfigurationByToken>>,
  TError = unknown,
>(
  params?: GetConfigurationByTokenParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConfigurationByToken>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetConfigurationByTokenQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Creates user specific authentication mapping between Skipr user identity and SSO identity.

Make sure to pass invitation token as X-Authorization header

This path is rate limited to 10 failing calls per minute.
Once threshold is reached, the IP will be blocked for 1 minute.
The limit is shared between all calls of /web/auth and /web/v2/auth

 */
export const setAuthMapping = (
  setAuthenticationMappingRequest: SetAuthenticationMappingRequest,
) => {
  return customInstance<void>({
    url: `/web/v2/auth/configuration`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: setAuthenticationMappingRequest,
  });
};

export const getSetAuthMappingMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setAuthMapping>>,
    TError,
    { data: SetAuthenticationMappingRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setAuthMapping>>,
  TError,
  { data: SetAuthenticationMappingRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setAuthMapping>>,
    { data: SetAuthenticationMappingRequest }
  > = (props) => {
    const { data } = props ?? {};

    return setAuthMapping(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetAuthMappingMutationResult = NonNullable<
  Awaited<ReturnType<typeof setAuthMapping>>
>;
export type SetAuthMappingMutationBody = SetAuthenticationMappingRequest;
export type SetAuthMappingMutationError = unknown;

export const useSetAuthMapping = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setAuthMapping>>,
    TError,
    { data: SetAuthenticationMappingRequest },
    TContext
  >;
}) => {
  const mutationOptions = getSetAuthMappingMutationOptions(options);

  return useMutation(mutationOptions);
};
