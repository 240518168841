import {
  Box,
  Flex,
  List,
  ListItem,
  Text,
  useBreakpointValue,
  useTheme,
} from '@chakra-ui/react';
import { Link } from '@libs/ui/components';
import React, { ReactNode } from 'react';
import { useMatch } from 'react-router';
import { Menu, MenuItems } from '../menu';

type MenuListItemProps = {
  exact?: boolean;
  link: string;
  isOpen: boolean;
  icon: ReactNode;
  children: ReactNode;
  disable?: boolean;
  level?: number;
};

const MenuListItem = ({
  exact,
  link,
  isOpen,
  icon,
  children,
  disable,
  level,
}: MenuListItemProps) => {
  const theme = useTheme();
  const breakpoint = useBreakpointValue({ base: 'base', lg: 'lg' });
  const isRouteMatch = !!useMatch({ path: link, end: exact });

  const active = !disable && isRouteMatch;

  return (
    <ListItem
      transition="ease .4s all"
      role="group"
      _hover={{ bgColor: 'nav.background.item', opacity: 0.8 }}
      bgColor={active ? 'nav.background.item' : 'transparent'}
    >
      <Link to={link}>
        <Flex align="center" h="5rem">
          <Box
            h="5rem"
            w=".3rem"
            bgColor={active ? 'nav.background.leftbar' : 'transparent'}
            mr={{ base: isOpen ? '1.5rem' : '1rem', lg: '1.5rem' }}
          />
          <Box
            pl={level && level > 0 ? `calc(2.5*${level}rem)` : null}
            w="3.2rem"
            h="3.2rem"
            mr={isOpen || breakpoint === 'lg' ? '1.2rem' : '0'}
            transition="ease .4s fill"
            fill={
              active
                ? theme.colors.nav.type.active
                : theme.colors.nav.type.light
            }
            _groupHover={{ fill: theme.colors.nav.type.lighter }}
          >
            {React.cloneElement(icon as React.ReactElement)}
          </Box>

          <Text
            transition="ease .4s color"
            display={isOpen || breakpoint === 'lg' ? 'block' : 'none'}
            color={active ? 'nav.type.active' : 'nav.type.light'}
            _groupHover={{ color: 'nav.type.lighter' }}
            size="Body1"
            fontWeight={active ? 700 : 500}
          >
            {children}
          </Text>
        </Flex>
      </Link>
    </ListItem>
  );
};

type MenuItemProps = MenuListItemProps & {
  items?: MenuItems;
};

export const MenuItem = ({
  children,
  link,
  icon,
  isOpen,
  disable = false,
  exact = false,
  items,
  level,
}: MenuItemProps) => {
  const isRouteMatch = !!useMatch({ path: link, end: exact });
  const isSubMenuOpened = !disable && isRouteMatch;

  return items?.length > 0 ? (
    <ListItem>
      <List>
        <MenuListItem
          level={level}
          exact={exact}
          icon={icon}
          disable={disable}
          isOpen={isOpen}
          link={link}
        >
          {children}
        </MenuListItem>
        {items?.length > 0 && isSubMenuOpened && (
          <List>
            <Menu level={2} items={items} isOpen={isOpen} />
          </List>
        )}
      </List>
    </ListItem>
  ) : (
    <MenuListItem
      exact={exact}
      icon={icon}
      disable={disable}
      isOpen={isOpen}
      link={link}
      level={level}
    >
      {children}
    </MenuListItem>
  );
};
