/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type PolicyConditionField =
  typeof PolicyConditionField[keyof typeof PolicyConditionField];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PolicyConditionField = {
  EXPENSE_AMOUNT: 'EXPENSE_AMOUNT',
} as const;
