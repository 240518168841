/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export interface ProgramMeta {
  total_employees?: number;
  total_products?: number;
  total_services?: number;
  total_expenses?: number;
  total_budget?: number;
  total_policies?: number;
}
