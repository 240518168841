/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { OperatingSystem } from './operatingSystem';
import type { UserLanguage } from './userLanguage';
import type { Platform } from './platform';

export interface DeviceUpdateRequest {
  device_id?: string;
  notification_token?: string;
  os?: OperatingSystem;
  language?: UserLanguage;
  is_disabled?: boolean;
  platform?: Platform;
}
