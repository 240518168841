/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type PolicyConditionStatement =
  typeof PolicyConditionStatement[keyof typeof PolicyConditionStatement];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PolicyConditionStatement = {
  BETWEEN: 'BETWEEN',
} as const;
