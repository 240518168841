/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryUnassignedParameter } from './queryUnassignedParameter';
import type { QueryPageNumberParameter } from './component-parameters/queryPageNumberParameter';
import type { QueryPageSizeParameter } from './component-parameters/queryPageSizeParameter';
import type { QuerySortDefinitionParameter } from './component-parameters/querySortDefinitionParameter';
import type { SearchMerchantNameParameter } from './searchMerchantNameParameter';

export type GetMerchantsParams = {
  /**
   * used as filter to fetch merchants not assigned yet to a service/provider
   */
  unassigned?: QueryUnassignedParameter;
  /**
   * Number of the page to fetch
   */
  page_number?: QueryPageNumberParameter;
  /**
   * Size of the page to fetch
   */
  page_size?: QueryPageSizeParameter;
  /**
   * Result sort order
   */
  sort?: QuerySortDefinitionParameter;
  /**
   * search on merchant first and last_name
   */
  search_merchant_name?: SearchMerchantNameParameter;
};
