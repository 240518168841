/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  MerchantsGetResponseV1,
  ErrorResponse,
  MerchantsGetParams,
  MerchantV1,
  GetMerchantsV2Params,
} from '../../models';
import type {
  MerchantsGetResponse,
  Merchant,
  UpdateMerchantRequest,
} from '../../models/api-web-policy';
import { customInstance } from '../../config';

export const merchantsGet = (
  params?: MerchantsGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<MerchantsGetResponseV1>({
    url: `/web/merchants`,
    method: 'get',
    params,
    signal,
  });
};

export const getMerchantsGetQueryKey = (params?: MerchantsGetParams) => [
  `/web/merchants`,
  ...(params ? [params] : []),
];

export type MerchantsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof merchantsGet>>
>;
export type MerchantsGetQueryError = ErrorResponse;

export const useMerchantsGet = <
  TData = Awaited<ReturnType<typeof merchantsGet>>,
  TError = ErrorResponse,
>(
  params?: MerchantsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof merchantsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getMerchantsGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof merchantsGet>>> = ({
    signal,
  }) => merchantsGet(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof merchantsGet>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const merchantsUpdate = (merchantId: string, merchantV1: MerchantV1) => {
  return customInstance<MerchantV1>({
    url: `/web/merchants/${merchantId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: merchantV1,
  });
};

export type MerchantsUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof merchantsUpdate>>
>;
export type MerchantsUpdateMutationBody = MerchantV1;
export type MerchantsUpdateMutationError = unknown;

export const useMerchantsUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof merchantsUpdate>>,
    TError,
    { merchantId: string; data: MerchantV1 },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof merchantsUpdate>>,
    { merchantId: string; data: MerchantV1 }
  > = (props) => {
    const { merchantId, data } = props ?? {};

    return merchantsUpdate(merchantId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof merchantsUpdate>>,
    TError,
    { merchantId: string; data: MerchantV1 },
    TContext
  >(mutationFn, mutationOptions);
};
export const getMerchantsV2 = (
  params?: GetMerchantsV2Params,
  signal?: AbortSignal,
) => {
  return customInstance<MerchantsGetResponse>({
    url: `/web/v2/merchants`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetMerchantsV2QueryKey = (params?: GetMerchantsV2Params) => [
  `/web/v2/merchants`,
  ...(params ? [params] : []),
];

export type GetMerchantsV2QueryResult = NonNullable<
  Awaited<ReturnType<typeof getMerchantsV2>>
>;
export type GetMerchantsV2QueryError = unknown;

export const useGetMerchantsV2 = <
  TData = Awaited<ReturnType<typeof getMerchantsV2>>,
  TError = unknown,
>(
  params?: GetMerchantsV2Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMerchantsV2>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMerchantsV2QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMerchantsV2>>> = ({
    signal,
  }) => getMerchantsV2(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getMerchantsV2>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * Allows to assign a merchant to service/provider
 */
export const updateMerchantV2 = (
  merchantId: string,
  updateMerchantRequest: UpdateMerchantRequest,
) => {
  return customInstance<Merchant>({
    url: `/web/v2/merchants/${merchantId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: updateMerchantRequest,
  });
};

export type UpdateMerchantV2MutationResult = NonNullable<
  Awaited<ReturnType<typeof updateMerchantV2>>
>;
export type UpdateMerchantV2MutationBody = UpdateMerchantRequest;
export type UpdateMerchantV2MutationError = unknown;

export const useUpdateMerchantV2 = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateMerchantV2>>,
    TError,
    { merchantId: string; data: UpdateMerchantRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateMerchantV2>>,
    { merchantId: string; data: UpdateMerchantRequest }
  > = (props) => {
    const { merchantId, data } = props ?? {};

    return updateMerchantV2(merchantId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateMerchantV2>>,
    TError,
    { merchantId: string; data: UpdateMerchantRequest },
    TContext
  >(mutationFn, mutationOptions);
};
