/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryIncludesMetaParameter } from './component-parameters/queryIncludesMetaParameter';

export type TeamGetParams = {
  /**
   * Includes meta in the results
   */
  includes_meta?: QueryIncludesMetaParameter;
};
