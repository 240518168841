import { Button } from '@chakra-ui/react';
import { resetPassword } from '@libs/api/endpoints';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { validateSchema } from '@libs/core/utils';
import { createApiError } from '@libs/dashboard-core/utils/api-errors';
import {
  FormSubmitData,
  handleFormSubmit,
  InputField,
  useToast,
} from '@libs/ui/components';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getPath, Routes, useQueryParam } from '../../routes';
import { SetPasswordForm, SetPasswordTokenClaims } from './auth.types';
import { decodeJWT } from './auth.utils';
import { validationSetPasswordSchema } from './auth.validations';
import { AuthLayout } from './components/auth-layout';

export const SetPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const originalToken = useQueryParam('token');
  const { token, error } = decodeJWT<SetPasswordTokenClaims>(originalToken);
  const [firstSetPassword, setFirstSetPassword] = useState(false);
  const toast = useToast();

  const { mutateAsync: submit } = useMutation(
    ({ values }: FormSubmitData<SetPasswordForm>) => {
      return resetPassword({ token: originalToken, password: values.password });
    },
    {
      onSuccess: () => {
        setFirstSetPassword(true);
      },
      onError: ({ response }) => {
        if (response) {
          const error = createApiError(response.data);
          toast({
            title: t(i18nKeys.common.unauthorized),
            content: t(
              i18nKeys.http_errors[error.translation.key] || error.detail,
            ),
            status: 'error',
            options: {
              duration: 15000,
            },
          });
        }
      },
    },
  );

  if (error) {
    return (
      <AuthLayout
        title={t(i18nKeys.confirm_reset_password.title)}
        introduction={t(i18nKeys.confirm_reset_password.errors.code_invalid)}
      >
        <Button
          type="submit"
          variant="primary"
          size="body1"
          mt="4rem"
          onClick={() => navigate(getPath(Routes.AUTH_RESET_PASSWORD))}
        >
          {t(i18nKeys.confirm_reset_password.errors.cta)}
        </Button>
      </AuthLayout>
    );
  }

  if (!firstSetPassword) {
    return (
      <AuthLayout title={t(i18nKeys.set_password.introduction)}>
        <Formik<SetPasswordForm>
          initialValues={{
            email: token.email,
            password: '',
            passwordConfirmed: '',
          }}
          validate={validateSchema(validationSetPasswordSchema)}
          onSubmit={handleFormSubmit(submit)}
        >
          {({ isSubmitting }) => (
            <Form>
              <InputField
                name="email"
                label={t(i18nKeys.common.email)}
                isRequired
              />
              <InputField
                name="password"
                label={t(i18nKeys.common.password)}
                isRequired
                isPassword
                hasValidation
              />
              <InputField
                name="passwordConfirmed"
                label={t(i18nKeys.confirm_reset_password.confirm_password)}
                isRequired
                isPassword
              />
              <Button
                type="submit"
                variant="primary"
                size="body1"
                mt="4rem"
                w={{ base: '100%', md: 'auto' }}
                isLoading={isSubmitting}
              >
                {t(i18nKeys.set_password.confirm)}
              </Button>
            </Form>
          )}
        </Formik>
      </AuthLayout>
    );
  }
  return (
    <AuthLayout
      title={t(i18nKeys.set_password.success.title)}
      introduction={t(i18nKeys.set_password.success.introduction)}
    >
      <Button
        onClick={() => navigate(getPath(Routes.AUTH_LOGIN))}
        variant="primary"
        size="body1"
        mt="4rem"
        w={{ base: '100%', md: 'auto' }}
        data-cy="set-password-button-success"
      >
        {t(i18nKeys.set_password.success.cta)}
      </Button>
    </AuthLayout>
  );
};
