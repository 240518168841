/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryOrganisationIdParameter } from './component-parameters/queryOrganisationIdParameter';
import type { QueryProgramIdParameter } from './component-parameters/queryProgramIdParameter';
import type { QueryTeamIdParameter } from './component-parameters/queryTeamIdParameter';
import type { QueryQParameter } from './component-parameters/queryQParameter';
import type { QueryPageNumberParameter } from './component-parameters/queryPageNumberParameter';
import type { QueryPageSizeParameter } from './component-parameters/queryPageSizeParameter';
import type { QueryRegistrationStatusParameter } from './queryRegistrationStatusParameter';
import type { QueryRolesParameter } from './queryRolesParameter';
import type { QuerySingleRoleParameter } from './querySingleRoleParameter';
import type { QueryIsSuspendedParameter } from './queryIsSuspendedParameter';
import type { QueryIsArchivedParameter } from './queryIsArchivedParameter';
import type { NoTeamParameter } from './noTeamParameter';
import type { NoGroupParameter } from './noGroupParameter';
import type { QueryHasMembershipActionParameter } from './queryHasMembershipActionParameter';

export type RegistrationsGetParams = {
  /**
   * ID of the organisation
   */
  organisation_id?: QueryOrganisationIdParameter;
  /**
   * ID of the program
   */
  program_id?: QueryProgramIdParameter;
  /**
   * ID of the team
   */
  team_id?: QueryTeamIdParameter;
  /**
   * Search query parameter
   */
  q?: QueryQParameter;
  /**
   * Number of the page to fetch
   */
  page_number?: QueryPageNumberParameter;
  /**
   * Size of the page to fetch
   */
  page_size?: QueryPageSizeParameter;
  /**
   * Filter on status of a registration
   */
  status?: QueryRegistrationStatusParameter;
  /**
   * Repeat "role" param multiple times to include multiple roles
   */
  role?: QueryRolesParameter;
  /**
   * Registrations should only have a single role. E.g. a user which is both an EMPLOYEE and ADMIN will be excluded. Can be used together with the roles filter.
   */
  single_role?: QuerySingleRoleParameter;
  /**
   * Membership is suspended
   */
  is_suspended?: QueryIsSuspendedParameter;
  /**
   * Membership is archived
   */
  is_archived?: QueryIsArchivedParameter;
  /**
   * Will include registrations without team if enabled
   */
  no_team?: NoTeamParameter;
  /**
   * Will include registrations without group if enabled
   */
  no_group?: NoGroupParameter;
  /**
   * Filter memberships with a planned membership action. E.g. providing `SUSPENSION` will only return memberships with a planned suspension.
   */
  has_membership_action?: QueryHasMembershipActionParameter;
};
