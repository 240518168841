/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type QueryPolicyTypeParameter =
  typeof QueryPolicyTypeParameter[keyof typeof QueryPolicyTypeParameter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QueryPolicyTypeParameter = {
  EXPENSE: 'EXPENSE',
  REFUND: 'REFUND',
} as const;
