/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type EntityType = typeof EntityType[keyof typeof EntityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityType = {
  REPORTING_FINANCIAL_INSTITUTION: 'REPORTING_FINANCIAL_INSTITUTION',
  NON_REPORTING_FINANCIAL_INSTITUTION: 'NON_REPORTING_FINANCIAL_INSTITUTION',
  ACTIVE_NON_FINANCIAL_ENTITY_GOVERNEMENT:
    'ACTIVE_NON_FINANCIAL_ENTITY_GOVERNEMENT',
  ACTIVE_NON_FINANCIAL_ENTITY_OTHER: 'ACTIVE_NON_FINANCIAL_ENTITY_OTHER',
  PASSIVE_NON_FINANCIAL_ENTITY: 'PASSIVE_NON_FINANCIAL_ENTITY',
} as const;
