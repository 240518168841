/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  ProviderListResponse,
  ListProvidersParams,
  ProviderDetails,
  GetProviderDetailsParams,
} from '../../models';
import { customInstance } from '../../config';

export const listProviders = (
  params?: ListProvidersParams,
  signal?: AbortSignal,
) => {
  return customInstance<ProviderListResponse>({
    url: `/web/v2/providers`,
    method: 'get',
    params,
    signal,
  });
};

export const getListProvidersQueryKey = (params?: ListProvidersParams) =>
  [`/web/v2/providers`, ...(params ? [params] : [])] as const;

export const getListProvidersQueryOptions = <
  TData = Awaited<ReturnType<typeof listProviders>>,
  TError = unknown,
>(
  params?: ListProvidersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listProviders>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<Awaited<ReturnType<typeof listProviders>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListProvidersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listProviders>>> = ({
    signal,
  }) => listProviders(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListProvidersQueryResult = NonNullable<
  Awaited<ReturnType<typeof listProviders>>
>;
export type ListProvidersQueryError = unknown;

export const useListProviders = <
  TData = Awaited<ReturnType<typeof listProviders>>,
  TError = unknown,
>(
  params?: ListProvidersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listProviders>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListProvidersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get the details of a provider
 */
export const getProviderDetails = (
  providerId: string,
  params: GetProviderDetailsParams,
  signal?: AbortSignal,
) => {
  return customInstance<ProviderDetails>({
    url: `/web/v2/providers/${providerId}/details`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetProviderDetailsQueryKey = (
  providerId: string,
  params: GetProviderDetailsParams,
) =>
  [
    `/web/v2/providers/${providerId}/details`,
    ...(params ? [params] : []),
  ] as const;

export const getGetProviderDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof getProviderDetails>>,
  TError = unknown,
>(
  providerId: string,
  params: GetProviderDetailsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getProviderDetails>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getProviderDetails>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetProviderDetailsQueryKey(providerId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getProviderDetails>>
  > = ({ signal }) => getProviderDetails(providerId, params, signal);

  return { queryKey, queryFn, enabled: !!providerId, ...queryOptions };
};

export type GetProviderDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProviderDetails>>
>;
export type GetProviderDetailsQueryError = unknown;

export const useGetProviderDetails = <
  TData = Awaited<ReturnType<typeof getProviderDetails>>,
  TError = unknown,
>(
  providerId: string,
  params: GetProviderDetailsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getProviderDetails>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProviderDetailsQueryOptions(
    providerId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
