/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type ExpenseType = typeof ExpenseType[keyof typeof ExpenseType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseType = {
  EXPENSE: 'EXPENSE',
  REFUND: 'REFUND',
} as const;
