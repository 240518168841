import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Currency, Table, Tooltip } from '@libs/ui/components';
import React, { Fragment } from 'react';
import { HStack, Text } from '@chakra-ui/layout';
import { useTranslation } from 'react-i18next';
import { MembershipSummary } from '@libs/api-v2/models';
import { useProgramGroupLimitGet } from '@libs/api-v2/endpoints';
import { useIcon } from '@libs/core/theme/utils/useIcon';
import { Box } from '@chakra-ui/react';

type MembershipSummariesTableRowProps = {
  membershipSummary: MembershipSummary;
};

export const MembershipSummariesTableRow = ({
  membershipSummary,
}: MembershipSummariesTableRowProps) => {
  // Attributes
  const { t } = useTranslation();

  const { data: programGroupLimit } = useProgramGroupLimitGet(
    membershipSummary.program_id as string,
    membershipSummary.group_id as string,
    { membership_id: membershipSummary.membership_id },
  );

  const EyeOff = useIcon('EyeOff');

  // Render
  return (
    <Fragment key={membershipSummary?.group_id}>
      <Table.TR
        selectable
        position="relative"
        transform="scale(1,1)"
        index={membershipSummary?.group_id}
      >
        <Table.TD>
          <Text>{membershipSummary?.program_name}</Text>
        </Table.TD>
        <Table.TD>
          <Text>{membershipSummary?.group_name}</Text>
        </Table.TD>

        <Table.TD>
          <HStack>
            <Currency
              size="Body2Bold"
              textOptions={{
                color:
                  programGroupLimit?.limit_amount > 0
                    ? 'status.positive.type'
                    : 'ink.dark',
              }}
            >
              {membershipSummary.used +
                membershipSummary.remaining +
                membershipSummary.reserved}
            </Currency>
            {!programGroupLimit?.display_budget_limit && (
              <Tooltip
                hasArrow={false}
                placement="top-start"
                bg="ink.white"
                textAlign="center"
                color="colors.ink.black"
                label={'Budget amount is hidden to the employee'}
              >
                <Box>
                  <EyeOff width="1.2rem" height="1.2rem" />
                </Box>
              </Tooltip>
            )}
          </HStack>
        </Table.TD>

        <Table.TD>
          <Currency
            size="Body2Bold"
            textOptions={{
              color:
                membershipSummary.used > 0
                  ? 'status.positive.type'
                  : 'ink.dark',
            }}
          >
            {membershipSummary.used}
          </Currency>
        </Table.TD>

        <Table.TD>
          <HStack>
            <Currency
              size="Body2Bold"
              textOptions={{
                color:
                  membershipSummary?.remaining > 0
                    ? 'status.positive.type'
                    : 'ink.dark',
              }}
            >
              {membershipSummary?.remaining}
            </Currency>
            {!programGroupLimit?.display_budget_limit && (
              <Tooltip
                hasArrow={false}
                placement="top-start"
                bg="ink.white"
                textAlign="center"
                color="colors.ink.black"
                label={'Remaining amount is hidden to the employee'}
              >
                <Box>
                  <EyeOff width="1.2rem" height="1.2rem" />
                </Box>
              </Tooltip>
            )}
          </HStack>
        </Table.TD>

        <Table.Date>{membershipSummary?.start_date}</Table.Date>
        <Table.Date>{membershipSummary?.end_date}</Table.Date>
        <Table.Date>{membershipSummary?.budget_start_on}</Table.Date>
        <Table.TD>
          {t(
            membershipSummary?.is_unlimited
              ? i18nKeys.common.true
              : i18nKeys.common.false,
          )}
        </Table.TD>
        <Table.TD>
          {t(
            membershipSummary?.is_current
              ? i18nKeys.common.true
              : i18nKeys.common.false,
          )}
        </Table.TD>
      </Table.TR>
    </Fragment>
  );
};
