/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryOrganisationIdParameter } from './component-parameters/queryOrganisationIdParameter';
import type { QueryQParameter } from './component-parameters/queryQParameter';
import type { QueryPageNumberParameter } from './component-parameters/queryPageNumberParameter';
import type { QueryPageSizeParameter } from './component-parameters/queryPageSizeParameter';
import type { QueryRolesParameter } from './component-parameters/queryRolesParameter';
import type { QueryIsSuspendedParameter } from './queryIsSuspendedParameter';

export type InvitationsGetParams = {
  /**
   * ID of the organisation
   */
  organisation_id?: QueryOrganisationIdParameter;
  /**
   * Search query parameter
   */
  q?: QueryQParameter;
  /**
   * Number of the page to fetch
   */
  page_number?: QueryPageNumberParameter;
  /**
   * Size of the page to fetch
   */
  page_size?: QueryPageSizeParameter;
  /**
   * Roles of users
   */
  roles?: QueryRolesParameter;
  /**
   * Membership is suspended
   */
  is_suspended?: QueryIsSuspendedParameter;
};
