/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export interface GroupUpdateRequest {
  name: string;
  description?: string;
  default_group?: boolean;
}
