/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { Status } from './status';

export type OrganisationStatisticsAllOf = {
  status?: Status;
};
