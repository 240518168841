/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { MembershipRole } from './membershipRole';

export interface AddMembershipRole {
  membership_id: Uuid;
  role: MembershipRole;
}
