import { useGetCurrentUser } from '@libs/api/endpoints';
import { QueryStatus, STORAGE_KEYS } from '@libs/core/constants';
import { AvailableLanguage, useLanguage } from '@libs/core/i18n';
import { Loader } from '@libs/ui/components';
import React, { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthState } from '../context';
import { getPath, Routes } from '../routes';

type PrivateRouteProps = PropsWithChildren<{
  fallBackRoute?: string;
}>;

const DEFAULT_FALLBACK_ROUTE = getPath(Routes.AUTH_LOGIN);

export const PrivateRoute = ({
  fallBackRoute = DEFAULT_FALLBACK_ROUTE,
  children,
  ...rest
}: PrivateRouteProps) => {
  const { isLogged, isLoaded } = useAuthState();
  const { status, data: user } = useGetCurrentUser({
    query: {
      enabled: false,
    },
  });
  const location = useLocation();
  const { i18n } = useTranslation();
  const { changeLanguage } = useLanguage();

  useEffect(() => {
    // Language is changed to user's profile language
    // Only if language was not already changed from the language selector
    if (
      user?.language &&
      user?.language !== i18n?.language &&
      !localStorage.getItem(STORAGE_KEYS.LAST_SELECTED_LANGUAGE)
    ) {
      changeLanguage(user.language as AvailableLanguage);
    }
  }, [changeLanguage, i18n, user]);

  if (!isLoaded) {
    return <Loader />;
  }

  if (!isLogged) {
    return <Navigate state={{ from: location }} to={fallBackRoute} />;
  }

  if (status !== QueryStatus.success) {
    return <Loader />;
  }

  return <Outlet />;
};
