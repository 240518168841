/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

/**
 * - SUGGESTED - RegistrationSuggested means suggested by Treezor, but to be completed and created by fleet manager.
- CREATED -  RegistrationCreated means created and still editable.
- STARTED - RegistrationStarted means created and accepted terms and conditions.
- CONFIRMED - RegistrationConfirmed means validation has been requested and some are still pending. Not editable.
- VALIDATED - RegistrationValidated means all validations were successful. Not editable.
- REFUSED - RegistrationRefused means one or more validations were refused. Is editable again.
- CANCELED - RegistrationCanceled means the registration has been canceled. E.g. employee left the company.
- IN_REVIEW - RegistrationInReview means the registration is in review

 */
export type MembershipStatus =
  typeof MembershipStatus[keyof typeof MembershipStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipStatus = {
  SUGGESTED: 'SUGGESTED',
  CREATED: 'CREATED',
  STARTED: 'STARTED',
  CONFIRMED: 'CONFIRMED',
  VALIDATED: 'VALIDATED',
  REFUSED: 'REFUSED',
  CANCELED: 'CANCELED',
  IN_REVIEW: 'IN_REVIEW',
} as const;
