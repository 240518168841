/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryPageNumberParameter } from './component-parameters/queryPageNumberParameter';
import type { QueryPageSizeParameter } from './component-parameters/queryPageSizeParameter';
import type { QuerySortDefinitionParameter } from './component-parameters/querySortDefinitionParameter';
import type { QueryMembershipIdsParameter } from './component-parameters/queryMembershipIdsParameter';

export type ExpenseRulesListParams = {
  /**
   * Number of the page to fetch
   */
  page_number?: QueryPageNumberParameter;
  /**
   * Size of the page to fetch
   */
  page_size?: QueryPageSizeParameter;
  /**
   * Result sort order
   */
  sort?: QuerySortDefinitionParameter;
  /**
   * ID of the memberships
   */
  membership_ids?: QueryMembershipIdsParameter;
};
