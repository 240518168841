/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type ProviderServiceStatus =
  typeof ProviderServiceStatus[keyof typeof ProviderServiceStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProviderServiceStatus = {
  SUGGESTED: 'SUGGESTED',
  CREATED: 'CREATED',
  CONFIRMED: 'CONFIRMED',
  VALIDATED: 'VALIDATED',
  REFUSED: 'REFUSED',
} as const;
