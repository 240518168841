import { Link as ChakraLink } from '@chakra-ui/react';
import React from 'react';

type DocumentEntity = {
  id: string;
  url: string;
  document_type: string;
  status: string;
};

type DescriptionDocumentProps = {
  documentType: string;
  documents: DocumentEntity[];
};

export const DescriptionDocument = ({
  documentType,
  documents,
}: DescriptionDocumentProps) => {
  if (documents === null || documents === undefined) {
    return null;
  }

  return (
    <>
      {documents
        .filter((document) => document.document_type === documentType)
        .map((doc) => (
          <ChakraLink key={doc.id} href={doc.url}>
            {' '}
            {doc.id}
          </ChakraLink>
        ))}
    </>
  );
};
