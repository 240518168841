/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { FrontendPlatform } from './component-common/frontendPlatform';

export type GetConfigurationBySubjectParams = {
  /**
   * Frontend platform authentication configuration is requested for
   */
  platform?: FrontendPlatform;
};
