/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type ExpenseAttachmentWithContentAllOf = {
  /** This is the data of the url, to bypass the google authentication. */
  readonly content?: string;
};
