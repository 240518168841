/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type OrganisationV2AllOf = {
  id?: string;
  legal_name?: string;
  use_card_coupling?: boolean;
  use_virtual_iban?: boolean;
};
