/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  PaymentMethodGetResponse,
  ListPaymentMethodParams,
  CreatePaymentMethodResponse,
  CreatePaymentMethodRequest,
  PaymentMethod,
} from '../../models';
import type { Error } from '../../models/component-common';
import { customInstance } from '../../config';

export const listPaymentMethod = (
  params?: ListPaymentMethodParams,
  signal?: AbortSignal,
) => {
  return customInstance<PaymentMethodGetResponse>({
    url: `/web/v2/payment_methods`,
    method: 'get',
    params,
    signal,
  });
};

export const getListPaymentMethodQueryKey = (
  params?: ListPaymentMethodParams,
) => [`/web/v2/payment_methods`, ...(params ? [params] : [])] as const;

export const getListPaymentMethodQueryOptions = <
  TData = Awaited<ReturnType<typeof listPaymentMethod>>,
  TError = unknown,
>(
  params?: ListPaymentMethodParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPaymentMethod>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof listPaymentMethod>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListPaymentMethodQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listPaymentMethod>>
  > = ({ signal }) => listPaymentMethod(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListPaymentMethodQueryResult = NonNullable<
  Awaited<ReturnType<typeof listPaymentMethod>>
>;
export type ListPaymentMethodQueryError = unknown;

export const useListPaymentMethod = <
  TData = Awaited<ReturnType<typeof listPaymentMethod>>,
  TError = unknown,
>(
  params?: ListPaymentMethodParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPaymentMethod>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListPaymentMethodQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createPaymentMethod = (
  createPaymentMethodRequest: CreatePaymentMethodRequest,
) => {
  return customInstance<CreatePaymentMethodResponse>({
    url: `/web/v2/payment_methods`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: createPaymentMethodRequest,
  });
};

export const getCreatePaymentMethodMutationOptions = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPaymentMethod>>,
    TError,
    { data: CreatePaymentMethodRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPaymentMethod>>,
  TError,
  { data: CreatePaymentMethodRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPaymentMethod>>,
    { data: CreatePaymentMethodRequest }
  > = (props) => {
    const { data } = props ?? {};

    return createPaymentMethod(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePaymentMethodMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPaymentMethod>>
>;
export type CreatePaymentMethodMutationBody = CreatePaymentMethodRequest;
export type CreatePaymentMethodMutationError = Error;

export const useCreatePaymentMethod = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPaymentMethod>>,
    TError,
    { data: CreatePaymentMethodRequest },
    TContext
  >;
}) => {
  const mutationOptions = getCreatePaymentMethodMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deletePaymentMethod = (paymentMethodId: string) => {
  return customInstance<void>({
    url: `/web/v2/payment_methods/${paymentMethodId}`,
    method: 'delete',
  });
};

export const getDeletePaymentMethodMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePaymentMethod>>,
    TError,
    { paymentMethodId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePaymentMethod>>,
  TError,
  { paymentMethodId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePaymentMethod>>,
    { paymentMethodId: string }
  > = (props) => {
    const { paymentMethodId } = props ?? {};

    return deletePaymentMethod(paymentMethodId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePaymentMethodMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePaymentMethod>>
>;

export type DeletePaymentMethodMutationError = unknown;

export const useDeletePaymentMethod = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePaymentMethod>>,
    TError,
    { paymentMethodId: string },
    TContext
  >;
}) => {
  const mutationOptions = getDeletePaymentMethodMutationOptions(options);

  return useMutation(mutationOptions);
};
export const validatePaymentMethod = (paymentMethodId: string) => {
  return customInstance<PaymentMethod>({
    url: `/web/v2/payment_methods/${paymentMethodId}/validate`,
    method: 'post',
  });
};

export const getValidatePaymentMethodMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validatePaymentMethod>>,
    TError,
    { paymentMethodId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof validatePaymentMethod>>,
  TError,
  { paymentMethodId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof validatePaymentMethod>>,
    { paymentMethodId: string }
  > = (props) => {
    const { paymentMethodId } = props ?? {};

    return validatePaymentMethod(paymentMethodId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ValidatePaymentMethodMutationResult = NonNullable<
  Awaited<ReturnType<typeof validatePaymentMethod>>
>;

export type ValidatePaymentMethodMutationError = unknown;

export const useValidatePaymentMethod = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validatePaymentMethod>>,
    TError,
    { paymentMethodId: string },
    TContext
  >;
}) => {
  const mutationOptions = getValidatePaymentMethodMutationOptions(options);

  return useMutation(mutationOptions);
};
