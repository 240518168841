/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { BankServiceRegistrationStatus } from './bankServiceRegistrationStatus';

export interface BankService {
  name?: string;
  user_reference?: string;
  registration_status?: BankServiceRegistrationStatus;
  comment?: string;
}
