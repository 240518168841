/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type { ImcclRestrictionCardsGetResponse } from '../../models';
import { customInstance } from '../../config';

/**
 * Get all IMCCL restriction linked to a card

 */
export const imcclRestrictionCardsGet = (
  cardReference: string,
  signal?: AbortSignal,
) => {
  return customInstance<ImcclRestrictionCardsGetResponse>({
    url: `/web/cards/${cardReference}/imccl-restrictions`,
    method: 'get',
    signal,
  });
};

export const getImcclRestrictionCardsGetQueryKey = (cardReference: string) =>
  [`/web/cards/${cardReference}/imccl-restrictions`] as const;

export const getImcclRestrictionCardsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof imcclRestrictionCardsGet>>,
  TError = unknown,
>(
  cardReference: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof imcclRestrictionCardsGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof imcclRestrictionCardsGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getImcclRestrictionCardsGetQueryKey(cardReference);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof imcclRestrictionCardsGet>>
  > = ({ signal }) => imcclRestrictionCardsGet(cardReference, signal);

  return { queryKey, queryFn, enabled: !!cardReference, ...queryOptions };
};

export type ImcclRestrictionCardsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof imcclRestrictionCardsGet>>
>;
export type ImcclRestrictionCardsGetQueryError = unknown;

export const useImcclRestrictionCardsGet = <
  TData = Awaited<ReturnType<typeof imcclRestrictionCardsGet>>,
  TError = unknown,
>(
  cardReference: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof imcclRestrictionCardsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getImcclRestrictionCardsGetQueryOptions(
    cardReference,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Attach directly the pending IMCCL restriction to the card

 */
export const imcclRestrictionCardAttach = (cardId: string) => {
  return customInstance<void>({
    url: `/web/cards/${cardId}/imccl-restrictions/attach`,
    method: 'post',
  });
};

export const getImcclRestrictionCardAttachMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof imcclRestrictionCardAttach>>,
    TError,
    { cardId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof imcclRestrictionCardAttach>>,
  TError,
  { cardId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof imcclRestrictionCardAttach>>,
    { cardId: string }
  > = (props) => {
    const { cardId } = props ?? {};

    return imcclRestrictionCardAttach(cardId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ImcclRestrictionCardAttachMutationResult = NonNullable<
  Awaited<ReturnType<typeof imcclRestrictionCardAttach>>
>;

export type ImcclRestrictionCardAttachMutationError = unknown;

export const useImcclRestrictionCardAttach = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof imcclRestrictionCardAttach>>,
    TError,
    { cardId: string },
    TContext
  >;
}) => {
  const mutationOptions = getImcclRestrictionCardAttachMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Attach directly the pending IMCCL restrictions to all cards of a program

 */
export const imcclRestrictionCardsAttach = (programId: string) => {
  return customInstance<void>({
    url: `/web/programs/${programId}/imccl-restrictions/attach`,
    method: 'post',
  });
};

export const getImcclRestrictionCardsAttachMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof imcclRestrictionCardsAttach>>,
    TError,
    { programId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof imcclRestrictionCardsAttach>>,
  TError,
  { programId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof imcclRestrictionCardsAttach>>,
    { programId: string }
  > = (props) => {
    const { programId } = props ?? {};

    return imcclRestrictionCardsAttach(programId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ImcclRestrictionCardsAttachMutationResult = NonNullable<
  Awaited<ReturnType<typeof imcclRestrictionCardsAttach>>
>;

export type ImcclRestrictionCardsAttachMutationError = unknown;

export const useImcclRestrictionCardsAttach = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof imcclRestrictionCardsAttach>>,
    TError,
    { programId: string },
    TContext
  >;
}) => {
  const mutationOptions =
    getImcclRestrictionCardsAttachMutationOptions(options);

  return useMutation(mutationOptions);
};
