/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type QueryReportTimeScaleParameter =
  typeof QueryReportTimeScaleParameter[keyof typeof QueryReportTimeScaleParameter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QueryReportTimeScaleParameter = {
  YEAR: 'YEAR',
  MONTH: 'MONTH',
  WEEK: 'WEEK',
  DAY: 'DAY',
} as const;
