/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { DocumentType } from './documentType';
import type { DocumentAllOfStatus } from './documentAllOfStatus';

export type DocumentAllOf = {
  id?: string;
  document_type?: DocumentType;
  url?: string;
  readonly status?: DocumentAllOfStatus;
};
