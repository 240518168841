import { getQueryParam } from '@libs/core/utils';
import { ROUTES } from '.';
import { Route, RouteActive, RouteOptions, RoutesDefinition } from './types';

/* eslint-disable */
/* tslint:disable */
// //////////////////////////////////////////////////////////////////////////////
// pick(routes, uri)
//
// Ranks and picks the best route to match. Each segment gets the highest
// amount of points, then the type of segment gets an additional amount of
// points where
//
//     static > dynamic > splat > root
//
// This way we don't have to worry about the order of our routes, let the
// computers do it.
//
// A route looks like this
//
//     { path, default, value }
//
// And a returned match looks like:
//
//     { route, params, uri }
//
// I know, I should use TypeScript not comments for these types.
const pick = <T extends { path: string }>(routes: Array<T>, uri: string) => {
  let match;

  const [uriPathname] = uri.split('?');
  const uriSegments = segmentize(uriPathname);
  const isRootUri = uriSegments[0] === '';
  const ranked = rankRoutes(routes);

  for (let i = 0, l = ranked.length; i < l; i++) {
    let missed = false;
    const { route } = ranked[i];

    const routeSegments = segmentize(route.path);
    const params = {};
    const max = Math.max(uriSegments.length, routeSegments.length);
    let index = 0;

    for (; index < max; index++) {
      const routeSegment = routeSegments[index];
      const uriSegment = uriSegments[index];

      const isSplat = routeSegment === '*';
      if (isSplat) {
        // Hit a splat, just grab the rest, and return a match
        // uri:   /files/documents/work
        // route: /files/*
        // @ts-ignore
        params['*'] = uriSegments
          .slice(index)
          .map(decodeURIComponent)
          .join('/');
        break;
      }

      if (uriSegment === undefined) {
        // URI is shorter than the route, no match
        // uri:   /users
        // route: /users/:userId
        missed = true;
        break;
      }

      const dynamicMatch = paramRe.exec(routeSegment);

      if (dynamicMatch && !isRootUri) {
        const matchIsNotReserved =
          reservedNames.indexOf(dynamicMatch[1]) === -1;
        if (!matchIsNotReserved) {
          const error = `<Router> dynamic segment "${dynamicMatch[1]}" is a reserved name. Please use a different name in path "${route.path}".`;
          throw error;
        }
        const value = decodeURIComponent(uriSegment);
        // @ts-ignore
        params[dynamicMatch[1]] = value;
      } else if (routeSegment !== uriSegment) {
        // Current segments don't match, not dynamic, not splat, so no match
        // uri:   /users/123/settings
        // route: /users/:id/profile
        missed = true;
        break;
      }
    }

    if (!missed) {
      match = {
        route,
        params,
        uri: `/${uriSegments.slice(0, index).join('/')}`,
      };
      break;
    }
  }

  return match || null;
};

// //////////////////////////////////////////////////////////////////////////////
// match(path, uri) - Matches just one path to a uri, also lol
const match = (path: string, uri: string) => pick([{ path }], uri);

// //////////////////////////////////////////////////////////////////////////////
// Junk
const paramRe = /^:(.+)/;

const SEGMENT_POINTS = 4;
const STATIC_POINTS = 3;
const DYNAMIC_POINTS = 2;
const SPLAT_PENALTY = 1;
const ROOT_POINTS = 1;

const isRootSegment = (segment: string) => segment === '';
const isDynamic = (segment: string) => paramRe.test(segment);
const isSplat = (segment: string) => segment === '*';

const rankRoute = (route: { path: string }, index: number) => {
  const score = segmentize(route.path).reduce(
    (score: number, segment: string) => {
      score += SEGMENT_POINTS;
      if (isRootSegment(segment)) score += ROOT_POINTS;
      else if (isDynamic(segment)) score += DYNAMIC_POINTS;
      else if (isSplat(segment)) score -= SEGMENT_POINTS + SPLAT_PENALTY;
      else score += STATIC_POINTS;
      return score;
    },
    0,
  );
  return { route, score, index };
};

const rankRoutes = (routes: Array<{ path: string }>) =>
  routes
    .map(rankRoute)
    .sort((a, b) =>
      a.score < b.score ? 1 : a.score > b.score ? -1 : a.index - b.index,
    );

const segmentize = (uri: string) =>
  uri
    // strip starting/ending slashes
    .replace(/(^\/+|\/+$)/g, '')
    .split('/');

const reservedNames = ['uri', 'path'];

let addQueryParams = (
  pathname: string,
  queryParams: { [key: string]: any } = {},
) =>
  Object.entries(queryParams).length > 0
    ? pathname +
      Object.entries(queryParams).reduce(
        (acc, [key, value], i) => acc + `${i ? '&' : '?'}${key}=${value}`,
        '',
      )
    : pathname;

// //////////////////////////////////////////////////////////////////////////////
export { pick, match, addQueryParams, getQueryParam };

export const getRouteParams = (
  path: string,
  params: {
    [index: string]: string;
  } = {},
): {} =>
  (path.match(/:\w+/g) || [])
    .map((key) => key.replace(':', ''))
    .reduce(
      (acc, key) =>
        params[key]
          ? { ...acc, [key]: params[key] }
          : { ...acc, [key]: undefined },
      {},
    );

export const getRoutePath = (
  path: string,
  params: {
    [index: string]: string;
  } = {},
): string =>
  Object.keys(params).reduce(
    (acc, key) => (params[key] ? acc.replace(`:${key}`, params[key]) : acc),
    path,
  );

const sanatizePath = (str: string) =>
  '/' + str.split('/').filter(Boolean).join('/');

export const getRouteForRoutes =
  <T extends string | symbol>(ROUTES: RoutesDefinition<T>, withParams = true) =>
  (target: T, options: RouteOptions = {}): Route<T> => {
    const { path: routePath, parent: routeParent, ...rest } = ROUTES[target];

    const params = getRouteParams(routePath, options.params);
    const path = withParams ? getRoutePath(routePath, params) : routePath;

    if (routeParent) {
      const parent = getRouteForRoutes(ROUTES, withParams)(
        routeParent as T,
        options,
      );

      return {
        id: target,
        path: addQueryParams(
          sanatizePath(`${parent.path}${path}`),
          options.queryParams,
        ),
        parent,
        params,
        ...rest,
      };
    }

    return {
      id: target,
      path: addQueryParams(path, options.queryParams),
      params,
      ...rest,
    };
  };

export const getRouterPathForRoutes =
  <T extends string | symbol>(ROUTES: RoutesDefinition<T>) =>
  (target: T): string =>
    getRouteForRoutes(ROUTES, false)(target).path;

export const getPathForRoutes =
  <T extends string | symbol>(ROUTES: RoutesDefinition<T>) =>
  (target: T, options: RouteOptions = {}): string =>
    getRouteForRoutes(ROUTES)(target, options).path;

export const getAllRoutesForRoutes = <T extends string | symbol>(
  ROUTES: RoutesDefinition<T>,
) => Object.keys(ROUTES).map((route) => getRouteForRoutes(ROUTES)(route as T));

export const pickRouteForRoutes =
  <T extends string | symbol>(ROUTES: RoutesDefinition<T>) =>
  (uri: string) =>
    pick(getAllRoutesForRoutes(ROUTES), uri) as RouteActive<T> | null;

export const useQueryParam = <T extends string>(name: string) => {
  return getQueryParam(name) as T;
};

export const getRoutesFromPath = (path?: string) =>
  (path || location.pathname)
    .split('/')
    .slice(1)
    .map(
      (path) =>
        Object.values(ROUTES).find((route) => route.path === `/${path}`) ||
        path,
    );
