/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { BudgetDistribution } from './budgetDistribution';

export interface MembershipSummaryTopup {
  readonly created_on: string;
  /** Start of the topup's period. Can be != from created_on */
  readonly period_start_date: string;
  readonly amount: number;
  readonly carry_over: boolean;
  budget_distribution: BudgetDistribution;
  /** Program ID of a program topup was made for */
  program_id: string;
  /** Program name of a program topup was made for */
  program_name?: string;
}
