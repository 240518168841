/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { Status } from './status';
import type { KYCStatus } from './kYCStatus';
import type { Legal } from './legal';
import type { AddressV1 } from './addressV1';
import type { OrganisationDocument } from './organisationDocument';
import type { EntityType } from './entityType';
import type { OnboardingStatus } from './onboardingStatus';
import type { OrganisationConfiguration } from './organisationConfiguration';
import type { OrganisationScope } from './organisationScope';

export type OrganisationAllOf = {
  id?: string;
  legal_name?: string;
  vat?: string;
  status?: Status;
  email?: string;
  phone_number?: string;
  is_freelancer?: boolean;
  use_mobility_budget?: boolean;
  social_secretary_reference?: string;
  wallet_iban?: string;
  wallet_amount?: number;
  kyc_status?: KYCStatus;
  readonly kyc_comment?: string;
  readonly kyc_started_on?: string;
  legal?: Legal;
  address?: AddressV1;
  documents?: OrganisationDocument[];
  shareholder_ids?: string[];
  use_payment_card?: boolean;
  bank_organisation_reference?: string;
  activity_outside_eu?: boolean;
  economic_sanctions?: boolean;
  resident_countries_sanctions?: boolean;
  involved_sanctions?: boolean;
  entity_type?: EntityType;
  onboarding_status?: OnboardingStatus;
  business_api_reference?: string;
  configuration?: OrganisationConfiguration;
  /** flag that indicates if organisation is part of NextGen, OldGen or Smartmove */
  readonly organisation_scope?: OrganisationScope;
  /** The id of the parent organisation. An organisation is a parent or a child (no child of child relationship), an empty id means that the organisation is a parent. */
  parent_organisation_id?: string | null;
};
