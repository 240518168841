/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { SwornStatementMeta } from './swornStatementMeta';

export interface SwornStatement {
  id?: Uuid;
  text: string;
  /** If it is required of the employees to sign this sworn statement */
  required: boolean;
  readonly created_at?: string;
  readonly updated_at?: string;
  meta?: SwornStatementMeta;
}
