/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { AmountOfMoney } from './amountOfMoney';
import type { TransactionStatus } from './transactionStatus';
import type { Uuid } from './component-common/uuid';
import type { ProviderName } from './providerName';
import type { ProviderNameKey } from './providerNameKey';

export interface ReservedExpense {
  /** Technical creation timestamp of the expense. This could be different from the timestamp at which the actual expense occurred. See field "ExpenseAt" for more info. */
  readonly created_at?: string;
  amount?: AmountOfMoney;
  status?: TransactionStatus;
  provider_id?: Uuid;
  membership_id?: Uuid;
  merchant_name?: string;
  authorization_note?: string;
  provider_icon_url?: string;
  provider_name?: ProviderName;
  provider_name_key?: ProviderNameKey;
  authorisation_response_code?: string;
  program_id?: Uuid;
}
