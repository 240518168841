/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryDeleteExpensesFromParameter } from './component-parameters/queryDeleteExpensesFromParameter';

export type ExpenseRulesDeleteParams = {
  /**
   * Date from which all expenses created by the expense_rule should be deleted. If empty, no expenses will be deleted.
   */
  delete_expenses_from?: QueryDeleteExpensesFromParameter;
};
