import {
  Box,
  Tag as ChakraTag,
  TagLabel,
  TagProps as ChakraTagProps,
} from '@chakra-ui/react';
import { useIcon } from '@libs/core/theme/utils';
import React, { ReactNode } from 'react';
import { Tooltip } from '../tooltip';

export type TagStatus =
  | 'created'
  | 'pending'
  | 'positive'
  | 'negative'
  | 'started'
  | 'none'
  | 'info';

export const TagStatus = {
  STARTED: 'started' as TagStatus,
  CREATED: 'created' as TagStatus,
  PENDING: 'pending' as TagStatus,
  POSITIVE: 'positive' as TagStatus,
  NEGATIVE: 'negative' as TagStatus,
  NONE: 'none' as TagStatus,
  INFO: 'info' as TagStatus,
};

type TagContentProps = {
  children?: ReactNode;
  hasIcon?: boolean;
  status?: TagStatus;
  rightIcon?: ReactNode;
};

const TagContent = ({
  status,
  hasIcon,
  children,
  rightIcon,
  ...rest
}: TagContentProps) => {
  const Pending = useIcon('Pending');
  const Check = useIcon('CheckRounded');
  const Close = useIcon('Close');
  const Info = useIcon('Info');

  return (
    <>
      {status && (
        <Box
          borderRadius="full"
          boxSize={hasIcon ? '2.4rem' : '.5rem'}
          bgColor={`status.${status}.type`}
          mr=".5rem"
          lineHeight="2.4rem"
          fill="ink.white"
          p=".3rem"
          {...rest}
        >
          {hasIcon && status === TagStatus.PENDING && (
            <Pending width="1.8rem" height="1.8rem" />
          )}
          {hasIcon && status === TagStatus.POSITIVE && (
            <Check width="1.8rem" height="1.8rem" />
          )}
          {hasIcon && status === TagStatus.CREATED && (
            <Check width="1.8rem" height="1.8rem" />
          )}
          {hasIcon && status === TagStatus.STARTED && (
            <Check width="1.8rem" height="1.8rem" />
          )}
          {hasIcon && status === TagStatus.NEGATIVE && (
            <Close width="1.8rem" height="1.8rem" />
          )}
          {hasIcon && status === TagStatus.NONE && (
            <Close width="1.8rem" height="1.8rem" />
          )}
          {hasIcon && status === TagStatus.INFO && (
            <Info width="1.8rem" height="1.8rem" />
          )}
        </Box>
      )}
      <TagLabel whiteSpace="normal">{children}</TagLabel>
      {rightIcon}
    </>
  );
};

export type TagProps = ChakraTagProps &
  TagContentProps & {
    tooltipLabel?: ReactNode;
  };

export const Tag = ({
  children,
  variant = 'status',
  size = 'md',
  colorScheme,
  status,
  hasIcon,
  rightIcon,
  tooltipLabel,
  ...rest
}: TagProps) => {
  return tooltipLabel ? (
    <Tooltip bg="ink.white" textAlign="center" label={tooltipLabel}>
      <ChakraTag
        variant={variant}
        size={size}
        colorScheme={colorScheme}
        {...rest}
      >
        <TagContent
          status={status}
          hasIcon={hasIcon}
          children={children}
          rightIcon={rightIcon}
          {...rest}
        />
      </ChakraTag>
    </Tooltip>
  ) : (
    <ChakraTag
      variant={variant}
      size={size}
      colorScheme={colorScheme}
      {...rest}
    >
      <TagContent
        status={status}
        hasIcon={hasIcon}
        children={children}
        rightIcon={rightIcon}
      />
    </ChakraTag>
  );
};
