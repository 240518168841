/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type { ResetPasswordBody, ForgotPasswordBody } from '../../models';
import type { GetConfigurationResponse } from '../../models/component-auth';
import type { Phone, Email } from '../../models/component-common';
import { customInstance } from '../../config';

/**
 * This path is rate limited to 10 failing calls per minute.
Once threshold is reached, the IP will be blocked for 1 minute.
The limit is shared between all calls of /web/auth and /web/v2/auth

 */
export const resetPassword = (resetPasswordBody: ResetPasswordBody) => {
  return customInstance<void>({
    url: `/web/auth/reset-password`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: resetPasswordBody,
  });
};

export type ResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof resetPassword>>
>;
export type ResetPasswordMutationBody = ResetPasswordBody;
export type ResetPasswordMutationError = unknown;

export const useResetPassword = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetPassword>>,
    TError,
    { data: ResetPasswordBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resetPassword>>,
    { data: ResetPasswordBody }
  > = (props) => {
    const { data } = props ?? {};

    return resetPassword(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof resetPassword>>,
    TError,
    { data: ResetPasswordBody },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * This path is rate limited to 10 failing calls per minute.
Once threshold is reached, the IP will be blocked for 1 minute.
The limit is shared between all calls of /web/auth and /web/v2/auth

 */
export const forgotPassword = (forgotPasswordBody: ForgotPasswordBody) => {
  return customInstance<void>({
    url: `/web/auth/forgot-password`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: forgotPasswordBody,
  });
};

export type ForgotPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof forgotPassword>>
>;
export type ForgotPasswordMutationBody = ForgotPasswordBody;
export type ForgotPasswordMutationError = unknown;

export const useForgotPassword = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof forgotPassword>>,
    TError,
    { data: ForgotPasswordBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof forgotPassword>>,
    { data: ForgotPasswordBody }
  > = (props) => {
    const { data } = props ?? {};

    return forgotPassword(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof forgotPassword>>,
    TError,
    { data: ForgotPasswordBody },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * This path is rate limited to 10 failing calls per minute.
Once threshold is reached, the IP will be blocked for 1 minute.
The limit is shared between all calls of /web/auth and /web/v2/auth

 */
export const getConfigurationByPhoneNumber = (
  phoneNumber: Phone,
  signal?: AbortSignal,
) => {
  return customInstance<GetConfigurationResponse>({
    url: `/web/auth/configuration/phone_number/${phoneNumber}`,
    method: 'get',
    signal,
  });
};

export const getGetConfigurationByPhoneNumberQueryKey = (
  phoneNumber: Phone,
) => [`/web/auth/configuration/phone_number/${phoneNumber}`];

export type GetConfigurationByPhoneNumberQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConfigurationByPhoneNumber>>
>;
export type GetConfigurationByPhoneNumberQueryError = unknown;

export const useGetConfigurationByPhoneNumber = <
  TData = Awaited<ReturnType<typeof getConfigurationByPhoneNumber>>,
  TError = unknown,
>(
  phoneNumber: Phone,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConfigurationByPhoneNumber>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetConfigurationByPhoneNumberQueryKey(phoneNumber);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getConfigurationByPhoneNumber>>
  > = ({ signal }) => getConfigurationByPhoneNumber(phoneNumber, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getConfigurationByPhoneNumber>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!phoneNumber,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Make sure to URL-encode the email!

This path is rate limited to 10 failing calls per minute.
Once threshold is reached, the IP will be blocked for 1 minute.
The limit is shared between all calls of /web/auth and /web/v2/auth

 */
export const getConfigurationByEmail = (email: Email, signal?: AbortSignal) => {
  return customInstance<GetConfigurationResponse>({
    url: `/web/auth/configuration/email/${email}`,
    method: 'get',
    signal,
  });
};

export const getGetConfigurationByEmailQueryKey = (email: Email) => [
  `/web/auth/configuration/email/${email}`,
];

export type GetConfigurationByEmailQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConfigurationByEmail>>
>;
export type GetConfigurationByEmailQueryError = unknown;

export const useGetConfigurationByEmail = <
  TData = Awaited<ReturnType<typeof getConfigurationByEmail>>,
  TError = unknown,
>(
  email: Email,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConfigurationByEmail>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetConfigurationByEmailQueryKey(email);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getConfigurationByEmail>>
  > = ({ signal }) => getConfigurationByEmail(email, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getConfigurationByEmail>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!email, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
