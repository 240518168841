import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
} from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { getPath, Routes } from '@libs/dashboard-core/routes';
import { PageHeader } from '@libs/ui/components';
import { Link } from '@libs/ui/components/link';
import { useDrawer } from '@libs/ui/components/table/table.utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardLayout } from '../../components/dashboard-layout';
import { RulesetDrawer } from './components/ruleset-drawer';
import { RulesetTable } from './components/ruleset-table';
import { useRulesetsGetTreezor } from '@libs/api-v2/endpoints/rulesets/rulesets';

export const RulesetPage = () => {
  // Attributes
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDrawer();
  const [rulesetId, setRulesetId] = useState<string>();

  const {
    // is false when fetching cached values
    isLoading,
    data: rulesets,
    // defaults to true so long as enabled is set to false
    // is true if the query is currently fetching, including background fetching
    isFetching,
    refetch,
  } = useRulesetsGetTreezor();

  // Render
  return (
    <DashboardLayout>
      <Breadcrumb mb="2rem">
        <BreadcrumbItem>
          <BreadcrumbLink
            variant="breadcrumb"
            as={Link}
            to={getPath(Routes.DASHBOARD)}
          >
            {t(i18nKeys.common.home)}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink variant="breadcrumb" as={Link} to="#">
            {t(i18nKeys.operator.rulesets.title)}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex display={{ base: 'block', lg: 'flex' }} justify="space-between">
        <PageHeader
          size="Large"
          title={t(i18nKeys.operator.rulesets.title)}
          introduction={t(i18nKeys.operator.rulesets.introduction)}
        />
        <Button
          mt={{ base: '2rem', lg: '0' }}
          type="button"
          variant="primary"
          size="body1"
          onClick={() => {
            setRulesetId(undefined);
            onOpen();
          }}
        >
          {t(i18nKeys.operator.rulesets.button.create)}
        </Button>
      </Flex>
      <RulesetTable
        open={onOpen}
        rulesets={rulesets}
        isFetching={isFetching}
        isLoading={isLoading}
        setRulesetId={setRulesetId}
      />
      {isOpen && (
        <RulesetDrawer
          isOpen={isOpen}
          onClose={onClose}
          refetch={refetch}
          rulesetId={rulesetId}
        />
      )}
    </DashboardLayout>
  );
};
