/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { JourneyType } from './journeyType';
import type { JourneyTripType } from './journeyTripType';
import type { Address } from './address';

export interface Journey {
  expense_id: Uuid;
  /** The distance in meter of the trip which lead to this expense. E.g. if you took a Husk for 10km, the distance will be 10000. */
  distance: number;
  /** The manual distance in meter of the trip which lead to this expense. E.g. if you took a Husk for 10km, the distance will be 10000. */
  manual_distance?: number | null;
  /** Manual distance is available for this journey. */
  is_manual_distance_available: boolean;
  type: JourneyType;
  trip_type: JourneyTripType;
  /** Optional additional information for journey, f.e. other passengers from car pooling. */
  additional_information?: string;
  home_address?: Address;
  office_address?: Address;
  start_station_address?: Address;
  end_station_address?: Address;
}
