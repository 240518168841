import { Button } from '@chakra-ui/button';
import { Flex, Text } from '@chakra-ui/layout';
import {
  getMerchantCategoryRestrictionGroupsGetQueryKey,
  getMerchantIdRestrictionGroupsGetQueryKey,
  merchantCategoryRestrictionGroupsGet,
  merchantCategoryRestrictionGroupUpdate,
  merchantIdRestrictionGroupsGet,
  merchantIdRestrictionGroupUpdate,
} from '@libs/api/endpoints';
import {
  MerchantCategoryRestrictionGroupsGetParams,
  MerchantIdRestrictionGroupsGetParams,
  MerchantRestrictionGroup,
  RestrictionGroupStatus,
} from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { validateSchema } from '@libs/core/utils/validation';
import {
  Drawer,
  FormSubmitData,
  handleFormSubmit,
  InputField,
  SelectField,
  useToast,
} from '@libs/ui/components';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  RestrictionGroupType,
  RestrictionGroupUpdateFormData,
} from '../restriction-group.type';
import { validationRestrictionGroupUpdateSchema } from '../restriction-group.validation';

type RestrictionGroupEditDrawerProps = {
  restrictionGroup: MerchantRestrictionGroup;
  isOpen: boolean;
  onClose: () => void;
  type: RestrictionGroupType;
  restrictionGroupGetParams:
    | MerchantIdRestrictionGroupsGetParams
    | MerchantCategoryRestrictionGroupsGetParams;
};

export const RestrictionGroupEditDrawer = ({
  restrictionGroup,
  isOpen,
  onClose,
  type,
  restrictionGroupGetParams,
}: RestrictionGroupEditDrawerProps) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: updateRestriction, isLoading } = useMutation(
    async ({ values }: FormSubmitData<RestrictionGroupUpdateFormData>) => {
      return type === RestrictionGroupType.MCC
        ? merchantCategoryRestrictionGroupUpdate(restrictionGroup.id, values)
        : merchantIdRestrictionGroupUpdate(restrictionGroup.id, values);
    },
    {
      onSuccess: (data: MerchantRestrictionGroup) => {
        if (data.status === RestrictionGroupStatus.CANCELED) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }

        toast({
          title: t(i18nKeys.operator.restriction_groups.details.success.title),
          content: t(
            i18nKeys.operator.restriction_groups.details.success.content,
          ),
        });
        const queryKey =
          type === RestrictionGroupType.MCC
            ? getMerchantCategoryRestrictionGroupsGetQueryKey(
                restrictionGroupGetParams,
              )
            : getMerchantIdRestrictionGroupsGetQueryKey(
                restrictionGroupGetParams,
              );
        queryClient.invalidateQueries(queryKey);
        queryClient.fetchQuery(queryKey, () =>
          type === RestrictionGroupType.MCC
            ? merchantCategoryRestrictionGroupsGet(restrictionGroupGetParams)
            : merchantIdRestrictionGroupsGet(restrictionGroupGetParams),
        );
      },
    },
  );

  const drawerTitle =
    type === RestrictionGroupType.MCC
      ? t(i18nKeys.operator.restriction_groups.details.mcc_title)
      : t(i18nKeys.operator.restriction_groups.details.mid_title);

  return (
    <Drawer title={drawerTitle} isOpen={isOpen} onClose={onClose} size="xl">
      <Formik<RestrictionGroupUpdateFormData>
        initialValues={{
          name: restrictionGroup.name,
          status: restrictionGroup.status,
        }}
        onSubmit={handleFormSubmit(updateRestriction)}
        validate={validateSchema(validationRestrictionGroupUpdateSchema)}
      >
        {({ values, isValid, dirty }) => (
          <Form>
            <Drawer.Body my="2rem">
              <InputField
                name="name"
                label={
                  type === RestrictionGroupType.MCC
                    ? t(
                        i18nKeys.operator.restriction_groups.details
                          .mcc_category_code,
                      )
                    : t(
                        i18nKeys.operator.restriction_groups.details
                          .mid_restriction_group,
                      )
                }
                isDisabled={
                  restrictionGroup.status === RestrictionGroupStatus.CANCELED ||
                  disabled
                }
              />
              <SelectField
                name="status"
                label={t(i18nKeys.common.status)}
                isDisabled={
                  restrictionGroup.status === RestrictionGroupStatus.CANCELED ||
                  disabled
                }
              >
                {Object.values(RestrictionGroupStatus).map((status) => (
                  <option key={status} value={status}>
                    {t(i18nKeys.common.TagStatus[status])}
                  </option>
                ))}
              </SelectField>
              {values.status === RestrictionGroupStatus.CANCELED && (
                <Text size="small" color="ink.dark">
                  {t(
                    i18nKeys.operator.restriction_groups.details.status.canceled
                      .explanation,
                  )}
                </Text>
              )}
            </Drawer.Body>
            <Drawer.Footer>
              <Flex justify="space-between" width="100%">
                <Button
                  size="body2"
                  w="auto"
                  variant="border"
                  onClick={onClose}
                >
                  {t(i18nKeys.common.cancel)}
                </Button>
                <Button
                  type="submit"
                  size="body2"
                  w="auto"
                  variant="primary"
                  disabled={!isValid || !dirty}
                  isLoading={isLoading}
                  loadingText={t(i18nKeys.common.submit)}
                >
                  {t(i18nKeys.common.submit)}
                </Button>
              </Flex>
            </Drawer.Footer>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};
