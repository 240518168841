import { Flex, Text } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/react';
import { useGetMerchants } from '@libs/api-v2/endpoints';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { capitalize } from '@libs/core/utils';
import { Pager, Table, usePager } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useDrawer, useTable } from '@libs/ui/components/table/table.utils';
import { useIcon } from '@libs/core/theme/utils';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MerchantsEditDrawer } from './merchants-edit-drawer';
import { getMerchantCategoryCodes } from '@libs/dashboard-core/utils/merchant_category_codes/mcc';

export const MerchantsTable = () => {
  const merchantCategoryCodesMap = getMerchantCategoryCodes();
  const EditIcon = useIcon('Edit');
  const { t } = useTranslation();
  const { pageNumber, pageSize, setPageNumber, setPageSize } = usePager();
  const { activeDrawerIndex, drawerHandler, isOpen, onClose } = useDrawer();
  const {
    filterQuery,
    sortQuery,
    setFilterQuery,
    setSortQuery,
    getFilterValue,
    useQueryParams,
  } = useTable({
    setPageNumber,
    setPageSize,
  });

  const missingProviderQueryParam = 'unassigned';
  const params = {
    page_number: pageNumber,
    page_size: pageSize,
  };

  // include unassigned query param only if it's set
  if (getFilterValue(missingProviderQueryParam) === 'true') {
    params[missingProviderQueryParam] = true;
  }
  const {
    isLoading,
    refetch,
    data: merchants,
    isFetching,
  } = useGetMerchants(params, { query: { keepPreviousData: true } });

  useQueryParams({
    refetch,
    pageNumber,
    pageSize,
    setPageSize,
    totalPages: merchants?.meta.total_pages,
  });

  // refetch query pageSize parameter is updated
  useEffect(() => {
    refetch();
  }, [refetch, pageSize]);

  const headers: TableHeaders = [
    t(i18nKeys.common.merchant_name),
    t(i18nKeys.common.merchant_reference),
    'MCC',
    'MCC Description',
    {
      title: t(i18nKeys.common.provider_name),
      name: missingProviderQueryParam,
      filter: true,
      filterValues: ['true'],
      filterValuesI18nKey: {
        true: t(i18nKeys.common.ValidationStatus.MISSING_PROVIDER),
      },
    },
    t(i18nKeys.common.service),
    '',
  ];

  return (
    <>
      <Table
        hasFilter
        sortQuery={sortQuery}
        setSortQuery={setSortQuery}
        filterQuery={filterQuery}
        setFilterQuery={setFilterQuery}
        headers={headers}
      >
        <Table.TBody
          isLoading={(isLoading || isFetching) && !merchants}
          colSpan={headers.length}
          isEmpty={
            !isLoading &&
            !isFetching &&
            (merchants?.data.length === 0 || !merchants)
          }
        >
          {merchants?.data.map(
            (
              {
                merchant_name,
                merchant_id,
                merchant_category_code,
                provider,
                service,
              },
              index,
            ) => (
              <Table.TR
                key={merchant_id}
                selectable
                index={merchant_id}
                onClick={() => drawerHandler(index)}
              >
                <Table.TD>
                  <Text size="Body2">{capitalize(merchant_name)}</Text>
                </Table.TD>
                <Table.TD>{merchant_id}</Table.TD>
                <Table.TD>{merchant_category_code}</Table.TD>
                <Table.TD>
                  {
                    merchantCategoryCodesMap.get(merchant_category_code)
                      ?.irs_description
                  }
                </Table.TD>
                <Table.TD>
                  {provider ? (
                    <Flex>
                      <Image
                        src={provider?.icon_url}
                        alt="provider-icon"
                        htmlWidth={20.0}
                      />
                      {capitalize(provider?.name)}
                    </Flex>
                  ) : null}
                </Table.TD>
                <Table.TD>{capitalize(service?.name_key)}</Table.TD>
                <Table.Action
                  icon={<EditIcon />}
                  onClick={() => drawerHandler(index)}
                />
              </Table.TR>
            ),
          )}
        </Table.TBody>
      </Table>
      {merchants && merchants?.meta.total_pages > 1 && (
        <Pager
          setPageNumber={setPageNumber}
          total_items={merchants.meta.total_items}
          page_index={merchants.meta.page_index}
          total_pages={merchants.meta.total_pages}
          mt="2rem"
          page_size={pageSize}
          setPageSize={setPageSize}
          isFetching={isFetching}
          pageNumber={pageNumber}
        />
      )}
      {merchants?.data?.length > 0 && isOpen && (
        <MerchantsEditDrawer
          merchant={merchants.data[activeDrawerIndex]}
          isOpen={isOpen}
          onClose={onClose}
          merchantsGetParams={params}
        />
      )}
    </>
  );
};
