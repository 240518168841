import {
  BudgetStatus,
  BudgetType,
  ExpenseAllOfType,
  ExpenseCategoryCode,
  ValidationStatus,
} from '@libs/api/models';

import { Card } from '@libs/api-v2/models';

export type EmployeeBudgetCreateFormData = {
  type: BudgetType;
  status: BudgetStatus;
  start_at: string;
  end_at: string;
  initial_total: number;
  is_carry_over_enabled: boolean;
};
export type EmployeeBudgetUpdateFormData = {
  type: BudgetType;
  status: BudgetStatus;
  start_at: string;
  end_at: string;
  initial_total: number;
  is_carry_over_enabled: boolean;
};

export type EmployeeExpenseCreateFormData = {
  title: string;
  category_code: ExpenseCategoryCode;
  is_home_work: boolean;
  provider_name?: string;
  type: ExpenseAllOfType;
  amount: number;
  expense_occurred_at: string;
  justification?: string;
  validation_status: ValidationStatus;
};

export type ExpenseUpdateReviewStatusFormData = {
  review_status: string;
  review_comment: string;
};

export type CardDeactivation = 'PERMANENT' | 'TEMPORARY';
export const CardDeactivation = {
  PERMANENT: 'PERMANENT' as CardDeactivation,
  TEMPORARY: 'TEMPORARY' as CardDeactivation,
};

export type PinCardDeactivation = 'LOCK' | 'UNLOCK';
export const PinCardDeactivation = {
  LOCK: 'LOCK' as PinCardDeactivation,
  UNLOCK: 'UNLOCK' as PinCardDeactivation,
};

export type CardDrawerProps = {
  card: Card;
  isOpen: boolean;
  onClose: () => void;
};

export type EmployeeFormInputsProps = {
  type?: string;
  options?: string[];
  optionsI18nKey?: { [x: string]: string };
  name: string;
  value: unknown;
  label?: string;
  isDisabled?: boolean;
};

// Add Address for InputField in drawer for adress
export type Address = {
  type?: string;
  name: string;
  value: unknown;
  label?: string;
  isDisabled?: boolean;
};
