/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { QueryUnassignedParameter } from './api-web-policy/queryUnassignedParameter';
import type { QueryPageNumberParameter } from './component-parameters/queryPageNumberParameter';
import type { QueryPageSizeParameter } from './component-parameters/queryPageSizeParameter';

export type GetMerchantsV2Params = {
  unassigned?: QueryUnassignedParameter;
  page_number?: QueryPageNumberParameter;
  page_size?: QueryPageSizeParameter;
};
