import i18n, { ResourceLanguage } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { STORAGE_KEYS } from '../constants';
import { locales as dashboardLocales } from './dashboard-core/locales';
import { countries } from './dashboard-core/locales/countries';
import { locales as dashboardMobilityLocales } from './dashboard-mobility/locales';
import { AvailableLanguage, I18nConfig } from './types';
import { locales as uiLocales } from './ui/locales';

const getRessources = (languages: AvailableLanguage[]) =>
  languages.reduce(
    (acc, language) => ({
      ...acc,
      [language]: {
        translation: {
          ...dashboardLocales[language],
          ...uiLocales[language],
          ...dashboardMobilityLocales[language],
        },
        countries: countries[language],
      },
    }),
    {} as Record<AvailableLanguage, ResourceLanguage>,
  );

export const getI18nInstance = (config: I18nConfig) => {
  const lastSelectedLanguage = localStorage.getItem(
    STORAGE_KEYS.LAST_SELECTED_LANGUAGE,
  );

  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      lng: lastSelectedLanguage || config.defaultLanguage,
      fallbackLng: config.fallbackLanguage,
      resources: getRessources(config.languages),
      debug: process.env.NODE_ENV !== 'production',
      keySeparator: false,
      returnEmptyString: false,
    });

  return i18n;
};
