/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Service } from './service';
import type { ManualSplitConfiguration } from './manualSplitConfiguration';
import type { ProgramServiceAllOf } from './programServiceAllOf';

export type ProgramService = Service &
  ManualSplitConfiguration &
  ProgramServiceAllOf;
