/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { AddressType } from './addressType';

export interface Address {
  street: string;
  postal_code: string;
  city: string;
  country_code: string;
  type?: AddressType;
}
