/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { PolicyLevel } from './policyLevel';
import type { PolicyConflict } from './policyConflict';

export type CheckPolicyResponseAllOf = {
  level?: PolicyLevel;
  conflicts?: PolicyConflict[];
};
