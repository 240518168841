/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { MetaStatus } from './metaStatus';

export interface Meta {
  status: MetaStatus;
  page_index: number;
  page_size: number;
  total_items: number;
  total_pages: number;
}
