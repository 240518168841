/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { CountryCode } from './component-common/countryCode';
import type { Program } from './program';
import type { ServiceDefinition } from './serviceDefinition';
import type { SpendingPolicyTemplate } from './spendingPolicyTemplate';
import type { Policy } from './policy';

export interface ProgramTemplate {
  id?: Uuid;
  readonly description?: string;
  description_key?: string;
  nationality?: CountryCode;
  program_details?: Program;
  program_services?: ServiceDefinition[];
  default_spending_policy?: SpendingPolicyTemplate;
  refunds_enabled?: boolean;
  /** This collection contains default policies that should be applied to every group assigned to a program.
This includes general policies for expense and refunds and, in some cases, service level policies -
ie. bike/car allowance policy
 */
  approval_policies?: Policy[];
}
