/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type BudgetStatus = typeof BudgetStatus[keyof typeof BudgetStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BudgetStatus = {
  VALIDATED: 'VALIDATED',
  BLOCKED: 'BLOCKED',
} as const;
