/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { PaymentMethodType } from './paymentMethodType';
import type { PaymentMethodStatus } from './paymentMethodStatus';
import type { PaymentMethodCreditCard } from './paymentMethodCreditCard';
import type { PaymentMethodVirtualIban } from './paymentMethodVirtualIban';

export interface PaymentMethod {
  id: Uuid;
  user_id: string;
  payment_method_type: PaymentMethodType;
  status: PaymentMethodStatus;
  credit_card?: PaymentMethodCreditCard;
  virtual_iban?: PaymentMethodVirtualIban;
  readonly created_at?: string;
  readonly updated_at?: string;
  readonly deleted_at?: string;
}
