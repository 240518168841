/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { Merchant } from './merchant';
import type { Meta } from './meta';

export interface MerchantsGetResponse {
  data?: Merchant[];
  meta?: Meta;
}
