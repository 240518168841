import { InputRightElement } from '@chakra-ui/react';
import { useIcon } from '@libs/core/theme/utils';
import dayjs from 'dayjs';
import Flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
// eslint-disable-next-line import/extensions
import English from 'flatpickr/dist/l10n/default.js';
// eslint-disable-next-line import/extensions
import French from 'flatpickr/dist/l10n/fr.js';
// eslint-disable-next-line import/extensions
import Dutch from 'flatpickr/dist/l10n/nl.js';
import { useField } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl } from '../components/form-control';
import {
  InputBar,
  InputButton,
  InputGroup,
  InputHighLight,
  InputStyledField,
} from '../form.style';
import { BaseProps } from '../form.types';

const LocalizationFlatpickr: { [key: string]: unknown } = {
  en: English,
  fr: French.fr,
  nl: Dutch.nl,
};

export type DateFieldProps = BaseProps & {
  maxDate?: Date;
  hasSimpleDate?: boolean;
};

export const DateField = (props: DateFieldProps) => {
  const { name, maxDate, hasSimpleDate, ...formControlProps } = props;
  const { i18n } = useTranslation();
  const [field, meta, helpers] = useField(name);
  const Calendar = useIcon('Calendar');
  const inputRef = useRef<HTMLInputElement>(null);
  const addonRef = useRef<HTMLDivElement>(null);

  const [flatpickr, setFlatpickr] = useState<Flatpickr.Instance | null>(null);

  const openDatepicker = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.nativeEvent.stopImmediatePropagation();
    if (flatpickr) {
      flatpickr.open();
    }
  };

  useEffect(() => {
    if (!flatpickr && inputRef.current) {
      const flatpickrInstance = Flatpickr(inputRef.current, {
        allowInput: true,
        disableMobile: true,
        dateFormat: 'd/m/Y',
        maxDate: maxDate,
        clickOpens: true,
        locale: LocalizationFlatpickr[i18n.language] as Flatpickr.CustomLocale,
        onChange: (changedSelectedDates: Date[]) => {
          if (hasSimpleDate) {
            // format function doesn't convert the datetime to utc so the date part will be correct
            // FIXME: this will need to be changed once ADR-OOO1 will be applied to the backend
            helpers.setValue(dayjs(changedSelectedDates[0]).format());
          } else {
            helpers.setValue(dayjs(changedSelectedDates[0]).toISOString());
          }
        },
        positionElement: addonRef.current,
      });

      setFlatpickr(flatpickrInstance);
    }

    return () => {
      if (flatpickr) {
        flatpickr.destroy();
        setFlatpickr(null);
      }
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatpickr]);

  useEffect(() => {
    if (!flatpickr) {
      return;
    }

    const date = dayjs(field.value);
    if (field.value && date.isValid()) {
      flatpickr.setDate(date.toDate());
    } else {
      flatpickr.setDate(null);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  return (
    <FormControl
      id={name}
      meta={meta}
      isEmpty={!field.value}
      {...formControlProps}
    >
      <InputGroup>
        <InputStyledField name={name} ref={inputRef} isReadOnly={true} />
        <InputRightElement
          ref={addonRef}
          children={
            <InputButton
              variant="transparant"
              size="icoLarge"
              pr=".75rem"
              onClick={openDatepicker}
            >
              <Calendar />
            </InputButton>
          }
        />
        <InputHighLight />
        <InputBar />
      </InputGroup>
    </FormControl>
  );
};
