/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { SplitPaymentConfiguration } from './splitPaymentConfiguration';
import type { ManualSplitConfiguration } from './manualSplitConfiguration';

export interface ProgramServiceUpdateRequest {
  split_payment_configuration?: SplitPaymentConfiguration;
  manual_split_configuration?: ManualSplitConfiguration;
}
