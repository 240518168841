/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { EntityType } from './entityType';
import type { AddressV1 } from './addressV1';

export type OrganisationCreateBody = {
  vat_number: string;
  email: string;
  use_mobility_budget?: boolean;
  is_freelancer?: boolean;
  country_code?: string;
  use_payment_card?: boolean;
  entity_type?: EntityType;
  force_creation?: boolean;
  legal_name?: string;
  legal_form?: string;
  legal_sector?: string;
  registration_date?: string;
  address?: AddressV1;
  /** The id of the parent organisation. An organisation is a parent or a child (no child of child relationship), an empty id means that the organisation is a parent. */
  parent_organisation_id?: string | null;
};
