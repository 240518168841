/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export interface ExpenseReports {
  first_name?: string;
  last_name?: string;
  payroll_id?: string;
  program_name?: string;
  program_start_date?: string;
  program_end_date?: string;
  total_budget?: number;
  remaining_budget?: number;
  total_spent_amount?: number;
  spent_reserved?: number;
  spent_pending?: number;
  spent_approved?: number;
  total_sum_expenses?: number;
}
