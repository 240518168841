import { Button, Container } from '@chakra-ui/react';
import { useGetCurrentUser } from '@libs/api/endpoints';
import { LoggedUser } from '@libs/api/models';
import { PageHeader } from '@libs/ui/components';
import * as Sentry from '@sentry/browser';
import React, { Component, ReactNode } from 'react';

interface RootProps {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  config: { [key: string]: any } | null;
  user?: LoggedUser;
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class RootErrorCrashTrackingBoundary extends Component<
  RootProps,
  State
> {
  constructor(props: RootProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  componentDidCatch(error: unknown, errorInfo: { [key: string]: any }) {
    const { config, user } = this.props;
    Sentry.withScope((scope) => {
      scope.setTag('APP_ID', config.appId);
      if (user) {
        scope.setUser(user);
      }
      scope.setExtras({
        errorInfo,
        config,
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Container py="6rem" maxW="xl" px="2rem">
          <PageHeader size="Large" title="Unexpected Error occured" />
          <Button
            mt="2rem"
            variant="primary"
            size="body2"
            onClick={() => window.location.reload()}
          >
            retry
          </Button>
        </Container>
      );
    }
    return children;
  }
}

export const ErrorCrashTrackingBoundary = ({
  config,
  children,
}: {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  config: { [key: string]: any } | null;
  children: ReactNode;
}) => {
  const { data: user } = useGetCurrentUser({ query: { enabled: false } });

  return (
    <RootErrorCrashTrackingBoundary user={user} config={config}>
      {children}
    </RootErrorCrashTrackingBoundary>
  );
};
