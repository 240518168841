/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { WalletStatus } from './walletStatus';

export interface Wallet {
  current_amount: number;
  status: WalletStatus;
  iban_number: string;
  bic: string;
  wallet_reference: string;
}
