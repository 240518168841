/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { PolicyActionAction } from './policyActionAction';
import type { PolicyActionActionRole } from './policyActionActionRole';

export interface PolicyAction {
  action: PolicyActionAction;
  action_role: PolicyActionActionRole;
  active: boolean;
}
