/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  PoliciesListResponse,
  ListProgramExpensePoliciesParams,
  Policy,
  UpsertPolicyRequest,
  GetApplicablePolicyParams,
  CheckPolicyResponse,
  CheckPolicyRequest,
} from '../../models';
import type { Error } from '../../models/component-common';
import { customInstance } from '../../config';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
  T,
>() => T extends Y ? 1 : 2
  ? A
  : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<
    { [Q in P]: T[P] },
    { -readonly [Q in P]: T[P] },
    P
  >;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object
        ? NonReadonly<NonNullable<T[P]>>
        : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

/**
 * Returns the policies for a program or program group:
- Program ID provided: Returns all policies of the PROGRAM level
- Program ID and Group ID provided: Returns all policies of the PROGRAM_GROUP and SERVICE levels

 */
export const listProgramExpensePolicies = (
  params: ListProgramExpensePoliciesParams,
  signal?: AbortSignal,
) => {
  return customInstance<PoliciesListResponse>({
    url: `/web/v2/policies`,
    method: 'get',
    params,
    signal,
  });
};

export const getListProgramExpensePoliciesQueryKey = (
  params: ListProgramExpensePoliciesParams,
) => [`/web/v2/policies`, ...(params ? [params] : [])] as const;

export const getListProgramExpensePoliciesQueryOptions = <
  TData = Awaited<ReturnType<typeof listProgramExpensePolicies>>,
  TError = unknown,
>(
  params: ListProgramExpensePoliciesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listProgramExpensePolicies>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof listProgramExpensePolicies>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListProgramExpensePoliciesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listProgramExpensePolicies>>
  > = ({ signal }) => listProgramExpensePolicies(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListProgramExpensePoliciesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listProgramExpensePolicies>>
>;
export type ListProgramExpensePoliciesQueryError = unknown;

export const useListProgramExpensePolicies = <
  TData = Awaited<ReturnType<typeof listProgramExpensePolicies>>,
  TError = unknown,
>(
  params: ListProgramExpensePoliciesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listProgramExpensePolicies>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListProgramExpensePoliciesQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createProgramPolicy = (
  upsertPolicyRequest: NonReadonly<UpsertPolicyRequest>,
) => {
  return customInstance<Policy>({
    url: `/web/v2/policies`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: upsertPolicyRequest,
  });
};

export const getCreateProgramPolicyMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createProgramPolicy>>,
    TError,
    { data: NonReadonly<UpsertPolicyRequest> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createProgramPolicy>>,
  TError,
  { data: NonReadonly<UpsertPolicyRequest> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createProgramPolicy>>,
    { data: NonReadonly<UpsertPolicyRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return createProgramPolicy(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateProgramPolicyMutationResult = NonNullable<
  Awaited<ReturnType<typeof createProgramPolicy>>
>;
export type CreateProgramPolicyMutationBody = NonReadonly<UpsertPolicyRequest>;
export type CreateProgramPolicyMutationError = unknown;

export const useCreateProgramPolicy = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createProgramPolicy>>,
    TError,
    { data: NonReadonly<UpsertPolicyRequest> },
    TContext
  >;
}) => {
  const mutationOptions = getCreateProgramPolicyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Returns applicable policy for given parameters
Assumes the check is made for a refundable MANUAL expense

 */
export const getApplicablePolicy = (
  membershipId: string,
  programId: string,
  params: GetApplicablePolicyParams,
  signal?: AbortSignal,
) => {
  return customInstance<Policy>({
    url: `/web/v2/memberships/${membershipId}/programs/${programId}/policy`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetApplicablePolicyQueryKey = (
  membershipId: string,
  programId: string,
  params: GetApplicablePolicyParams,
) =>
  [
    `/web/v2/memberships/${membershipId}/programs/${programId}/policy`,
    ...(params ? [params] : []),
  ] as const;

export const getGetApplicablePolicyQueryOptions = <
  TData = Awaited<ReturnType<typeof getApplicablePolicy>>,
  TError = Error,
>(
  membershipId: string,
  programId: string,
  params: GetApplicablePolicyParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApplicablePolicy>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getApplicablePolicy>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApplicablePolicyQueryKey(membershipId, programId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApplicablePolicy>>
  > = ({ signal }) =>
    getApplicablePolicy(membershipId, programId, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(membershipId && programId),
    ...queryOptions,
  };
};

export type GetApplicablePolicyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApplicablePolicy>>
>;
export type GetApplicablePolicyQueryError = Error;

export const useGetApplicablePolicy = <
  TData = Awaited<ReturnType<typeof getApplicablePolicy>>,
  TError = Error,
>(
  membershipId: string,
  programId: string,
  params: GetApplicablePolicyParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApplicablePolicy>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApplicablePolicyQueryOptions(
    membershipId,
    programId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const checkPolicy = (
  checkPolicyRequest: NonReadonly<CheckPolicyRequest>,
) => {
  return customInstance<CheckPolicyResponse>({
    url: `/web/v2/policies/check_policy`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: checkPolicyRequest,
  });
};

export const getCheckPolicyMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checkPolicy>>,
    TError,
    { data: NonReadonly<CheckPolicyRequest> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof checkPolicy>>,
  TError,
  { data: NonReadonly<CheckPolicyRequest> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof checkPolicy>>,
    { data: NonReadonly<CheckPolicyRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return checkPolicy(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CheckPolicyMutationResult = NonNullable<
  Awaited<ReturnType<typeof checkPolicy>>
>;
export type CheckPolicyMutationBody = NonReadonly<CheckPolicyRequest>;
export type CheckPolicyMutationError = unknown;

export const useCheckPolicy = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checkPolicy>>,
    TError,
    { data: NonReadonly<CheckPolicyRequest> },
    TContext
  >;
}) => {
  const mutationOptions = getCheckPolicyMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getProgramExpensePolicy = (
  policyId: string,
  signal?: AbortSignal,
) => {
  return customInstance<Policy>({
    url: `/web/v2/policies/${policyId}`,
    method: 'get',
    signal,
  });
};

export const getGetProgramExpensePolicyQueryKey = (policyId: string) =>
  [`/web/v2/policies/${policyId}`] as const;

export const getGetProgramExpensePolicyQueryOptions = <
  TData = Awaited<ReturnType<typeof getProgramExpensePolicy>>,
  TError = unknown,
>(
  policyId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getProgramExpensePolicy>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getProgramExpensePolicy>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetProgramExpensePolicyQueryKey(policyId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getProgramExpensePolicy>>
  > = ({ signal }) => getProgramExpensePolicy(policyId, signal);

  return { queryKey, queryFn, enabled: !!policyId, ...queryOptions };
};

export type GetProgramExpensePolicyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProgramExpensePolicy>>
>;
export type GetProgramExpensePolicyQueryError = unknown;

export const useGetProgramExpensePolicy = <
  TData = Awaited<ReturnType<typeof getProgramExpensePolicy>>,
  TError = unknown,
>(
  policyId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getProgramExpensePolicy>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProgramExpensePolicyQueryOptions(
    policyId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putProgramExpensePolicy = (
  policyId: string,
  upsertPolicyRequest: NonReadonly<UpsertPolicyRequest>,
) => {
  return customInstance<Policy>({
    url: `/web/v2/policies/${policyId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: upsertPolicyRequest,
  });
};

export const getPutProgramExpensePolicyMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putProgramExpensePolicy>>,
    TError,
    { policyId: string; data: NonReadonly<UpsertPolicyRequest> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putProgramExpensePolicy>>,
  TError,
  { policyId: string; data: NonReadonly<UpsertPolicyRequest> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putProgramExpensePolicy>>,
    { policyId: string; data: NonReadonly<UpsertPolicyRequest> }
  > = (props) => {
    const { policyId, data } = props ?? {};

    return putProgramExpensePolicy(policyId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutProgramExpensePolicyMutationResult = NonNullable<
  Awaited<ReturnType<typeof putProgramExpensePolicy>>
>;
export type PutProgramExpensePolicyMutationBody =
  NonReadonly<UpsertPolicyRequest>;
export type PutProgramExpensePolicyMutationError = unknown;

export const usePutProgramExpensePolicy = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putProgramExpensePolicy>>,
    TError,
    { policyId: string; data: NonReadonly<UpsertPolicyRequest> },
    TContext
  >;
}) => {
  const mutationOptions = getPutProgramExpensePolicyMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteProgramExpensePolicy = (policyId: string) => {
  return customInstance<void>({
    url: `/web/v2/policies/${policyId}`,
    method: 'delete',
  });
};

export const getDeleteProgramExpensePolicyMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProgramExpensePolicy>>,
    TError,
    { policyId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteProgramExpensePolicy>>,
  TError,
  { policyId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteProgramExpensePolicy>>,
    { policyId: string }
  > = (props) => {
    const { policyId } = props ?? {};

    return deleteProgramExpensePolicy(policyId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteProgramExpensePolicyMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteProgramExpensePolicy>>
>;

export type DeleteProgramExpensePolicyMutationError = unknown;

export const useDeleteProgramExpensePolicy = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProgramExpensePolicy>>,
    TError,
    { policyId: string },
    TContext
  >;
}) => {
  const mutationOptions = getDeleteProgramExpensePolicyMutationOptions(options);

  return useMutation(mutationOptions);
};
