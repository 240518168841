/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type ExpenseSource = typeof ExpenseSource[keyof typeof ExpenseSource];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseSource = {
  CLIENT: 'CLIENT',
  OPERATOR: 'OPERATOR',
  BANK: 'BANK',
} as const;
