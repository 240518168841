/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  RegistrationsGetResponseV1,
  ErrorResponse,
  RegistrationsGetParams,
  RegistrationV1,
  RegistrationRequestCreate,
  RegistrationImportCsv,
  RegistrationsStatisticsGetResponse,
  RegistrationsStatisticsGetParams,
} from '../../models';
import { customInstance } from '../../config';

export const registrationsGet = (
  params?: RegistrationsGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<RegistrationsGetResponseV1>({
    url: `/web/registrations`,
    method: 'get',
    params,
    signal,
  });
};

export const getRegistrationsGetQueryKey = (
  params?: RegistrationsGetParams,
) => [`/web/registrations`, ...(params ? [params] : [])];

export type RegistrationsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof registrationsGet>>
>;
export type RegistrationsGetQueryError = ErrorResponse;

export const useRegistrationsGet = <
  TData = Awaited<ReturnType<typeof registrationsGet>>,
  TError = ErrorResponse,
>(
  params?: RegistrationsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof registrationsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRegistrationsGetQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof registrationsGet>>
  > = ({ signal }) => registrationsGet(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof registrationsGet>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const registrationCreate = (
  registrationRequestCreate: RegistrationRequestCreate,
) => {
  return customInstance<RegistrationV1>({
    url: `/web/registrations`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: registrationRequestCreate,
  });
};

export type RegistrationCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof registrationCreate>>
>;
export type RegistrationCreateMutationBody = RegistrationRequestCreate;
export type RegistrationCreateMutationError = ErrorResponse;

export const useRegistrationCreate = <
  TError = ErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registrationCreate>>,
    TError,
    { data: RegistrationRequestCreate },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registrationCreate>>,
    { data: RegistrationRequestCreate }
  > = (props) => {
    const { data } = props ?? {};

    return registrationCreate(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof registrationCreate>>,
    TError,
    { data: RegistrationRequestCreate },
    TContext
  >(mutationFn, mutationOptions);
};
export const registrationImportCsv = (
  registrationImportCsv: RegistrationImportCsv,
) => {
  return customInstance<void>({
    url: `/web/registrations/import/csv`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: registrationImportCsv,
  });
};

export type RegistrationImportCsvMutationResult = NonNullable<
  Awaited<ReturnType<typeof registrationImportCsv>>
>;
export type RegistrationImportCsvMutationBody = RegistrationImportCsv;
export type RegistrationImportCsvMutationError = ErrorResponse;

export const useRegistrationImportCsv = <
  TError = ErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registrationImportCsv>>,
    TError,
    { data: RegistrationImportCsv },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registrationImportCsv>>,
    { data: RegistrationImportCsv }
  > = (props) => {
    const { data } = props ?? {};

    return registrationImportCsv(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof registrationImportCsv>>,
    TError,
    { data: RegistrationImportCsv },
    TContext
  >(mutationFn, mutationOptions);
};
export const registrationsStatisticsGet = (
  params?: RegistrationsStatisticsGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<RegistrationsStatisticsGetResponse>({
    url: `/web/registrations/statistics`,
    method: 'get',
    params,
    signal,
  });
};

export const getRegistrationsStatisticsGetQueryKey = (
  params?: RegistrationsStatisticsGetParams,
) => [`/web/registrations/statistics`, ...(params ? [params] : [])];

export type RegistrationsStatisticsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof registrationsStatisticsGet>>
>;
export type RegistrationsStatisticsGetQueryError = ErrorResponse;

export const useRegistrationsStatisticsGet = <
  TData = Awaited<ReturnType<typeof registrationsStatisticsGet>>,
  TError = ErrorResponse,
>(
  params?: RegistrationsStatisticsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof registrationsStatisticsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRegistrationsStatisticsGetQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof registrationsStatisticsGet>>
  > = ({ signal }) => registrationsStatisticsGet(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof registrationsStatisticsGet>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};
