/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { RestrictionGroupStatus } from './restrictionGroupStatus';

export interface MerchantRestrictionGroupsRequest {
  name?: string;
  start_at?: string;
  status?: RestrictionGroupStatus;
}
