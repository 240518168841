/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type PaymentSource = typeof PaymentSource[keyof typeof PaymentSource];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentSource = {
  APP: 'APP',
  CARD: 'CARD',
  PLATFORM: 'PLATFORM',
} as const;
