/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type ExpenseMissingFields =
  typeof ExpenseMissingFields[keyof typeof ExpenseMissingFields];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseMissingFields = {
  product: 'product',
  attachments: 'attachments',
  justification: 'justification',
  expense_code: 'expense_code',
} as const;
