import { useListProgramExpensePolicies } from '@libs/api-v2/endpoints';
import { Card, Drawer } from '@libs/ui/components';
import React from 'react';
import { PoliciesTable } from './policies-table';

type PoliciesDrawerProps = {
  programId: string;
  groupId?: string;
  programName: string;
  groupName?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const PoliciesDrawer = ({
  programId,
  groupId,
  programName,
  groupName,
  isOpen,
  onClose,
}: PoliciesDrawerProps) => {
  const paramsProgramGroup = {
    program_id: programId,
    group_id: groupId,
  };
  const paramsProgram = {
    program_id: programId,
  };

  const {
    isLoading: isLoadingProgramGroup,
    isFetching: isFetchingProgramGroup,
    refetch: refetchProgramGroup,
    data: PoliciesProgramGroup,
  } = useListProgramExpensePolicies(paramsProgramGroup, {
    query: { keepPreviousData: true },
  });

  const {
    isLoading: isLoadingProgram,
    isFetching: isFetchingProgram,
    refetch: refetchProgram,
    data: PoliciesProgram,
  } = useListProgramExpensePolicies(paramsProgram, {
    query: { keepPreviousData: true },
  });

  return (
    <Drawer
      title={`Policies ${programName} - ${groupName}`}
      isOpen={isOpen}
      onClose={onClose}
      size="xxl"
    >
      <Drawer.Body>
        <Card bgColor="background.page" mt="1rem">
          <PoliciesTable
            isLoading={isLoadingProgram}
            isFetching={isFetchingProgram}
            policies={PoliciesProgram}
            refetch={refetchProgram}
            policiesGetParams={paramsProgram}
          />
        </Card>
        <Card bgColor="background.page" mt="1rem">
          <PoliciesTable
            isLoading={isLoadingProgramGroup}
            isFetching={isFetchingProgramGroup}
            policies={PoliciesProgramGroup}
            refetch={refetchProgramGroup}
            policiesGetParams={paramsProgramGroup}
          />
        </Card>
      </Drawer.Body>
    </Drawer>
  );
};
