/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type MembershipRole = typeof MembershipRole[keyof typeof MembershipRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipRole = {
  EMPLOYEE: 'EMPLOYEE',
  OBSERVER: 'OBSERVER',
  REVIEWER: 'REVIEWER',
  ADMIN: 'ADMIN',
} as const;
