/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { NotionTextArrayItemText } from './notionTextArrayItemText';
import type { NotionTextAnnotations } from './notionTextAnnotations';
import type { Url } from './component-common/url';

export type NotionTextArrayItem = {
  type?: string;
  text?: NotionTextArrayItemText;
  annotations?: NotionTextAnnotations;
  plain_text?: string;
  href?: Url;
};
