/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type DocumentType = typeof DocumentType[keyof typeof DocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentType = {
  ORGANISATION_CONSTITUTION: 'ORGANISATION_CONSTITUTION',
  ORGANISATION_BCE: 'ORGANISATION_BCE',
  ID_FRONT: 'ID_FRONT',
  ID_BACK: 'ID_BACK',
  PASSPORT: 'PASSPORT',
  PROOF_OF_ADDRESS: 'PROOF_OF_ADDRESS',
  US_PERSON_ADDITIONAL: 'US_PERSON_ADDITIONAL',
  PROXY_GRANTING_EMPLOYEE: 'PROXY_GRANTING_EMPLOYEE',
  RESIDENCE_PERMIT: 'RESIDENCE_PERMIT',
} as const;
