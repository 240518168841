/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { BudgetDistribution } from './budgetDistribution';

export interface SpendingPolicy {
  limit_amount: number;
  carry_over: boolean;
  prorata: boolean;
  budget_distribution: BudgetDistribution;
  display_budget_limit: boolean;
}
