/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryMembershipIdParameter } from './component-parameters/queryMembershipIdParameter';
import type { QueryDateFromParameter } from './component-parameters/queryDateFromParameter';
import type { QuerySimulateWithExistingExpensesParameter } from './querySimulateWithExistingExpensesParameter';

export type ProgramGroupLimitSimulationParams = {
  /**
   * ID of the membership
   */
  membership_id?: QueryMembershipIdParameter;
  /**
   * Start of date range (including)
   */
  date_from?: QueryDateFromParameter;
  /**
   * Indicates whether existing expenses should impact the simulation
   */
  with_existing_expenses?: QuerySimulateWithExistingExpensesParameter;
};
