/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  RegistrationV1,
  ErrorResponse,
  RegistrationGetParams,
} from '../../models';
import { customInstance } from '../../config';

export const registrationGet = (
  membershipId: string,
  params?: RegistrationGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<RegistrationV1>({
    url: `/web/memberships/${membershipId}/registration`,
    method: 'get',
    params,
    signal,
  });
};

export const getRegistrationGetQueryKey = (
  membershipId: string,
  params?: RegistrationGetParams,
) => [
  `/web/memberships/${membershipId}/registration`,
  ...(params ? [params] : []),
];

export type RegistrationGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof registrationGet>>
>;
export type RegistrationGetQueryError = ErrorResponse;

export const useRegistrationGet = <
  TData = Awaited<ReturnType<typeof registrationGet>>,
  TError = ErrorResponse,
>(
  membershipId: string,
  params?: RegistrationGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof registrationGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRegistrationGetQueryKey(membershipId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof registrationGet>>> = ({
    signal,
  }) => registrationGet(membershipId, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof registrationGet>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!membershipId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const registrationUpdate = (
  membershipId: string,
  registrationV1: RegistrationV1,
) => {
  return customInstance<RegistrationV1>({
    url: `/web/memberships/${membershipId}/registration`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: registrationV1,
  });
};

export type RegistrationUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof registrationUpdate>>
>;
export type RegistrationUpdateMutationBody = RegistrationV1;
export type RegistrationUpdateMutationError = ErrorResponse;

export const useRegistrationUpdate = <
  TError = ErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registrationUpdate>>,
    TError,
    { membershipId: string; data: RegistrationV1 },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registrationUpdate>>,
    { membershipId: string; data: RegistrationV1 }
  > = (props) => {
    const { membershipId, data } = props ?? {};

    return registrationUpdate(membershipId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof registrationUpdate>>,
    TError,
    { membershipId: string; data: RegistrationV1 },
    TContext
  >(mutationFn, mutationOptions);
};
