/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { MembershipRole } from './membershipRole';

/**
 * Repeat "role" param multiple times to include multiple roles
 */
export type QueryRolesParameter = MembershipRole[];
