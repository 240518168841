/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { ActivationStatus } from './activationStatus';

export type ProgramServiceAllOf = {
  status?: ActivationStatus;
  is_splittable?: boolean;
  is_default_for_provider?: boolean;
  employer_reimbursement_percentage?: number;
};
