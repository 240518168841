/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { Address } from './address';
import type { Gender } from './gender';
import type { RegistrationAllOfRole } from './registrationAllOfRole';
import type { Provider } from './provider';
import type { IdentityCard } from './identityCard';
import type { DrivingLicence } from './drivingLicence';
import type { Selfie } from './selfie';
import type { BankService } from './bankService';
import type { Status } from './status';
import type { SocialSecretary } from './socialSecretary';
import { Platform } from './platform';
import { Card } from './card';

export type RegistrationAllOf = {
  membership_id?: string;
  is_suspended?: boolean;
  readonly user_id?: string;
  readonly organisation_id?: string;
  email?: string;
  address?: Address;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  gender?: Gender;
  date_of_birth?: string;
  is_us_person?: boolean;
  nationality?: string;
  place_of_birth?: string;
  country_of_birth?: string;
  start_on?: string;
  role?: RegistrationAllOfRole;
  job_title?: string;
  organisation_name?: string;
  payment_profiles?: string[];
  providers?: Provider[];
  identity_card?: IdentityCard;
  driving_licence?: DrivingLicence;
  selfie?: Selfie;
  bank?: BankService;
  status?: Status;
  internal_payroll_id?: string;
  readonly bank_reference?: string;
  readonly platforms?: Platform[];
  social_secretary?: SocialSecretary;
};
