import React from 'react';
import { Flex } from '@chakra-ui/layout';
import { ProgramsTable } from '../../../program/components/programs-table';
import { Registration } from '@libs/api-v2/models';

type ProgramsTableProps = {
  employee: Registration;
};

export const EmployeeDetailsPrograms = ({ employee }: ProgramsTableProps) => {
  return (
    <Flex direction="column">
      <ProgramsTable organisationId={employee?.organisation_id} />
    </Flex>
  );
};
