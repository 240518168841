import { FormControlProps } from '@chakra-ui/react';
import { FormikConfig } from 'formik';

export type BaseProps = Omit<FormControlProps, 'id'> & {
  name: string;
};

export type FormSubmitParameters<Values> = Parameters<
  FormikConfig<Values>['onSubmit']
>;
export type FormSubmitData<Values> = {
  values: FormSubmitParameters<Values>[0];
  helpers: FormSubmitParameters<Values>[1];
};
