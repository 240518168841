/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { MerchantRestrictionGroup } from './merchantRestrictionGroup';
import type { MerchantCategoryRestrictionGroupGet200AllOf } from './merchantCategoryRestrictionGroupGet200AllOf';

export type MerchantCategoryRestrictionGroupGet200 = MerchantRestrictionGroup &
  MerchantCategoryRestrictionGroupGet200AllOf;
