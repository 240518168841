/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

/**
 * CURRENT: Retriction currently used for the card PENDING: Next restriction to be applied on the card ARCHIVED: History of restriction for the card
 */
export type RestrictionCardStatus =
  typeof RestrictionCardStatus[keyof typeof RestrictionCardStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RestrictionCardStatus = {
  CURRENT: 'CURRENT',
  PENDING: 'PENDING',
  ARCHIVED: 'ARCHIVED',
} as const;
