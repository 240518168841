import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Drawer } from '@libs/ui/components';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
//import { useLanguage } from '@libs/core/i18n';
import { useCreateForm } from '@libs/dashboard-core/utils/formik';
import { useFormik } from 'formik';
import Editor from '@monaco-editor/react';
import {
  useRulesetsUpdateTreezor,
  useRulesetsCreateTreezor,
  useRulesetGetByIdTreezor,
} from '@libs/api-v2/endpoints';

type RulesetCreateDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  rulesetId?: string;
};

export const RulesetDrawer = ({
  isOpen,
  onClose,
  refetch,
  rulesetId,
}: RulesetCreateDrawerProps) => {
  // Attributes
  //const { language } = useLanguage();
  const { t } = useTranslation();
  const toast = useToast();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [rulesetJson, setRulesetJson] = useState<any>();

  const { data: ruleset } = useRulesetGetByIdTreezor(rulesetId);
  const { mutateAsync: createRuleset } = useRulesetsCreateTreezor();
  const { mutateAsync: updateRuleset } = useRulesetsUpdateTreezor();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    status: Yup.string().required('Required'),
    ruleset: Yup.string().required('Required'),
  });

  function updateRuleSet(values, rulesetId) {
    updateRuleset(
      {
        rulesetId: rulesetId,
        data: {
          name: values.name,
          description: values.description,
          status: values.status,
          conditions: values.ruleset.split('\n').join(' '),
        },
      },
      {
        onSuccess: () => {
          onClose();
          refetch();
          toast({
            title: 'Ruleset updated',
            description: '',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        },
      },
    );
  }

  const { values, errors, setFieldError, setValues, ...rest } = useFormik<{
    name: string;
    description: string;
    ruleset: string;
    status: string;
  }>({
    initialValues: {
      name: ruleset?.name ?? '',
      description: ruleset?.description ?? '',
      ruleset: rulesetJson?.conditions ?? '',
      status: ruleset?.status ?? '',
    },
    enableReinitialize: true,
    onSubmit: (value) => {
      if (isJsonString(value.ruleset) && !rulesetId) {
        createRuleset(
          {
            data: {
              name: value.name,
              description: value.description,
              conditions: JSON.stringify(value.ruleset),
            },
          },
          {
            onSuccess: () => {
              onClose();
              refetch();
              toast({
                title: t(
                  i18nKeys.operator.rulesets.toast.creation.success,
                ) as string,
                description: '',
                status: 'success',
                duration: 5000,
                isClosable: true,
              });
            },
          },
        );
      } else if (isJsonString(value.ruleset) && !!rulesetId) {
        updateRuleSet(value, rulesetId);
      } else {
        setFieldError('ruleset', 'This is not a valid json format');
      }
    },
    validationSchema: validationSchema,
  });
  const form = useCreateForm(values, { errors, ...rest });
  // Functions
  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    let prettyRuleset = '';
    if (ruleset?.conditions !== undefined) {
      setRulesetJson(JSON.parse(ruleset?.conditions));
      prettyRuleset = JSON.stringify(JSON.parse(ruleset?.conditions), null, 2);
    }
    setValues({
      name: ruleset?.name,
      description: ruleset?.description,
      ruleset: prettyRuleset,
      status: ruleset?.status,
    });
  }, [ruleset, setValues]);

  // Render
  return (
    <Drawer
      title={
        rulesetId
          ? `${t(i18nKeys.operator.ruleset.drawer.title.details)}${
              ruleset?.name
            }`
          : t(i18nKeys.operator.ruleset.drawer.title.create)
      }
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box>
        <Drawer.Body>
          <VStack alignItems={'flex-start'}>
            <Text color="ink.dark" fontSize={'2rem'} my={'2rem'}>
              {t(i18nKeys.common.metadata)}
            </Text>
            <Stack width={'full'} pb={'2rem'}>
              <Text>{t(i18nKeys.common.full_name)}</Text>
              <FormControl
                isInvalid={errors?.name !== undefined && errors?.name !== ''}
              >
                <Input
                  name="name"
                  borderRadius={'.5rem'}
                  placeholder={t(i18nKeys.drawer.rulesets.placeholder.name)}
                  py={'2rem'}
                  onChange={(value) =>
                    form.name.handleSelect(value.target.value)
                  }
                  value={values.name}
                  disabled={rulesetId && true}
                />
                {errors?.name !== undefined && errors?.name !== '' && (
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                )}
              </FormControl>
            </Stack>
            <Stack width={'full'} pb={'2rem'}>
              <Text>{t(i18nKeys.common.status)}</Text>
              <FormControl
                isInvalid={errors?.name !== undefined && errors?.name !== ''}
              >
                <Input
                  name="status"
                  borderRadius={'.5rem'}
                  placeholder={t(i18nKeys.drawer.rulesets.placeholder.status)}
                  py={'2rem'}
                  onChange={(value) =>
                    form.status.handleSelect(value.target.value)
                  }
                  value={values.status}
                  disabled={rulesetId && true}
                />
                {errors?.name !== undefined && errors?.name !== '' && (
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                )}
              </FormControl>
            </Stack>
            <Stack width={'full'} pb={'2rem'}>
              <Text>{t(i18nKeys.common.description)}</Text>
              <FormControl
                isInvalid={
                  errors?.description !== undefined &&
                  errors?.description !== ''
                }
              >
                <Input
                  name="description"
                  borderRadius={'.5rem'}
                  placeholder={t(
                    i18nKeys.drawer.rulesets.placeholder.description,
                  )}
                  py={'2rem'}
                  onChange={(value) =>
                    form.description.handleSelect(value.target.value)
                  }
                  disabled={rulesetId && true}
                  value={values.description}
                />
                {errors?.description !== undefined &&
                  errors?.description !== '' && (
                    <FormErrorMessage>{errors.description}</FormErrorMessage>
                  )}
              </FormControl>
            </Stack>
            <Text color="ink.dark" fontSize={'2rem'} pb={'1rem'}>
              {t(i18nKeys.operator.rulesets.title)}
            </Text>
            <FormControl
              isInvalid={
                errors?.ruleset !== undefined && errors?.ruleset !== ''
              }
            >
              <Editor
                height="50vh"
                defaultLanguage="json"
                defaultValue={t(i18nKeys.drawer.rulesets.placeholder.json)}
                value={values.ruleset}
                onChange={(value) => form.ruleset.handleSelect(value)}
                options={{
                  minimap: { autohide: true },
                }}
              />
              {errors?.ruleset !== undefined && errors?.ruleset !== '' && (
                <FormErrorMessage>{errors.ruleset}</FormErrorMessage>
              )}
            </FormControl>
          </VStack>
        </Drawer.Body>

        <Drawer.Footer>
          <Flex w="100%" justify="space-between">
            <Button size="body2" w="auto" variant="border" onClick={onClose}>
              {t(i18nKeys.common.cancel)}
            </Button>
            <Button
              type="submit"
              size="body2"
              w="auto"
              variant="primary"
              loadingText={t(i18nKeys.common.submit)}
              onClick={form.submitForm}
            >
              {!rulesetId ? t(i18nKeys.common.submit) : 'Update'}
            </Button>
          </Flex>
        </Drawer.Footer>
      </Box>
    </Drawer>
  );
};
