/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type SocialSecretaryDataSocialSecretaryEmployeeType =
  typeof SocialSecretaryDataSocialSecretaryEmployeeType[keyof typeof SocialSecretaryDataSocialSecretaryEmployeeType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocialSecretaryDataSocialSecretaryEmployeeType = {
  WORKER: 'WORKER',
  CLERK: 'CLERK',
} as const;
