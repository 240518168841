import {
  Button,
  Flex,
  Select,
  Tag,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Program } from '@libs/api-v2/models';
import {
  useImcclRestrictionActivate,
  useImcclRestrictionAllGet,
} from '@libs/api-v2/endpoints/imccl-restrictions/imccl-restrictions';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { capitalize } from '@libs/core/utils';
import { Drawer } from '@libs/ui/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type ProgramImcclDrawerProps = {
  program: Program;
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
};

export const ProgramImcclDrawer = ({
  program,
  isOpen,
  onClose,
  refetch,
}: ProgramImcclDrawerProps) => {
  // Attributes
  const { t } = useTranslation();
  const toast = useToast();
  const [restrictionId, setRestrictionId] = useState('');
  const [secondaryRestrictionId, setSecondaryRestrictionId] = useState('');

  const { data: restrictions } = useImcclRestrictionAllGet();
  const { mutateAsync: linkRestriction } = useImcclRestrictionActivate();

  // Functions
  function handleLinkRestriction() {
    linkRestriction(
      {
        programId: program.id,
        params: {
          primary_restriction_id: restrictionId,
          secondary_restriction_id: secondaryRestrictionId,
        },
      },
      {
        onSuccess: () => {
          onClose();
          refetch();
          toast({
            title: t(i18nKeys.operator.rulesets.toast.creation.success),
            description: '',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        },
      },
    );
  }

  // Render
  return (
    <Drawer
      title={t(i18nKeys.operator.employees.programs.services.title)}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Flex>
        <Tag size={'md'} key={program.id} width="auto">
          {' '}
          Id : {program.id}{' '}
        </Tag>
      </Flex>
      <Drawer.Body>
        <VStack alignItems={'flex-start'}>
          <Text color="ink.dark" fontSize={'2rem'} pb={'1rem'}>
            {t(i18nKeys.operator.rulesets.title)}
          </Text>
          <Select
            defaultValue={program.imccl_restriction_id}
            onChange={(e) => setRestrictionId(e.target.value)}
          >
            {restrictions?.restrictions?.map((restriction) => (
              <option key={restriction.name} value={restriction.id}>
                {capitalize(restriction.name)}
              </option>
            ))}
          </Select>
          <Text color="dark" fontSize={'2rem'} pb={'1rem'}>
            {t(i18nKeys.operator.secondary_rulesets.title)}
          </Text>
          <Select
            defaultValue={program.secondary_imccl_restriction_id}
            onChange={(e) => setSecondaryRestrictionId(e.target.value)}
          >
            {restrictions?.restrictions?.map((restriction) => (
              <option key={restriction.name} value={restriction.id}>
                {capitalize(restriction.name)}
              </option>
            ))}
          </Select>
        </VStack>
      </Drawer.Body>

      <Drawer.Footer>
        <Flex w="100%" justify="space-between">
          <Button size="body2" w="auto" variant="border" onClick={onClose}>
            {t(i18nKeys.common.cancel)}
          </Button>
          <Button
            size="body2"
            w="auto"
            variant="primary"
            loadingText={t(i18nKeys.common.submit)}
            onClick={handleLinkRestriction}
          >
            {t(i18nKeys.common.submit)}
          </Button>
        </Flex>
      </Drawer.Footer>
    </Drawer>
  );
};
