/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type ProgramsGetProgramStatus = 'active' | 'inactive';

export const ProgramsGetProgramStatus = {
  active: 'active' as ProgramsGetProgramStatus,
  inactive: 'inactive' as ProgramsGetProgramStatus,
};
