import { Text } from '@chakra-ui/layout';
import {
  ListProgramExpensePoliciesParams,
  PoliciesListResponse,
} from '@libs/api-v2/models';
import { Table } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useTable } from '@libs/ui/components/table/table.utils';
import React, { Fragment, useEffect } from 'react';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';

type PoliciesTableProps = {
  policies: PoliciesListResponse;
  refetch: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<PoliciesListResponse, unknown>>;
  isLoading: boolean;
  isFetching: boolean;
  policiesGetParams: ListProgramExpensePoliciesParams;
};

export const PoliciesTable = ({
  policies,
  refetch,
  isLoading,
  isFetching,
}: PoliciesTableProps) => {
  const { useQueryParams } = useTable();

  useQueryParams({
    refetch,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  function compare_level(a, b) {
    if (a.level.toLowerCase() < b.level.toLowerCase()) {
      return -1;
    }
    if (a.level.toLowerCase() > b.level.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  const headers: TableHeaders = [
    {
      name: 'level',
      title: 'level',
    },
    {
      name: 'type',
      title: 'type',
    },
    {
      name: 'required_fields',
      title: 'required',
    },
    {
      name: 'conditions',
      title: 'conditions',
    },
    {
      name: 'services',
      title: 'services',
    },
    {
      name: 'actions',
      title: 'actions',
    },
  ];

  return (
    <Table headers={headers}>
      <Table.TBody
        isLoading={(isLoading || isFetching) && !policies}
        colSpan={headers.length}
        isEmpty={
          !isLoading &&
          !isFetching &&
          (policies?.data.length === 0 || !policies)
        }
      >
        {policies?.data
          .sort(compare_level)
          .map(
            (
              {
                id,
                type,
                level,
                conditions,
                required_fields,
                services,
                actions,
              },
              index,
            ) => {
              return (
                <Fragment key={`${id}`}>
                  <Table.TR
                    selectable
                    position="relative"
                    transform="scale(1,1)"
                    index={id}
                  >
                    <Table.TD>
                      <Text>{level}</Text>
                    </Table.TD>
                    <Table.TD>
                      <Text>{type}</Text>
                    </Table.TD>
                    <Table.TD>
                      <Text>{required_fields}</Text>
                    </Table.TD>
                    <Table.TD>
                      <Text>
                        {conditions?.map(({ field, statement, between }) => {
                          return (
                            <Text>
                              {field} {statement} {between?.min} and{' '}
                              {between?.max === 0 ? '∞' : between?.max}
                            </Text>
                          );
                        })}
                      </Text>
                    </Table.TD>
                    <Table.TD>
                      <Text>
                        {services?.map(({ name_key, category_name_key }) => {
                          return (
                            <Text>
                              {name_key} ({category_name_key})
                            </Text>
                          );
                        })}
                      </Text>
                    </Table.TD>
                    <Table.TD>
                      <Text>
                        {actions?.map(({ action, action_role }) => {
                          return (
                            <Text>
                              {action_role} as {action}
                            </Text>
                          );
                        })}
                      </Text>
                    </Table.TD>
                  </Table.TR>
                </Fragment>
              );
            },
          )}
      </Table.TBody>
    </Table>
  );
};
