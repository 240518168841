import {
  getOrganisationGetQueryKey,
  useChildOrganisationsGet,
} from '@libs/api/endpoints';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { getPath, Routes } from '@libs/dashboard-core/routes';
import { selectCell } from '@libs/dashboard-core/utils/select-cell';
import { PageHeader, Pager, Table, usePager } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useTable } from '@libs/ui/components/table/table.utils';
import { useIcon } from '@libs/core/theme/utils';
import { default as React } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

export const OrganisationChildsTable = () => {
  // Attributes
  const queryClient = useQueryClient();
  const ArrowRight = useIcon('ArrowRight');
  const { t } = useTranslation();
  const { organisationId } = useParams<{ organisationId: string }>();
  const navigate = useNavigate();
  const { pageNumber, pageSize, setPageNumber, setPageSize } = usePager();
  const { useQueryParams } = useTable({
    setPageNumber,
    setPageSize,
  });

  const {
    // is false when fetching cached values
    isLoading,
    data: organisations,
    refetch,
    // defaults to true so long as enabled is set to false
    // is true if the query is currently fetching, including background fetching
    isFetching,
  } = useChildOrganisationsGet(
    organisationId,
    {
      page_number: pageNumber,
      page_size: pageSize,
    },
    { query: { keepPreviousData: true } },
  );

  // Functions
  useQueryParams({
    totalPages: organisations?.meta.total_pages,
    pageSize,
    pageNumber,
    setPageSize,
    refetch,
  });

  const headers: TableHeaders = [
    t(i18nKeys.common.full_name),
    t(i18nKeys.common.go.to),
  ];

  // Render
  return (
    <Box>
      <PageHeader
        size="Title2"
        title={t(i18nKeys.operator.organisation.child.table)}
        mt={'3rem'}
      />
      <Table headers={headers}>
        <Table.TBody
          isLoading={(isLoading || isFetching) && !organisations}
          isEmpty={
            !isLoading &&
            !isFetching &&
            (organisations?.data.length === 0 || !organisations)
          }
          colSpan={headers.length}
        >
          {organisations?.data.map((organisation) => {
            return (
              <Table.TR
                key={organisation.id}
                selectable
                onClick={() =>
                  selectCell(
                    getOrganisationGetQueryKey(organisation.id),
                    queryClient,
                    organisation,
                    navigate,
                    getPath(Routes.DASHBOARD_ORGANISATIONS_DETAILS, {
                      params: {
                        organisationId: organisation.id,
                      },
                    }),
                  )
                }
                index={organisation.id}
              >
                <Table.TD>{organisation?.legal_name}</Table.TD>
                <Table.Action
                  onClick={() =>
                    selectCell(
                      getOrganisationGetQueryKey(organisation.id),
                      queryClient,
                      organisation,
                      navigate,
                      getPath(Routes.DASHBOARD_ORGANISATIONS_DETAILS, {
                        params: {
                          organisationId: organisation.id,
                        },
                      }),
                    )
                  }
                  icon={<ArrowRight width="2rem" height="2rem" />}
                  alignContent="center"
                />
              </Table.TR>
            );
          })}
        </Table.TBody>
      </Table>
      {organisations && organisations?.meta.total_pages > 1 && (
        <Pager
          setPageNumber={setPageNumber}
          total_items={organisations.meta.total_items}
          page_index={organisations.meta.page_index}
          total_pages={organisations.meta.total_pages}
          page_size={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          mt="2rem"
          isFetching={isFetching}
        />
      )}
    </Box>
  );
};
