import { Button } from '@chakra-ui/button';
import { Box, Text } from '@chakra-ui/layout';

import {
  membershipSuspendPost,
  useRegistrationDelete,
} from '@libs/api-v2/endpoints';
import { Organisation, OrganisationScope } from '@libs/api/models';
import { QueryStatus } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Drawer, Loader } from '@libs/ui/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryStatusText } from '../../../components/query-status-text';
import { Registration } from '@libs/api-v2/models';
import { useOrganisationGet } from '@libs/api/endpoints';

type ArchiveEmployeeDrawerProps = {
  employee: Registration;
  isOpen: boolean;
  onClose: () => void;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  refetch: any;
};
export const ArchiveEmployeeDrawer = ({
  employee,
  isOpen,
  onClose,
  refetch,
}: ArchiveEmployeeDrawerProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const membershipid = employee.membership_id;

  const { data: organisation, isLoading } = useOrganisationGet(
    employee.organisation_id,
  );

  const { mutateAsync: archiveEmployee, status } = useRegistrationDelete({
    mutation: {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        setError(error.translation?.key || error.detail);
      },
      onSuccess: () => {
        refetch();
        setError(null);
      },
    },
  });

  const handleArchive = () => {
    archiveEmployee({ membershipId: employee.membership_id });
  };

  const handleSuspendEmployee = () => {
    membershipSuspendPost(membershipid, {
      suspension_date: new Date().toISOString().split('T')[0],
    });
  };

  const statusText = {
    [QueryStatus.loading]: t(i18nKeys.common.loading),
    [QueryStatus.error]: t(i18nKeys.common.errors.http_error),
    [QueryStatus.success]: t(i18nKeys.common.success),
  };

  const isNextGen = (organisation: Organisation) =>
    organisation.organisation_scope === OrganisationScope.NEXTGEN;
  return (
    <Drawer
      title={t(i18nKeys.operator.employees.archive.title)}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Drawer.Body>
        {isLoading ? (
          <Loader />
        ) : (
          <Box>
            <Text size="Body2" color="ink.dark" mt="2rem" mb="4rem">
              {t(i18nKeys.operator.employees.archive.explanation)}
            </Text>
            {(isNextGen(organisation) && (
              <Box>
                <Button
                  variant="primary"
                  size="body1"
                  onClick={handleSuspendEmployee}
                  isLoading={status === QueryStatus.loading}
                  loadingText={t(
                    i18nKeys.operator.employees.archive.status.idle,
                  )}
                  disabled={employee.is_suspended}
                >
                  {t(i18nKeys.operator.employees.archive.title) + '(NextGen)'}
                </Button>
                <QueryStatusText
                  error={error}
                  status={status}
                  statusText={statusText}
                />
              </Box>
            )) || (
              <Box>
                <Button
                  variant="primary"
                  size="body1"
                  onClick={handleArchive}
                  isLoading={status === QueryStatus.loading}
                  loadingText={t(
                    i18nKeys.operator.employees.archive.status.idle,
                  )}
                  disabled={employee.is_suspended}
                >
                  {t(i18nKeys.operator.employees.archive.title) + '(OldGen)'}
                </Button>
                <QueryStatusText
                  error={error}
                  status={status}
                  statusText={statusText}
                />
                {employee.is_suspended && (
                  <Text>{t(i18nKeys.operator.employee.already.suspended)}</Text>
                )}
              </Box>
            )}
          </Box>
        )}
      </Drawer.Body>
    </Drawer>
  );
};
