import { Flex, Heading } from '@chakra-ui/layout';
import { Button, useDisclosure } from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardLayout } from '../../components/dashboard-layout';
import { MerchantRestrictionCreateDrawer } from '../merchant/components/merchants-edit-drawer';
import { RestrictionGroupTable } from './components/restriction-group-table';
import { RestrictionGroupType } from './restriction-group.type';

export const RestrictionGroupPage = () => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <DashboardLayout>
      <Flex display={{ base: 'block', lg: 'flex' }} justify="space-between">
        <Heading>{t(i18nKeys.operator.restriction_groups.mid_title)}</Heading>
        <Flex display={{ base: 'block', lg: 'flex' }} justify="space-between">
          <Button
            mt={{ base: '2rem', lg: '0' }}
            type="button"
            variant="primary"
            size="body1"
            onClick={() => onOpen()}
          >
            {t(i18nKeys.common.restriction_groups_create.title)}
            <MerchantRestrictionCreateDrawer
              isOpen={isOpen}
              onClose={onClose}
            />
          </Button>
        </Flex>
      </Flex>
      <RestrictionGroupTable type={RestrictionGroupType.MID} />
      <Flex
        display={{ base: 'block', lg: 'flex' }}
        justify="space-between"
        mt="4rem"
      >
        <Heading>{t(i18nKeys.operator.restriction_groups.mcc_title)}</Heading>
      </Flex>
      <RestrictionGroupTable type={RestrictionGroupType.MCC} />
    </DashboardLayout>
  );
};
