/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

/**
 * enum for program professional label, these values need to be included in the expense Program label filter
 */
export type ProgramLabel = typeof ProgramLabel[keyof typeof ProgramLabel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProgramLabel = {
  PROFESSIONAL: 'PROFESSIONAL',
  COMMUTE: 'COMMUTE',
} as const;
