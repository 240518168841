/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { Role } from './role';
import type { MembershipV1 } from './membershipV1';

export interface LoggedUserV1 {
  /** user ID */
  id: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  language?: string;
  roles: Role[];
  memberships?: MembershipV1[];
}
