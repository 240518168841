import { Card, CardExpirationStatus, CardStatus } from '@libs/api-v2/models';

export function getDateRangeFromExpiryDate(card: Card) {
  const todayDate = new Date().getTime();
  const expiryDate = new Date(card.expiry_date).getTime();
  return Math.ceil((expiryDate - todayDate) / (24 * 60 * 60 * 1000));
}

export function getCardExpirationStatus(card: Card): CardExpirationStatus {
  const dateRange = getDateRangeFromExpiryDate(card);
  if (card.card_status !== CardStatus.UNLOCK || dateRange <= 0) {
    return CardExpirationStatus.EXPIRED;
  } else if (dateRange <= 60) {
    return CardExpirationStatus.EXPIRING;
  } else {
    return CardExpirationStatus.ACTIVE;
  }
}
