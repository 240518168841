/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { NotionBlockType } from './notionBlockType';
import type { NotionSegment } from './notionSegment';
import type { NotionVideo } from './notionVideo';
import type { NotionImage } from './notionImage';
import type { NotionChildren } from './notionChildren';

export interface NotionBlock {
  id?: string;
  has_children?: boolean;
  type?: NotionBlockType;
  paragraph?: NotionSegment;
  heading_1?: NotionSegment;
  heading_2?: NotionSegment;
  heading_3?: NotionSegment;
  bulleted_list_item?: NotionSegment;
  video?: NotionVideo;
  image?: NotionImage;
  children_blocks?: NotionChildren;
}
