/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { Audit } from './audit';
import type { RegistrationAllOf } from './registrationAllOf';

export type Registration = Audit & RegistrationAllOf;
