/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { SocialSecretarySocialSecretaryName } from './socialSecretarySocialSecretaryName';
import type { SocialSecretarySocialSecretaryEmployeeType } from './socialSecretarySocialSecretaryEmployeeType';

export interface SocialSecretary {
  social_secretary_name?: SocialSecretarySocialSecretaryName;
  social_secretary_employee_type?: SocialSecretarySocialSecretaryEmployeeType;
  social_secretary_employee_reference?: string;
}
