/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type RecurringExpenseInterval =
  typeof RecurringExpenseInterval[keyof typeof RecurringExpenseInterval];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecurringExpenseInterval = {
  NEVER: 'NEVER',
  MONTHLY: 'MONTHLY',
} as const;
