/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  ExpensesGetResponseV1,
  ErrorResponse,
  ExpensesGetParams,
  ExpenseV1,
} from '../../models';
import { customInstance } from '../../config';

export const expensesGet = (
  params?: ExpensesGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<ExpensesGetResponseV1>({
    url: `/web/expenses`,
    method: 'get',
    params,
    signal,
  });
};

export const getExpensesGetQueryKey = (params?: ExpensesGetParams) => [
  `/web/expenses`,
  ...(params ? [params] : []),
];

export type ExpensesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof expensesGet>>
>;
export type ExpensesGetQueryError = ErrorResponse;

export const useExpensesGet = <
  TData = Awaited<ReturnType<typeof expensesGet>>,
  TError = ErrorResponse,
>(
  params?: ExpensesGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof expensesGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getExpensesGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof expensesGet>>> = ({
    signal,
  }) => expensesGet(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof expensesGet>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const expensesCreate = (expenseV1: ExpenseV1) => {
  return customInstance<void>({
    url: `/web/expenses`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: expenseV1,
  });
};

export type ExpensesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof expensesCreate>>
>;
export type ExpensesCreateMutationBody = ExpenseV1;
export type ExpensesCreateMutationError = unknown;

export const useExpensesCreate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesCreate>>,
    TError,
    { data: ExpenseV1 },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof expensesCreate>>,
    { data: ExpenseV1 }
  > = (props) => {
    const { data } = props ?? {};

    return expensesCreate(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof expensesCreate>>,
    TError,
    { data: ExpenseV1 },
    TContext
  >(mutationFn, mutationOptions);
};
