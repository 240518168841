/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Audit } from './audit';
import type { OrganisationV2AllOf } from './organisationV2AllOf';

export type OrganisationV2 = Audit & OrganisationV2AllOf;
