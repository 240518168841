/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type ExpenseV1AllOfType =
  typeof ExpenseV1AllOfType[keyof typeof ExpenseV1AllOfType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseV1AllOfType = {
  PRO: 'PRO',
  PRIVATE: 'PRIVATE',
  MIXED: 'MIXED',
  UNCATEGORIZED: 'UNCATEGORIZED',
} as const;
