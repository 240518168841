import { useDisclosure } from '@chakra-ui/hooks';
import { PAGE_SIZE, SORT_VALUES } from '@libs/core/constants';
import { getQueryParam } from '@libs/core/utils';
import { useEffect, useState } from 'react';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

/**
 * @description sort table by column
 * @param options
 * @param options.sortName column name to use in query
 * @param options.value value of sort, one of [asc, desc, default]
 * @param options.setQuery React setState function
 */
export const sortColumn = ({
  sortName,
  value,
  setQuery,
}: {
  sortName: string;
  value?: string;
  setQuery: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  if (!value || value === SORT_VALUES.DEFAULT) {
    setQuery((query) => query.filter((param) => !param?.startsWith(sortName)));
  } else {
    // old regex
    const regexp = new RegExp(`${sortName}:[^,&]+`);
    // new regex
    // const regexp = new RegExp(`${sortName}:^([a-zA-Z]*)+([a-zA-Z ]*)$`);
    const sortParam = `${sortName}:${value}`;

    setQuery((query) =>
      // check if query already exists before adding it
      query.toString().match(regexp)
        ? [...query.filter((param) => !param.match(regexp)), sortParam]
        : [...query, sortParam],
    );
  }
};

/**
 * @description Extract current value from the query parameter of a sorted column
 * @param query array of query parameters to fetch the sorted data
 * @param sortName column name to use in query
 */
export const getSortValue = (query: string[], sortName: string) =>
  query.find((param) => param?.startsWith(sortName))?.split(':')[1] ||
  SORT_VALUES.DEFAULT;

export const useTable = ({
  filterQueryParams,
  setPageNumber,
  setPageSize,
}: {
  filterQueryParams?: { name: string; value?: string }[];
  setPageNumber?: React.Dispatch<React.SetStateAction<number>>;
  setPageSize?: React.Dispatch<React.SetStateAction<number>>;
} = {}) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sortQuery, setSortQuery] = useState<string[]>([]);
  const [filterQuery, setFilterQuery] = useState<string[]>([]);

  // update states used by components with query parameter values on first page load
  useEffect(() => {
    let filters = null;
    if (filterQueryParams?.length > 0) {
      filters = filterQueryParams
        .map(({ name, value }) => {
          return value && name.length > 0 ? `${name}:${value}` : null;
        })
        .filter((e) => e);
    }
    const sortQueryParam = getQueryParam('sort');
    const searchQueryParam = getQueryParam('q');
    const pageQueryParam = Number(getQueryParam('page'));
    const pageSizeQueryParam = Number(getQueryParam('pageSize'));

    if (setPageSize) {
      setPageSize(pageSizeQueryParam > 0 ? pageSizeQueryParam : PAGE_SIZE);
    }
    if (setPageNumber) {
      setPageNumber(pageQueryParam > 0 ? pageQueryParam : 1);
    }
    setSearchQuery(searchQueryParam);
    if (sortQueryParam?.length > 0) {
      setSortQuery(sortQueryParam?.split(','));
    }
    if (filters?.length > 0) {
      setFilterQuery(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setFilterQuery,
    setPageNumber,
    setSearchQuery,
    setSortQuery,
    filterQueryParams?.length,
  ]);

  const useQueryParams = ({
    refetch,
    setPageSize,
    pageSize,
    pageNumber,
    totalPages,
  }: {
    refetch: (
      options?: RefetchOptions,
    ) => Promise<QueryObserverResult<unknown, unknown>>;
    setPageSize?: React.Dispatch<React.SetStateAction<number>>;
    pageSize?: number;
    pageNumber?: number;
    totalPages?: number;
  }) => {
    const navigate = useNavigate();

    // reset the page number if it is above total_pages
    // this occurs whenever a filter/search query is applied and
    // the number of pages from the resulting query is below the previous result
    useEffect(() => {
      if (totalPages < pageNumber) {
        setPageNumber(1);
      }
    }, [pageNumber, totalPages]);

    // refetch query and update url query params if
    // the searchQuery, sortQuery, filterQuery, pageNumber, pageSize parameters are updated
    useEffect(() => {
      let newSearchStringList = [];
      if (searchQuery.length > 0) {
        newSearchStringList = [...newSearchStringList, `q=${searchQuery}`];
      }
      if (sortQuery.length > 0) {
        newSearchStringList = [...newSearchStringList, `sort=${sortQuery}`];
      }
      if (filterQuery.length > 0) {
        newSearchStringList = [
          ...newSearchStringList,
          `${filterQuery.join('&')?.split(':').join('=')}`,
        ];
      }
      if (pageNumber) {
        newSearchStringList = [...newSearchStringList, `page=${pageNumber}`];
      }
      if (pageSize) {
        newSearchStringList = [...newSearchStringList, `pageSize=${pageSize}`];
        setPageSize(pageSize);
      }

      const filterNames = filterQuery.map((query) => query.split(':')[1]);
      const regex = new RegExp(
        `page|pageSize|q${
          filterNames.length > 0 ? filterNames.map((name) => `|${name}`) : ''
        }`,
      );
      // find query params that are not q, sort, page, pageSize, or in filterQuery
      const currentSearch = window.location.search
        .slice(1)
        .split('&')
        .filter((e) => e)
        .filter((query) => !query.match(regex));

      const newSearchString = `?${newSearchStringList.join('&')}${
        currentSearch.length > 0 ? `&${currentSearch.join('&')}` : ''
      }`;
      navigate(newSearchString, { replace: true });
      refetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      navigate,
      pageNumber,
      pageSize,
      refetch,
      setPageSize,
      filterQuery,
      searchQuery,
      sortQuery,
    ]);
  };

  const getFilterValue = (name: string) =>
    filterQuery.length > 0
      ? filterQuery.find((query) => query.includes(name))?.split(':')[1]
      : null;

  return {
    useQueryParams,
    searchQuery,
    setSearchQuery,
    sortQuery,
    setSortQuery,
    filterQuery,
    setFilterQuery,
    getFilterValue,
  };
};

export const useDrawer = (args?: { active?: boolean }) => {
  const { active = true } = args || {};
  const [activeDrawerIndex, setActiveDrawerIndex] = useState<number>(null);
  const [selectedDrawerType, setSelectedDrawerType] = useState(null);
  const { onOpen, onClose, isOpen } = useDisclosure();

  const drawerHandler = (
    args:
      | number
      | {
          index?: number;
          drawerType?: string;
          beforeOpen?: () => void;
          afterOpen?: () => void;
        } = {},
  ) => {
    if (active) {
      if (typeof args === 'number') {
        setActiveDrawerIndex(args);
        onOpen();
      } else {
        args?.beforeOpen && args.beforeOpen();
        setSelectedDrawerType(args.drawerType);
        setActiveDrawerIndex(args.index);
        onOpen();
        args?.afterOpen && args.afterOpen();
      }
    }
  };

  const onDrawerClose = () => {
    onClose();
    if (selectedDrawerType) {
      setSelectedDrawerType(null);
    }
    setActiveDrawerIndex(null);
  };

  return {
    activeDrawerIndex,
    setActiveDrawerIndex,
    onOpen,
    onClose: onDrawerClose,
    isOpen,
    drawerHandler,
    selectedDrawerType,
    setSelectedDrawerType,
  };
};
