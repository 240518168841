/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export interface PartialSucessMeta {
  /** Aomunt of successes */
  successes: number;
  /** Amount of failures */
  failures: number;
  /** Total amount */
  total: number;
}
