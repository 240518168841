/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type MetaV1Status = typeof MetaV1Status[keyof typeof MetaV1Status];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MetaV1Status = {
  succeeded: 'succeeded',
  failed: 'failed',
} as const;
