/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export interface AutomaticExpenseApprovalLimits {
  app_pro_expense?: number;
  card_pro_expense?: number;
}
