/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { Service } from './service';

export type PolicyAllOf = {
  readonly id?: Uuid;
  services?: Service[];
};
