/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  CardV1,
  CardsGetResponseV1,
  ErrorResponse,
  CardsGetParams,
  CardCreateRequest,
} from '../../models';
import { customInstance } from '../../config';

export const cardsUpdate = (cardId: string, cardV1: CardV1) => {
  return customInstance<CardV1>({
    url: `/web/cards/${cardId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: cardV1,
  });
};

export type CardsUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof cardsUpdate>>
>;
export type CardsUpdateMutationBody = CardV1;
export type CardsUpdateMutationError = unknown;

export const useCardsUpdate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cardsUpdate>>,
    TError,
    { cardId: string; data: CardV1 },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cardsUpdate>>,
    { cardId: string; data: CardV1 }
  > = (props) => {
    const { cardId, data } = props ?? {};

    return cardsUpdate(cardId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof cardsUpdate>>,
    TError,
    { cardId: string; data: CardV1 },
    TContext
  >(mutationFn, mutationOptions);
};
export const cardsGet = (params?: CardsGetParams, signal?: AbortSignal) => {
  return customInstance<CardsGetResponseV1>({
    url: `/web/cards`,
    method: 'get',
    params,
    signal,
  });
};

export const getCardsGetQueryKey = (params?: CardsGetParams) => [
  `/web/cards`,
  ...(params ? [params] : []),
];

export type CardsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof cardsGet>>
>;
export type CardsGetQueryError = ErrorResponse;

export const useCardsGet = <
  TData = Awaited<ReturnType<typeof cardsGet>>,
  TError = ErrorResponse,
>(
  params?: CardsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof cardsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCardsGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof cardsGet>>> = ({
    signal,
  }) => cardsGet(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof cardsGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const cardsCreate = (cardCreateRequest: CardCreateRequest) => {
  return customInstance<CardV1>({
    url: `/web/cards`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: cardCreateRequest,
  });
};

export type CardsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof cardsCreate>>
>;
export type CardsCreateMutationBody = CardCreateRequest;
export type CardsCreateMutationError = unknown;

export const useCardsCreate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cardsCreate>>,
    TError,
    { data: CardCreateRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cardsCreate>>,
    { data: CardCreateRequest }
  > = (props) => {
    const { data } = props ?? {};

    return cardsCreate(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof cardsCreate>>,
    TError,
    { data: CardCreateRequest },
    TContext
  >(mutationFn, mutationOptions);
};
