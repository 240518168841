/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryProgramIdParameter } from './component-parameters/queryProgramIdParameter';

export type MembershipSummaryRecalculateParams = {
  /**
   * ID of the program
   */
  program_id?: QueryProgramIdParameter;
};
