/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryOrganisationIdParameter } from './component-parameters/queryOrganisationIdParameter';
import type { QueryOrganisationYearParameter } from './component-parameters/queryOrganisationYearParameter';

export type GetExpenseReportsUrlParams = {
  /**
   * ID of the organisation
   */
  organisation_id?: QueryOrganisationIdParameter;
  /**
   * year of the organisation
   */
  organisation_year?: QueryOrganisationYearParameter;
};
