/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { MerchantAllOf } from './merchantAllOf';
import type { Audit } from './audit';

export type Merchant = MerchantAllOf & Audit;
