/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { ProviderService } from './providerService';
import type { KeyValue } from './keyValue';

export interface ProviderV1 {
  name?: string;
  services?: ProviderService[];
  legals_accepted?: KeyValue[];
  user_reference?: string;
}
