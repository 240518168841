/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { ExpenseStatus } from './expenseStatus';

export interface ExpenseReviewItem {
  expense_id: string;
  /** Comment provided by the reviewer to explain why ExpenseReviewStatus has its value. */
  review_comment: string;
  review_status: ExpenseStatus;
}
