/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';

/**
 * List of ruleset that are related to a program
 */
export interface ImcclRestriction {
  readonly id?: Uuid;
  readonly created_at?: string;
  readonly updated_at?: string;
  name?: string;
  version?: number;
  created_by?: string;
  readonly attached_at?: string;
  is_active?: boolean;
  restriction_json?: string;
}
