/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type OnboardingStatus =
  typeof OnboardingStatus[keyof typeof OnboardingStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OnboardingStatus = {
  STARTED: 'STARTED',
  ORG_DATA_SENT: 'ORG_DATA_SENT',
  UBO_SELECTED: 'UBO_SELECTED',
  UBO_DOC_UPLOADED: 'UBO_DOC_UPLOADED',
  BANK_REVIEW: 'BANK_REVIEW',
  INTERNAL_REVIEW: 'INTERNAL_REVIEW',
  ONBOARDING_FINISHED: 'ONBOARDING_FINISHED',
} as const;
