/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type PolicyActionAction =
  typeof PolicyActionAction[keyof typeof PolicyActionAction];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PolicyActionAction = {
  ASSIGN_REVIEWER: 'ASSIGN_REVIEWER',
  BLOCK: 'BLOCK',
  NOTIFY: 'NOTIFY',
} as const;
