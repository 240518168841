/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type KmAllowanceType =
  typeof KmAllowanceType[keyof typeof KmAllowanceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KmAllowanceType = {
  LEGAL: 'LEGAL',
  CUSTOM: 'CUSTOM',
} as const;
