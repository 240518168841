/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type TransactionStatus =
  typeof TransactionStatus[keyof typeof TransactionStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionStatus = {
  AUTHORIZED: 'AUTHORIZED',
  REVERSED: 'REVERSED',
  FAILED: 'FAILED',
  PRE_AUTHORIZED: 'PRE_AUTHORIZED',
} as const;
