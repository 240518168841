/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { ProductAllOfAttributes } from './productAllOfAttributes';
import type { ActivationStatus } from './activationStatus';
import type { ProductCategory } from './productCategory';
import type { Provider } from './provider';
import type { PaymentMethods } from './paymentMethods';

export type ProductAllOf = {
  readonly id?: string;
  readonly name?: string;
  /** Specific attributes of a product */
  readonly attributes?: ProductAllOfAttributes;
  /** This determines if a user can manually input the km distance of his journey Only for km allowance services */
  is_manual_km_amount_enabled?: boolean | null;
  /** When a manual km amount is set, this will determine if the expense should be auto approved regardless of other configs Only for km allowance services */
  force_manual_approve_for_manual_km_amount?: boolean | null;
  readonly status?: ActivationStatus;
  readonly category?: ProductCategory;
  provider?: Provider;
  payment_methods?: PaymentMethods;
};
