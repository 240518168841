/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';

export interface UpdateMerchantRequest {
  merchant_name: string;
  service_id: Uuid;
  provider_id: Uuid;
}
