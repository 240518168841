/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { SplittableTransaction } from './splittableTransaction';

/**
 * potentially splittable transactions to action upon
 */
export type SplittableTransactionsResponse = SplittableTransaction[];
