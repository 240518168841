/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type SubscriptionStatus =
  typeof SubscriptionStatus[keyof typeof SubscriptionStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionStatus = {
  NOT_STARTED: 'NOT_STARTED',
  VALIDATED: 'VALIDATED',
  PENDING: 'PENDING',
  REFUSED: 'REFUSED',
} as const;
