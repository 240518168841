/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryAuthorizationStatusParameter } from './queryAuthorizationStatusParameter';

export type GetPaymentAuthorizationsParams = {
  /**
   * Status filter for authorizations
   */
  status_filter?: QueryAuthorizationStatusParameter;
};
