/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type QueryCardStatusParameter =
  typeof QueryCardStatusParameter[keyof typeof QueryCardStatusParameter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QueryCardStatusParameter = {
  UNLOCK: 'UNLOCK',
  LOCK: 'LOCK',
  LOST: 'LOST',
  STOLEN: 'STOLEN',
} as const;
