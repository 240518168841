import { Button } from '@chakra-ui/button';
import { Box, Text } from '@chakra-ui/layout';
import {
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
} from '@chakra-ui/popover';
import { useTheme } from '@chakra-ui/system';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useCurrentBreakpointValue } from '@libs/core/theme';
import { useIcon } from '@libs/core/theme/utils';
import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchField } from '../../form/search-field';
import { PopoverTrigger } from '@libs/ui/components/pop-over-trigger';

type TableSearchProps = {
  placeholder?: string;
};

const Content = ({ placeholder }: TableSearchProps) => {
  const { dirty, setFieldValue } = useFormikContext();
  const { t } = useTranslation();

  return (
    <SearchField
      p="0"
      m="0"
      w="29.75rem"
      name="q"
      placeholder={placeholder || t(i18nKeys.common.table.search)}
      setFieldValue={setFieldValue}
      dirty={dirty}
    />
  );
};

export const TableSearch = ({ placeholder }: TableSearchProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const Search = useIcon('Search');
  const { isMobile } = useCurrentBreakpointValue();

  return isMobile ? (
    <Popover variant="responsive" placement="bottom">
      <PopoverTrigger>
        <Button
          variant="border"
          size="body2"
          fontFamily={theme.fonts.body1}
          borderWidth=".1rem"
          p="1.5rem"
        >
          <Box w="2.5rem" h="2.5rem">
            <Search />
          </Box>
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverCloseButton />
        <PopoverHeader>
          <Text fontFamily={theme.fonts.body2} fontWeight="bold">
            {t(i18nKeys.common.table.search)}
          </Text>
        </PopoverHeader>
        <PopoverBody>
          <Content placeholder={placeholder} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  ) : (
    <Content placeholder={placeholder} />
  );
};
