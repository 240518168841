/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type AddressV1Type = typeof AddressV1Type[keyof typeof AddressV1Type];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressV1Type = {
  OFFICIAL: 'OFFICIAL',
} as const;
