/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Product } from './product';
import type { ProductWithMetaAllOf } from './productWithMetaAllOf';

export type ProductWithMeta = Product & ProductWithMetaAllOf;
