import { Text } from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { capitalize } from '@libs/core/utils';
import { Table, usePager } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useDrawer, useTable } from '@libs/ui/components/table/table.utils';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useProgramGroupsGet } from '@libs/api-v2/endpoints/programs/programs';
import { useIcon } from '@libs/core/theme/utils';
import { PoliciesDrawer } from '../../employee/details/components/policies-drawer';

type OrganisationDetailsProgramGroupsProps = {
  programId?: string;
  programName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setDefaultProgram?: any;
  only_default?: boolean;
};

export const ProgramGroupsTable = ({
  programId,
  programName,
  setDefaultProgram,
  only_default,
}: OrganisationDetailsProgramGroupsProps) => {
  const { t } = useTranslation();
  const { pageNumber, pageSize, setPageNumber, setPageSize } = usePager();
  const { setSearchQuery, useQueryParams } = useTable({
    setPageNumber,
    setPageSize,
  });
  const EyeIcon = useIcon('Eye');
  const { drawerHandler, isOpen, onClose, activeDrawerIndex } = useDrawer();

  const submitHandler = ({ q }) => {
    setSearchQuery(q);
  };

  const {
    // is false when fetching cached values
    isLoading,
    refetch,
    data: programgroups,
    // defaults to true so long as enabled is set to false
    // is true if the query is currently fetching, including background fetching
    isFetching,
  } = useProgramGroupsGet(
    programId,
    {
      page_number: pageNumber,
      page_size: pageSize,
    },
    { query: { keepPreviousData: true } },
  );

  useQueryParams({
    pageSize,
    pageNumber,
    setPageSize,
    refetch,
    totalPages: programgroups?.meta.total_pages,
  });

  useEffect(() => {
    if (setDefaultProgram) {
      programgroups?.data.forEach(({ is_default, program_id }) => {
        if (is_default) {
          setDefaultProgram((state) => [...state, program_id]);
        }
      });
    }
  }, [programgroups, setDefaultProgram]);

  if (isLoading) {
    return null;
  }

  const headers: TableHeaders = [
    {
      name: 'name',
      title: only_default
        ? t(i18nKeys.common.default_program)
        : t(i18nKeys.common.group),
    },
  ];
  //   renew authorisation:
  //    * how can we renew an captured authorization ?
  //   * what happens if authorization  < captured_amount
  //   * skipr_card_transaction_id
  //   * get expense skipr_card_transaction_id
  //   *
  //   * too many edge case how do we do for a user than capture failed but was archived in the meantime
  //   * what email is sent when capture failed because authorization  < captured_amount

  // * edge case
  const programGroupsResult = only_default
    ? programgroups?.data.filter((e) => e.is_default)
    : programgroups?.data;

  return (
    <>
      <Table onSubmit={submitHandler} headers={headers}>
        <Table.TBody
          isLoading={(isLoading || isFetching) && !programGroupsResult}
          colSpan={headers.length}
          isEmpty={
            !isLoading &&
            !isFetching &&
            (programGroupsResult?.length === 0 || !programGroupsResult)
          }
        >
          {programGroupsResult?.map(
            (
              {
                program_id,
                group_id,
                name,
                is_monthly_limit_enabled,
                is_default,
              },
              index,
            ) => (
              <Table.TR key={group_id} selectable index={group_id}>
                <Table.TD>
                  <Text size="Body2">{capitalize(name)} </Text>
                </Table.TD>
                <Table.Action
                  actions={[
                    {
                      onClick: () =>
                        drawerHandler({
                          index,
                        }),
                      icon: <EyeIcon width="2rem" height="2rem" />,
                    },
                  ]}
                />
              </Table.TR>
            ),
          )}
        </Table.TBody>
      </Table>
      {isOpen && programgroups?.data[activeDrawerIndex] && (
        <PoliciesDrawer
          programId={programgroups?.data[activeDrawerIndex]?.program_id}
          groupId={programgroups?.data[activeDrawerIndex]?.group_id}
          programName={programName}
          groupName={programgroups?.data[activeDrawerIndex]?.name}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
};
