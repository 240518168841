/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryServiceIdRequiredParameter } from './component-parameters/queryServiceIdRequiredParameter';
import type { QueryAmountRequiredParameter } from './component-parameters/queryAmountRequiredParameter';

export type GetApplicablePolicyParams = {
  /**
   * ID of the service
   */
  service_id: QueryServiceIdRequiredParameter;
  /**
   * amount of expense
   */
  amount: QueryAmountRequiredParameter;
};
