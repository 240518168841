/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export interface SepaMandate {
  readonly unique_mandate_reference: string;
  /** Amount debited from the account in cents */
  readonly amount: number;
  service_name_key?: string;
  /** Indicates whether given mandate was blacklisted at payment provider
   */
  readonly is_blacklisted: boolean;
}
