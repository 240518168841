/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

/**
 * Carries customer fading details of existing Virtual IBAN

 */
export interface PaymentMethodVirtualIban {
  iban: string;
  bic: string;
  iban_reference?: string;
  /** Indicates if, and how many beneficiaries use virtual iban instance as payment method
   */
  beneficiary_count?: number;
}
