/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Product } from './product';
import type { Program } from './program';
import type { Service } from './service';

export interface SplittableTransactionProduct {
  product?: Product;
  program?: Program;
  service?: Service;
}
