/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type AuthorizationStatus =
  typeof AuthorizationStatus[keyof typeof AuthorizationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuthorizationStatus = {
  UNKNOWN: 'UNKNOWN',
  AUTHORIZED: 'AUTHORIZED',
  EXPIRED: 'EXPIRED',
  CANCELLED: 'CANCELLED',
  AUTHENTICATION_REQUESTED: 'AUTHENTICATION_REQUESTED',
  CAPTURED: 'CAPTURED',
  FAILED: 'FAILED',
} as const;
