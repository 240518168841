/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';

export interface RulesetRequest {
  readonly id?: Uuid;
  name?: string;
  status?: string;
  description?: string;
  conditions?: string;
}
