/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { OrganisationDocument } from './organisationDocument';
import type { Title } from './title';
import type { KYCStatus } from './kYCStatus';
import type { AddressV1 } from './addressV1';
import type { Taxes } from './taxes';
import type { Status } from './status';

export type ShareholderAllOf = {
  readonly id?: string;
  readonly organisation_id?: string;
  documents?: OrganisationDocument[];
  email?: string;
  last_name?: string;
  first_name?: string;
  phone_number?: string;
  title?: Title;
  date_of_birth?: string;
  place_of_birth?: string;
  occupation?: string;
  nationality?: string;
  kyc_status?: KYCStatus;
  readonly kyc_comment?: string;
  address?: AddressV1;
  taxes?: Taxes;
  bank_user_reference?: string;
  registration_status?: Status;
  business_api_reference?: string;
};
