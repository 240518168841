/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { AmountOfMoney } from './amountOfMoney';

export interface FailedPaymentInfo {
  readonly created_at: string;
  readonly updated_at: string;
  readonly payment_id: string;
  amount: AmountOfMoney;
  readonly service_provider_name: string;
}
