/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { AddressV1Type } from './addressV1Type';

export interface AddressV1 {
  street: string;
  postal_code: string;
  city: string;
  country_code: string;
  type?: AddressV1Type;
}
