import { Button, Text } from '@chakra-ui/react';
import { QueryStatus } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Drawer } from '@libs/ui/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { QueryStatus as QueryStatusType } from '@tanstack/react-query';
import { QueryStatusText } from '../../../components/query-status-text';

export const BankReviewDrawer = ({
  status,
  isDisabled,
  startBankReview,
  error,
  isOpen,
  onClose,
}: {
  isDisabled: boolean;
  status: QueryStatusType;
  startBankReview: () => void;
  error?: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const statusText = {
    [QueryStatus.loading]: t(i18nKeys.onboarding.kyc_loading),
    [QueryStatus.error]: t(i18nKeys.onboarding.kyc_error_info),
    [QueryStatus.success]: t(i18nKeys.onboarding.kyc_success_info),
  };

  return (
    <Drawer
      title={t(i18nKeys.onboarding.start_kyc)}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
    >
      <Drawer.Body>
        <Text size="Body1" color="ink.dark" mb="3rem">
          {t(i18nKeys.onboarding.start_kyc_intro)}
        </Text>
        <Button
          mt="2rem"
          variant="primary"
          size="body2"
          type="button"
          w="18rem"
          onClick={startBankReview}
          isDisabled={status === QueryStatus.loading || isDisabled}
        >
          {t(i18nKeys.onboarding.start_kyc)}
        </Button>
        <QueryStatusText
          status={status}
          statusText={statusText}
          error={error}
        />
      </Drawer.Body>
    </Drawer>
  );
};
