/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type Role = typeof Role[keyof typeof Role];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Role = {
  USER: 'USER',
  OPERATOR_ADMIN: 'OPERATOR_ADMIN',
} as const;
