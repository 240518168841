/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type FrontendConfigurationCustomApp =
  typeof FrontendConfigurationCustomApp[keyof typeof FrontendConfigurationCustomApp];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FrontendConfigurationCustomApp = {
  DEFAULT: 'DEFAULT',
  ALD: 'ALD',
  POA: 'POA',
  SXO: 'SXO',
} as const;
