/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type MerchantIdRestrictionGroupMerchantsListDeleteParams = {
  merchants: string;
};
