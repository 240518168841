/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { ExpenseAttachment } from './expenseAttachment';

export interface ExpensePatchRequest {
  product_id?: Uuid;
  /** Explanation by the employee why they are making this expense (e.g. Client meeting with Skipr). */
  justification?: string;
  /** Documents used as proof to justify an expense */
  attachments?: ExpenseAttachment[];
  expense_code?: string;
}
