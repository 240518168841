/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { ExpenseV1 } from './expenseV1';
import type { MetaV1 } from './metaV1';

export interface ExpensesGetResponseV1 {
  data?: ExpenseV1[];
  meta?: MetaV1;
}
