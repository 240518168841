/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { MembershipRole } from './membershipRole';
import type { MembershipStatus } from './membershipStatus';

export interface Membership {
  readonly id?: Uuid;
  readonly role?: MembershipRole;
  status?: MembershipStatus;
  readonly organisation_id?: Uuid;
  readonly card_id?: Uuid;
  is_suspended?: boolean;
  /** when the membership is last used */
  readonly last_used_at?: string | null;
}
