/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { UpsertPolicyRequestAllOf } from './upsertPolicyRequestAllOf';
import type { PolicyData } from './policyData';

export type UpsertPolicyRequest = UpsertPolicyRequestAllOf & PolicyData;
