/* eslint-disable  @typescript-eslint/no-explicit-any */
import cuid from 'cuid';
import React, { useRef, useState } from 'react';
import {
  OnChangeHandler,
  UseWizard,
  useWizard,
  useWizardProvider,
  WizardContext,
} from './hooks';

export interface WizardProps {
  children?: ((useWizard: UseWizard) => React.ReactNode) | React.ReactNode;
  initialStepIndex?: number;
  initialHash?: string;
  onChange?: OnChangeHandler;
  useQueryParams?: boolean;

  renderFunc?: (state: any) => React.ReactNode | React.ReactNode[];
}

export const Wizard = ({
  children,
  initialStepIndex,
  initialHash,
  onChange,
  useQueryParams = false,
  renderFunc,
}: WizardProps) => {
  const internalState = useWizardProvider({
    initialStepIndex,
    initialHash,
    onChange,
    useQueryParams,
  });

  return (
    <WizardContext.Provider value={internalState}>
      {renderFunc ? (renderFunc(internalState) as any) : children}
    </WizardContext.Provider>
  );
};

export interface WizardStepProps {
  children?: React.ReactNode;
  label?: string;
  time?: string;
  isHidden?: boolean;
  hash?: string;
  canActivate?: (goToStep) => boolean;
  redirect?: (goToStep) => boolean;
  style?: unknown;
}

const WizardStep = ({
  children,
  label,
  isHidden,
  time,
  hash,
  canActivate = (goToStep) => true,
  redirect = (goToStep) => true,
  style,
}: WizardStepProps) => {
  const { register, activeStepIndex, unregister, steps, goToStep } =
    useWizard();
  const ref = useRef<HTMLDivElement>();
  const stepId = useRef<string>();
  const [isActive, setIsActive] = useState(false);

  React.useEffect(() => {
    ref.current.setAttribute('data-id', 'wizard-step');
    const index = [
      ...(document.querySelectorAll('div[data-id="wizard-step"]') as any),
    ].indexOf(ref.current);

    const id = cuid();
    register(index, { id, label, isHidden, time, hash });
    stepId.current = id;

    return () => {
      unregister(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label]);

  React.useEffect(() => {
    setIsActive(
      typeof activeStepIndex === 'number' &&
        steps[activeStepIndex]?.id === stepId?.current &&
        canActivate(goToStep) &&
        redirect(goToStep),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStepIndex, steps.length]);

  return (
    <div style={style} ref={ref}>
      {isActive ? children : null}
    </div>
  );
};

Wizard.Step = WizardStep;
