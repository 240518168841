/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

/**
 * For KmAllowance it will be: amount_per_km, max_km_per_trip and office_address
 */
export type ProgramProductConfigurationConfigurationAttributes = {
  [key: string]: string;
};
