/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export interface CreatePaymentMethodVirtualIbanRequest {
  valid_from?: string;
  valid_to?: string;
  membership_id: string;
}
