/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryOrganisationIdRequiredParameter } from './component-parameters/queryOrganisationIdRequiredParameter';

export type ProgramGroupLimitDisplayPutParams = {
  /**
   * ID of the organisation
   */
  organisation_id: QueryOrganisationIdRequiredParameter;
};
