/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { PaymentMethod } from './paymentMethod';

export interface CreatePaymentMethodResponse {
  payment_method: PaymentMethod;
  /** If set, frontend should redirect to this URL to complete adding the payment method. */
  forward_url?: string;
}
