/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { CardStatus } from './cardStatus';

export type CardAllOf = {
  readonly id?: string;
  readonly membership_id?: string;
  card_status?: CardStatus;
  readonly monthly_limit?: number;
  is_physical?: boolean;
  mcc_restriction_group_id?: string;
  mid_restriction_group_id?: string;
};
