/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { Statistics } from './statistics';
import type { OrganisationStatisticsAllOf } from './organisationStatisticsAllOf';

export type OrganisationStatistics = Statistics & OrganisationStatisticsAllOf;
