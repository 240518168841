import { Box, Text } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/tooltip';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useIcon } from '@libs/core/theme/utils';
import { capitalize } from '@libs/core/utils';
import { Currency, Pager, Table, Tag, usePager } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useTranslation } from 'react-i18next';
import { ValidationStatusToTagStatus } from '../../employee.utils';
import { ExpenseDetailsDrawer } from './expense-details-drawer';
import { useExpensesGet } from '@libs/api/endpoints';
import { ExpensesGetResponse } from '@libs/api-v2/models';
import {
  Expense,
  ExpensesGetResponse as ExpensesGetResponseV1,
} from '@libs/api/models';
import { useTable, useDrawer } from '@libs/ui/components/table/table.utils';
import React, { useEffect } from 'react';

type ExpensesTableOldGenProps = {
  membershipId: string;
};

interface ExpensesListResponse {
  data: ExpensesGetResponseV1 | ExpensesGetResponse;
  isLoading: boolean;
  isFetching: boolean;
  refetch;
}

export const ExpensesTableOldGen = ({
  membershipId,
}: ExpensesTableOldGenProps) => {
  const { t } = useTranslation();
  const Edit = useIcon('Edit');
  const Comment = useIcon('Talk');

  const { pageNumber, pageSize, setPageNumber, setPageSize } = usePager();

  const params = {
    membership_id: membershipId,
    page_number: pageNumber,
    page_size: pageSize,
  };

  const {
    data: expenses,
    isLoading,
    isFetching,
    refetch,
  }: ExpensesListResponse = useExpensesGet(params, {
    query: { keepPreviousData: true },
  });

  const { useQueryParams } = useTable({
    setPageNumber,
    setPageSize,
  });

  useQueryParams({
    totalPages: expenses?.meta.total_pages,
    pageSize,
    pageNumber,
    setPageSize,
    refetch,
  });

  useEffect(() => {
    refetch();
  }, [refetch, pageSize]);

  const { activeDrawerIndex, isOpen, onClose, drawerHandler } = useDrawer();

  const headers: TableHeaders = [
    t(i18nKeys.operator.employees.expenses.operator_category),
    t(i18nKeys.common.amount),
    t(i18nKeys.operator.employees.budgets.type.title),
    t(i18nKeys.common.source),
    t(i18nKeys.operator.employees.expenses.created_at),
    t(i18nKeys.common.status),
    t(i18nKeys.common.comments),
    '',
  ];

  return (
    <>
      <Table headers={headers}>
        <Table.TBody
          isLoading={(isLoading || isFetching) && !expenses}
          colSpan={headers.length}
          isEmpty={
            !isLoading &&
            !isFetching &&
            (expenses?.data.length === 0 || !expenses)
          }
        >
          {expenses?.data.map(
            (
              {
                id,
                category_code,
                provider_name,
                amount,
                budget_type,
                created_at,
                validation_status,
                validation_comment,
                payment_source,
              },
              index,
            ) => (
              <Table.TR
                selectable
                onClick={() => drawerHandler(index)}
                key={id}
                index={id}
              >
                <Table.TD>
                  <Text size="Body2">{capitalize(provider_name)}</Text>
                  <Text size="Small" color="ink.dark">
                    {t(i18nKeys.common.ExpenseCategoryCode[category_code])}
                  </Text>
                </Table.TD>
                <Table.TD>
                  <Currency size="Body2Bold">{amount}</Currency>
                </Table.TD>
                <Table.TD>
                  <Text>
                    {t(i18nKeys.operator.employees.budgets.type[budget_type])}
                  </Text>
                </Table.TD>
                <Table.TD>
                  <Text size="Body2">{capitalize(payment_source)}</Text>
                </Table.TD>
                <Table.Date>{created_at}</Table.Date>
                <Table.TD>
                  <Tag
                    status={ValidationStatusToTagStatus[validation_status]}
                    hasIcon
                    tooltipLabel={t(
                      i18nKeys.common.ValidationStatus[validation_status],
                    )}
                  />
                </Table.TD>
                <Table.TD>
                  {validation_comment?.length > 0 && (
                    <Tooltip
                      hasArrow
                      bg="white"
                      color="colors.ink.black"
                      label={validation_comment}
                    >
                      <Box w="2.5rem" h="2.5rem" fill="colors.ink.dark">
                        <Comment />
                      </Box>
                    </Tooltip>
                  )}
                </Table.TD>
                <Table.Action
                  onClick={() => drawerHandler(index)}
                  icon={<Edit width="2rem" height="2rem" />}
                />
              </Table.TR>
            ),
          )}
        </Table.TBody>
      </Table>
      {isOpen && (
        <ExpenseDetailsDrawer
          // todo: remove this type assertion
          expense={expenses.data[activeDrawerIndex] as Expense}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
      {expenses && expenses?.meta.total_pages > 1 && (
        <Pager
          setPageNumber={setPageNumber}
          total_items={expenses.meta.total_items}
          page_index={expenses.meta.page_index}
          total_pages={expenses.meta.total_pages}
          page_size={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          mt="2rem"
          isFetching={isFetching}
        />
      )}
    </>
  );
};
