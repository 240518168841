/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { MetaStatus } from './metaStatus';
import type { ErrorResponse } from './errorResponse';

export interface Meta {
  status: MetaStatus;
  messages?: ErrorResponse[];
  page_index: number;
  page_size: number;
  total_items: number;
  total_pages: number;
}
