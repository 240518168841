/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { AmountOfMoney } from './amountOfMoney';
import type { ExpenseAttachment } from './expenseAttachment';
import type { ExpenseRefundStatus } from './expenseRefundStatus';
import type { ExpenseStatus } from './expenseStatus';
import type { ExpenseSource } from './expenseSource';
import type { ExpenseInterface } from './expenseInterface';
import type { ExpenseRefundIssuer } from './expenseRefundIssuer';
import type { ManualSplitData } from './manualSplitData';
import type { ExpenseLabel } from './expenseLabel';

export interface Expense {
  id?: Uuid;
  /** Technical creation timestamp of the expense. This could be different from the timestamp at which the actual expense occurred. See field "ExpenseAt" for more info. */
  readonly created_at?: string;
  /** The timestamp when the action was performed which lead to this expense. E.g. when you take a Husk on 27/05, but we receive the final callback only the day after on 28/05, ExpenseAt will contain 27/05. CreatedAt will contain 28/05 in this case. */
  expense_at?: string;
  amount?: AmountOfMoney;
  amount_refund?: AmountOfMoney;
  category_id?: Uuid;
  provider_id?: Uuid;
  product_id?: Uuid;
  program_id?: Uuid;
  service_id?: Uuid;
  /** The distance in meter of the trip which lead to this expense. E.g. if you took a Husk for 10km, the distance will be 10000. */
  distance?: number;
  /** The manual distance in meter of the trip which lead to this expense. E.g. if you took a Husk for 10km, the distance will be 10000. */
  manual_distance?: number | null;
  /** Explanation by the employee why they are making this expense (e.g. Client meeting with Skipr). */
  justification?: string;
  /** Documents used as proof to justify an expense */
  attachments?: ExpenseAttachment[];
  /** Comment provided by the reviewer to explain why ValidationStatus has its value. */
  readonly review_comment?: string;
  readonly refund_status?: ExpenseRefundStatus;
  /** Field that enables an admin to define if an expense is refundable or not. */
  refundable?: boolean;
  expense_code?: string;
  /** User that reviewed the expense */
  readonly reviewed_by?: string;
  reviewed_by_first_name?: string;
  reviewed_by_last_name?: string;
  /** The timestamp when the expense was reviewed */
  reviewed_at?: string;
  readonly review_status?: ExpenseStatus;
  readonly source?: ExpenseSource;
  readonly interface?: ExpenseInterface;
  readonly refund_issuer?: ExpenseRefundIssuer;
  membership_id?: Uuid;
  /** The timestamp when the expense was refunded */
  refunded_at?: string;
  /** The total of km used only for business travel E.g. if you drove 30km in one week (week-end included), you used only 20km for business travel */
  professional_distance?: number;
  /** The oldgen expense reference used to link the expense to the oldgen expense from migrations */
  oldgen_expense_reference?: string;
  rule_id?: Uuid;
  manual_split_data?: ManualSplitData;
  /** The custom provider's name when the user enters an unknown provider name */
  custom_provider_name?: string;
  /** expense label */
  label?: ExpenseLabel;
}
