/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type RegistrationRequestCreateRole =
  typeof RegistrationRequestCreateRole[keyof typeof RegistrationRequestCreateRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RegistrationRequestCreateRole = {
  EMPLOYEE: 'EMPLOYEE',
  FLEET_MGR: 'FLEET_MGR',
} as const;
