/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';

/**
 * Document used as proof to justify an expense
 */
export interface ExpenseAttachment {
  id?: Uuid;
  url: string;
  /** This is the data of the url, to bypass the google authentication. */
  readonly content?: string | null;
}
