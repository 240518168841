import { Box } from '@chakra-ui/layout';
import {
  getImcclRestrictionGetQueryKey,
  getProgramGetQueryKey,
  imcclRestrictionGet,
  programGet,
  useMembershipRegistrationGet,
} from '@libs/api-v2/endpoints';
import { CardTransaction } from '@libs/api/models';
import { FORMAT_DATE_WITH_HOURS } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Direction } from '@libs/core/types';
import { Routes, getPath } from '@libs/dashboard-core/routes';
import {
  Currency,
  DescriptionData,
  Drawer,
  Link,
  Loader,
} from '@libs/ui/components';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { MerchantCategoryCode } from '@libs/dashboard-core/utils/merchant_category_codes/mcc';

type TransactionDetailsDrawer = {
  transaction: CardTransaction;
  merchantCategoryCodes: Map<string, MerchantCategoryCode>;
  isOpen: boolean;
  onClose: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TransactionDetailsDrawer = ({
  transaction,
  merchantCategoryCodes,
  isOpen,
  onClose,
}: TransactionDetailsDrawer) => {
  const { t } = useTranslation();
  const { data: employee, isLoading: isLoadingRegistration } =
    useMembershipRegistrationGet(transaction.membership_id);

  const { data: restriction, isLoading: isLoadingRestriction } = useQuery(
    getImcclRestrictionGetQueryKey(transaction.restriction_id),
    () => imcclRestrictionGet(transaction.restriction_id),
    {
      keepPreviousData: true,
      enabled: !!transaction.restriction_id,
    },
  );
  const { data: program, isLoading: isLoadingProgram } = useQuery(
    getProgramGetQueryKey(transaction.program_id),
    () => programGet(transaction.program_id),
    {
      keepPreviousData: true,
      enabled: !!transaction.program_id,
    },
  );
  const hasMetadata = !!transaction.restriction_id;
  if (
    hasMetadata &&
    (isLoadingRegistration || isLoadingRestriction || isLoadingProgram)
  ) {
    return <Loader />;
  }

  const json = hasMetadata ? JSON.parse(restriction.restriction_json) : null;

  return (
    <Drawer
      title={t(i18nKeys.operator.transactions.details.title)}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Drawer.Body>
        <Box my="2rem">
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.common.transaction)}
          >
            {transaction.transaction_reference}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.common.amount)}
            formatChildren={false}
          >
            <Currency alignItems="center">{transaction.amount}</Currency>
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.common.created_at)}
          >
            {transaction.created_at &&
              dayjs(transaction.created_at).format(FORMAT_DATE_WITH_HOURS)}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.common.merchant_name)}
          >
            {transaction.merchant_name}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.common.merchant_reference)}
          >
            {transaction.merchant_reference}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.common.merchant_code)}
          >
            {transaction.merchant_code} {' - '}{' '}
            {
              merchantCategoryCodes.get(transaction.merchant_code)
                ?.irs_description
            }
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.operator.transactions.details.card_reference)}
          >
            {transaction.card_reference}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.common.status)}
          >
            {t(i18nKeys.operator.transactions.status[transaction.status])}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.operator.transactions.details.authorization_note)}
          >
            {transaction.authorization_note}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={'authorization response code'}
          >
            {transaction.authorization_code}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.common.organisation_name)}
          >
            {employee?.organisation_name}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.common.full_name)}
          >
            {employee ? (
              <Link
                to={getPath(Routes.DASHBOARD_EMPLOYEES_DETAILS, {
                  params: {
                    membershipId: employee.membership_id,
                  },
                })}
              >{`${employee.first_name} ${employee.last_name}`}</Link>
            ) : null}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.common.program)}
          >
            {program?.name}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.operator.cards.imccl_restriction)}
          >
            {restriction?.name}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.operator.rulesets.title)}
          >
            {json?.map((elt, index) => {
              return `${elt.metadata.name}${
                index !== json.length - 1 ? ', ' : ''
              }`;
            })}
          </DescriptionData>
        </Box>
      </Drawer.Body>
    </Drawer>
  );
};
