/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  MerchantRestrictionGroupsGetResponse,
  ErrorResponse,
  MerchantIdRestrictionGroupsGetParams,
  MerchantRestrictionGroup,
  MerchantRestrictionGroupsRequest,
  MerchantIdRestrictionGroupGet200,
  MerchantIdRestrictionGroupMerchantsListUpdateParams,
  MerchantIdRestrictionGroupMerchantsListDeleteParams,
  MerchantRestrictionGroupsGetPresenceResponse,
  MerchantIdRestrictionGroupsGetPresenceParams,
} from '../../models';
import { customInstance } from '../../config';

export const merchantIdRestrictionGroupsGet = (
  params?: MerchantIdRestrictionGroupsGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<MerchantRestrictionGroupsGetResponse>({
    url: `/web/merchantIdRestrictionGroups`,
    method: 'get',
    params,
    signal,
  });
};

export const getMerchantIdRestrictionGroupsGetQueryKey = (
  params?: MerchantIdRestrictionGroupsGetParams,
) => [`/web/merchantIdRestrictionGroups`, ...(params ? [params] : [])];

export type MerchantIdRestrictionGroupsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof merchantIdRestrictionGroupsGet>>
>;
export type MerchantIdRestrictionGroupsGetQueryError = ErrorResponse;

export const useMerchantIdRestrictionGroupsGet = <
  TData = Awaited<ReturnType<typeof merchantIdRestrictionGroupsGet>>,
  TError = ErrorResponse,
>(
  params?: MerchantIdRestrictionGroupsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof merchantIdRestrictionGroupsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getMerchantIdRestrictionGroupsGetQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof merchantIdRestrictionGroupsGet>>
  > = ({ signal }) => merchantIdRestrictionGroupsGet(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof merchantIdRestrictionGroupsGet>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const merchantIdRestrictionGroupsCreate = (
  merchantRestrictionGroupsRequest: MerchantRestrictionGroupsRequest,
) => {
  return customInstance<MerchantRestrictionGroup>({
    url: `/web/merchantIdRestrictionGroups`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: merchantRestrictionGroupsRequest,
  });
};

export type MerchantIdRestrictionGroupsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof merchantIdRestrictionGroupsCreate>>
>;
export type MerchantIdRestrictionGroupsCreateMutationBody =
  MerchantRestrictionGroupsRequest;
export type MerchantIdRestrictionGroupsCreateMutationError = unknown;

export const useMerchantIdRestrictionGroupsCreate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof merchantIdRestrictionGroupsCreate>>,
    TError,
    { data: MerchantRestrictionGroupsRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof merchantIdRestrictionGroupsCreate>>,
    { data: MerchantRestrictionGroupsRequest }
  > = (props) => {
    const { data } = props ?? {};

    return merchantIdRestrictionGroupsCreate(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof merchantIdRestrictionGroupsCreate>>,
    TError,
    { data: MerchantRestrictionGroupsRequest },
    TContext
  >(mutationFn, mutationOptions);
};
export const merchantIdRestrictionGroupGet = (
  midRestrictionId: string,
  signal?: AbortSignal,
) => {
  return customInstance<MerchantIdRestrictionGroupGet200>({
    url: `/web/merchantIdRestrictionGroups/${midRestrictionId}`,
    method: 'get',
    signal,
  });
};

export const getMerchantIdRestrictionGroupGetQueryKey = (
  midRestrictionId: string,
) => [`/web/merchantIdRestrictionGroups/${midRestrictionId}`];

export type MerchantIdRestrictionGroupGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof merchantIdRestrictionGroupGet>>
>;
export type MerchantIdRestrictionGroupGetQueryError = unknown;

export const useMerchantIdRestrictionGroupGet = <
  TData = Awaited<ReturnType<typeof merchantIdRestrictionGroupGet>>,
  TError = unknown,
>(
  midRestrictionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof merchantIdRestrictionGroupGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMerchantIdRestrictionGroupGetQueryKey(midRestrictionId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof merchantIdRestrictionGroupGet>>
  > = ({ signal }) => merchantIdRestrictionGroupGet(midRestrictionId, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof merchantIdRestrictionGroupGet>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!midRestrictionId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const merchantIdRestrictionGroupUpdate = (
  midRestrictionId: string,
  merchantRestrictionGroupsRequest: MerchantRestrictionGroupsRequest,
) => {
  return customInstance<MerchantRestrictionGroup>({
    url: `/web/merchantIdRestrictionGroups/${midRestrictionId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: merchantRestrictionGroupsRequest,
  });
};

export type MerchantIdRestrictionGroupUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof merchantIdRestrictionGroupUpdate>>
>;
export type MerchantIdRestrictionGroupUpdateMutationBody =
  MerchantRestrictionGroupsRequest;
export type MerchantIdRestrictionGroupUpdateMutationError = unknown;

export const useMerchantIdRestrictionGroupUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof merchantIdRestrictionGroupUpdate>>,
    TError,
    { midRestrictionId: string; data: MerchantRestrictionGroupsRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof merchantIdRestrictionGroupUpdate>>,
    { midRestrictionId: string; data: MerchantRestrictionGroupsRequest }
  > = (props) => {
    const { midRestrictionId, data } = props ?? {};

    return merchantIdRestrictionGroupUpdate(midRestrictionId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof merchantIdRestrictionGroupUpdate>>,
    TError,
    { midRestrictionId: string; data: MerchantRestrictionGroupsRequest },
    TContext
  >(mutationFn, mutationOptions);
};
export const merchantIdRestrictionGroupMerchantsListUpdate = (
  midRestrictionId: string,
  params: MerchantIdRestrictionGroupMerchantsListUpdateParams,
) => {
  return customInstance<MerchantRestrictionGroup>({
    url: `/web/merchantIdRestrictionGroups/${midRestrictionId}/merchants`,
    method: 'put',
    params,
  });
};

export type MerchantIdRestrictionGroupMerchantsListUpdateMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof merchantIdRestrictionGroupMerchantsListUpdate>>
  >;

export type MerchantIdRestrictionGroupMerchantsListUpdateMutationError =
  unknown;

export const useMerchantIdRestrictionGroupMerchantsListUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof merchantIdRestrictionGroupMerchantsListUpdate>>,
    TError,
    {
      midRestrictionId: string;
      params: MerchantIdRestrictionGroupMerchantsListUpdateParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof merchantIdRestrictionGroupMerchantsListUpdate>>,
    {
      midRestrictionId: string;
      params: MerchantIdRestrictionGroupMerchantsListUpdateParams;
    }
  > = (props) => {
    const { midRestrictionId, params } = props ?? {};

    return merchantIdRestrictionGroupMerchantsListUpdate(
      midRestrictionId,
      params,
    );
  };

  return useMutation<
    Awaited<ReturnType<typeof merchantIdRestrictionGroupMerchantsListUpdate>>,
    TError,
    {
      midRestrictionId: string;
      params: MerchantIdRestrictionGroupMerchantsListUpdateParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
export const merchantIdRestrictionGroupMerchantsListDelete = (
  midRestrictionId: string,
  params: MerchantIdRestrictionGroupMerchantsListDeleteParams,
) => {
  return customInstance<MerchantRestrictionGroup>({
    url: `/web/merchantIdRestrictionGroups/${midRestrictionId}/merchants`,
    method: 'delete',
    params,
  });
};

export type MerchantIdRestrictionGroupMerchantsListDeleteMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof merchantIdRestrictionGroupMerchantsListDelete>>
  >;

export type MerchantIdRestrictionGroupMerchantsListDeleteMutationError =
  unknown;

export const useMerchantIdRestrictionGroupMerchantsListDelete = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof merchantIdRestrictionGroupMerchantsListDelete>>,
    TError,
    {
      midRestrictionId: string;
      params: MerchantIdRestrictionGroupMerchantsListDeleteParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof merchantIdRestrictionGroupMerchantsListDelete>>,
    {
      midRestrictionId: string;
      params: MerchantIdRestrictionGroupMerchantsListDeleteParams;
    }
  > = (props) => {
    const { midRestrictionId, params } = props ?? {};

    return merchantIdRestrictionGroupMerchantsListDelete(
      midRestrictionId,
      params,
    );
  };

  return useMutation<
    Awaited<ReturnType<typeof merchantIdRestrictionGroupMerchantsListDelete>>,
    TError,
    {
      midRestrictionId: string;
      params: MerchantIdRestrictionGroupMerchantsListDeleteParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
export const merchantIdRestrictionGroupsGetPresence = (
  midRestrictionId: string,
  params?: MerchantIdRestrictionGroupsGetPresenceParams,
  signal?: AbortSignal,
) => {
  return customInstance<MerchantRestrictionGroupsGetPresenceResponse>({
    url: `/web/merchantIdRestrictionGroups/${midRestrictionId}/presence`,
    method: 'get',
    params,
    signal,
  });
};

export const getMerchantIdRestrictionGroupsGetPresenceQueryKey = (
  midRestrictionId: string,
  params?: MerchantIdRestrictionGroupsGetPresenceParams,
) => [
  `/web/merchantIdRestrictionGroups/${midRestrictionId}/presence`,
  ...(params ? [params] : []),
];

export type MerchantIdRestrictionGroupsGetPresenceQueryResult = NonNullable<
  Awaited<ReturnType<typeof merchantIdRestrictionGroupsGetPresence>>
>;
export type MerchantIdRestrictionGroupsGetPresenceQueryError = unknown;

export const useMerchantIdRestrictionGroupsGetPresence = <
  TData = Awaited<ReturnType<typeof merchantIdRestrictionGroupsGetPresence>>,
  TError = unknown,
>(
  midRestrictionId: string,
  params?: MerchantIdRestrictionGroupsGetPresenceParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof merchantIdRestrictionGroupsGetPresence>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMerchantIdRestrictionGroupsGetPresenceQueryKey(midRestrictionId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof merchantIdRestrictionGroupsGetPresence>>
  > = ({ signal }) =>
    merchantIdRestrictionGroupsGetPresence(midRestrictionId, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof merchantIdRestrictionGroupsGetPresence>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!midRestrictionId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
