/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { CardTransactionAllOf } from './cardTransactionAllOf';
import type { Audit } from './audit';

export type CardTransaction = CardTransactionAllOf & Audit;
