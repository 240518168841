/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryOnlyUnsignedParameter } from './component-parameters/queryOnlyUnsignedParameter';
import type { QueryOnlyRequiredParameter } from './component-parameters/queryOnlyRequiredParameter';

export type MembershipSwornStatementsGetParams = {
  /**
   * Flag to query only unsgined sworn statements
   */
  only_unsigned?: QueryOnlyUnsignedParameter;
  /**
   * Flag to query only sworn statements that are required to be signed
   */
  only_required?: QueryOnlyRequiredParameter;
};
