/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { ServiceNameKey } from './serviceNameKey';
import type { ServiceCategoryNameKey } from './serviceCategoryNameKey';
import type { PaymentMethods } from './paymentMethods';
import type { Provider } from './provider';
import type { Url } from './component-common/url';

export interface Service {
  id?: Uuid;
  /** translation key for the service name */
  name_key?: ServiceNameKey;
  category_name_key?: ServiceCategoryNameKey;
  icon_url?: string;
  payment_methods?: PaymentMethods;
  /** populated when query_featured_provider */
  featured_providers?: Provider[];
  header_image_url?: Url;
  is_recommended?: boolean;
}
