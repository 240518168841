/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { InvitationStatus } from './invitationStatus';

export interface Invitation {
  id?: string;
  readonly status?: InvitationStatus;
  last_communication?: string;
  membership_id?: string;
}
