/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type ExportFormat = typeof ExportFormat[keyof typeof ExportFormat];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExportFormat = {
  CSV: 'CSV',
  EXCEL: 'EXCEL',
} as const;
