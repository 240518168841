/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryIncludeInactiveParameter } from './queryIncludeInactiveParameter';

export type GroupProgramsGetParams = {
  /**
   * Indicates whether inactive programs will be returned in API response
   */
  include_inactive?: QueryIncludeInactiveParameter;
};
