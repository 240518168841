/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { ImcclRestrictionCardStatus } from './imcclRestrictionCardStatus';

export interface ImcclRestrictionCard {
  readonly imccl_restriction_id?: Uuid;
  readonly created_at?: string;
  readonly updated_at?: string;
  readonly card_reference?: string;
  readonly attached_at?: string;
  status?: ImcclRestrictionCardStatus;
}
