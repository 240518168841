import React, { useMemo } from 'react';
import { Card, CardExpirationStatus } from '@libs/api-v2/models';
import { Tag } from '@libs/ui/components';
import { HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { CardExpirationStatusToTagStatus } from '../../employee.utils';
import {
  getCardExpirationStatus,
  getDateRangeFromExpiryDate,
} from './utils/card-status';

type CardStatusProps = {
  card: Card;
};

export const CardExpiration = ({ card }: CardStatusProps) => {
  const { t } = useTranslation();

  const dateRange = useMemo(() => {
    return getDateRangeFromExpiryDate(card);
  }, [card]);

  const expirationStatus = useMemo(() => {
    return getCardExpirationStatus(card);
  }, [card]);

  const labelTranslation = useMemo(() => {
    const keys = i18nKeys.operator.cards.ExpirationStatus;
    const suffix = dateRange === 1 ? '_one' : '_other';
    const isExpiring = expirationStatus === CardExpirationStatus.EXPIRING;
    return keys[expirationStatus + (isExpiring ? suffix : '')];
  }, [dateRange, expirationStatus]);

  return (
    <HStack>
      <Tag
        hasIcon
        tooltipLabel={
          <Text size="Small">
            {t(i18nKeys.operator.cards.CardStatus[card.card_status])}
          </Text>
        }
        status={CardExpirationStatusToTagStatus[expirationStatus]}
      />
      <Text size="Small">
        {t(labelTranslation, {
          days: dateRange,
        })}
      </Text>
    </HStack>
  );
};
