/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { ProviderName } from './providerName';
import type { ProviderNameKey } from './providerNameKey';
import type { Url } from './component-common/url';

export interface Provider {
  id?: Uuid;
  name?: ProviderName;
  name_key?: ProviderNameKey;
  icon_url?: Url;
  deeplink_android?: Url;
  deeplink_ios?: Url;
  has_rich_content?: boolean;
  is_featured?: boolean;
}
