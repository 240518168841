/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

/**
 * Registrations should only have a single role. E.g. a user which is both an EMPLOYEE and ADMIN will be excluded. Can be used together with the roles filter.
 */
export type QuerySingleRoleParameter = boolean;
