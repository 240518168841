/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { QueryGroupIdParameter } from './component-parameters/queryGroupIdParameter';
import type { QueryPolicyTypeParameter } from './queryPolicyTypeParameter';
import type { QueryPageNumberParameter } from './component-parameters/queryPageNumberParameter';
import type { QueryPageSizeParameter } from './component-parameters/queryPageSizeParameter';

export type ListProgramExpensePoliciesParams = {
  /**
   * ID of the program
   */
  program_id: Uuid;
  /**
   * ID of the group
   */
  group_id?: QueryGroupIdParameter;
  /**
   * type of a policy
   */
  policy_type?: QueryPolicyTypeParameter;
  /**
   * Number of the page to fetch
   */
  page_number?: QueryPageNumberParameter;
  /**
   * Size of the page to fetch
   */
  page_size?: QueryPageSizeParameter;
};
