/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type NetIncomeRange = typeof NetIncomeRange[keyof typeof NetIncomeRange];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NetIncomeRange = {
  less_five: 'less_five',
  less_ten: 'less_ten',
  less_fifty: 'less_fifty',
  less_hundred_fifty: 'less_hundred_fifty',
  less_five_hundred_fifty: 'less_five_hundred_fifty',
  over_five_hundred_fifty: 'over_five_hundred_fifty',
} as const;
