/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { DocumentType } from './documentType';
import type { DocumentV1AllOfStatus } from './documentV1AllOfStatus';

export type DocumentV1AllOf = {
  id?: string;
  document_type?: DocumentType;
  url?: string;
  readonly status?: DocumentV1AllOfStatus;
};
