/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { FrequencyAuthorization } from './frequencyAuthorization';
import type { AmountOfMoney } from './amountOfMoney';
import type { Uuid } from './component-common/uuid';

export interface AddPaymentAuthorizationRequest {
  frequency: FrequencyAuthorization;
  amount: AmountOfMoney;
  service_id?: Uuid;
  product_id?: Uuid;
  membership_id: Uuid;
  /** After the 3DS flow, the provider redirects to a URL based on the status of the transaction.
The following query parameters will be appended to the base URL:
- payment_method_id: ID of the newly created Payment Method
- skipr_state: State of the payment. Query param "state" is already defined by HiPay. Therefore, parameter needed to be prefixed.
  - ACCEPT: Payment is accepted
  - DECLINE: Payment is declined
  - PENDING: Payment is pending and result is not yet available
  - EXCEPTION: A system error occurred
  - CANCEL: A system error occurred
 */
  redirect_base_url: string;
  /** if true it won't apply the split percentage to the authorisation amount provided in the request.
   */
  skip_amount_split?: boolean;
}
