/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryDateFromParameter } from './component-parameters/queryDateFromParameter';

export type UpdateLimitForGroupsParams = {
  /**
   * Start of date range (including)
   */
  date_from?: QueryDateFromParameter;
};
