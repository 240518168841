/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Provider } from './provider';
import type { ProviderDetailsAllOf } from './providerDetailsAllOf';

export type ProviderDetails = Provider & ProviderDetailsAllOf;
