/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { MetaV1Status } from './metaV1Status';
import type { ErrorResponse } from './errorResponse';

export interface MetaV1 {
  status: MetaV1Status;
  messages?: ErrorResponse[];
  page_index: number;
  page_size: number;
  total_items: number;
  total_pages: number;
}
