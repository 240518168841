/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

/**
 * CREATED: Payment method is created, but should still be validated. Payment method can't be used yet. VALIDATED: Payment method is validated and ready to use.
 */
export type PaymentMethodStatus =
  typeof PaymentMethodStatus[keyof typeof PaymentMethodStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentMethodStatus = {
  CREATED: 'CREATED',
  VALIDATED: 'VALIDATED',
} as const;
