/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { QueryMembershipIdParameter } from './component-parameters/queryMembershipIdParameter';
import type { QueryPageNumberParameter } from './component-parameters/queryPageNumberParameter';
import type { QueryPageSizeParameter } from './component-parameters/queryPageSizeParameter';

export type BudgetsGetPastParams = {
  membership_id?: QueryMembershipIdParameter;
  sort?: string;
  page_number?: QueryPageNumberParameter;
  page_size?: QueryPageSizeParameter;
};
