/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export interface PaymentMethodCreditCard {
  /** Brand name of the card. */
  readonly brand: string;
  /** Masked number of the credit card, also called PAN. */
  readonly card_number: string;
}
