import { Box, Button, Flex, Link, Text } from '@chakra-ui/react';
import { TEN_MB } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { validateSchema } from '@libs/core/utils/validation';
import { FIREBASE_STORAGE_FILES } from '@libs/dashboard-core/constants';
import {
  Drawer,
  FormSubmitData,
  handleFormSubmit,
  UploadField,
  useToast,
} from '@libs/ui/components';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { validationUploadExpensesSchema } from '../expense.validations';
import {
  expensesImportCsv,
  getExpenseListQueryKey,
} from '@libs/api-v2/endpoints';

type ExpensesUploadDrawerProps = {
  isOpen: boolean;
  onClose(): void;
};

export const ExpensesUploadDrawer = ({
  isOpen,
  onClose,
}: ExpensesUploadDrawerProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: submit } = useMutation(
    async ({ values }: FormSubmitData<{ expenses: File[] }>) => {
      const { expenses } = values;
      return expensesImportCsv({
        file: expenses[0],
      });
    },
    {
      onSuccess: () => {
        onClose();
        toast({
          title: t(i18nKeys.employees_form.success.title),
        });
        queryClient.invalidateQueries(getExpenseListQueryKey());
      },
    },
  );
  return (
    <Drawer
      title={t(i18nKeys.expenses_form.title)}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
    >
      <Formik<{ expenses: File[] }>
        initialValues={{
          expenses: [],
        }}
        validate={validateSchema(validationUploadExpensesSchema)}
        onSubmit={handleFormSubmit(submit)}
      >
        {({ isSubmitting }) => (
          <>
            <Drawer.Body>
              <Form noValidate id="upload-expenses-form">
                {t(i18nKeys.expenses_form.introduction)
                  .split('\n')
                  .map((item) => {
                    return (
                      <Text color="ink.dark" size="Body2">
                        {item}
                      </Text>
                    );
                  })}
                <UploadField
                  name="expenses"
                  explanations={t(i18nKeys.common.upload_explaination)}
                  options={{
                    multiple: false,
                    maxFiles: 1,
                    accept: 'text/csv',
                    maxSize: TEN_MB,
                  }}
                />
              </Form>
              <Box mt={'7rem'} mb={'2rem'}>
                <Link
                  href={FIREBASE_STORAGE_FILES.templates.UPLOAD_EXPENSES}
                  download
                >
                  {t(i18nKeys.employees_form.template_link)}
                </Link>
                <Link
                  ml="1rem"
                  href={FIREBASE_STORAGE_FILES.templates.PRODUCT_KEYS}
                  download
                >
                  {t(i18nKeys.expenses_form.product_keys)}
                </Link>
              </Box>
            </Drawer.Body>
            <Drawer.Footer>
              <Flex justify="space-between" width="100%">
                <Button size="body1" variant="border" onClick={onClose}>
                  {t(i18nKeys.common.cancel)}
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  size="body1"
                  isLoading={isSubmitting}
                  form="upload-expenses-form"
                >
                  {t(i18nKeys.common.submit)}
                </Button>
              </Flex>
            </Drawer.Footer>
          </>
        )}
      </Formik>
    </Drawer>
  );
};
