/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type Operation = typeof Operation[keyof typeof Operation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Operation = {
  expense_approved: 'expense_approved',
  expense_create: 'expense_create',
} as const;
