/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { Budget } from './budget';
import type { MetaV1 } from './metaV1';

export interface BudgetsGetResponse {
  data?: Budget[];
  meta?: MetaV1;
}
