/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { ProviderService } from './providerService';
import type { KeyValue } from './keyValue';

export interface Provider {
  name?: string;
  services?: ProviderService[];
  legals_accepted?: KeyValue[];
  user_reference?: string;
}
