/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Provider } from './provider';
import type { Service } from './service';

export interface SearchProviderResultItem {
  providers?: Provider[];
  service?: Service;
}
