/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { ProgramBudgetStartOn } from './programBudgetStartOn';

export interface GroupMemberUpdateRequest {
  budget_period_start_on: ProgramBudgetStartOn[];
}
