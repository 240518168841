/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Expense } from './expense';
import type { ExpenseWithMetaAllOf } from './expenseWithMetaAllOf';

export type ExpenseWithMeta = Expense & ExpenseWithMetaAllOf;
