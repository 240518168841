/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryOrganisationIdParameter } from './component-parameters/queryOrganisationIdParameter';
import type { QueryRegistrationStatusParameter } from './queryRegistrationStatusParameter';
import type { QueryRolesParameter } from './queryRolesParameter';
import type { QueryIsSuspendedParameter } from './queryIsSuspendedParameter';

export type RegistrationsExportParams = {
  /**
   * ID of the organisation
   */
  organisation_id?: QueryOrganisationIdParameter;
  /**
   * Filter on status of a registration
   */
  status?: QueryRegistrationStatusParameter;
  /**
   * Repeat "role" param multiple times to include multiple roles
   */
  role?: QueryRolesParameter;
  /**
   * Membership is suspended
   */
  is_suspended?: QueryIsSuspendedParameter;
};
