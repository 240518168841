import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Role } from '@libs/api-v2/models';
import { getI18nInstance } from '@libs/core/i18n';
import { ErrorCrashTrackingBoundary } from '@libs/dashboard-core/components/error-crash-tracking';
import { QUERY_CLIENT } from '@libs/dashboard-core/constants';
import { ProvideAuth, ProvideRole } from '@libs/dashboard-core/context';
import {
  OnboardedRoute,
  PrivateRoute,
  RoleRoute,
} from '@libs/dashboard-core/guards';
import {
  ConfirmResetPasswordPage,
  DashboardFleetManagerPage,
  EmployeeDetailsPage,
  DashboardOperatorPage,
  EmployeePage,
  LoginPage,
  MerchantPage,
  OnboardingPage,
  OrganisationDetailsPage,
  OrganisationOnboardingPage,
  OrganisationPage,
  ResetPasswordPage,
  RestrictionGroupPage,
  ServiceUnavailablePage,
  SetPasswordPage,
  TransactionPage,
  ServicePage,
  ExpensePage,
  RulesetPage,
  RestrictionPage,
} from '@libs/dashboard-core/pages';
import { getRouterPath, Routes } from '@libs/dashboard-core/routes';
import {
  DefaultTheme,
  GlobalFonts,
  GlobalStyle,
} from '@libs/dashboard-core/theme';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from '@tanstack/react-query';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes as ReactRouterRoutes,
} from 'react-router-dom';
import { BUILD_CONFIG } from '../build-config';

export const App = () => {
  const Theme = extendTheme(DefaultTheme);

  return (
    <QueryClientProvider client={QUERY_CLIENT}>
      <ChakraProvider resetCSS theme={Theme}>
        <GlobalStyle />
        <GlobalFonts />
        <I18nextProvider i18n={getI18nInstance(BUILD_CONFIG.i18n)}>
          <ErrorCrashTrackingBoundary config={BUILD_CONFIG}>
            <ProvideAuth>
              <Router>
                <ReactRouterRoutes>
                  <Route
                    path={getRouterPath(Routes.SERVICE_UNAVAILABLE)}
                    element={<ServiceUnavailablePage />}
                  />

                  <Route path={getRouterPath(Routes.AUTH)}>
                    <Route
                      path={getRouterPath(Routes.AUTH_LOGIN)}
                      element={<LoginPage />}
                    />

                    <Route
                      path={getRouterPath(Routes.AUTH_SET_PASSWORD)}
                      element={<SetPasswordPage />}
                    />

                    <Route
                      path={getRouterPath(Routes.AUTH_RESET_PASSWORD)}
                      element={<ResetPasswordPage />}
                    />

                    <Route
                      path={getRouterPath(Routes.AUTH_CONFIRM_RESET_PASSWORD)}
                      element={<ConfirmResetPasswordPage />}
                    />
                    <Route
                      element={
                        <Navigate
                          to={getRouterPath(Routes.AUTH_LOGIN)}
                          replace
                        />
                      }
                    />
                  </Route>
                  <Route element={<PrivateRoute />} path="*">
                    <Route
                      path={'*'}
                      element={
                        <ProvideRole>
                          <RoleRoute role={Role.USER}>
                            <Route
                              path={getRouterPath(Routes.DASHBOARD)}
                              element={
                                <OnboardedRoute>
                                  <DashboardFleetManagerPage />
                                </OnboardedRoute>
                              }
                            />

                            <Route
                              path={getRouterPath(Routes.ONBOARDING)}
                              element={<OnboardingPage />}
                            />
                            <Route
                              element={
                                <Navigate
                                  to={getRouterPath(Routes.DASHBOARD)}
                                  replace
                                />
                              }
                            />
                          </RoleRoute>
                          <RoleRoute role={Role.OPERATOR_ADMIN}>
                            <Route
                              path={getRouterPath(Routes.DASHBOARD)}
                              element={<DashboardOperatorPage />}
                            />
                            <Route
                              path={getRouterPath(
                                Routes.DASHBOARD_TRANSACTIONS,
                              )}
                              element={<TransactionPage />}
                            />
                            <Route
                              path={getRouterPath(Routes.DASHBOARD_MERCHANTS)}
                              element={<MerchantPage />}
                            />
                            <Route
                              path={getRouterPath(
                                Routes.DASHBOARD_RESTRICTION_GROUPS,
                              )}
                              element={<RestrictionGroupPage />}
                            />
                            <Route
                              path={getRouterPath(
                                Routes.DASHBOARD_ORGANISATIONS,
                              )}
                              element={<OrganisationPage />}
                            />
                            <Route
                              path={getRouterPath(
                                Routes.DASHBOARD_ORGANISATIONS_DETAILS,
                              )}
                              element={<OrganisationDetailsPage />}
                            />
                            <Route
                              path={getRouterPath(Routes.DASHBOARD_SERVICES)}
                              element={<ServicePage />}
                            />
                            <Route
                              path={getRouterPath(Routes.DASHBOARD_EXPENSES)}
                              element={<ExpensePage />}
                            />
                            <Route
                              path={getRouterPath(
                                Routes.DASHBOARD_EMPLOYEES_DETAILS_BUDGETS,
                              )}
                              element={<EmployeeDetailsPage />}
                            />
                            <Route
                              path={getRouterPath(
                                Routes.DASHBOARD_EMPLOYEES_DETAILS_EXPENSES,
                              )}
                              element={<EmployeeDetailsPage />}
                            />
                            <Route
                              path={getRouterPath(
                                Routes.DASHBOARD_EMPLOYEES_DETAILS_CARD,
                              )}
                              element={<EmployeeDetailsPage />}
                            />
                            <Route
                              path={getRouterPath(
                                Routes.DASHBOARD_EMPLOYEES_DETAILS_PROGRAMS,
                              )}
                              element={<EmployeeDetailsPage />}
                            />
                            <Route
                              path={getRouterPath(
                                Routes.DASHBOARD_EMPLOYEES_DETAILS,
                              )}
                              element={<EmployeeDetailsPage />}
                            />
                            <Route
                              path={getRouterPath(Routes.DASHBOARD_EMPLOYEES)}
                              element={<EmployeePage />}
                            />
                            <Route
                              path={getRouterPath(
                                Routes.DASHBOARD_ORGANISATIONS_DETAILS_ONBOARDING,
                              )}
                              element={<OrganisationOnboardingPage />}
                            />
                            <Route
                              path={getRouterPath(Routes.DASHBOARD_RULESETS)}
                              element={<RulesetPage />}
                            />
                            <Route
                              path={getRouterPath(
                                Routes.DASHBOARD_RESTRICTIONS,
                              )}
                              element={<RestrictionPage />}
                            />
                            <Route
                              element={
                                <Navigate
                                  to={getRouterPath(Routes.DASHBOARD)}
                                  replace
                                />
                              }
                            />
                          </RoleRoute>
                        </ProvideRole>
                      }
                    />
                  </Route>
                </ReactRouterRoutes>
              </Router>
            </ProvideAuth>
          </ErrorCrashTrackingBoundary>
        </I18nextProvider>
      </ChakraProvider>
    </QueryClientProvider>
  );
};

export default App;
