import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { STORAGE_KEYS } from '../constants';
import { AvailableLanguage } from './types';

export const useLanguage = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<AvailableLanguage>(
    i18n.language as AvailableLanguage,
  );

  const changeLanguage = useCallback(
    (lng: AvailableLanguage) => {
      i18n.changeLanguage(lng);
      localStorage.setItem(STORAGE_KEYS.LAST_SELECTED_LANGUAGE, lng);
    },
    [i18n],
  );

  useEffect(() => {
    setLanguage(i18n.language as AvailableLanguage);
  }, [i18n.language]);

  useEffect(() => {
    document.documentElement.setAttribute('lang', language);
  }, [language]);

  return { language, changeLanguage, availableLanguages: i18n.languages };
};
