/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export interface ResetRegistrationMembershipRequest {
  readonly send_invite?: boolean;
}
