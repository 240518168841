/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

/**
 * EMPTY: The current wallet amount of the organisation is empty or below 0. LOW: The current wallet is below the threshold. FILLED: The current wallet is above the threshold.
 */
export type WalletStatus = typeof WalletStatus[keyof typeof WalletStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WalletStatus = {
  EMPTY: 'EMPTY',
  LOW: 'LOW',
  FILLED: 'FILLED',
} as const;
