/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Resource } from './resource';
import type { Operation } from './operation';

export interface NotificationBody {
  resource?: Resource;
  resource_id?: string;
  operation?: Operation;
}
