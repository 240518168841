/* eslint-disable @typescript-eslint/no-explicit-any */
import { AXIOS_INSTANCE as AXIOS_INSTANCE_OLDGEN } from '@libs/api/config';
import { AXIOS_INSTANCE } from '@libs/api-v2/config';
import { getCurrentUser, getGetCurrentUserQueryKey } from '@libs/api/endpoints';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { AuthError } from '@libs/core/types';
import { useToast } from '@libs/ui/components';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import {
  FIREBASE_CONFIG,
  HTTP_CODE,
  HTTP_ERRORS_CODE,
  QUERY_CLIENT,
} from '../constants';
import { getPath, Routes } from '../routes';
import { createApiError } from '../utils/api-errors';
import { AxiosInstance } from 'axios';

if (firebase.apps.length === 0) {
  firebase.initializeApp(FIREBASE_CONFIG);
}

type AuthContextState = {
  signin: (email: string, password: string) => Promise<firebase.User>;
  signout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  confirmResetPassword: (code: string, password: string) => Promise<void>;
  isValidResetCode: (code: string) => Promise<string>;
  createUser: (
    email: string,
    password: string,
  ) => Promise<firebase.auth.UserCredential>;
  isLogged: boolean;
  isLoaded: boolean;
};

const AuthContext = createContext<AuthContextState>(null);

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuthState = () => {
  return useContext(AuthContext);
};

function useProvideAuth() {
  const [isLogged, setLogged] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const toast = useToast();
  const { t } = useTranslation();
  const { data: token } = useQuery(
    ['token'],
    () => firebase.auth().currentUser.getIdToken(),
    {
      enabled: isLogged,
      retry: false,
      onError: () => signout(),
    },
  );

  useEffect(() => {
    if (token) {
      // Register axios interceptors for requests/responses
      const [requestInterceptorIdOldGen, responseInterceptorIdOldGen] =
        registerAxiosInterceptors(AXIOS_INSTANCE_OLDGEN);
      const [requestInterceptorId, responseInterceptorId] =
        registerAxiosInterceptors(AXIOS_INSTANCE);

      QUERY_CLIENT.fetchQuery(getGetCurrentUserQueryKey(), () =>
        getCurrentUser(),
      );

      return () => {
        AXIOS_INSTANCE.interceptors.request.eject(requestInterceptorId);
        AXIOS_INSTANCE.interceptors.response.eject(responseInterceptorId);
        AXIOS_INSTANCE_OLDGEN.interceptors.request.eject(
          requestInterceptorIdOldGen,
        );
        AXIOS_INSTANCE_OLDGEN.interceptors.response.eject(
          responseInterceptorIdOldGen,
        );
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const clearCache = () => {
    localStorage.clear();
    QUERY_CLIENT.clear();
  };

  const signin = (email: string, password: string) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        setLogged(true);
        return response.user;
      })
      .catch((error: AuthError) => {
        return Promise.reject(error);
      });
  };

  const signout = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setLogged(false);
        clearCache();
      });
  };

  const createUser = (email: string, password: string) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  };

  const resetPassword = (email: string) => {
    return firebase.auth().sendPasswordResetEmail(email);
  };

  const confirmResetPassword = (code: string, password: string) => {
    return firebase.auth().confirmPasswordReset(code, password);
  };

  const isValidResetCode = (code: string) => {
    return firebase.auth().verifyPasswordResetCode(code);
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setLogged(true);
      } else {
        setLogged(false);
        clearCache();
      }

      if (!isLoaded) {
        setLoaded(true);
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registerAxiosInterceptors = (instance: AxiosInstance) => {
    const requestInterceptorId = instance.interceptors.request.use(
      (config: any) => {
        return {
          ...config,
          headers: {
            ...config.headers,
            'X-Authorization': `JWT ${token}`,
          },
        };
      },
    );

    const responseInterceptorId = instance.interceptors.response.use(
      undefined,
      ({ response }) => {
        if (response?.status === HTTP_CODE.UNAUTHORIZED) {
          signout();
        }
        if (
          response?.status === HTTP_CODE.FORBIDDEN &&
          response?.data.detail === HTTP_ERRORS_CODE.FORBIDDEN_EMPLOYEES_ACCESS
        ) {
          signout();
          toast({
            title: t(i18nKeys.common.unauthorized),
            content: t(i18nKeys.common.errors.unauthorized.employee),
            status: 'error',
            options: {
              isClosable: true,
              duration: null,
            },
          });
          return;
        }
        if (response?.status >= HTTP_CODE.INTERNAL_SERVER_ERROR) {
          window.location.href = getPath(Routes.SERVICE_UNAVAILABLE);
        }

        if (response?.data) {
          const error = createApiError(response.data);
          toast({
            title: t(i18nKeys.common.errors.title),
            content: t(
              i18nKeys.http_errors[error.translation.key] || error.detail,
              {
                value: error.translation.value,
              },
            ),
            status: 'error',
            options: {
              isClosable: true,
              duration: null,
            },
          });
          return Promise.reject(error);
        }

        return Promise.reject(response);
      },
    );
    return [requestInterceptorId, responseInterceptorId];
  };

  return {
    isLoaded,
    isLogged,
    signin,
    signout,
    confirmResetPassword,
    resetPassword,
    createUser,
    isValidResetCode,
  };
}
