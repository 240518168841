/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  ImcclRulesets,
  ImcclRuleset,
  CreateImcclRulesetRequest,
  UpdateImcclRulesetRequest,
} from '../../models';
import { customInstance } from '../../config';

export const imcclRulesetAllGet = (signal?: AbortSignal) => {
  return customInstance<ImcclRulesets>({
    url: `/web/imccl-rulesets`,
    method: 'get',
    signal,
  });
};

export const getImcclRulesetAllGetQueryKey = () =>
  [`/web/imccl-rulesets`] as const;

export const getImcclRulesetAllGetQueryOptions = <
  TData = Awaited<ReturnType<typeof imcclRulesetAllGet>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof imcclRulesetAllGet>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<typeof imcclRulesetAllGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getImcclRulesetAllGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof imcclRulesetAllGet>>
  > = ({ signal }) => imcclRulesetAllGet(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ImcclRulesetAllGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof imcclRulesetAllGet>>
>;
export type ImcclRulesetAllGetQueryError = unknown;

export const useImcclRulesetAllGet = <
  TData = Awaited<ReturnType<typeof imcclRulesetAllGet>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof imcclRulesetAllGet>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getImcclRulesetAllGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const imcclRulesetCreate = (
  createImcclRulesetRequest: CreateImcclRulesetRequest,
) => {
  return customInstance<ImcclRuleset>({
    url: `/web/imccl-rulesets`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: createImcclRulesetRequest,
  });
};

export const getImcclRulesetCreateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof imcclRulesetCreate>>,
    TError,
    { data: CreateImcclRulesetRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof imcclRulesetCreate>>,
  TError,
  { data: CreateImcclRulesetRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof imcclRulesetCreate>>,
    { data: CreateImcclRulesetRequest }
  > = (props) => {
    const { data } = props ?? {};

    return imcclRulesetCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ImcclRulesetCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof imcclRulesetCreate>>
>;
export type ImcclRulesetCreateMutationBody = CreateImcclRulesetRequest;
export type ImcclRulesetCreateMutationError = unknown;

export const useImcclRulesetCreate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof imcclRulesetCreate>>,
    TError,
    { data: CreateImcclRulesetRequest },
    TContext
  >;
}) => {
  const mutationOptions = getImcclRulesetCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const imcclRulesetGet = (
  imcclRulesetId: string,
  signal?: AbortSignal,
) => {
  return customInstance<ImcclRuleset>({
    url: `/web/imccl-rulesets/${imcclRulesetId}`,
    method: 'get',
    signal,
  });
};

export const getImcclRulesetGetQueryKey = (imcclRulesetId: string) =>
  [`/web/imccl-rulesets/${imcclRulesetId}`] as const;

export const getImcclRulesetGetQueryOptions = <
  TData = Awaited<ReturnType<typeof imcclRulesetGet>>,
  TError = unknown,
>(
  imcclRulesetId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof imcclRulesetGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof imcclRulesetGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getImcclRulesetGetQueryKey(imcclRulesetId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof imcclRulesetGet>>> = ({
    signal,
  }) => imcclRulesetGet(imcclRulesetId, signal);

  return { queryKey, queryFn, enabled: !!imcclRulesetId, ...queryOptions };
};

export type ImcclRulesetGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof imcclRulesetGet>>
>;
export type ImcclRulesetGetQueryError = unknown;

export const useImcclRulesetGet = <
  TData = Awaited<ReturnType<typeof imcclRulesetGet>>,
  TError = unknown,
>(
  imcclRulesetId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof imcclRulesetGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getImcclRulesetGetQueryOptions(imcclRulesetId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const imcclRulesetUpdate = (
  imcclRulesetId: string,
  updateImcclRulesetRequest: UpdateImcclRulesetRequest,
) => {
  return customInstance<ImcclRuleset>({
    url: `/web/imccl-rulesets/${imcclRulesetId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: updateImcclRulesetRequest,
  });
};

export const getImcclRulesetUpdateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof imcclRulesetUpdate>>,
    TError,
    { imcclRulesetId: string; data: UpdateImcclRulesetRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof imcclRulesetUpdate>>,
  TError,
  { imcclRulesetId: string; data: UpdateImcclRulesetRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof imcclRulesetUpdate>>,
    { imcclRulesetId: string; data: UpdateImcclRulesetRequest }
  > = (props) => {
    const { imcclRulesetId, data } = props ?? {};

    return imcclRulesetUpdate(imcclRulesetId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ImcclRulesetUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof imcclRulesetUpdate>>
>;
export type ImcclRulesetUpdateMutationBody = UpdateImcclRulesetRequest;
export type ImcclRulesetUpdateMutationError = unknown;

export const useImcclRulesetUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof imcclRulesetUpdate>>,
    TError,
    { imcclRulesetId: string; data: UpdateImcclRulesetRequest },
    TContext
  >;
}) => {
  const mutationOptions = getImcclRulesetUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
