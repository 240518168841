/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { AuthenticationProviderName } from './component-auth/authenticationProviderName';

export interface SetAuthenticationMappingRequest {
  organisation_id?: string;
  provider?: AuthenticationProviderName;
  /** It may be either ID token or access token, depending on the authentication provider implementation.
However, it should be a valid JWT token at all times carrying a 'sub' claim
 */
  sso_token: string;
}
