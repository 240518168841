/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  ServicesGetResponse,
  ServicesGetParams,
  Service,
  ServiceDetails,
  ServiceDetailsParams,
} from '../../models';
import { customInstance } from '../../config';

/**
 * Due to technical reasons, we don't support pagination when "program_id" is provided. When "program_id" is provided, we always assume a single page with all results.
 */
export const servicesGet = (
  params?: ServicesGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<ServicesGetResponse>({
    url: `/web/v2/services`,
    method: 'get',
    params,
    signal,
  });
};

export const getServicesGetQueryKey = (params?: ServicesGetParams) =>
  [`/web/v2/services`, ...(params ? [params] : [])] as const;

export const getServicesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof servicesGet>>,
  TError = unknown,
>(
  params?: ServicesGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof servicesGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<Awaited<ReturnType<typeof servicesGet>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getServicesGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof servicesGet>>> = ({
    signal,
  }) => servicesGet(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ServicesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof servicesGet>>
>;
export type ServicesGetQueryError = unknown;

export const useServicesGet = <
  TData = Awaited<ReturnType<typeof servicesGet>>,
  TError = unknown,
>(
  params?: ServicesGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof servicesGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getServicesGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Fetch a single service by ID
 */
export const serviceGet = (serviceId: string, signal?: AbortSignal) => {
  return customInstance<Service>({
    url: `/web/v2/services/${serviceId}`,
    method: 'get',
    signal,
  });
};

export const getServiceGetQueryKey = (serviceId: string) =>
  [`/web/v2/services/${serviceId}`] as const;

export const getServiceGetQueryOptions = <
  TData = Awaited<ReturnType<typeof serviceGet>>,
  TError = unknown,
>(
  serviceId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof serviceGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<Awaited<ReturnType<typeof serviceGet>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getServiceGetQueryKey(serviceId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof serviceGet>>> = ({
    signal,
  }) => serviceGet(serviceId, signal);

  return { queryKey, queryFn, enabled: !!serviceId, ...queryOptions };
};

export type ServiceGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof serviceGet>>
>;
export type ServiceGetQueryError = unknown;

export const useServiceGet = <
  TData = Awaited<ReturnType<typeof serviceGet>>,
  TError = unknown,
>(
  serviceId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof serviceGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getServiceGetQueryOptions(serviceId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get the details of a service
 */
export const serviceDetails = (
  serviceId: string,
  params: ServiceDetailsParams,
  signal?: AbortSignal,
) => {
  return customInstance<ServiceDetails>({
    url: `/web/v2/services/${serviceId}/details`,
    method: 'get',
    params,
    signal,
  });
};

export const getServiceDetailsQueryKey = (
  serviceId: string,
  params: ServiceDetailsParams,
) =>
  [
    `/web/v2/services/${serviceId}/details`,
    ...(params ? [params] : []),
  ] as const;

export const getServiceDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof serviceDetails>>,
  TError = unknown,
>(
  serviceId: string,
  params: ServiceDetailsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof serviceDetails>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof serviceDetails>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getServiceDetailsQueryKey(serviceId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof serviceDetails>>> = ({
    signal,
  }) => serviceDetails(serviceId, params, signal);

  return { queryKey, queryFn, enabled: !!serviceId, ...queryOptions };
};

export type ServiceDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof serviceDetails>>
>;
export type ServiceDetailsQueryError = unknown;

export const useServiceDetails = <
  TData = Awaited<ReturnType<typeof serviceDetails>>,
  TError = unknown,
>(
  serviceId: string,
  params: ServiceDetailsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof serviceDetails>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getServiceDetailsQueryOptions(
    serviceId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
