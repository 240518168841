/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { PayrollReportMetadataStatus } from './payrollReportMetadataStatus';

export interface PayrollReportMetadata {
  id?: string;
  organisationId?: string;
  hrisId?: string;
  generatedAt?: string;
  periodStart?: string;
  periodEnd?: string;
  status?: PayrollReportMetadataStatus;
}
