/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryOrganisationIdParameter } from './component-parameters/queryOrganisationIdParameter';
import type { QueryReportAggregationParameter } from './queryReportAggregationParameter';
import type { QueryReportTimeScaleParameter } from './queryReportTimeScaleParameter';
import type { QueryReportDateFromParameter } from './queryReportDateFromParameter';
import type { QueryReportDateToParameter } from './queryReportDateToParameter';

export type ReportGetReportCo2GraphDataParams = {
  /**
   * ID of the organisation
   */
  organisation_id?: QueryOrganisationIdParameter;
  /**
   * level of the aggregation
   */
  aggregation?: QueryReportAggregationParameter;
  /**
   * time scale of the report
   */
  time_scale?: QueryReportTimeScaleParameter;
  /**
   * start of date range
   */
  range_from?: QueryReportDateFromParameter;
  /**
   * end of date range
   */
  range_to?: QueryReportDateToParameter;
};
