/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { MembershipRole } from './membershipRole';

export interface Membership {
  id?: string;
  role?: MembershipRole;
  organisation_id?: string;
}
