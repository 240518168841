import React from 'react';
import {
  useCardsGet,
  useImcclRestrictionCardsGet,
} from '@libs/api-v2/endpoints';
import { CardStatus } from '@libs/api-v2/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Table } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useTranslation } from 'react-i18next';
import { Registration } from '@libs/api-v2/models';
import { ImcclRestrictionName } from '../../../restriction/components/imccl-restriction-name';

type CardImcclRestrictionsTableProps = {
  employee: Registration;
};

export const CardImcclRestrictionsTable = ({
  employee,
}: CardImcclRestrictionsTableProps) => {
  // Attributes missing
  const { t } = useTranslation();

  const params = {
    membership_ids: [employee.membership_id],
  };
  const { data } = useCardsGet(params, {
    query: { keepPreviousData: true },
  });

  const { data: cards } = data || {};

  // sort cards by status
  // first in the list are unlocked
  const sortedCards = cards?.sort(({ card_status }) =>
    card_status === CardStatus.UNLOCK ? -1 : 1,
  );
  const firstCard =
    !!sortedCards && sortedCards.length > 0 ? sortedCards[0] : null;
  const { data: imcclRestrictions, isLoading } = useImcclRestrictionCardsGet(
    firstCard?.card_reference,
    { query: { enabled: !!sortedCards } },
  );

  const headers: TableHeaders = [
    t(i18nKeys.operator.cards.card_reference),
    t(i18nKeys.operator.cards.imccl_restriction),
    t(i18nKeys.common.status),
    t(i18nKeys.common.attached_at),
    t(i18nKeys.common.created_at),
    t(i18nKeys.common.updated_at),
    '',
  ];

  // Render
  return (
    <Table headers={headers}>
      <Table.TBody
        colSpan={headers.length}
        isLoading={isLoading && !imcclRestrictions}
        isEmpty={
          !isLoading &&
          (!imcclRestrictions || imcclRestrictions?.data?.length === 0)
        }
      >
        {imcclRestrictions?.data?.map((restriction, index) => {
          return (
            <Table.TR
              key={restriction.imccl_restriction_id}
              index={restriction.imccl_restriction_id}
            >
              <Table.TD>{restriction.card_reference}</Table.TD>
              <Table.TD>
                <ImcclRestrictionName
                  imccl_restriction_id={restriction?.imccl_restriction_id}
                />
              </Table.TD>
              <Table.TD>{restriction.status}</Table.TD>
              <Table.Date>{restriction.attached_at}</Table.Date>
              <Table.Date>{restriction.created_at}</Table.Date>
              <Table.Date>{restriction.updated_at}</Table.Date>
            </Table.TR>
          );
        })}
      </Table.TBody>
    </Table>
  );
};
