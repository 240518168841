/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryOrganisationIdParameter } from './component-parameters/queryOrganisationIdParameter';
import type { QueryIsSuspendedParameter } from './queryIsSuspendedParameter';

export type InvitationsExportParams = {
  /**
   * ID of the organisation
   */
  organisation_id?: QueryOrganisationIdParameter;
  /**
   * Membership is suspended
   */
  is_suspended?: QueryIsSuspendedParameter;
};
