/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type Status = typeof Status[keyof typeof Status];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Status = {
  SUGGESTED: 'SUGGESTED',
  CREATED: 'CREATED',
  CONFIRMED: 'CONFIRMED',
  VALIDATED: 'VALIDATED',
  REFUSED: 'REFUSED',
  CANCELED: 'CANCELED',
  STARTED: 'STARTED',
  IN_REVIEW: 'IN_REVIEW',
} as const;
