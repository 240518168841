/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type SplitPaymentStatus =
  typeof SplitPaymentStatus[keyof typeof SplitPaymentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SplitPaymentStatus = {
  PENDING: 'PENDING',
  DECLINED: 'DECLINED',
  CANCELLED: 'CANCELLED',
  EXCEPTION: 'EXCEPTION',
  ACCEPTED: 'ACCEPTED',
} as const;
