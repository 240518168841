import { Text } from '@chakra-ui/layout';
import { useCardTransactionsGet } from '@libs/api/endpoints';
import { CardTransactionAllOfStatus } from '@libs/api/models';
import { FORMAT_DATE_WITH_HOURS } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useIcon } from '@libs/core/theme/utils';
import { capitalize, getQueryParam } from '@libs/core/utils';
import { Currency, Pager, Table, Tag, usePager } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useDrawer, useTable } from '@libs/ui/components/table/table.utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardTransactionStatusToTagStatus } from '../transaction.utils';
import { TransactionDetailsDrawer } from './transaction-details-drawer';
import { getMerchantCategoryCodes } from '@libs/dashboard-core/utils/merchant_category_codes/mcc';

export const TransactionTable = () => {
  const merchantCategoryCodesMap = getMerchantCategoryCodes();
  const { t } = useTranslation();
  const EyeIcon = useIcon('Eye');
  const { activeDrawerIndex, drawerHandler, isOpen, onClose } = useDrawer();
  const { pageNumber, pageSize, setPageNumber, setPageSize } = usePager();
  const {
    filterQuery,
    setFilterQuery,
    getFilterValue,
    sortQuery,
    setSortQuery,
    searchQuery,
    setSearchQuery,
    useQueryParams,
  } = useTable({
    setPageNumber,
    setPageSize,
  });

  const transactionReferenceParam = getQueryParam('transaction_reference');

  const { isLoading, isFetching, data, refetch } = useCardTransactionsGet(
    {
      page_number: pageNumber,
      page_size: transactionReferenceParam ? 1000 : pageSize,
      status: getFilterValue('status') as CardTransactionAllOfStatus,
      sort: sortQuery.length > 0 ? sortQuery.toString() : null,
      q: searchQuery,
      membership_id: getQueryParam('membership_id') || null,
    },
    { query: { keepPreviousData: true } },
  );

  useQueryParams({
    refetch,
    pageNumber,
    pageSize,
    setPageSize,
    totalPages: data?.meta.total_pages,
  });

  const headers: TableHeaders = [
    t(i18nKeys.common.merchant_name),
    t(i18nKeys.common.transaction),
    t(i18nKeys.common.card),
    t(i18nKeys.operator.transactions.payment),
    t(i18nKeys.common.amount),
    { name: 'created_at', title: t(i18nKeys.common.created_at), sort: true },
    {
      title: t(i18nKeys.common.status),
      name: 'status',
      filter: true,
      filterValues: Object.values(CardTransactionAllOfStatus),
      filterValuesI18nKey: i18nKeys.operator.transactions.status,
    },
    '',
  ];

  const submitHandler = ({ q }) => {
    setSearchQuery(q);
  };

  if (!isLoading) {
    // Override data to only have the selected transaction in it
    data.data = transactionReferenceParam
      ? data?.data?.filter(
          (transaction) =>
            transaction.transaction_reference === transactionReferenceParam,
        )
      : data.data;
  }

  return (
    <>
      <Table
        onSubmit={submitHandler}
        initialValues={{ q: '' }}
        hasFilter
        hasSearch
        filterQuery={filterQuery}
        setFilterQuery={setFilterQuery}
        sortQuery={sortQuery}
        setSortQuery={setSortQuery}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        headers={headers}
      >
        <Table.TBody
          isLoading={(isLoading || isFetching) && !data}
          colSpan={headers.length}
          isEmpty={
            !isLoading &&
            !isFetching &&
            (!data || !data?.data || data?.data.length === 0)
          }
        >
          {data?.data?.map((transaction, index) => (
            <Table.TR
              selectable
              onClick={() => drawerHandler(index)}
              key={transaction.transaction_reference}
              index={transaction.transaction_reference}
            >
              <Table.TD>
                <Text size="Body2">
                  {capitalize(transaction.merchant_name)}
                </Text>
                <Text size="Small" color="ink.dark">
                  {transaction.merchant_reference}
                </Text>
              </Table.TD>
              <Table.TD>
                <Text>{transaction.transaction_reference}</Text>
              </Table.TD>
              <Table.TD>
                <Text>{transaction.card_reference}</Text>
              </Table.TD>
              <Table.TD>
                <Text>{transaction.payment_reference}</Text>
              </Table.TD>
              <Table.TD>
                <Currency size="Body2Bold">{transaction.amount}</Currency>
              </Table.TD>
              <Table.Date format={FORMAT_DATE_WITH_HOURS}>
                {transaction.created_at}
              </Table.Date>
              <Table.TD>
                <Tag
                  status={CardTransactionStatusToTagStatus[transaction.status]}
                  hasIcon
                  tooltipLabel={t(
                    i18nKeys.operator.transactions.status[
                      transaction.status?.toUpperCase()
                    ],
                  )}
                />
              </Table.TD>
              <Table.Action
                onClick={() => drawerHandler(index)}
                icon={<EyeIcon width="2rem" height="2rem" />}
              />
            </Table.TR>
          ))}
        </Table.TBody>
      </Table>
      {isOpen && (
        <TransactionDetailsDrawer
          transaction={data.data[activeDrawerIndex]}
          merchantCategoryCodes={merchantCategoryCodesMap}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
      {data && data?.meta.total_pages > 1 && (
        <Pager
          setPageNumber={setPageNumber}
          total_items={data.meta.total_items}
          page_index={data.meta.page_index}
          total_pages={data.meta.total_pages}
          page_size={pageSize}
          setPageSize={setPageSize}
          mt="2rem"
          isFetching={isFetching}
          pageNumber={pageNumber}
        />
      )}
    </>
  );
};
