import {
  Box,
  Button,
  Flex,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  Radio,
  Text,
  useTheme,
  VStack,
} from '@chakra-ui/react';
import { useGetCurrentUser } from '@libs/api/endpoints';
import { LoggedUser } from '@libs/api/models';
import { useLanguage } from '@libs/core/i18n';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useIcon } from '@libs/core/theme/utils';
import { useRoleState } from '@libs/dashboard-core/context';
import { RadioGroupField } from '@libs/ui/components';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageSelector } from './language-selector';
import { LogoutButton } from './logout-button';
import { PopoverTrigger } from '@libs/ui/components/pop-over-trigger';

export const UserProfile = () => {
  const { t } = useTranslation();
  const { data: user } = useGetCurrentUser({ query: { enabled: false } });
  const theme = useTheme();
  const ArrowDown = useIcon('ArrowDown');
  const User = useIcon('User');
  const { first_name = '', last_name = '' } = user || ({} as LoggedUser);
  const { role, setRole, availableRoles } = useRoleState();
  const { availableLanguages } = useLanguage();

  return (
    <Popover placement="bottom-end">
      <PopoverTrigger data-cy="user-profile-popover-trigger">
        <Flex align="center" cursor="pointer">
          <Box textAlign="right" mr="1rem">
            <Heading size="Body2">{t(i18nKeys.header.welcome)}</Heading>
            <Text size="Small">
              {first_name}{' '}
              <Box as="span" verticalAlign="text-bottom" display="inline-block">
                <ArrowDown
                  color={theme.colors.main.secondary.light}
                  width="1.4rem"
                />
              </Box>
            </Text>
          </Box>
        </Flex>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader p="1rem 2rem">
          <Box as="span" verticalAlign="text-bottom" display="inline-block">
            <User fill={theme.colors.ink.darker} width="2rem" />
          </Box>{' '}
          {first_name} {last_name}
        </PopoverHeader>
        {availableRoles.length > 1 && (
          <PopoverBody p="1.5rem 2rem">
            <Formik
              initialValues={{ role }}
              onSubmit={(values, actions) => {
                actions.setSubmitting(false);
                setRole(values.role);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Heading size="Body2" mb="1.5rem">
                    {t(i18nKeys.header.loggedAs)}
                  </Heading>
                  <RadioGroupField name="role">
                    <VStack spacing="2rem" align="stretch">
                      {availableRoles.map((role) => (
                        <Radio key={role} value={role}>
                          {t(i18nKeys.common.role[role])}
                        </Radio>
                      ))}
                    </VStack>
                  </RadioGroupField>
                  <Button
                    mt="1rem"
                    type="submit"
                    isLoading={isSubmitting}
                    size="small"
                    variant="border"
                    w="100%"
                  >
                    {t(i18nKeys.header.change_profile)}
                  </Button>
                </Form>
              )}
            </Formik>
          </PopoverBody>
        )}
        {availableLanguages.length > 1 && (
          <PopoverFooter px="2rem">
            <LanguageSelector />
          </PopoverFooter>
        )}
        <PopoverFooter px="2rem">
          <LogoutButton />
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
