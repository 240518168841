import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useIcon } from '@libs/core/theme/utils';
import { validateSchema } from '@libs/core/utils/validation';
import { FIREBASE_STORAGE_FILES } from '@libs/dashboard-core/constants';
import { CardInfoTile, Drawer, SelectField } from '@libs/ui/components';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SELECTABLE_DOCUMENTS,
  ShareholderDocumentTypeForm,
  UPLOAD_FILE_OPTIONS_ID,
} from '../../onboarding-wizard.constants';
import { OnboardingDocumentIdentityFormData } from '../../onboarding-wizard.types';
import { validationShareholderDocumentsIdentity } from '../../onboarding-wizard.validations';
import { UploadPreview } from '../upload-preview';
import { ProofProps } from './document.types';
import { hasAlreadyCompletedForm } from './document.utils';
import { ProofQualityImage } from './proof-quality-image';
import { ProofValidIcon } from './proof-valid-icon';

export const ProofIdentity = ({
  errorMessage,
  mainValues,
  setMainValues,
  shareholderName,
}: ProofProps) => {
  const theme = useTheme();
  const Identity = useIcon('Identity');
  const Add = useIcon('Add');
  const Edit = useIcon('Edit');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  // boolean indicating if user has already fill in the form in the past
  const alreadyCompleted = hasAlreadyCompletedForm({
    documentType: mainValues.documentType,
    document: mainValues.idFrontUrl || mainValues.passportUrl,
    secondaryDocument: mainValues.idFrontUrl && mainValues.idBackUrl,
  });
  const [isValid, setIsValid] = useState(alreadyCompleted);
  const onSubmit = (values: OnboardingDocumentIdentityFormData) => {
    if (values.backFile.length > 0) {
      setMainValues('backFile', values.backFile);
      setMainValues('frontFile', values.frontFile);
    } else {
      setMainValues('passportFile', values.passportFile);
    }
    setIsValid(true);
    onClose();
  };

  return (
    <>
      <CardInfoTile
        colorDark={
          isValid
            ? theme.colors.status.positive.type
            : theme.colors.main.secondary.normal
        }
        colorLight={theme.colors.main.secondary.lighter}
        modRightIcon
        rightIcon={isValid ? <Edit /> : <Add />}
        onClick={onOpen}
        icon={isValid ? <ProofValidIcon icon={<Identity />} /> : <Identity />}
        title={
          <Box
            as="span"
            color={isValid ? theme.colors.status.positive.type : ''}
          >
            {t(i18nKeys.common.proof_of_identity)} {shareholderName}
          </Box>
        }
        introduction={t(
          i18nKeys.onboarding.documents.cards.identity.introduction,
          { username: shareholderName },
        )}
        data-cy="proof-identity-CardInfoTile"
      >
        <Text size="Small" color={theme.colors.status.negative.type}>
          {t(errorMessage)}
        </Text>
      </CardInfoTile>
      <Drawer
        title={
          <>
            {t(i18nKeys.common.proof_of_identity)} - {shareholderName}
          </>
        }
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
      >
        <Formik<OnboardingDocumentIdentityFormData>
          initialValues={{
            documentType: mainValues.documentType,
            backFile: mainValues.backFile,
            frontFile: mainValues.frontFile,
            passportFile: mainValues.passportFile,
            imageClear: alreadyCompleted,
            imageCopy: alreadyCompleted,
            imageColor: alreadyCompleted,
            imagePdf: alreadyCompleted,
            idBackUrl: mainValues.idBackUrl,
            idFrontUrl: mainValues.idFrontUrl,
            passportUrl: mainValues.passportUrl,
          }}
          validate={validateSchema(validationShareholderDocumentsIdentity)}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, values, isValid }) => (
            <>
              <Drawer.Body data-cy="proof-identity-DrawerBody">
                <Form noValidate id="identity-form">
                  <Text size="Body1" color="ink.dark" mb="3rem">
                    {t(
                      i18nKeys.onboarding.documents.cards.identity.introduction,
                      {
                        username: shareholderName,
                      },
                    )}
                  </Text>

                  <Text>
                    {t(i18nKeys.onboarding.documents.proof_of_identity)}
                  </Text>
                  <Box w={{ sm: '100%', md: '50%' }}>
                    <SelectField
                      name="documentType"
                      label={t(i18nKeys.common.document_type)}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        const value = event.target.value;

                        setMainValues('documentType', value);
                        if (value === ShareholderDocumentTypeForm.IDENTITY) {
                          setFieldValue('passportFile', []);
                        } else {
                          setFieldValue('frontFile', []);
                          setFieldValue('backFile', []);
                        }
                      }}
                    >
                      {SELECTABLE_DOCUMENTS.map((document) => (
                        <option value={document} key={document}>
                          {t(i18nKeys.onboarding.documents[document])}
                        </option>
                      ))}
                    </SelectField>
                  </Box>

                  <Heading size="Subhead" mt="2rem">
                    {t(i18nKeys.common.front)}
                  </Heading>
                  <UploadPreview
                    options={UPLOAD_FILE_OPTIONS_ID}
                    uploadName={
                      values.documentType ===
                      ShareholderDocumentTypeForm.PASSPORT
                        ? 'passportFile'
                        : 'frontFile'
                    }
                    previewTitle={
                      values.documentType ===
                      ShareholderDocumentTypeForm.PASSPORT
                        ? t(i18nKeys.onboarding.documents.passport_example)
                        : t(i18nKeys.onboarding.documents.identity_card_example)
                    }
                    uploadURL={
                      values.documentType ===
                      ShareholderDocumentTypeForm.PASSPORT
                        ? values.passportUrl
                        : values.idFrontUrl
                    }
                    previewURL={
                      values.documentType ===
                      ShareholderDocumentTypeForm.PASSPORT
                        ? FIREBASE_STORAGE_FILES.images.PASSPORT_EXAMPLE
                        : FIREBASE_STORAGE_FILES.images.ID_EXAMPLE_FRONT
                    }
                  />

                  {values.documentType ===
                  ShareholderDocumentTypeForm.IDENTITY ? (
                    <>
                      <Heading size="Subhead" mt="4rem">
                        {t(i18nKeys.common.back)}
                      </Heading>
                      <UploadPreview
                        options={UPLOAD_FILE_OPTIONS_ID}
                        uploadName="backFile"
                        previewTitle={t(
                          i18nKeys.onboarding.documents.identity_card_example,
                        )}
                        uploadURL={values.idBackUrl}
                        previewURL={
                          FIREBASE_STORAGE_FILES.images.ID_EXAMPLE_BACK
                        }
                      />
                    </>
                  ) : null}
                  <ProofQualityImage documentType={values.documentType} />
                </Form>
              </Drawer.Body>
              <Drawer.Footer>
                <Flex justify="space-between" width="100%">
                  <Button size="body1" variant="border" onClick={onClose}>
                    {t(i18nKeys.common.cancel)}
                  </Button>
                  <Button
                    form="identity-form"
                    type="submit"
                    variant="primary"
                    size="body1"
                    disabled={!isValid}
                  >
                    {t(i18nKeys.common.submit)}
                  </Button>
                </Flex>
              </Drawer.Footer>
            </>
          )}
        </Formik>
      </Drawer>
    </>
  );
};
