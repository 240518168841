/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { ExpenseCategoryCode } from './expenseCategoryCode';

export type MerchantAllOf = {
  readonly id?: string;
  readonly merchant_reference?: string;
  readonly merchant_name?: string;
  provider_name?: string;
  expense_category_code?: ExpenseCategoryCode;
};
