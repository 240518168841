import { Box } from '@chakra-ui/layout';
import {
  MerchantCategoryRestrictionGroupsGetParams,
  MerchantRestrictionGroup,
} from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Drawer, SearchField } from '@libs/ui/components';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RestrictionGroupType } from '../restriction-group.type';
import { RestrictionGroupDetailsSearchResult } from './restriction-group-details-search-result';

type RestrictionGroupSearchDrawerProps = {
  restrictionGroup: MerchantRestrictionGroup;
  isOpen: boolean;
  onClose: () => void;
  type: RestrictionGroupType;
  RestrictionGroupQueryKey: (
    | string
    | MerchantCategoryRestrictionGroupsGetParams
  )[];
};

export const RestrictionGroupSearchDrawer = ({
  restrictionGroup,
  isOpen,
  onClose,
  type,
  RestrictionGroupQueryKey,
}: RestrictionGroupSearchDrawerProps) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string[]>([]);

  const submitHandler = ({ q }) => {
    if (q?.length > 0) {
      // removes whitespaces and duplicates
      const cleanQuery = q.match(/([^\s,]+)(?!.*\1)/g);
      setSearchQuery(cleanQuery);
    } else {
      setSearchQuery([]);
    }
  };

  return (
    <Drawer
      title={t(i18nKeys.common.table.search)}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Formik
        initialValues={{
          q: '',
        }}
        onSubmit={submitHandler}
      >
        {({ dirty, setFieldValue }) => (
          <Form noValidate>
            <Drawer.Body>
              <Box my="2rem">
                <Box>
                  <SearchField
                    py="1.5rem"
                    name="q"
                    dirty={dirty}
                    setFieldValue={setFieldValue}
                    placeholder={t(
                      i18nKeys.common.table.merchants.search_placeholder,
                    )}
                  />
                  <RestrictionGroupDetailsSearchResult
                    searchQuery={searchQuery}
                    type={type}
                    restrictionGroupId={restrictionGroup.id}
                    RestrictionGroupQueryKey={RestrictionGroupQueryKey}
                  />
                </Box>
              </Box>
            </Drawer.Body>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};
