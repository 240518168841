import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Drawer, MultiSelect } from '@libs/ui/components';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
//import { useLanguage } from '@libs/core/i18n';
import { useCreateForm } from '@libs/dashboard-core/utils/formik';
import { useFormik } from 'formik';
import {
  useImcclRestrictionGet,
  useImcclRestrictionCreate,
} from '@libs/api-v2/endpoints/imccl-restrictions/imccl-restrictions';
import { useImcclRulesetAllGet } from '@libs/api-v2/endpoints/imccl-rulesets/imccl-rulesets';

type RestrictionDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  restrictionId?: string;
};

export const RestrictionDrawer = ({
  isOpen,
  onClose,
  refetch,
  restrictionId,
}: RestrictionDrawerProps) => {
  // Attributes
  const { t } = useTranslation();
  const toast = useToast();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const [rulesets, setRulesets] = useState<any[]>();

  const { data: restriction } = useImcclRestrictionGet(restrictionId);
  const { mutateAsync: createRestriction } = useImcclRestrictionCreate();
  const { data: allRulesets } = useImcclRulesetAllGet();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    rulesetIds: Yup.array().of(Yup.string()).required('Required'),
  });

  const { values, errors, setFieldError, ...rest } = useFormik<{
    name: string;
    rulesets: string[];
  }>({
    initialValues: {
      name: restriction?.name ?? '',
      rulesets: rulesets,
    },
    enableReinitialize: true,
    onSubmit: handleCreateRestriction,
    validationSchema: validationSchema,
  });
  const form = useCreateForm(values, { errors, ...rest });

  // Functions
  function handleCreateRestriction(value) {
    createRestriction(
      {
        data: { name: value.name, ruleset_ids: value.rulesets },
      },
      {
        onSuccess: async () => {
          onClose();
          await refetch();
          toast({
            title: t(i18nKeys.operator.rulesets.toast.creation.success),
            description: '',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        },
      },
    );
  }

  // Effect;
  useEffect(() => {
    if (restriction?.restriction_json !== undefined) {
      const rulesetJson = JSON.parse(restriction?.restriction_json);
      setRulesets(rulesetJson.map((elt) => elt.metadata.rulesetId));
    }
  }, [restriction]);

  // Render
  return (
    <Drawer
      title={
        restrictionId
          ? `${t(i18nKeys.operator.ruleset.drawer.title.details)}${
              restriction?.name
            }`
          : 'Ajouter une restriction' //t(i18nKeys.operator.restrictions.button.create)
      }
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box>
        <Drawer.Body>
          <VStack alignItems={'flex-start'}>
            <Text color="ink.dark" fontSize={'2rem'} my={'2rem'}>
              {t(i18nKeys.common.metadata)}
            </Text>
            <Stack width={'full'} pb={'2rem'}>
              <Text>{t(i18nKeys.common.full_name)}</Text>
              <FormControl
                isInvalid={errors?.name !== undefined && errors?.name !== ''}
              >
                <Input
                  name="name"
                  borderRadius={'.5rem'}
                  placeholder={t(i18nKeys.drawer.rulesets.placeholder.name)}
                  py={'2rem'}
                  onChange={(value) =>
                    form.name.handleSelect(value.target.value)
                  }
                  value={values.name}
                  disabled={restrictionId && true}
                />
                {errors?.name !== undefined && errors?.name !== '' && (
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                )}
              </FormControl>
            </Stack>
            <Text color="ink.dark" fontSize={'2rem'} pb={'1rem'}>
              {t(i18nKeys.operator.rulesets.title)}
            </Text>
            <FormControl
              isInvalid={
                errors?.rulesets !== undefined && errors?.rulesets !== ''
              }
            >
              <MultiSelect
                options={allRulesets?.rulesets?.map((elt) => elt.id) ?? []}
                optionNames={
                  allRulesets?.rulesets?.map((elt) => ({
                    id: elt.id,
                    name: elt.name,
                  })) ?? []
                }
                setSelectedItems={(value) => form.rulesets.handleSelect(value)}
              />
              {errors?.rulesets !== undefined && errors?.rulesets !== '' && (
                <FormErrorMessage>{errors.rulesets}</FormErrorMessage>
              )}
            </FormControl>
          </VStack>
        </Drawer.Body>
        {!restrictionId && (
          <Drawer.Footer>
            <Flex w="100%" justify="space-between">
              <Button size="body2" w="auto" variant="border" onClick={onClose}>
                {t(i18nKeys.common.cancel)}
              </Button>
              <Button
                type="submit"
                size="body2"
                w="auto"
                variant="primary"
                loadingText={t(i18nKeys.common.submit)}
                onClick={() => handleCreateRestriction(values)}
              >
                {t(i18nKeys.common.submit)}
              </Button>
            </Flex>
          </Drawer.Footer>
        )}
      </Box>
    </Drawer>
  );
};
