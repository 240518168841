/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type Role = typeof Role[keyof typeof Role];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Role = {
  USER: 'USER',
  USER_BYPASS_SSO: 'USER_BYPASS_SSO',
  OPERATOR_READ: 'OPERATOR_READ',
  OPERATOR_WRITE: 'OPERATOR_WRITE',
  OPERATOR_ADMIN: 'OPERATOR_ADMIN',
} as const;
