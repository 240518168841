import {
  Box,
  Button,
  ListItem,
  Text,
  UnorderedList,
  useTheme,
} from '@chakra-ui/react';
import {
  FILE_INVALID_TYPE,
  FILE_TOO_LARGE,
  FILE_TOO_SMALL,
  TOO_MANY_FILES,
} from '@libs/core/constants';
import { i18nKeys as i18nKeysCore } from '@libs/core/i18n/dashboard-core';
import { i18nKeys } from '@libs/core/i18n/ui';
import { useIcon } from '@libs/core/theme/utils';
import { useField } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import {
  DropEvent,
  DropzoneOptions,
  FileError,
  FileRejection,
  useDropzone,
} from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { PreviewAfterUpload } from '../../preview-after-upload';
import { FormControl } from '../components/form-control';
import { BaseProps } from '../form.types';
import { Container } from './upload-field.style';
import { FileExtensionTypeToExtension } from '../../thumbnail/thumbnail.constant';

export type UploadFieldProps = BaseProps & {
  options?: DropzoneOptions;
  explanations?: string;
  fileURL?: string;
};

export const UploadField = ({
  explanations,
  fileURL,
  ...props
}: UploadFieldProps) => {
  const { name, options = { maxFiles: 1 }, ...formControlProps } = props;
  const [field, meta, helpers] = useField(name);
  const [files, setFiles] = useState(field.value);
  const [preview, setPreview] = useState<boolean>(!!fileURL);
  const Attachment = useIcon('Attachment');
  const UploadIcon = useIcon('Upload');
  const theme = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    helpers.setValue(files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    setFiles(field.value);
    if (field.value.length > 0) {
      setPreview(true);
    } else if (!fileURL) {
      setPreview(false);
    }
  }, [field.value, fileURL]);

  const onDropAccepted = useCallback((files: File[], event: DropEvent) => {
    if (options?.onDropAccepted) {
      options.onDropAccepted(files, event);
    }

    setFiles((actualFiles = []) => {
      const newFiles = [
        ...actualFiles.filter(
          (file) => !files.some((f) => file.name === f.name),
        ),
        ...files,
      ];

      return newFiles.slice(newFiles.length - options.maxFiles);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    ...options,
    onDropAccepted,
  });

  const translateErrors = (
    file: FileRejection,
    meta?: { accept: string | string[] },
  ) => {
    return file.errors.map((error: FileError) => {
      switch (error.code) {
        case FILE_INVALID_TYPE:
          // convert to array
          if (typeof meta.accept === 'string') {
            // expected extensions are then a comma separated list
            meta.accept = meta.accept.split(',');
          }
          return t(i18nKeys.common.errors.file_invalid_type, {
            types: meta.accept
              .map((type) =>
                FileExtensionTypeToExtension[type.trim()].toUpperCase(),
              )
              .join(', '),
          });
        case FILE_TOO_LARGE:
          return t(i18nKeys.common.errors.file_too_large);
        case FILE_TOO_SMALL:
          return t(i18nKeys.common.errors.file_too_small);
        case TOO_MANY_FILES:
          return t(i18nKeys.common.errors.too_many_files);
        default:
          return error.code;
      }
    });
  };

  return (
    <FormControl id={name} meta={meta} isEmpty={false} {...formControlProps}>
      <Container {...getRootProps()}>
        {
          // Preview new upload files
          field.value.length > 0 && preview ? (
            field.value.map((file) => (
              <PreviewAfterUpload
                key={file.name}
                fileURL={URL.createObjectURL(file)}
                name={file.name}
                type={file.type}
                onClose={() => setPreview(false)}
              />
              // Preview existing file
            ))
          ) : preview ? (
            <PreviewAfterUpload
              fileURL={fileURL}
              name={name}
              onClose={() => setPreview(false)}
            />
          ) : (
            <Box textAlign="center" m="0 auto" width="100%">
              <input name={name} {...getInputProps()} />
              {explanations ? (
                <Text w="100%">{explanations}</Text>
              ) : (
                <>
                  <Box pos="relative" w="3.6rem" h="3.6rem" m="0 auto">
                    <UploadIcon fill={theme.colors.ink.dark} />
                  </Box>
                  <Text w="100%">
                    {t(i18nKeysCore.common.upload_explaination_part1)}
                  </Text>
                  <Button
                    mt="1rem"
                    px="1rem"
                    display="inline-block"
                    size="small"
                    height="auto"
                    minHeight="3rem"
                    variant="secondary"
                    width="auto"
                    whiteSpace="normal"
                  >
                    {t(i18nKeysCore.common.upload_explaination_part2)}
                  </Button>
                </>
              )}
            </Box>
          )
        }
      </Container>

      <UnorderedList
        listStyleType="none"
        m="0"
        p="0"
        data-cy="upload-field-error-list"
      >
        {fileRejections?.map((file) => (
          <ListItem key={file.file.name}>
            <Box
              as="span"
              verticalAlign="bottom"
              display="inline-block"
              mr=".5rem"
            >
              <Attachment
                fill={theme.colors.status.negative.type}
                height="2rem"
                width="2rem"
              />
            </Box>
            <Text
              color={theme.colors.status.negative.type}
              display="inline-block"
              size="Small"
            >
              {file.file.name}
              {' - '}{' '}
              {translateErrors(file, { accept: options.accept }).join(' ')}
            </Text>
          </ListItem>
        ))}
      </UnorderedList>
    </FormControl>
  );
};
