import { RegistrationGetParams } from '@libs/api/models';
import { QueryClient } from '@tanstack/react-query';
import { NavigateFunction } from 'react-router-dom';

export const selectCell = (
  queryKey: (string | RegistrationGetParams)[],
  queryClient: QueryClient,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  updater: any,
  navigate: NavigateFunction,
  path: string,
) => {
  // force cache refresh
  queryClient.setQueryData(queryKey, updater);
  queryClient.invalidateQueries(queryKey);
  navigate(path);
};
