export type LoginForm = {
  email: string;
  password: string;
};

export type ResetPasswordForm = {
  email: string;
};

export type ConfirmResetPasswordForm = {
  password: string;
  passwordConfirmed: string;
};

export type CreateUserForm = {
  email: string;
  password: string;
  passwordConfirmed: string;
};

export type SetPasswordForm = {
  email: string;
  password: string;
  passwordConfirmed: string;
};

export type SetPasswordTokenClaims = {
  email: string;
  firstName: string;
  lastName: string;
};
