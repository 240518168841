/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { Audit } from './audit';
import type { RegistrationV1AllOf } from './registrationV1AllOf';

export type RegistrationV1 = Audit & RegistrationV1AllOf;
