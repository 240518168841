/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export interface ReportGraphData {
  organisation_id?: string;
  program_name?: string;
  group_name?: string;
  service_key?: string;
  category_key?: string;
  /** Date so that the frontend will apply the appropriate localization and formating. If the scale is year then we send first day of the year if the scale is month then we send first day of the month if the scale is week then we send first day of the week */
  date?: string;
  /** in g */
  emissions_total?: number;
  /** in g */
  emissions_average?: number;
  /** in g */
  emissions_saved_total?: number;
  /** number of employee with expenses */
  employees_count?: number;
  /** number of expenses */
  expenses_count?: number;
  /** total amount of expenses in eurocent */
  expenses_total?: number;
  /** total amount of refunds in eurocent */
  refunds_total?: number;
  /** total distance in meters */
  distance_total?: number;
}
