/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type ExpenseLabel = typeof ExpenseLabel[keyof typeof ExpenseLabel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseLabel = {
  PROFESSIONAL: 'PROFESSIONAL',
  PRIVATE: 'PRIVATE',
  COMMUTE: 'COMMUTE',
} as const;
