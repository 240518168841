import {
  Box,
  Flex,
  Heading,
  Image,
  Link as ChakraLink,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useGetCurrentUser, useOrganisationsGet } from '@libs/api/endpoints';
import { Status } from '@libs/api/models';
import { useLanguage } from '@libs/core/i18n';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useImage } from '@libs/core/theme/utils';
import { getEnvByLanguage } from '@libs/dashboard-core/utils/environment';
import { Loader, PageHeader } from '@libs/ui/components';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardLayout } from '../components/dashboard-layout';
import { OrganisationTable } from './organisation/components/organisation-table';

export const DashboardOperatorPage = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const faqUrl = getEnvByLanguage(language, 'NX_FAQ_URL');
  const assistantImg = useImage('assistant');
  const { data: user, isLoading: isLoadingUser } = useGetCurrentUser();

  // fetch  organisations
  const { data: refusedOrganisations, isLoading: isLoadingRefusedOrgs } =
    useOrganisationsGet({ page_size: 1, status: Status.REFUSED });
  const { data: inReviewOrganisations, isLoading: isLoadingInReviewOrgs } =
    useOrganisationsGet({ page_size: 1, status: Status.IN_REVIEW });

  if (isLoadingUser) {
    return (
      <Box py="10rem" maxW="69rem" position="relative">
        <Loader spinnerProps={{ size: 'md' }} modRight>
          {t(i18nKeys.common.loading)}
        </Loader>
      </Box>
    );
  }

  const title: ReactNode = (
    <>
      {t(i18nKeys.dashboard.hello)} {user.first_name},
      <br />
      {t(i18nKeys.operator.dashboard.welcome)}
    </>
  );

  return (
    <DashboardLayout>
      <PageHeader
        size="Large"
        title={title}
        introduction={t(i18nKeys.operator.dashboard.welcome_page.introduction)}
      />

      <Heading variant="title3" fontStyle="italic" fontWeight={700} mt="6rem">
        {t(i18nKeys.operator.dashboard.table.refused_organisations)}
      </Heading>
      <Text mt="1rem">
        {t(i18nKeys.operator.dashboard.table.table_introduction, {
          number:
            isLoadingInReviewOrgs || isLoadingRefusedOrgs
              ? 0
              : inReviewOrganisations.meta.total_items +
                refusedOrganisations.meta.total_items,
        })}
      </Text>
      <OrganisationTable
        hasSearch={false}
        filters={[
          { name: 'status', values: [Status.IN_REVIEW, Status.REFUSED] },
        ]}
      />
      <Flex mt="4rem" justifyContent="flex-end" textAlign="end">
        <Stack mr="1rem">
          <Heading>{t(i18nKeys.onboarding.success.question)}</Heading>
          <Text color="ink.dark">
            {t(i18nKeys.common.consult)}{' '}
            <Link href={faqUrl} as="a" target="_blank">
              {t(i18nKeys.common.faq)}
            </Link>{' '}
            {t(i18nKeys.onboarding.success.contact)}{' '}
            <ChakraLink href={`mailto:${process.env.NX_EMAIL_SUPPORT}`}>
              {t(i18nKeys.common.support)}
            </ChakraLink>
          </Text>
        </Stack>
        <Box boxSize={{ base: '4rem', md: '5rem' }}>
          <Image
            border=".4rem solid white"
            backgroundColor="white"
            boxSize="100%"
            alt="Your dashboard Assistant"
            borderRadius="full"
            src={assistantImg}
            zIndex="55"
          />
        </Box>
      </Flex>
    </DashboardLayout>
  );
};
