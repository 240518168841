/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export interface PayrollConfiguration {
  organisation_id?: string;
  hris_id?: string;
  cut_off_day?: number;
  schedule_day?: number;
  /** Time at which automated report generation is run, in Europe/Brussels timezone. */
  schedule_hour?: number;
  is_active?: boolean;
}
