/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Language } from './component-common/language';

export type GetProviderDetailsParams = {
  /**
   * Language
   */
  language: Language;
};
