/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

/**
 * used as filter to fetch merchants not assigned yet to a service/provider
 */
export type QueryUnassignedParameter = boolean;
