/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

/**
 * - EXPENSE_RECEIPT: Attachment to prove the expense
- EXPENSE_MEMO: Justification why the expense was made
- EXPENSE_EXPENSE_CODE: Expense code

 */
export type PolicyRequiredField =
  typeof PolicyRequiredField[keyof typeof PolicyRequiredField];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PolicyRequiredField = {
  EXPENSE_RECEIPT: 'EXPENSE_RECEIPT',
  EXPENSE_MEMO: 'EXPENSE_MEMO',
  EXPENSE_EXPENSE_CODE: 'EXPENSE_EXPENSE_CODE',
} as const;
