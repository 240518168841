/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  ImcclRestrictions,
  ImcclRestriction,
  CreateImcclRestrictionPayload,
  CreateImcclRestrictionRequest,
  ImcclRestrictionActivateParams,
} from '../../models';
import { customInstance } from '../../config';

export const imcclRestrictionAllGet = (signal?: AbortSignal) => {
  return customInstance<ImcclRestrictions>({
    url: `/web/imccl-restrictions`,
    method: 'get',
    signal,
  });
};

export const getImcclRestrictionAllGetQueryKey = () =>
  [`/web/imccl-restrictions`] as const;

export const getImcclRestrictionAllGetQueryOptions = <
  TData = Awaited<ReturnType<typeof imcclRestrictionAllGet>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof imcclRestrictionAllGet>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<typeof imcclRestrictionAllGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getImcclRestrictionAllGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof imcclRestrictionAllGet>>
  > = ({ signal }) => imcclRestrictionAllGet(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ImcclRestrictionAllGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof imcclRestrictionAllGet>>
>;
export type ImcclRestrictionAllGetQueryError = unknown;

export const useImcclRestrictionAllGet = <
  TData = Awaited<ReturnType<typeof imcclRestrictionAllGet>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof imcclRestrictionAllGet>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getImcclRestrictionAllGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const imcclRestrictionCreate = (
  createImcclRestrictionPayload: CreateImcclRestrictionPayload,
) => {
  return customInstance<ImcclRestriction>({
    url: `/web/imccl-restrictions`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: createImcclRestrictionPayload,
  });
};

export const getImcclRestrictionCreateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof imcclRestrictionCreate>>,
    TError,
    { data: CreateImcclRestrictionPayload },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof imcclRestrictionCreate>>,
  TError,
  { data: CreateImcclRestrictionPayload },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof imcclRestrictionCreate>>,
    { data: CreateImcclRestrictionPayload }
  > = (props) => {
    const { data } = props ?? {};

    return imcclRestrictionCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ImcclRestrictionCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof imcclRestrictionCreate>>
>;
export type ImcclRestrictionCreateMutationBody = CreateImcclRestrictionPayload;
export type ImcclRestrictionCreateMutationError = unknown;

export const useImcclRestrictionCreate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof imcclRestrictionCreate>>,
    TError,
    { data: CreateImcclRestrictionPayload },
    TContext
  >;
}) => {
  const mutationOptions = getImcclRestrictionCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const imcclRestrictionGet = (
  imcclRestrictionId: string,
  signal?: AbortSignal,
) => {
  return customInstance<ImcclRestriction>({
    url: `/web/imccl-restrictions/${imcclRestrictionId}`,
    method: 'get',
    signal,
  });
};

export const getImcclRestrictionGetQueryKey = (imcclRestrictionId: string) =>
  [`/web/imccl-restrictions/${imcclRestrictionId}`] as const;

export const getImcclRestrictionGetQueryOptions = <
  TData = Awaited<ReturnType<typeof imcclRestrictionGet>>,
  TError = unknown,
>(
  imcclRestrictionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof imcclRestrictionGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof imcclRestrictionGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getImcclRestrictionGetQueryKey(imcclRestrictionId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof imcclRestrictionGet>>
  > = ({ signal }) => imcclRestrictionGet(imcclRestrictionId, signal);

  return { queryKey, queryFn, enabled: !!imcclRestrictionId, ...queryOptions };
};

export type ImcclRestrictionGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof imcclRestrictionGet>>
>;
export type ImcclRestrictionGetQueryError = unknown;

export const useImcclRestrictionGet = <
  TData = Awaited<ReturnType<typeof imcclRestrictionGet>>,
  TError = unknown,
>(
  imcclRestrictionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof imcclRestrictionGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getImcclRestrictionGetQueryOptions(
    imcclRestrictionId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const imcclRestrictionByProgramCreate = (
  programId: string,
  createImcclRestrictionRequest: CreateImcclRestrictionRequest,
) => {
  return customInstance<ImcclRestriction>({
    url: `/web/programs/${programId}/imccl-restrictions`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: createImcclRestrictionRequest,
  });
};

export const getImcclRestrictionByProgramCreateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof imcclRestrictionByProgramCreate>>,
    TError,
    { programId: string; data: CreateImcclRestrictionRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof imcclRestrictionByProgramCreate>>,
  TError,
  { programId: string; data: CreateImcclRestrictionRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof imcclRestrictionByProgramCreate>>,
    { programId: string; data: CreateImcclRestrictionRequest }
  > = (props) => {
    const { programId, data } = props ?? {};

    return imcclRestrictionByProgramCreate(programId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ImcclRestrictionByProgramCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof imcclRestrictionByProgramCreate>>
>;
export type ImcclRestrictionByProgramCreateMutationBody =
  CreateImcclRestrictionRequest;
export type ImcclRestrictionByProgramCreateMutationError = unknown;

export const useImcclRestrictionByProgramCreate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof imcclRestrictionByProgramCreate>>,
    TError,
    { programId: string; data: CreateImcclRestrictionRequest },
    TContext
  >;
}) => {
  const mutationOptions =
    getImcclRestrictionByProgramCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const imcclRestrictionActivate = (
  programId: string,
  params: ImcclRestrictionActivateParams,
) => {
  return customInstance<void>({
    url: `/web/programs/${programId}/imccl-restrictions/activate`,
    method: 'post',
    params,
  });
};

export const getImcclRestrictionActivateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof imcclRestrictionActivate>>,
    TError,
    { programId: string; params: ImcclRestrictionActivateParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof imcclRestrictionActivate>>,
  TError,
  { programId: string; params: ImcclRestrictionActivateParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof imcclRestrictionActivate>>,
    { programId: string; params: ImcclRestrictionActivateParams }
  > = (props) => {
    const { programId, params } = props ?? {};

    return imcclRestrictionActivate(programId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ImcclRestrictionActivateMutationResult = NonNullable<
  Awaited<ReturnType<typeof imcclRestrictionActivate>>
>;

export type ImcclRestrictionActivateMutationError = unknown;

export const useImcclRestrictionActivate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof imcclRestrictionActivate>>,
    TError,
    { programId: string; params: ImcclRestrictionActivateParams },
    TContext
  >;
}) => {
  const mutationOptions = getImcclRestrictionActivateMutationOptions(options);

  return useMutation(mutationOptions);
};
