/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { ProgramGroupAllOf } from './programGroupAllOf';
import type { GroupData } from './groupData';
import type { ProgramGroupData } from './programGroupData';

export type ProgramGroup = ProgramGroupAllOf & GroupData & ProgramGroupData;
