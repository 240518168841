/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { ProgramGroupWithMeta } from './programGroupWithMeta';
import type { Meta } from './meta';

export interface ProgramGroupGetResponse {
  data?: ProgramGroupWithMeta[];
  meta?: Meta;
}
