/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { RecurringExpenseInterval } from './recurringExpenseInterval';
import type { ExpenseAttachment } from './expenseAttachment';
import type { ExpenseInterface } from './expenseInterface';
import type { ManualSplitData } from './manualSplitData';
import type { ExpenseRuleExecutionReport } from './expenseRuleExecutionReport';

export interface ExpenseRule {
  readonly id?: Uuid;
  /** The starting date from which the expense rule to start to be executed, creating expenses (repeated according with the interval attribute). */
  start_date: string;
  /** The date on which the expense rule will stop being executed (no more expenses will be created). */
  end_date: string;
  interval: RecurringExpenseInterval;
  membership_id: Uuid;
  expense_total_amount: number;
  expense_distance?: number;
  /** Explanation by the employee why they are making this expense (e.g. Client meeting with Skipr). */
  expense_justification?: string;
  expense_program_id: Uuid;
  expense_service_id: Uuid;
  expense_product_id: Uuid;
  expense_provider_id: Uuid;
  expense_title?: string;
  expense_expense_code?: string;
  expense_attachments?: ExpenseAttachment[];
  expense_source?: string;
  expense_interface: ExpenseInterface;
  expense_manual_split_data?: ManualSplitData;
  /** Field that lets the admin to define if an expense should be refunded in the employee's payroll or not. */
  refundable: boolean;
  is_archived?: boolean;
  readonly last_execution_status?: string;
  readonly last_execution_at?: string;
  readonly last_execution_report?: ExpenseRuleExecutionReport;
}
