/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export interface ReinitializeTestUserRequest {
  /** if true, finalize the onboarding of the user */
  onboard_user?: boolean;
  /** if true, initiate fake poppy credentials for the user */
  init_poppy_credentials?: boolean;
}
