/* eslint-disable  @typescript-eslint/no-explicit-any */
import { CommonImages } from '@libs/core/theme/types';

interface CommonColors {
  background: {
    page: string;
  };
  status: {
    info: {
      light: string;
      normal: string;
      type: string;
    };
    pending: {
      light: string;
      normal: string;
      type: string;
    };
    positive: {
      light: string;
      normal: string;
      type: string;
    };
    negative: {
      light: string;
      normal: string;
      type: string;
    };
  };
  main: {
    primary: {
      lightest: string;
      lighter: string;
      light: string;
      normal: string;
      dark: string;
      darker: string;
    };
    secondary: {
      lighter: string;
      light: string;
      normal: string;
      dark: string;
      darker: string;
    };
    gray: {
      lighter: string;
      light: string;
      normal: string;
      dark: string;
      darker: string;
    };
  };
  ink: {
    white: string;
    lighter: string;
    light: string;
    medium: string;
    dark: string;
    darker: string;
    black: string;
  };
}

export interface CommonHeadings {
  XLarge: string;
  Large: string;
  Title1: string;
  Title2: string;
  Title3: string;
  Headline: string;
  Subhead: string;
}

export interface CommonTheme {
  colors: CommonColors;
  images: CommonImages;
  fonts: { [key: string]: string };
  components: { [key: string]: any };

  breakpoints: {
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
}
