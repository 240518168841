/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Provider } from './provider';
import type { Service } from './service';
import type { ExpenseInterface } from './expenseInterface';
import type { NotionRichText } from './notionRichText';

export interface ServiceDetails {
  provider?: Provider[];
  service?: Service;
  types?: ExpenseInterface[];
  content?: NotionRichText;
  short_description?: string;
  featured_providers?: Provider[];
}
