/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { CheckPolicyRequestAllOf } from './checkPolicyRequestAllOf';
import type { UpsertPolicyRequest } from './upsertPolicyRequest';

export type CheckPolicyRequest = CheckPolicyRequestAllOf & UpsertPolicyRequest;
