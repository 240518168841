/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { CardV1 } from './cardV1';
import type { MetaV1 } from './metaV1';

export interface CardsGetResponseV1 {
  data?: CardV1[];
  meta?: MetaV1;
}
