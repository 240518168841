/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type JourneyTripType =
  typeof JourneyTripType[keyof typeof JourneyTripType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JourneyTripType = {
  COMPLETE: 'COMPLETE',
  PARTIAL: 'PARTIAL',
  TO_STATION: 'TO_STATION',
  TELEWORKING: 'TELEWORKING',
} as const;
