/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export interface ManualSplitConfiguration {
  is_manually_splittable: boolean;
  manual_employer_reimbursement_percentage: number;
}
