import React from 'react';
import { Text } from '@chakra-ui/react';
import { Organisation, Status } from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useCurrentBreakpointValue } from '@libs/core/theme';
import { capitalize } from '@libs/core/utils';
import { getPath, Routes } from '@libs/dashboard-core/routes';
import { selectCell } from '@libs/dashboard-core/utils/select-cell';
import { StatusToTagStatus } from '@libs/dashboard-core/utils/status';
import { Pager, Table, Tag, usePager } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useTable, useDrawer } from '@libs/ui/components/table/table.utils';
import { DrawerType } from '@libs/dashboard-core/pages/dashboard/dashboard.utils';
import { useIcon } from '@libs/core/theme/utils';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { ArchiveEmployeeDrawer } from '../details/components/archive-employee-drawer';
import { EmployeeDetailsDrawer } from '../details/components/employee-details-drawer';
import { getRegistrationGetQueryKey } from '@libs/api/endpoints/memberships/memberships';
import { useCardsGet, useRegistrationsGet } from '@libs/api-v2/endpoints';
import { CardExpiration } from '../details/components/card-status';

type OrganisationDetailsEmployeesProps = {
  organisation?: Organisation;
};

export const EmployeesTable = ({
  organisation,
}: OrganisationDetailsEmployeesProps) => {
  const Edit = useIcon('Edit');
  const Blocked = useIcon('Blocked');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { pageNumber, pageSize, setPageNumber, setPageSize } = usePager();
  const { isMobile, isTablet } = useCurrentBreakpointValue();
  const { searchQuery, setSearchQuery, useQueryParams } = useTable({
    setPageNumber,
    setPageSize,
  });
  const {
    activeDrawerIndex,
    drawerHandler,
    isOpen,
    onClose,
    selectedDrawerType,
  } = useDrawer();

  const submitHandler = ({ q }) => {
    setSearchQuery(q);
  };

  const {
    // is false when fetching cached values
    isLoading,
    refetch,
    data: employees,
    // defaults to true so long as enabled is set to false
    // is true if the query is currently fetching, including background fetching
    isFetching,
  } = useRegistrationsGet(
    {
      organisation_id: organisation ? organisation.id : null,
      q: searchQuery,
      page_number: pageNumber,
      page_size: pageSize,
    },
    { query: { keepPreviousData: true } },
  );

  const { isLoading: isLoadingCards, data: cards } = useCardsGet(
    {
      membership_ids: employees?.data?.map(
        (employee) => employee.membership_id,
      ),
    },
    {
      query: {
        enabled: !!employees?.data,
        keepPreviousData: true,
      },
    },
  );

  useQueryParams({
    pageSize,
    pageNumber,
    setPageSize,
    refetch,
    totalPages: employees?.meta?.total_pages,
  });

  const headers: TableHeaders = (isMobile && [
    t(i18nKeys.common.full_name),
    t(i18nKeys.common.organisation_name),
    t(i18nKeys.common.status),
    '',
  ]) ||
    (isTablet && [
      t(i18nKeys.common.full_name),
      t(i18nKeys.common.organisation_name),
      t(i18nKeys.common.status),
      '',
    ]) || [
      t(i18nKeys.common.full_name),
      t(i18nKeys.common.phone_number),
      t(i18nKeys.common.organisation_name),
      t(i18nKeys.common.card_expiration_status),
      t(i18nKeys.common.internal_payroll_id),
      t(i18nKeys.common.status),
      '',
    ];

  return (
    <>
      <Table
        onSubmit={submitHandler}
        initialValues={{ q: '' }}
        headers={headers}
        hasSearch
        searchPlaceholder={t(i18nKeys.common.table.employee.search_placeholder)}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      >
        <Table.TBody
          isLoading={(isLoading || isFetching) && !employees}
          colSpan={headers.length}
          isEmpty={
            !isLoading &&
            !isFetching &&
            (employees?.data?.length === 0 || !employees)
          }
        >
          {employees?.data?.map((employee, index) => {
            const card = cards?.data?.find(
              (card) => card.membership_id === employee.membership_id,
            );
            return (
              <Table.TR
                key={employee.membership_id}
                selectable
                index={employee.membership_id}
                onClick={() =>
                  selectCell(
                    getRegistrationGetQueryKey(employee.membership_id),
                    queryClient,
                    employee,
                    navigate,
                    getPath(Routes.DASHBOARD_EMPLOYEES_DETAILS, {
                      params: {
                        membershipId: employee.membership_id,
                      },
                    }),
                  )
                }
              >
                <Table.TD>
                  <Text size="Body2">
                    {capitalize(employee.first_name)}{' '}
                    {capitalize(employee.last_name)}
                  </Text>
                  <Text size="Small" color="ink.dark">
                    {t(i18nKeys.common.role[employee.role])}
                  </Text>
                </Table.TD>
                {!isMobile && !isTablet && (
                  <Table.TD>{employee.phone_number}</Table.TD>
                )}
                <Table.TD>{capitalize(employee.organisation_name)}</Table.TD>
                <Table.TD>
                  {card && !isLoadingCards && <CardExpiration card={card} />}
                </Table.TD>
                <Table.TD>{capitalize(employee.internal_payroll_id)}</Table.TD>
                <Table.TD>
                  <Tag
                    hasIcon
                    tooltipLabel={
                      <Text size="Small">
                        {t(
                          i18nKeys.common.TagStatus[
                            employee.is_suspended
                              ? Status.CANCELED
                              : employee.status
                          ],
                        )}
                      </Text>
                    }
                    status={
                      // todo: fix this condition, I imagine is_suspended has been removed?
                      //employee.is_suspended
                      //  ? StatusToTagStatus[Status.CANCELED]
                      //  : StatusToTagStatus[employee.status]
                      StatusToTagStatus[
                        employee.is_suspended
                          ? Status.CANCELED
                          : employee.status
                      ]
                    }
                  />
                </Table.TD>
                <Table.Action
                  actions={[
                    {
                      icon: <Edit w="2rem" h="2rem" />,
                      onClick: () => {
                        drawerHandler({
                          index,
                          drawerType: DrawerType.edit,
                        });
                      },
                      tooltipLabel: (
                        <Text size="small">
                          {t(i18nKeys.operator.employees.details.title)}
                        </Text>
                      ),
                    },
                    {
                      icon: <Blocked w="2rem" h="2rem" />,
                      onClick: () => {
                        drawerHandler({
                          index,
                          drawerType: DrawerType.conversion,
                        });
                      },
                      tooltipLabel: (
                        <Text size="small">
                          {t(i18nKeys.operator.employees.archive.title)}
                        </Text>
                      ),
                    },
                  ]}
                />
              </Table.TR>
            );
          })}
        </Table.TBody>
      </Table>
      {isOpen &&
        employees?.data?.length &&
        ((selectedDrawerType === DrawerType.conversion && (
          <ArchiveEmployeeDrawer
            employee={employees.data[activeDrawerIndex]}
            isOpen={isOpen}
            onClose={onClose}
            refetch={refetch}
          />
        )) || (
          <EmployeeDetailsDrawer
            employee={employees.data[activeDrawerIndex]}
            isOpen={isOpen}
            onClose={onClose}
            refetch={refetch}
          />
        ))}

      {employees && employees?.meta?.total_pages > 1 && (
        <Pager
          setPageNumber={setPageNumber}
          total_items={employees.meta.total_items}
          page_index={employees.meta.page_index}
          total_pages={employees.meta.total_pages}
          mt="2rem"
          page_size={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          isFetching={isFetching}
        />
      )}
    </>
  );
};
