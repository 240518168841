import React from 'react';
import { Text } from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Table } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useTranslation } from 'react-i18next';
import { useIcon } from '@libs/core/theme/utils/useIcon';
import { ImcclRestrictions } from '@libs/api-v2/models';

type RestrictionTableProps = {
  restrictions: ImcclRestrictions;
  isLoading: boolean;
  isFetching: boolean;
  open: () => void;
  setRestrictionId;
};

export const RestrictionTable = ({
  restrictions,
  isLoading,
  isFetching,
  setRestrictionId,
  open,
}: RestrictionTableProps) => {
  // Attributes
  const { t } = useTranslation();

  const Edit = useIcon('Edit');
  const headers: TableHeaders = [
    t(i18nKeys.common.full_name),
    //t(i18nKeys.common.ruleset_ids),
    'Ruleset ids',
    '',
  ];

  // Render
  return (
    <Table
      headers={headers}
      // Uncomment when available
      // hasSearch
      // searchPlaceholder={t(i18nKeys.common.table.employee.search_placeholder)}
      // searchQuery={searchQuery}
      // setSearchQuery={setSearchQuery}
    >
      <Table.TBody
        isLoading={(isLoading || isFetching) && !restrictions}
        colSpan={headers.length}
        isEmpty={
          !isLoading &&
          !isFetching &&
          (restrictions?.restrictions?.length === 0 || !restrictions)
        }
      >
        {restrictions?.restrictions?.map((restriction, index) => {
          const json = JSON.parse(restriction.restriction_json);

          return (
            <Table.TR
              key={restriction.id}
              selectable
              index={restriction.id}
              position="relative"
              transform="scale(1,1)"
            >
              <Table.TD>
                <Text>{restriction.name} </Text>
              </Table.TD>
              <Table.TD>
                <Text>
                  {json.map((elt, index) => {
                    return `${elt.metadata.name}${
                      index !== json.length - 1 ? ', ' : ''
                    }`;
                  })}{' '}
                </Text>
              </Table.TD>
              <Table.Action
                actions={[
                  {
                    icon: <Edit w="2rem" h="2rem" />,
                    onClick: () => {
                      setRestrictionId(restriction.id);
                      open();
                    },
                    tooltipLabel: (
                      <Text size="small">
                        {t(i18nKeys.operator.ruleset.tooltip.edit)}
                      </Text>
                    ),
                  },
                ]}
              />
            </Table.TR>
          );
        })}
      </Table.TBody>
    </Table>
  );
};
