/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { PayrollReportFormattingRuleUserDetails } from './payrollReportFormattingRuleUserDetails';

export interface PayrollReportFormattingRuleDetails {
  formatting_rule_name?: string;
  users?: PayrollReportFormattingRuleUserDetails[];
}
