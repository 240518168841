/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  RulesetArrayResponse,
  RulesetResponse,
  RulesetRequest,
  RulesetIdsRequest,
} from '../../models';
import { customInstance } from '../../config';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
  T,
>() => T extends Y ? 1 : 2
  ? A
  : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<
    { [Q in P]: T[P] },
    { -readonly [Q in P]: T[P] },
    P
  >;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object
        ? NonReadonly<NonNullable<T[P]>>
        : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

export const rulesetsGetTreezor = (signal?: AbortSignal) => {
  return customInstance<RulesetArrayResponse>({
    url: `/web/rulesets`,
    method: 'get',
    signal,
  });
};

export const getRulesetsGetTreezorQueryKey = () => [`/web/rulesets`] as const;

export const getRulesetsGetTreezorQueryOptions = <
  TData = Awaited<ReturnType<typeof rulesetsGetTreezor>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof rulesetsGetTreezor>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<typeof rulesetsGetTreezor>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRulesetsGetTreezorQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof rulesetsGetTreezor>>
  > = ({ signal }) => rulesetsGetTreezor(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type RulesetsGetTreezorQueryResult = NonNullable<
  Awaited<ReturnType<typeof rulesetsGetTreezor>>
>;
export type RulesetsGetTreezorQueryError = unknown;

export const useRulesetsGetTreezor = <
  TData = Awaited<ReturnType<typeof rulesetsGetTreezor>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof rulesetsGetTreezor>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRulesetsGetTreezorQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * create a ruleset.

 */
export const rulesetsCreateTreezor = (
  rulesetRequest: NonReadonly<RulesetRequest>,
) => {
  return customInstance<RulesetResponse>({
    url: `/web/rulesets`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: rulesetRequest,
  });
};

export const getRulesetsCreateTreezorMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rulesetsCreateTreezor>>,
    TError,
    { data: NonReadonly<RulesetRequest> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof rulesetsCreateTreezor>>,
  TError,
  { data: NonReadonly<RulesetRequest> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof rulesetsCreateTreezor>>,
    { data: NonReadonly<RulesetRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return rulesetsCreateTreezor(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RulesetsCreateTreezorMutationResult = NonNullable<
  Awaited<ReturnType<typeof rulesetsCreateTreezor>>
>;
export type RulesetsCreateTreezorMutationBody = NonReadonly<RulesetRequest>;
export type RulesetsCreateTreezorMutationError = unknown;

export const useRulesetsCreateTreezor = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rulesetsCreateTreezor>>,
    TError,
    { data: NonReadonly<RulesetRequest> },
    TContext
  >;
}) => {
  const mutationOptions = getRulesetsCreateTreezorMutationOptions(options);

  return useMutation(mutationOptions);
};
export const rulesetGetByIdTreezor = (
  rulesetId: string,
  signal?: AbortSignal,
) => {
  return customInstance<RulesetResponse>({
    url: `/web/rulesets/${rulesetId}`,
    method: 'get',
    signal,
  });
};

export const getRulesetGetByIdTreezorQueryKey = (rulesetId: string) =>
  [`/web/rulesets/${rulesetId}`] as const;

export const getRulesetGetByIdTreezorQueryOptions = <
  TData = Awaited<ReturnType<typeof rulesetGetByIdTreezor>>,
  TError = unknown,
>(
  rulesetId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof rulesetGetByIdTreezor>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof rulesetGetByIdTreezor>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRulesetGetByIdTreezorQueryKey(rulesetId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof rulesetGetByIdTreezor>>
  > = ({ signal }) => rulesetGetByIdTreezor(rulesetId, signal);

  return { queryKey, queryFn, enabled: !!rulesetId, ...queryOptions };
};

export type RulesetGetByIdTreezorQueryResult = NonNullable<
  Awaited<ReturnType<typeof rulesetGetByIdTreezor>>
>;
export type RulesetGetByIdTreezorQueryError = unknown;

export const useRulesetGetByIdTreezor = <
  TData = Awaited<ReturnType<typeof rulesetGetByIdTreezor>>,
  TError = unknown,
>(
  rulesetId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof rulesetGetByIdTreezor>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRulesetGetByIdTreezorQueryOptions(rulesetId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * update a ruleset

 */
export const rulesetsUpdateTreezor = (
  rulesetId: string,
  rulesetRequest: NonReadonly<RulesetRequest>,
) => {
  return customInstance<RulesetResponse>({
    url: `/web/rulesets/${rulesetId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: rulesetRequest,
  });
};

export const getRulesetsUpdateTreezorMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rulesetsUpdateTreezor>>,
    TError,
    { rulesetId: string; data: NonReadonly<RulesetRequest> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof rulesetsUpdateTreezor>>,
  TError,
  { rulesetId: string; data: NonReadonly<RulesetRequest> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof rulesetsUpdateTreezor>>,
    { rulesetId: string; data: NonReadonly<RulesetRequest> }
  > = (props) => {
    const { rulesetId, data } = props ?? {};

    return rulesetsUpdateTreezor(rulesetId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RulesetsUpdateTreezorMutationResult = NonNullable<
  Awaited<ReturnType<typeof rulesetsUpdateTreezor>>
>;
export type RulesetsUpdateTreezorMutationBody = NonReadonly<RulesetRequest>;
export type RulesetsUpdateTreezorMutationError = unknown;

export const useRulesetsUpdateTreezor = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rulesetsUpdateTreezor>>,
    TError,
    { rulesetId: string; data: NonReadonly<RulesetRequest> },
    TContext
  >;
}) => {
  const mutationOptions = getRulesetsUpdateTreezorMutationOptions(options);

  return useMutation(mutationOptions);
};
export const rulesetDeleteTreezor = (rulesetId: string) => {
  return customInstance<void>({
    url: `/web/rulesets/${rulesetId}`,
    method: 'delete',
  });
};

export const getRulesetDeleteTreezorMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rulesetDeleteTreezor>>,
    TError,
    { rulesetId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof rulesetDeleteTreezor>>,
  TError,
  { rulesetId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof rulesetDeleteTreezor>>,
    { rulesetId: string }
  > = (props) => {
    const { rulesetId } = props ?? {};

    return rulesetDeleteTreezor(rulesetId);
  };

  return { mutationFn, ...mutationOptions };
};

export type RulesetDeleteTreezorMutationResult = NonNullable<
  Awaited<ReturnType<typeof rulesetDeleteTreezor>>
>;

export type RulesetDeleteTreezorMutationError = unknown;

export const useRulesetDeleteTreezor = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rulesetDeleteTreezor>>,
    TError,
    { rulesetId: string },
    TContext
  >;
}) => {
  const mutationOptions = getRulesetDeleteTreezorMutationOptions(options);

  return useMutation(mutationOptions);
};
export const cardGetRulesetsTreezor = (
  cardId: string,
  signal?: AbortSignal,
) => {
  return customInstance<RulesetArrayResponse>({
    url: `/web/cards/${cardId}/rulesets`,
    method: 'get',
    signal,
  });
};

export const getCardGetRulesetsTreezorQueryKey = (cardId: string) =>
  [`/web/cards/${cardId}/rulesets`] as const;

export const getCardGetRulesetsTreezorQueryOptions = <
  TData = Awaited<ReturnType<typeof cardGetRulesetsTreezor>>,
  TError = unknown,
>(
  cardId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof cardGetRulesetsTreezor>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof cardGetRulesetsTreezor>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCardGetRulesetsTreezorQueryKey(cardId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof cardGetRulesetsTreezor>>
  > = ({ signal }) => cardGetRulesetsTreezor(cardId, signal);

  return { queryKey, queryFn, enabled: !!cardId, ...queryOptions };
};

export type CardGetRulesetsTreezorQueryResult = NonNullable<
  Awaited<ReturnType<typeof cardGetRulesetsTreezor>>
>;
export type CardGetRulesetsTreezorQueryError = unknown;

export const useCardGetRulesetsTreezor = <
  TData = Awaited<ReturnType<typeof cardGetRulesetsTreezor>>,
  TError = unknown,
>(
  cardId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof cardGetRulesetsTreezor>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCardGetRulesetsTreezorQueryOptions(cardId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Set the rulesets for the card

 */
export const cardSetRulesetsTreezor = (
  cardId: string,
  rulesetIdsRequest: RulesetIdsRequest,
) => {
  return customInstance<RulesetArrayResponse>({
    url: `/web/cards/${cardId}/rulesets`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: rulesetIdsRequest,
  });
};

export const getCardSetRulesetsTreezorMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cardSetRulesetsTreezor>>,
    TError,
    { cardId: string; data: RulesetIdsRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof cardSetRulesetsTreezor>>,
  TError,
  { cardId: string; data: RulesetIdsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cardSetRulesetsTreezor>>,
    { cardId: string; data: RulesetIdsRequest }
  > = (props) => {
    const { cardId, data } = props ?? {};

    return cardSetRulesetsTreezor(cardId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CardSetRulesetsTreezorMutationResult = NonNullable<
  Awaited<ReturnType<typeof cardSetRulesetsTreezor>>
>;
export type CardSetRulesetsTreezorMutationBody = RulesetIdsRequest;
export type CardSetRulesetsTreezorMutationError = unknown;

export const useCardSetRulesetsTreezor = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cardSetRulesetsTreezor>>,
    TError,
    { cardId: string; data: RulesetIdsRequest },
    TContext
  >;
}) => {
  const mutationOptions = getCardSetRulesetsTreezorMutationOptions(options);

  return useMutation(mutationOptions);
};
