/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { ExpenseCategoryCode } from './expenseCategoryCode';

export type MerchantV1AllOf = {
  readonly id?: string;
  readonly merchant_reference?: string;
  readonly merchant_name?: string;
  provider_name?: string;
  expense_category_code?: ExpenseCategoryCode;
};
