/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type DocumentV1AllOfStatus =
  typeof DocumentV1AllOfStatus[keyof typeof DocumentV1AllOfStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentV1AllOfStatus = {
  PENDING: 'PENDING',
  REFUSED: 'REFUSED',
  VALIDATED: 'VALIDATED',
  NOT_STARTED: 'NOT_STARTED',
} as const;
