/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryDateFromMandatoryParameter } from './component-parameters/queryDateFromMandatoryParameter';
import type { QueryMembershipIdParameter } from './component-parameters/queryMembershipIdParameter';

export type ProgramGroupLimitUpdateParams = {
  /**
   * Start of date range (including)
   */
  date_from: QueryDateFromMandatoryParameter;
  /**
   * ID of the membership
   */
  membership_id?: QueryMembershipIdParameter;
};
