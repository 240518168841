/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type QueryReportAggregationParameter =
  typeof QueryReportAggregationParameter[keyof typeof QueryReportAggregationParameter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QueryReportAggregationParameter = {
  ALL: 'ALL',
  PROGRAM: 'PROGRAM',
  PROGRAM_GROUP: 'PROGRAM_GROUP',
  SERVICE: 'SERVICE',
  CATEGORY: 'CATEGORY',
  PROVIDER: 'PROVIDER',
  EMPLOYEE: 'EMPLOYEE',
  GROUP: 'GROUP',
} as const;
