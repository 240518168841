/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Meta } from './meta';
import type { ProgramServiceGetResponseMetaAllOf } from './programServiceGetResponseMetaAllOf';

export type ProgramServiceGetResponseMeta = Meta &
  ProgramServiceGetResponseMetaAllOf;
