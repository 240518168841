/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryServiceIdParameter } from './component-parameters/queryServiceIdParameter';
import type { QueryRefundsEnabledParameter } from './component-parameters/queryRefundsEnabledParameter';
import type { QueryServiceNameKeysParameter } from './queryServiceNameKeysParameter';

export type MembershipProgramsGetParams = {
  /**
   * ID of the service
   */
  service_id?: QueryServiceIdParameter;
  /**
   * filter by refund enabled groups only
   */
  refunds_enabled?: QueryRefundsEnabledParameter;
  /**
   * A list of services to filter against
   */
  service_name_keys?: QueryServiceNameKeysParameter;
};
