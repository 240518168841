import {
  Center,
  Flex,
  FlexProps,
  Spinner,
  SpinnerProps,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';

export type LoaderProps = FlexProps & {
  modRight?: boolean;
  modLeft?: boolean;
  children?: ReactNode;
  componentRef?: React.LegacyRef<HTMLDivElement>;
  spinnerProps?: SpinnerProps;
};

export const Loader = ({
  modRight,
  modLeft,
  children,
  spinnerProps,
  componentRef,
  ...rest
}: LoaderProps) => {
  return (
    <Flex
      ref={componentRef}
      position="absolute"
      width="100%"
      height="100%"
      top="0"
    >
      <Flex m="auto" {...rest}>
        {modLeft && <Center ml="1rem">{children}</Center>}
        <Spinner {...spinnerProps} />
        {modRight && <Center ml="1rem">{children}</Center>}
      </Flex>
    </Flex>
  );
};
