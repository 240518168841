/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type SplittableTransactionStatus =
  typeof SplittableTransactionStatus[keyof typeof SplittableTransactionStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SplittableTransactionStatus = {
  UNKNOWN: 'UNKNOWN',
  AWAITING_PRODUCT_ASSOCIATION: 'AWAITING_PRODUCT_ASSOCIATION',
  AWAITING_SPLIT_PAYMENT: 'AWAITING_SPLIT_PAYMENT',
  RECONCILED: 'RECONCILED',
  FAILED: 'FAILED',
} as const;
