import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useServiceDetails } from '@libs/api-v2/endpoints/services/services';
import { Service } from '@libs/api-v2/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Drawer } from '@libs/ui/components';
import { useTranslation } from 'react-i18next';
import { ServiceContent } from './service-content';
import { ServiceProviders } from './service-providers';
import { useLanguage } from '@libs/core/i18n';

type ServiceDetailsDrawerProps = {
  service: Service;
  isOpen: boolean;
  onClose: () => void;
};

export const ServiceDetailsDrawer = ({
  service,
  isOpen,
  onClose,
}: ServiceDetailsDrawerProps) => {
  const { language } = useLanguage();
  const { t } = useTranslation();
  const { data: serviceDetails, isLoading } = useServiceDetails(service.id, {
    language,
  });

  if (isLoading) {
    return null;
  }

  return (
    <Drawer
      title={t(i18nKeys.operator.dashboard.service_details)}
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box>
        <Text color="ink.dark">{serviceDetails?.service.id}</Text>
        <Text color="ink.dark">{serviceDetails?.short_description}</Text>
        <ServiceContent contents={serviceDetails?.content?.results} />
        {serviceDetails?.provider && serviceDetails?.provider.length > 1 && (
          <ServiceProviders providers={serviceDetails?.provider} />
        )}
      </Box>
    </Drawer>
  );
};
