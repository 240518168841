import { Box, BoxProps, Flex, useTheme } from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Form, Formik } from 'formik';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TableActionCell } from './components/table-action-cell';
import { TableBody } from './components/table-body';
import { TableCell } from './components/table-cell';
import { TableColumnSort } from './components/table-column-sort';
import { TableDate } from './components/table-date';
import { TableFilter } from './components/table-filter';
import { TableHead } from './components/table-head';
import { TableHeadCell } from './components/table-header-cell';
import { TableNumber } from './components/table-number';
import { TableRow } from './components/table-row';
import { TableSearch } from './components/table-search';
import { TableHeaders } from './table.types';

export type TableHeaderAddonsProps = {
  hasSearch?: boolean;
  hasFilter?: boolean;
  filterQuery?: string[];
  setFilterQuery?: React.Dispatch<React.SetStateAction<string[]>>;
  filterValuesI18nKey?: { [x: string]: string };
  searchPlaceholder?: string;
  headers?: TableHeaders;
};

export const TableHeaderAddons = ({
  headers,
  hasFilter,
  hasSearch,
  filterQuery,
  setFilterQuery,
  filterValuesI18nKey,
  searchPlaceholder,
}: TableHeaderAddonsProps) => {
  const { t } = useTranslation();
  return (
    <Flex
      p="2rem"
      justify={!hasFilter && hasSearch ? 'flex-end' : 'space-between'}
      align="center"
      borderBottom="1px"
      borderBottomColor="ink.lighter"
    >
      {hasFilter && (
        <Table.Filter
          columns={headers
            .map(
              (header) =>
                typeof header !== 'string' &&
                header.filter && {
                  name: header.filterName || header.name,
                  values: header.filterValues,
                  valuesI18nKey: header.filterValuesI18nKey,
                },
            )
            .filter((e) => e)}
          filterQuery={filterQuery}
          setFilterQuery={setFilterQuery}
          valuesI18nKey={filterValuesI18nKey}
        />
      )}
      <Box>
        {hasSearch && (
          <Table.Search
            placeholder={searchPlaceholder || t(i18nKeys.common.table.search)}
          />
        )}
      </Box>
    </Flex>
  );
};

export type TableProps = Omit<BoxProps, 'onSubmit'> &
  TableHeaderAddonsProps & {
    children: ReactNode;
    initialValues?: unknown;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    onSubmit?: (v: any) => void;
    searchQuery?: string;
    setSearchQuery?: React.Dispatch<React.SetStateAction<string>>;
    sortQuery?: string[];
    setSortQuery?: React.Dispatch<React.SetStateAction<string[]>>;
  };

/**
 * @param props
 * @param props.children table body
 * @param props.initialValues initialValues for formik
 * @param props.onSubmit submit handler for formik
 * @param props.hasSearch activate search in table
 * @param props.hasFilter activate filters in table
 * @param props.searchQuery search query
 * @param props.setSearchQuery search query setter
 * @param props.searchPlaceholder search input placeholder, defaults to `i18nKeys.common.table.search`
 * @param props.sortQuery sort query
 * @param props.setSortQuery sort query setter
 * @param props.filterQuery filter query
 * @param props.setFilterQuery filter query setter
 * @param props.filterValuesI18nKey i18nkey that will be used to display filters in the filter popover
 * @param props.headers column title or object containing configuration to handle sorting/filtering
 * @param props.headers.name  column name used in the GET request queries
 * @param props.headers.title column title
 * @param props.headers.sort  column is sortable
 * @param props.headers.filter column is filterable
 * @param props.headers.filterName  column name used in the GET request filter query, defaults to headers.name
 * @param props.headers.filterValues  column filter values that will be used as value in the GET requests filter query filterValuesI18nKey
 * @param props.headers.filterValuesI18nKey  column filter i18nKeys for values that will be used to display filters in popover, takes priority on `props.filterValuesI18nKey`
 *
 * @returns styled table element
 * or styled table element with formik
 * or styled table element with formik and headers
 *
 * @example
 * // simlple use
 * <Table>
 *  <Table.THead>
 *    ... headers
 *  </Table.THead>
 *  <Table.TBody>
 *    ... body
 *  </Table.TBody>
 * </Table>
 *
 * // manual headers + formik
 * // you can add Table.Search, Table.Sort, Table.Filter components in this table
 * <Table>
 *  <Table.THead>
 *    ... headers
 *  </Table.THead>
 *  <Table.TBody>
 *    ... body
 *  </Table.TBody>
 * </Table>
 *
 * // auto headers + formik
 * <Table
 *  hasFilter
 *  hasSearch
 *  onSubmit={submitHandler}
 *  initialValues={{ q: '' }}
 *  searchQuery={searchQuery}
 *  setSearchQuery={setSearchQuery}
 *  sortQuery={sortQuery}
 *  setSortQuery={setSortQuery}
 *  filterQuery={filterQuery}
 *  setFilterQuery={setFilterQuery}
 *  headers={[{
 *    name: 'legal_name',
 *    title: t(i18nKeys.common.full_name),
 *    sort: true,
 *    filter: true,
 *    filterName: 'status',
 *    filterValues: Object.values(Status)
 *  },
 *  t(i18nKeys.common.vat)]}
 *  searchPlaceholder={your_placeholder}
 *  filterValuesI18nKey={i18nKeys.common.TagStatus}
 * >
 *  <Table.TBody>
 *    ... body
 *  </Table.TBody>
 * </Table>
 */
export const Table = ({
  children,
  initialValues = {},
  onSubmit = () => null,
  hasSearch,
  hasFilter,
  searchQuery,
  setSearchQuery,
  searchPlaceholder,
  sortQuery,
  setSortQuery,
  filterQuery,
  setFilterQuery,
  filterValuesI18nKey,
  headers,
  ...rest
}: TableProps) => {
  const theme = useTheme();

  if (headers?.length > 0 && initialValues && onSubmit) {
    return (
      <Box bgColor="white" borderRadius={theme.borders.md} mt="4rem">
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          <Form noValidate>
            {(hasFilter || hasSearch) && (
              <TableHeaderAddons
                headers={headers}
                hasFilter={hasFilter}
                hasSearch={hasSearch}
                filterQuery={filterQuery}
                setFilterQuery={setFilterQuery}
                filterValuesI18nKey={filterValuesI18nKey}
                searchPlaceholder={searchPlaceholder}
              />
            )}
            <Box overflowX="auto">
              <Box
                as="table"
                w="100%"
                table-layout="auto"
                border-collapse="collapse"
                {...rest}
              >
                <Table.THead>
                  <Table.TR>
                    {headers.map((header) =>
                      typeof header !== 'string' ? (
                        <Table.TH key={header.name}>
                          {header.sort ? (
                            <Table.Sort
                              setQuery={setSortQuery}
                              query={sortQuery}
                              name={header.name}
                            >
                              {header.title}
                            </Table.Sort>
                          ) : (
                            header.title
                          )}
                        </Table.TH>
                      ) : (
                        <Table.TH key={header}>{header}</Table.TH>
                      ),
                    )}
                  </Table.TR>
                </Table.THead>
                {children}
              </Box>
            </Box>
          </Form>
        </Formik>
      </Box>
    );
  }

  // Formik is needed for tables that use compound components TableColumnSort, TableSearch or TableFilter
  return initialValues && onSubmit ? (
    <Box
      bgColor="white"
      borderRadius={theme.borders.md}
      mt="4rem"
      overflowX="auto"
    >
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form noValidate>
          <Box
            as="table"
            w="100%"
            table-layout="auto"
            border-collapse="collapse"
            {...rest}
          >
            {children}
          </Box>
        </Form>
      </Formik>
    </Box>
  ) : (
    <Box
      bgColor="white"
      borderRadius={theme.borders.md}
      mt="4rem"
      overflowX="auto"
    >
      <Box
        as="table"
        w="100%"
        table-layout="auto"
        border-collapse="collapse"
        {...rest}
      >
        {children}
      </Box>
    </Box>
  );
};

Table.THead = TableHead;
Table.TBody = TableBody;
Table.TR = TableRow;
Table.TD = TableCell;
Table.Action = TableActionCell;
Table.TH = TableHeadCell;
Table.Sort = TableColumnSort;
Table.Search = TableSearch;
Table.Filter = TableFilter;
Table.Date = TableDate;
Table.Number = TableNumber;
