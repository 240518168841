/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Service } from './service';
import type { ProgramProduct } from './programProduct';
import type { SplitPaymentConfiguration } from './splitPaymentConfiguration';

export interface ServiceDefinition {
  service_details: Service;
  related_products?: ProgramProduct[];
  split_payment_config?: SplitPaymentConfiguration;
  is_active_by_default: boolean;
}
