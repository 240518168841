import { ErrorResponse } from '@libs/api/models';

export const createApiError = (error: ErrorResponse) => {
  // booking/create_registration/email_required
  const [api_name, action, field, meta] = error.detail?.split('/') ?? [];

  return {
    api_name,
    action,
    field,
    meta,
    translation: parseError({
      api_name,
      action,
      field,
      meta,
    }),
    ...error,
  };
};

const parseError = ({
  api_name,
  action,
  field,
  meta,
}: {
  api_name: string;
  action: string;
  field: string;
  meta?: string;
}): {
  key: string;
  value?: string;
} => {
  return {
    key: `${api_name}${action ? `_${action}` : ''}${field ? `_${field}` : ''}`,
    value: meta?.split('=')[1],
  };
};
