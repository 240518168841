/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { ExpenseLabel } from './expenseLabel';

/**
 * used for filtering based on expense_label value
 */
export type QueryExpenseLabelParameter = ExpenseLabel[];
