/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type JourneyType = typeof JourneyType[keyof typeof JourneyType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JourneyType = {
  NONE: 'NONE',
  ONE_WAY: 'ONE_WAY',
  ROUND_TRIP: 'ROUND_TRIP',
} as const;
