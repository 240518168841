/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { BudgetType } from './component-common/budgetType';
import type { BudgetStatus } from './budgetStatus';

export type BudgetAllOf = {
  readonly id?: string;
  membership_id?: string;
  type?: BudgetType;
  status?: BudgetStatus;
  start_at?: string;
  end_at?: string;
  /** effective total with carry-over */
  readonly total?: number;
  /** initial total value that has been set when budget was created */
  initial_total?: number;
  readonly used?: number;
  readonly remaining?: number;
  is_carry_over_enabled?: boolean;
  readonly carry_over_amount?: number;
};
