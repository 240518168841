/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Provider } from './provider';
import type { SepaMandate } from './sepaMandate';

/**
 * Carries data of payment method beneficiary
This concept is valid in context of wire transfers and direct debits

 */
export interface PaymentMethodBeneficiary {
  readonly id: string;
  /** name of a creditor */
  readonly name: string;
  /** provider linked to the beneficiary */
  provider?: Provider;
  /** address of a creditor */
  readonly address: string;
  /** account number of a creditor */
  readonly iban: string;
  /** bic of creditor's bank */
  readonly bic: string;
  /** creditor identifier in context of direct debit transactions */
  readonly sepa_creditor_identifier: string;
  /** Payment mandates associated with a payment method.
Relevant only in context of SEPA Direct Debit.
 */
  readonly mandates: SepaMandate[];
}
