/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import { useMutation } from '@tanstack/react-query';
import type {
  UseMutationOptions,
  MutationFunction,
} from '@tanstack/react-query';
import type {
  CostEstimationResponse,
  CostEstimationRequest,
  TripCreateRequest,
  ExpenseWithMeta,
  CreateJourneyRequest,
} from '../../models';
import { customInstance } from '../../config';

export const kmAllowanceCostEstimationCalculate = (
  productId: string,
  costEstimationRequest: CostEstimationRequest,
) => {
  return customInstance<CostEstimationResponse>({
    url: `/web/v2/products/${productId}/km_allowance/cost_estimations`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: costEstimationRequest,
  });
};

export const getKmAllowanceCostEstimationCalculateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof kmAllowanceCostEstimationCalculate>>,
    TError,
    { productId: string; data: CostEstimationRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof kmAllowanceCostEstimationCalculate>>,
  TError,
  { productId: string; data: CostEstimationRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof kmAllowanceCostEstimationCalculate>>,
    { productId: string; data: CostEstimationRequest }
  > = (props) => {
    const { productId, data } = props ?? {};

    return kmAllowanceCostEstimationCalculate(productId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type KmAllowanceCostEstimationCalculateMutationResult = NonNullable<
  Awaited<ReturnType<typeof kmAllowanceCostEstimationCalculate>>
>;
export type KmAllowanceCostEstimationCalculateMutationBody =
  CostEstimationRequest;
export type KmAllowanceCostEstimationCalculateMutationError = unknown;

export const useKmAllowanceCostEstimationCalculate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof kmAllowanceCostEstimationCalculate>>,
    TError,
    { productId: string; data: CostEstimationRequest },
    TContext
  >;
}) => {
  const mutationOptions =
    getKmAllowanceCostEstimationCalculateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const kmAllowanceCalculateTrip = (
  productId: string,
  tripCreateRequest: TripCreateRequest,
) => {
  return customInstance<CostEstimationResponse>({
    url: `/web/v2/products/${productId}/km_allowance/calculate_trip_cost`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: tripCreateRequest,
  });
};

export const getKmAllowanceCalculateTripMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof kmAllowanceCalculateTrip>>,
    TError,
    { productId: string; data: TripCreateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof kmAllowanceCalculateTrip>>,
  TError,
  { productId: string; data: TripCreateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof kmAllowanceCalculateTrip>>,
    { productId: string; data: TripCreateRequest }
  > = (props) => {
    const { productId, data } = props ?? {};

    return kmAllowanceCalculateTrip(productId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type KmAllowanceCalculateTripMutationResult = NonNullable<
  Awaited<ReturnType<typeof kmAllowanceCalculateTrip>>
>;
export type KmAllowanceCalculateTripMutationBody = TripCreateRequest;
export type KmAllowanceCalculateTripMutationError = unknown;

export const useKmAllowanceCalculateTrip = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof kmAllowanceCalculateTrip>>,
    TError,
    { productId: string; data: TripCreateRequest },
    TContext
  >;
}) => {
  const mutationOptions = getKmAllowanceCalculateTripMutationOptions(options);

  return useMutation(mutationOptions);
};
export const kmAllowanceCreate = (
  productId: string,
  tripCreateRequest: TripCreateRequest,
) => {
  return customInstance<ExpenseWithMeta>({
    url: `/web/v2/products/${productId}/km_allowance/trip`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: tripCreateRequest,
  });
};

export const getKmAllowanceCreateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof kmAllowanceCreate>>,
    TError,
    { productId: string; data: TripCreateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof kmAllowanceCreate>>,
  TError,
  { productId: string; data: TripCreateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof kmAllowanceCreate>>,
    { productId: string; data: TripCreateRequest }
  > = (props) => {
    const { productId, data } = props ?? {};

    return kmAllowanceCreate(productId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type KmAllowanceCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof kmAllowanceCreate>>
>;
export type KmAllowanceCreateMutationBody = TripCreateRequest;
export type KmAllowanceCreateMutationError = unknown;

export const useKmAllowanceCreate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof kmAllowanceCreate>>,
    TError,
    { productId: string; data: TripCreateRequest },
    TContext
  >;
}) => {
  const mutationOptions = getKmAllowanceCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const kmAllowanceCreateJourney = (
  productId: string,
  createJourneyRequest: CreateJourneyRequest,
) => {
  return customInstance<ExpenseWithMeta[]>({
    url: `/web/v2/products/${productId}/km_allowance/journey`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: createJourneyRequest,
  });
};

export const getKmAllowanceCreateJourneyMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof kmAllowanceCreateJourney>>,
    TError,
    { productId: string; data: CreateJourneyRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof kmAllowanceCreateJourney>>,
  TError,
  { productId: string; data: CreateJourneyRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof kmAllowanceCreateJourney>>,
    { productId: string; data: CreateJourneyRequest }
  > = (props) => {
    const { productId, data } = props ?? {};

    return kmAllowanceCreateJourney(productId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type KmAllowanceCreateJourneyMutationResult = NonNullable<
  Awaited<ReturnType<typeof kmAllowanceCreateJourney>>
>;
export type KmAllowanceCreateJourneyMutationBody = CreateJourneyRequest;
export type KmAllowanceCreateJourneyMutationError = unknown;

export const useKmAllowanceCreateJourney = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof kmAllowanceCreateJourney>>,
    TError,
    { productId: string; data: CreateJourneyRequest },
    TContext
  >;
}) => {
  const mutationOptions = getKmAllowanceCreateJourneyMutationOptions(options);

  return useMutation(mutationOptions);
};
