/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type ValidationStatus =
  typeof ValidationStatus[keyof typeof ValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ValidationStatus = {
  APPROVED: 'APPROVED',
  REFUSED: 'REFUSED',
  NEED_MORE_INFO: 'NEED_MORE_INFO',
  PENDING: 'PENDING',
  NO_APPROVAL_REQUIRED: 'NO_APPROVAL_REQUIRED',
} as const;
