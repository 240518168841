/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryProgramIdsParameter } from './component-parameters/queryProgramIdsParameter';
import type { QueryMembershipIdsParameter } from './component-parameters/queryMembershipIdsParameter';
import type { QueryServiceIdsParameter } from './component-parameters/queryServiceIdsParameter';
import type { QueryPageNumberParameter } from './component-parameters/queryPageNumberParameter';
import type { QueryPageSizeParameter } from './component-parameters/queryPageSizeParameter';
import type { QuerySortDefinitionParameter } from './component-parameters/querySortDefinitionParameter';
import type { QueryDatetimeFromParameter } from './component-parameters/queryDatetimeFromParameter';
import type { QueryDatetimeToParameter } from './component-parameters/queryDatetimeToParameter';
import type { QueryExpenseIdsParameter } from './component-parameters/queryExpenseIdsParameter';
import type { QueryOnlyTeamsParameter } from './component-parameters/queryOnlyTeamsParameter';
import type { QueryCategoryNameKeyParameter } from './component-parameters/queryCategoryNameKeyParameter';
import type { ReviewStatusesParameter } from './reviewStatusesParameter';
import type { RefundStatusesParameter } from './refundStatusesParameter';
import type { RefundIssuerParameter } from './refundIssuerParameter';
import type { SearchEmployeeNameParameter } from './searchEmployeeNameParameter';
import type { QueryExpenseTypeParameter } from './queryExpenseTypeParameter';
import type { QueryExpenseInterfacesParameter } from './queryExpenseInterfacesParameter';
import type { QueryExpenseLabelParameter } from './queryExpenseLabelParameter';
import type { QuerySortByParameter } from './component-parameters/querySortByParameter';

export type ExpenseListParams = {
  /**
   * ID of the programs
   */
  program_ids?: QueryProgramIdsParameter;
  /**
   * ID of the memberships
   */
  membership_ids?: QueryMembershipIdsParameter;
  /**
   * ID of the services
   */
  service_ids?: QueryServiceIdsParameter;
  /**
   * Number of the page to fetch
   */
  page_number?: QueryPageNumberParameter;
  /**
   * Size of the page to fetch
   */
  page_size?: QueryPageSizeParameter;
  /**
   * Result sort order
   */
  sort?: QuerySortDefinitionParameter;
  /**
   * Start of date range (including)
   */
  date_from?: QueryDatetimeFromParameter;
  /**
   * End of date range (including)
   */
  range_to?: QueryDatetimeToParameter;
  /**
   * ID of the expenses
   */
  expense_ids?: QueryExpenseIdsParameter;
  /**
   * get expense for your teams only
   */
  only_teams?: QueryOnlyTeamsParameter;
  /**
   * Name key of the category
   */
  category_name_key?: QueryCategoryNameKeyParameter;
  /**
   * review statuses of an expense
   */
  review_statuses?: ReviewStatusesParameter;
  /**
   * refund statuses of an expense
   */
  refund_statuses?: RefundStatusesParameter;
  /**
   * Refund issuer of an expense
   */
  refund_issuer?: RefundIssuerParameter;
  /**
   * search on employe first and last_name
   */
  search_employee_name?: SearchEmployeeNameParameter;
  /**
   * filter on expense type
   */
  expense_type?: QueryExpenseTypeParameter;
  /**
   * Interfaces of expense
   */
  expense_interfaces?: QueryExpenseInterfacesParameter;
  /**
   * used for filtering based on expense_label value
   */
  expense_label?: QueryExpenseLabelParameter;
  /**
   * filter by specific field (for the range date)
   */
  sort_by?: QuerySortByParameter;
};
