/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type MetaStatus = typeof MetaStatus[keyof typeof MetaStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MetaStatus = {
  succeeded: 'succeeded',
  failed: 'failed',
} as const;
