/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type PayrollStatus = typeof PayrollStatus[keyof typeof PayrollStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayrollStatus = {
  PAID: 'PAID',
  ON_HOLD: 'ON_HOLD',
} as const;
