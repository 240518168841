import { Text, Button, Flex, useClipboard } from '@chakra-ui/react';
import { Table, Tag } from '@libs/ui/components';
import { useGroupMembersGet } from '@libs/api-v2/endpoints';
import { Group } from '@libs/api-v2/models';
import { Drawer } from '@libs/ui/components';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import React, { Fragment } from 'react';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useTranslation } from 'react-i18next';

type GroupMembersDrawerProps = {
  group: Group;
  isOpen: boolean;
  onClose: () => void;
};

export const GroupMembersDrawer = ({
  group,
  isOpen,
  onClose,
}: GroupMembersDrawerProps) => {
  const { hasCopied, onCopy } = useClipboard(group.id);
  const { t } = useTranslation();

  const { data: members } = useGroupMembersGet(group?.id, {
    query: { keepPreviousData: true },
  });

  const headers: TableHeaders = [
    {
      name: 'members',
      title: t(i18nKeys.common.employees_list),
    },
  ];

  return (
    <Drawer title={group?.name} isOpen={isOpen} onClose={onClose} size="xl">
      <Flex>
        <Tag size={'md'} key={group?.id} width="auto">
          {' '}
          Id : {group?.id}{' '}
        </Tag>
        <Button onClick={onCopy}>{hasCopied ? 'Copied' : 'Copy'}</Button>
      </Flex>
      <Drawer.Body>
        <Table headers={headers}>
          <Table.TBody
            colSpan={headers.length}
            isEmpty={members?.data.length === 0 || !members}
          >
            {members?.data?.map((member) => {
              return (
                <Fragment key={`${member.membership_id}`}>
                  <Table.TR
                    selectable
                    position="relative"
                    transform="scale(1,1)"
                    index={member.membership_id}
                  >
                    <Table.TD>
                      <Text>
                        {member.first_name} {member.last_name}
                      </Text>
                    </Table.TD>
                  </Table.TR>
                </Fragment>
              );
            })}
          </Table.TBody>
        </Table>
      </Drawer.Body>
    </Drawer>
  );
};
