/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  TeamsGetResponse,
  Error404NotFoundResponse,
  TeamsListParams,
  TeamUpSertResponse,
  Error400TeamNameAlreadyExistsResponse,
  Error403ForbiddenResponse,
  TeamPostRequest,
  TeamWithMeta,
  TeamGetParams,
  TeamPutRequest,
  TeamMembersGetResponse,
  TeamMembersListParams,
  Team,
  Error400TeamMembershipNotManagerResponse,
} from '../../models';
import type { Error, ErrorResponse } from '../../models/component-common';
import { customInstance } from '../../config';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
  T,
>() => T extends Y ? 1 : 2
  ? A
  : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<
    { [Q in P]: T[P] },
    { -readonly [Q in P]: T[P] },
    P
  >;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object
        ? NonReadonly<NonNullable<T[P]>>
        : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

/**
 * Fetch all teams based on current membership role and organisation, can be limited via the organisation_id querystring.
- employee will see only the team he belongs to
- manager will see only the team for which he his manager and belongs to (!)
- administration will see all teams belonging to the same organisation
- operator will see all teams cross organisation except if one specific organisation is specified

 */
export const teamsList = (params?: TeamsListParams, signal?: AbortSignal) => {
  return customInstance<TeamsGetResponse>({
    url: `/web/v2/teams`,
    method: 'get',
    params,
    signal,
  });
};

export const getTeamsListQueryKey = (params?: TeamsListParams) =>
  [`/web/v2/teams`, ...(params ? [params] : [])] as const;

export const getTeamsListQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsList>>,
  TError = Error404NotFoundResponse,
>(
  params?: TeamsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsList>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<Awaited<ReturnType<typeof teamsList>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTeamsListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof teamsList>>> = ({
    signal,
  }) => teamsList(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type TeamsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsList>>
>;
export type TeamsListQueryError = Error404NotFoundResponse;

export const useTeamsList = <
  TData = Awaited<ReturnType<typeof teamsList>>,
  TError = Error404NotFoundResponse,
>(
  params?: TeamsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsList>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create an empty new named team for an organisation.
- administrator can only create team for its own organisation.
- operator can create a team for any organisation

throw an 404 error if organisation does not exist.

 */
export const teamsPost = (teamPostRequest: NonReadonly<TeamPostRequest>) => {
  return customInstance<TeamUpSertResponse>({
    url: `/web/v2/teams`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: teamPostRequest,
  });
};

export const getTeamsPostMutationOptions = <
  TError =
    | Error400TeamNameAlreadyExistsResponse
    | Error403ForbiddenResponse
    | Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsPost>>,
    TError,
    { data: NonReadonly<TeamPostRequest> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamsPost>>,
  TError,
  { data: NonReadonly<TeamPostRequest> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamsPost>>,
    { data: NonReadonly<TeamPostRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return teamsPost(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamsPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamsPost>>
>;
export type TeamsPostMutationBody = NonReadonly<TeamPostRequest>;
export type TeamsPostMutationError =
  | Error400TeamNameAlreadyExistsResponse
  | Error403ForbiddenResponse
  | Error404NotFoundResponse;

export const useTeamsPost = <
  TError =
    | Error400TeamNameAlreadyExistsResponse
    | Error403ForbiddenResponse
    | Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsPost>>,
    TError,
    { data: NonReadonly<TeamPostRequest> },
    TContext
  >;
}) => {
  const mutationOptions = getTeamsPostMutationOptions(options);

  return useMutation(mutationOptions);
};
export const teamGet = (
  teamId: string,
  params?: TeamGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<TeamWithMeta>({
    url: `/web/v2/teams/${teamId}`,
    method: 'get',
    params,
    signal,
  });
};

export const getTeamGetQueryKey = (teamId: string, params?: TeamGetParams) =>
  [`/web/v2/teams/${teamId}`, ...(params ? [params] : [])] as const;

export const getTeamGetQueryOptions = <
  TData = Awaited<ReturnType<typeof teamGet>>,
  TError = Error404NotFoundResponse,
>(
  teamId: string,
  params?: TeamGetParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof teamGet>>, TError, TData>;
  },
): UseQueryOptions<Awaited<ReturnType<typeof teamGet>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTeamGetQueryKey(teamId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof teamGet>>> = ({
    signal,
  }) => teamGet(teamId, params, signal);

  return { queryKey, queryFn, enabled: !!teamId, ...queryOptions };
};

export type TeamGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamGet>>
>;
export type TeamGetQueryError = Error404NotFoundResponse;

export const useTeamGet = <
  TData = Awaited<ReturnType<typeof teamGet>>,
  TError = Error404NotFoundResponse,
>(
  teamId: string,
  params?: TeamGetParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof teamGet>>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamGetQueryOptions(teamId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const teamPut = (teamId: string, teamPutRequest: TeamPutRequest) => {
  return customInstance<TeamUpSertResponse>({
    url: `/web/v2/teams/${teamId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: teamPutRequest,
  });
};

export const getTeamPutMutationOptions = <
  TError = Error400TeamNameAlreadyExistsResponse | Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamPut>>,
    TError,
    { teamId: string; data: TeamPutRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamPut>>,
  TError,
  { teamId: string; data: TeamPutRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamPut>>,
    { teamId: string; data: TeamPutRequest }
  > = (props) => {
    const { teamId, data } = props ?? {};

    return teamPut(teamId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamPut>>
>;
export type TeamPutMutationBody = TeamPutRequest;
export type TeamPutMutationError =
  | Error400TeamNameAlreadyExistsResponse
  | Error404NotFoundResponse;

export const useTeamPut = <
  TError = Error400TeamNameAlreadyExistsResponse | Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamPut>>,
    TError,
    { teamId: string; data: TeamPutRequest },
    TContext
  >;
}) => {
  const mutationOptions = getTeamPutMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Remove a team from an organisation. The team assigment of all members, managers and HR managers which were part of this team will be removed. Except for this, they will remain untouched.
 */
export const teamDelete = (teamId: string) => {
  return customInstance<void>({
    url: `/web/v2/teams/${teamId}`,
    method: 'delete',
  });
};

export const getTeamDeleteMutationOptions = <
  TError = Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamDelete>>,
    TError,
    { teamId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamDelete>>,
  TError,
  { teamId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamDelete>>,
    { teamId: string }
  > = (props) => {
    const { teamId } = props ?? {};

    return teamDelete(teamId);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamDelete>>
>;

export type TeamDeleteMutationError = Error404NotFoundResponse;

export const useTeamDelete = <
  TError = Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamDelete>>,
    TError,
    { teamId: string },
    TContext
  >;
}) => {
  const mutationOptions = getTeamDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * All members of a Team

 */
export const teamMembersList = (
  teamId: string,
  params?: TeamMembersListParams,
  signal?: AbortSignal,
) => {
  return customInstance<TeamMembersGetResponse>({
    url: `/web/v2/teams/${teamId}/members`,
    method: 'get',
    params,
    signal,
  });
};

export const getTeamMembersListQueryKey = (
  teamId: string,
  params?: TeamMembersListParams,
) => [`/web/v2/teams/${teamId}/members`, ...(params ? [params] : [])] as const;

export const getTeamMembersListQueryOptions = <
  TData = Awaited<ReturnType<typeof teamMembersList>>,
  TError = Error404NotFoundResponse,
>(
  teamId: string,
  params?: TeamMembersListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamMembersList>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof teamMembersList>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTeamMembersListQueryKey(teamId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof teamMembersList>>> = ({
    signal,
  }) => teamMembersList(teamId, params, signal);

  return { queryKey, queryFn, enabled: !!teamId, ...queryOptions };
};

export type TeamMembersListQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamMembersList>>
>;
export type TeamMembersListQueryError = Error404NotFoundResponse;

export const useTeamMembersList = <
  TData = Awaited<ReturnType<typeof teamMembersList>>,
  TError = Error404NotFoundResponse,
>(
  teamId: string,
  params?: TeamMembersListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamMembersList>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamMembersListQueryOptions(teamId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const teamMemberPut = (teamId: string, membershipId: string) => {
  return customInstance<void>({
    url: `/web/v2/teams/${teamId}/members/${membershipId}`,
    method: 'put',
  });
};

export const getTeamMemberPutMutationOptions = <
  TError = Error | Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamMemberPut>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamMemberPut>>,
  TError,
  { teamId: string; membershipId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamMemberPut>>,
    { teamId: string; membershipId: string }
  > = (props) => {
    const { teamId, membershipId } = props ?? {};

    return teamMemberPut(teamId, membershipId);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamMemberPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamMemberPut>>
>;

export type TeamMemberPutMutationError = Error | Error404NotFoundResponse;

export const useTeamMemberPut = <
  TError = Error | Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamMemberPut>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >;
}) => {
  const mutationOptions = getTeamMemberPutMutationOptions(options);

  return useMutation(mutationOptions);
};
export const teamMemberDelete = (teamId: string, membershipId: string) => {
  return customInstance<void>({
    url: `/web/v2/teams/${teamId}/members/${membershipId}`,
    method: 'delete',
  });
};

export const getTeamMemberDeleteMutationOptions = <
  TError = Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamMemberDelete>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamMemberDelete>>,
  TError,
  { teamId: string; membershipId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamMemberDelete>>,
    { teamId: string; membershipId: string }
  > = (props) => {
    const { teamId, membershipId } = props ?? {};

    return teamMemberDelete(teamId, membershipId);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamMemberDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamMemberDelete>>
>;

export type TeamMemberDeleteMutationError = Error404NotFoundResponse;

export const useTeamMemberDelete = <
  TError = Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamMemberDelete>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >;
}) => {
  const mutationOptions = getTeamMemberDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};
export const teamManagerPut = (teamId: string, membershipId: string) => {
  return customInstance<Team>({
    url: `/web/v2/teams/${teamId}/managers/${membershipId}`,
    method: 'put',
  });
};

export const getTeamManagerPutMutationOptions = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamManagerPut>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamManagerPut>>,
  TError,
  { teamId: string; membershipId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamManagerPut>>,
    { teamId: string; membershipId: string }
  > = (props) => {
    const { teamId, membershipId } = props ?? {};

    return teamManagerPut(teamId, membershipId);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamManagerPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamManagerPut>>
>;

export type TeamManagerPutMutationError = Error;

export const useTeamManagerPut = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamManagerPut>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >;
}) => {
  const mutationOptions = getTeamManagerPutMutationOptions(options);

  return useMutation(mutationOptions);
};
export const teamManagerDelete = (teamId: string, membershipId: string) => {
  return customInstance<void>({
    url: `/web/v2/teams/${teamId}/managers/${membershipId}`,
    method: 'delete',
  });
};

export const getTeamManagerDeleteMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamManagerDelete>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamManagerDelete>>,
  TError,
  { teamId: string; membershipId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamManagerDelete>>,
    { teamId: string; membershipId: string }
  > = (props) => {
    const { teamId, membershipId } = props ?? {};

    return teamManagerDelete(teamId, membershipId);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamManagerDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamManagerDelete>>
>;

export type TeamManagerDeleteMutationError = unknown;

export const useTeamManagerDelete = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamManagerDelete>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >;
}) => {
  const mutationOptions = getTeamManagerDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};
export const teamHrmanagerPut = (teamId: string, membershipId: string) => {
  return customInstance<Team>({
    url: `/web/v2/teams/${teamId}/hr_managers/${membershipId}`,
    method: 'put',
  });
};

export const getTeamHrmanagerPutMutationOptions = <
  TError = Error400TeamMembershipNotManagerResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamHrmanagerPut>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamHrmanagerPut>>,
  TError,
  { teamId: string; membershipId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamHrmanagerPut>>,
    { teamId: string; membershipId: string }
  > = (props) => {
    const { teamId, membershipId } = props ?? {};

    return teamHrmanagerPut(teamId, membershipId);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamHrmanagerPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamHrmanagerPut>>
>;

export type TeamHrmanagerPutMutationError =
  Error400TeamMembershipNotManagerResponse;

export const useTeamHrmanagerPut = <
  TError = Error400TeamMembershipNotManagerResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamHrmanagerPut>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >;
}) => {
  const mutationOptions = getTeamHrmanagerPutMutationOptions(options);

  return useMutation(mutationOptions);
};
export const teamHrmanagerDelete = (teamId: string, membershipId: string) => {
  return customInstance<void>({
    url: `/web/v2/teams/${teamId}/hr_managers/${membershipId}`,
    method: 'delete',
  });
};

export const getTeamHrmanagerDeleteMutationOptions = <
  TError = ErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamHrmanagerDelete>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamHrmanagerDelete>>,
  TError,
  { teamId: string; membershipId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamHrmanagerDelete>>,
    { teamId: string; membershipId: string }
  > = (props) => {
    const { teamId, membershipId } = props ?? {};

    return teamHrmanagerDelete(teamId, membershipId);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamHrmanagerDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamHrmanagerDelete>>
>;

export type TeamHrmanagerDeleteMutationError = ErrorResponse;

export const useTeamHrmanagerDelete = <
  TError = ErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamHrmanagerDelete>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >;
}) => {
  const mutationOptions = getTeamHrmanagerDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};
