/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export interface BrowserInfo {
  /** Boolean that represents the ability of the cardholder browser to execute Java. */
  java_enabled: boolean;
  /** Boolean that represents the ability of the cardholder browser to execute JavaScript. */
  javascript_enabled: boolean;
  /** Customer's IP address. */
  ipaddr: string;
  /** This element should contain the exact content of the HTTP Accept header as sent to the merchant from the customer's browser. */
  http_accept: string;
  /** This element should contain the exact content of the HTTP User-Agent header as sent to the merchant from the customer's browser. */
  http_user_agent: string;
  /** Value representing the browser language as defined in IETF BCP47. */
  language: string;
  /** Value representing the bit depth of the colour palette for displaying images, in bits per pixel.
   * 1 : 1 bit
   * 4 : 4 bits
   * 8 : 8 bits
   * 15 : 15 bits
   * 16 : 16 bits
   * 24 : 24 bits
   * 32 : 32 bits
   * 48 : 48 bits
   */
  color_depth: string;
  /** Total height of the Cardholder’s screen in pixels. */
  screen_height: number;
  /** Total height of the Cardholder’s screen in pixels. */
  screen_width: number;
  /** Time-zone offset in minutes between UTC and the Cardholder. */
  timezone: string;
  /** This element should contain the value of the ioBB hidden field. */
  device_fingerprint: string;
}
