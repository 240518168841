/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { ProviderServiceStatus } from './providerServiceStatus';

export interface ProviderService {
  service?: string;
  status?: ProviderServiceStatus;
  comment?: string;
}
