/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { CardTransaction } from './cardTransaction';
import type { MetaV1 } from './metaV1';

export interface CardTransactionsGetResponse {
  data?: CardTransaction[];
  meta?: MetaV1;
}
