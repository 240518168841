import { Button } from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { validateSchema } from '@libs/core/utils';
import { useAuthState } from '@libs/dashboard-core/context';
import {
  FormSubmitData,
  handleFormSubmit,
  InputField,
} from '@libs/ui/components';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Navigate, useNavigate } from 'react-router-dom';
import { getPath, Routes, useQueryParam } from '../../routes';
import { ConfirmResetPasswordForm } from './auth.types';
import { validationConfirmResetPasswordSchema } from './auth.validations';
import { AuthLayout } from './components/auth-layout';

export const ConfirmResetPasswordPage = () => {
  const { t } = useTranslation();
  const code = useQueryParam('oobCode');
  const navigate = useNavigate();

  const { confirmResetPassword, isValidResetCode } = useAuthState();
  const { error } = useQuery([code], () => isValidResetCode(code), {
    enabled: !!code,
    retry: false,
  });

  const { mutateAsync: submit } = useMutation(
    ({ values }: FormSubmitData<ConfirmResetPasswordForm>) => {
      return confirmResetPassword(code, values.password);
    },
    {
      onSettled: (data, error, { helpers }) => helpers.setSubmitting(false),
      onSuccess: () => {
        navigate(getPath(Routes.AUTH_LOGIN));
      },
    },
  );

  if (!code) {
    return <Navigate replace to={getPath(Routes.AUTH_RESET_PASSWORD)} />;
  }

  if (error) {
    return (
      <AuthLayout
        title={t(i18nKeys.confirm_reset_password.title)}
        introduction={t(i18nKeys.confirm_reset_password.errors.code_invalid)}
      >
        {/** TODO: replace this with a link **/}
        <Button
          type="submit"
          variant="primary"
          size="body1"
          mt="4rem"
          width="100%"
          onClick={() => navigate(getPath(Routes.AUTH_RESET_PASSWORD))}
        >
          {t(i18nKeys.confirm_reset_password.errors.cta)}
        </Button>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout
      title={t(i18nKeys.confirm_reset_password.title)}
      introduction={t(i18nKeys.confirm_reset_password.introduction)}
    >
      <Formik<ConfirmResetPasswordForm>
        initialValues={{ password: '', passwordConfirmed: '' }}
        validate={validateSchema(validationConfirmResetPasswordSchema)}
        onSubmit={handleFormSubmit(submit)}
      >
        {({ isSubmitting }) => (
          <Form>
            <InputField
              name="password"
              label="Password"
              isRequired
              isPassword
              hasValidation
            />
            <InputField
              name="passwordConfirmed"
              label={t(i18nKeys.confirm_reset_password.confirm_password)}
              isRequired
              isPassword
            />
            <Button
              type="submit"
              variant="primary"
              size="body1"
              mt="4rem"
              w="100%"
              isLoading={isSubmitting}
            >
              {t(i18nKeys.common.reset_password)}
            </Button>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};
