import { Text } from '@chakra-ui/layout';
import { Currency, Table } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import React from 'react';
import { useTopupsQuery } from '@libs/api-v2/endpoints';

type TopupTableProps = {
  membershipId: string;
  includeCanceled: boolean;
};

export const TopupTable = ({
  membershipId,
  includeCanceled,
}: TopupTableProps) => {
  const {
    data: topups,
    isLoading,
    isFetching,
  } = useTopupsQuery({
    membership_id: membershipId,
  });
  const { t } = useTranslation();

  const headers: TableHeaders = [
    {
      name: 'program_name',
      title: t(i18nKeys.common.program),
    },
    {
      name: 'created_on',
      title: t(i18nKeys.common.created_at),
    },
    {
      name: 'period_start_date',
      title: t(i18nKeys.employees.details.topup.table.period_start_date.title),
    },
    {
      name: 'amount',
      title: t(i18nKeys.common.amount),
    },
    {
      name: 'carry_over',
      title: t(i18nKeys.employees.details.topup.table.carry_over),
    },
    {
      name: 'budget_distribution',
      title: t(
        i18nKeys.employees.details.topup.table.budget_distribution.title,
      ),
    },
    '',
  ];
  if (includeCanceled) {
    headers.push({
      name: 'canceled_at',
      title: t(i18nKeys.employees.details.topup.table.canceled_at.title),
    });
  }
  return (
    <Table headers={headers} width="full">
      <Table.TBody
        isLoading={(isLoading || isFetching) && !topups}
        colSpan={headers.length}
        isEmpty={
          !isLoading && !isFetching && (topups?.data.length === 0 || !topups)
        }
      >
        {topups?.data?.map(
          (
            {
              budget_distribution,
              amount,
              program_id,
              carry_over,
              created_on,
              period_start_date,
              program_name,
            },
            index,
          ) => {
            return (
              <Fragment key={`${program_id + index}`}>
                <Table.TR
                  position="relative"
                  transform="scale(1,1)"
                  index={program_id + index}
                >
                  <Table.TD>{program_name}</Table.TD>
                  <Table.Date>{created_on}</Table.Date>
                  <Table.Date>{period_start_date}</Table.Date>
                  <Table.TD>
                    <Currency
                      size="Body2Bold"
                      textOptions={{
                        color: amount > 0 ? 'status.positive.type' : 'ink.dark',
                      }}
                    >
                      {amount}
                    </Currency>
                  </Table.TD>
                  <Table.TD>
                    <Text>
                      {carry_over
                        ? t(i18nKeys.common.yes)
                        : t(i18nKeys.common.no)}
                    </Text>
                  </Table.TD>
                  <Table.TD>
                    <Text>{budget_distribution}</Text>
                  </Table.TD>
                </Table.TR>
              </Fragment>
            );
          },
        )}
      </Table.TBody>
    </Table>
  );
};
