import { Box, Button } from '@chakra-ui/react';
import { forgotPassword } from '@libs/api/endpoints';
import { ForgotPasswordBody } from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { validateSchema } from '@libs/core/utils';
import { getPath, Routes } from '@libs/dashboard-core/routes';
import {
  FormSubmitData,
  handleFormSubmit,
  InputField,
  Link,
  useToast,
} from '@libs/ui/components';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { ResetPasswordForm } from './auth.types';
import { validationResetPasswordSchema } from './auth.validations';
import { AuthLayout } from './components/auth-layout';

export const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const toast = useToast();

  const { mutateAsync: submitForgotPassword } = useMutation(
    ({ values }: FormSubmitData<ResetPasswordForm>) => {
      const params: ForgotPasswordBody = {
        email: values.email,
        reset_password_url: `${window.location.origin}${getPath(
          Routes.AUTH_CONFIRM_RESET_PASSWORD,
        )}`,
      };
      return forgotPassword(params);
    },
    {
      onError: (data) => {
        toast({
          title: t(i18nKeys.common.issue),
          content: t(i18nKeys.confirm_reset_password.invalid_email),
          status: 'error',
        });
      },
      onSuccess: (data) => {
        toast({
          title: t(i18nKeys.common.congrats),
          content: t(i18nKeys.confirm_reset_password.success),
          status: 'success',
        });
      },
    },
  );

  return (
    <AuthLayout
      title={t(i18nKeys.reset_password.title)}
      introduction={t(i18nKeys.reset_password.introduction)}
    >
      <Formik<ResetPasswordForm>
        initialValues={{ email: '' }}
        validate={validateSchema(validationResetPasswordSchema)}
        onSubmit={handleFormSubmit(submitForgotPassword)}
      >
        {({ isSubmitting }) => (
          <Form noValidate>
            <InputField
              type="email"
              name="email"
              label={t(i18nKeys.common.email)}
              isRequired
            />
            <Button
              type="submit"
              variant="primary"
              size="body1"
              w="100%"
              mt="4rem"
              isLoading={isSubmitting}
            >
              {t(i18nKeys.common.reset_password)}
            </Button>
          </Form>
        )}
      </Formik>
      <Box pt="1rem" textAlign="center">
        <Link to={getPath(Routes.AUTH_LOGIN)}>{t(i18nKeys.login.back)}</Link>
      </Box>
    </AuthLayout>
  );
};
