/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { CardTransactionAllOfStatus } from './cardTransactionAllOfStatus';

export type CardTransactionAllOf = {
  transaction_reference?: string;
  card_reference?: string;
  payment_reference?: string;
  merchant_name?: string;
  merchant_code?: string;
  merchant_reference?: string;
  amount?: number;
  status?: CardTransactionAllOfStatus;
  authorization_note?: string;
  authorization_code?: string;
  membership_id?: string;
  program_id?: string;
  restriction_id?: string;
};
