/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  BudgetsGetResponse,
  ErrorResponse,
  BudgetsGetParams,
  Budget,
  BudgetsGetCurrentParams,
  BudgetsGetFutureParams,
  BudgetsGetPastParams,
} from '../../models';
import { customInstance } from '../../config';

export const budgetsGet = (params?: BudgetsGetParams, signal?: AbortSignal) => {
  return customInstance<BudgetsGetResponse>({
    url: `/web/budgets`,
    method: 'get',
    params,
    signal,
  });
};

export const getBudgetsGetQueryKey = (params?: BudgetsGetParams) => [
  `/web/budgets`,
  ...(params ? [params] : []),
];

export type BudgetsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof budgetsGet>>
>;
export type BudgetsGetQueryError = ErrorResponse;

export const useBudgetsGet = <
  TData = Awaited<ReturnType<typeof budgetsGet>>,
  TError = ErrorResponse,
>(
  params?: BudgetsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof budgetsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBudgetsGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof budgetsGet>>> = ({
    signal,
  }) => budgetsGet(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof budgetsGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const budgetsCreate = (budget: Budget) => {
  return customInstance<Budget>({
    url: `/web/budgets`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: budget,
  });
};

export type BudgetsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof budgetsCreate>>
>;
export type BudgetsCreateMutationBody = Budget;
export type BudgetsCreateMutationError = unknown;

export const useBudgetsCreate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof budgetsCreate>>,
    TError,
    { data: Budget },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof budgetsCreate>>,
    { data: Budget }
  > = (props) => {
    const { data } = props ?? {};

    return budgetsCreate(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof budgetsCreate>>,
    TError,
    { data: Budget },
    TContext
  >(mutationFn, mutationOptions);
};
export const budgetsUpdate = (budgetId: string, budget: Budget) => {
  return customInstance<Budget>({
    url: `/web/budgets/${budgetId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: budget,
  });
};

export type BudgetsUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof budgetsUpdate>>
>;
export type BudgetsUpdateMutationBody = Budget;
export type BudgetsUpdateMutationError = unknown;

export const useBudgetsUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof budgetsUpdate>>,
    TError,
    { budgetId: string; data: Budget },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof budgetsUpdate>>,
    { budgetId: string; data: Budget }
  > = (props) => {
    const { budgetId, data } = props ?? {};

    return budgetsUpdate(budgetId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof budgetsUpdate>>,
    TError,
    { budgetId: string; data: Budget },
    TContext
  >(mutationFn, mutationOptions);
};
export const budgetsGetCurrent = (
  params?: BudgetsGetCurrentParams,
  signal?: AbortSignal,
) => {
  return customInstance<BudgetsGetResponse>({
    url: `/web/budgets/current`,
    method: 'get',
    params,
    signal,
  });
};

export const getBudgetsGetCurrentQueryKey = (
  params?: BudgetsGetCurrentParams,
) => [`/web/budgets/current`, ...(params ? [params] : [])];

export type BudgetsGetCurrentQueryResult = NonNullable<
  Awaited<ReturnType<typeof budgetsGetCurrent>>
>;
export type BudgetsGetCurrentQueryError = unknown;

export const useBudgetsGetCurrent = <
  TData = Awaited<ReturnType<typeof budgetsGetCurrent>>,
  TError = unknown,
>(
  params?: BudgetsGetCurrentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof budgetsGetCurrent>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getBudgetsGetCurrentQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof budgetsGetCurrent>>
  > = ({ signal }) => budgetsGetCurrent(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof budgetsGetCurrent>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const budgetsGetFuture = (
  params?: BudgetsGetFutureParams,
  signal?: AbortSignal,
) => {
  return customInstance<BudgetsGetResponse>({
    url: `/web/budgets/future`,
    method: 'get',
    params,
    signal,
  });
};

export const getBudgetsGetFutureQueryKey = (
  params?: BudgetsGetFutureParams,
) => [`/web/budgets/future`, ...(params ? [params] : [])];

export type BudgetsGetFutureQueryResult = NonNullable<
  Awaited<ReturnType<typeof budgetsGetFuture>>
>;
export type BudgetsGetFutureQueryError = unknown;

export const useBudgetsGetFuture = <
  TData = Awaited<ReturnType<typeof budgetsGetFuture>>,
  TError = unknown,
>(
  params?: BudgetsGetFutureParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof budgetsGetFuture>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getBudgetsGetFutureQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof budgetsGetFuture>>
  > = ({ signal }) => budgetsGetFuture(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof budgetsGetFuture>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const budgetsGetPast = (
  params?: BudgetsGetPastParams,
  signal?: AbortSignal,
) => {
  return customInstance<BudgetsGetResponse>({
    url: `/web/budgets/past`,
    method: 'get',
    params,
    signal,
  });
};

export const getBudgetsGetPastQueryKey = (params?: BudgetsGetPastParams) => [
  `/web/budgets/past`,
  ...(params ? [params] : []),
];

export type BudgetsGetPastQueryResult = NonNullable<
  Awaited<ReturnType<typeof budgetsGetPast>>
>;
export type BudgetsGetPastQueryError = unknown;

export const useBudgetsGetPast = <
  TData = Awaited<ReturnType<typeof budgetsGetPast>>,
  TError = unknown,
>(
  params?: BudgetsGetPastParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof budgetsGetPast>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBudgetsGetPastQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof budgetsGetPast>>> = ({
    signal,
  }) => budgetsGetPast(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof budgetsGetPast>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};
