/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type MembershipV1Role =
  typeof MembershipV1Role[keyof typeof MembershipV1Role];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipV1Role = {
  EMPLOYEE: 'EMPLOYEE',
  FLEET_MGR: 'FLEET_MGR',
} as const;
