/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type PayrollReportMetadataStatus =
  typeof PayrollReportMetadataStatus[keyof typeof PayrollReportMetadataStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayrollReportMetadataStatus = {
  validating: 'validating',
  done: 'done',
} as const;
