/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { QueryOrganisationIdParameter } from './component-parameters/queryOrganisationIdParameter';
import type { QuerySortDefinitionParameter } from './component-parameters/querySortDefinitionParameter';
import type { QueryPageNumberParameter } from './component-parameters/queryPageNumberParameter';
import type { QueryPageSizeParameter } from './component-parameters/queryPageSizeParameter';

export type ShareholdersGetParams = {
  organisation_id?: QueryOrganisationIdParameter;
  sort?: QuerySortDefinitionParameter;
  page_number?: QueryPageNumberParameter;
  page_size?: QueryPageSizeParameter;
};
