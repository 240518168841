import { initErrorTracking } from '@libs/dashboard-core/config/sentry';
import '@libs/dashboard-core/config/yup-config';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './app/app';

/*if (process.env.NODE_ENV === 'development') {
  require('@libs/api/mock');
}*/

if (process.env.NODE_ENV === 'production') {
  initErrorTracking(process.env.NX_SENTRY_DSN, process.env.NX_ENVIRONMENT);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
