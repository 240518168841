import { MembershipSummariesGetResponse } from '@libs/api-v2/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { MembershipSummariesTableRow } from './membership-summaries-table-row';
import { Table } from '@libs/ui/components';

type MembershipSummariesTableProps = {
  membershipSummaries: MembershipSummariesGetResponse;
  refetch: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<MembershipSummariesGetResponse, unknown>>;
  isLoading: boolean;
  isFetching: boolean;
};

export const MembershipSummariesTable = ({
  membershipSummaries,
  isLoading,
  isFetching,
}: MembershipSummariesTableProps) => {
  const { t } = useTranslation();

  const headers: TableHeaders = [
    {
      name: 'title',
      title: t(i18nKeys.operator.employees.budgets.type.title),
    },
    {
      name: 'group_name',
      title: t(i18nKeys.operator.employees.budgets.group.title),
    },
    {
      name: 'budget_limit',
      title: t(i18nKeys.operator.employees.budgets.total),
    },
    {
      name: 'used',
      title: t(i18nKeys.common.used),
    },
    {
      name: 'remaining',
      title: t(i18nKeys.operator.employees.budgets.remaining),
    },
    {
      name: 'period_start_at',
      title: t(i18nKeys.operator.employees.budgets.period_start_date),
    },
    {
      name: 'period_end_at',
      title: t(i18nKeys.operator.employees.budgets.period_end_date),
    },
    {
      name: 'budget_start_on',
      title: t(i18nKeys.operator.employees.budgets.start_on),
    },
    {
      name: 'is_unlimited',
      title: t(i18nKeys.operator.employees.budgets.is_unlimited.title),
      filter: true,
      filterValues: [true.toString(), false.toString()],
      filterValuesI18nKey:
        i18nKeys.operator.employees.budgets.is_carry_over_enabled,
    },
    {
      name: 'is_current',
      title: t(i18nKeys.operator.employees.budgets.is_current.title),
      filter: true,
      filterValues: [true.toString(), false.toString()],
      filterValuesI18nKey:
        i18nKeys.operator.employees.budgets.is_carry_over_enabled,
    },
    '',
  ];

  return (
    <Table headers={headers} width="full">
      <Table.TBody
        isLoading={(isLoading || isFetching) && !membershipSummaries}
        colSpan={headers.length}
        isEmpty={
          !isLoading &&
          !isFetching &&
          (membershipSummaries?.length === 0 || !membershipSummaries)
        }
      >
        {membershipSummaries?.map((membershipSummary) => {
          return (
            <MembershipSummariesTableRow
              membershipSummary={membershipSummary}
            />
          );
        })}
      </Table.TBody>
    </Table>
  );
};
