/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryOrganisationIdParameter } from './component-parameters/queryOrganisationIdParameter';
import type { QueryProgramStatusParameter } from './component-parameters/queryProgramStatusParameter';
import type { QueryPageNumberParameter } from './component-parameters/queryPageNumberParameter';
import type { QueryPageSizeParameter } from './component-parameters/queryPageSizeParameter';
import type { QuerySortDefinitionParameter } from './component-parameters/querySortDefinitionParameter';
import type { QueryQParameter } from './component-parameters/queryQParameter';

export type ProgramsGetParams = {
  /**
   * ID of the organisation
   */
  organisation_id?: QueryOrganisationIdParameter;
  /**
   * Program status
   */
  program_status?: QueryProgramStatusParameter;
  /**
   * Number of the page to fetch
   */
  page_number?: QueryPageNumberParameter;
  /**
   * Size of the page to fetch
   */
  page_size?: QueryPageSizeParameter;
  /**
   * Result sort order
   */
  sort?: QuerySortDefinitionParameter;
  /**
   * Search query parameter
   */
  q?: QueryQParameter;
};
