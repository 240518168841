import { i18nKeys } from '@libs/core/i18n/dashboard-mobility';
import { RoutesDefinition } from './types';
import {
  getPathForRoutes,
  getRouteForRoutes,
  getRouterPathForRoutes,
  pickRouteForRoutes,
  useQueryParam,
  addQueryParams,
} from './utils';

export type Routes =
  | 'AUTH'
  | 'AUTH_LOGIN'
  | 'AUTH_CONFIRM_RESET_PASSWORD'
  | 'AUTH_RESET_PASSWORD'
  | 'CREATE_USER'
  | 'AUTH_SET_PASSWORD'
  | 'DASHBOARD'
  | 'ONBOARDING'
  | 'ONBOARDING_SUCCESS'
  | 'ORGANISATIONS'
  | 'ORGANISATIONS_DETAILS'
  | 'SERVICES'
  | 'EXPENSES'
  | 'DASHBOARD_EMPLOYEES'
  | 'DASHBOARD_EMPLOYEES_DETAILS'
  | 'DASHBOARD_TRANSACTIONS'
  | 'DASHBOARD_RESTRICTION_GROUPS'
  | 'DASHBOARD_MERCHANTS'
  | 'DASHBOARD_PROGRAMS'
  | 'DASHBOARD_PROGRAMS_DETAILS'
  | 'DASHBOARD_PROGRAMS_DETAILS_PREVIEW'
  | 'DASHBOARD_PROGRAMS_DETAILS_EMPLOYEES'
  | 'DASHBOARD_PROGRAMS_DETAILS_EXPENSES'
  | 'DASHBOARD_EMPLOYEES_DETAILS_EXPENSES_NEXTGEN'
  | 'DASHBOARD_PROGRAMS_DETAILS_PARAMETERS'
  | 'DASHBOARD_PROGRAMS_DETAILS_PARAMETERS_BASIC'
  | 'DASHBOARD_PROGRAMS_DETAILS_PARAMETERS_ENROLMENT'
  | 'DASHBOARD_PROGRAMS_DETAILS_PARAMETERS_POLICIES'
  | 'DASHBOARD_PROGRAMS_DETAILS_SERVICES_DETAILS'
  | 'DASHBOARD_CATALOG_PROGRAMS'
  | 'DASHBOARD_USERS'
  | 'DASHBOARD_USERS_EMPLOYEES'
  | 'DASHBOARD_USERS_GROUPS'
  | 'DASHBOARD_CATALOG_SERVICES'
  | 'DASHBOARD_CATALOG'
  | 'DASHBOARD_PROGRAMS_DETAILS_SERVICES_DETAILS_CONFIGURATION'
  | 'DASHBOARD_TRANSACTIONS_DETAILS'
  | 'DASHBOARD_RESTRICTIONS';

export const Routes = {
  SERVICE_UNAVAILABLE: 'SERVICE_UNAVAILABLE' as Routes,
  AUTH: 'AUTH' as Routes,
  AUTH_LOGIN: 'AUTH_LOGIN' as Routes,
  AUTH_CONFIRM_RESET_PASSWORD: 'AUTH_CONFIRM_RESET_PASSWORD' as Routes,
  AUTH_RESET_PASSWORD: 'AUTH_RESET_PASSWORD' as Routes,
  AUTH_CREATE_USER: 'AUTH_CREATE_USER' as Routes,
  AUTH_SET_PASSWORD: 'AUTH_SET_PASSWORD' as Routes,
  DASHBOARD: 'DASHBOARD' as Routes,
  ONBOARDING: 'ONBOARDING' as Routes,
  DASHBOARD_SERVICES: 'DASHBOARD_SERVICES' as Routes,
  DASHBOARD_EXPENSES: 'DASHBOARD_EXPENSES' as Routes,
  DASHBOARD_ORGANISATIONS: 'DASHBOARD_ORGANISATIONS' as Routes,
  DASHBOARD_ORGANISATIONS_DETAILS: 'DASHBOARD_ORGANISATIONS_DETAILS' as Routes,
  DASHBOARD_ORGANISATIONS_DETAILS_ONBOARDING:
    'DASHBOARD_ORGANISATIONS_ONBOARDING' as Routes,
  DASHBOARD_EMPLOYEES: 'DASHBOARD_EMPLOYEES' as Routes,
  DASHBOARD_EMPLOYEES_DETAILS: 'DASHBOARD_EMPLOYEES_DETAILS' as Routes,
  DASHBOARD_EMPLOYEES_DETAILS_BUDGETS:
    'DASHBOARD_EMPLOYEES_DETAILS_BUDGET' as Routes,
  DASHBOARD_EMPLOYEES_DETAILS_EXPENSES:
    'DASHBOARD_EMPLOYEES_DETAILS_EXPENSES' as Routes,
  DASHBOARD_EMPLOYEES_DETAILS_CARD:
    'DASHBOARD_EMPLOYEES_DETAILS_CARD' as Routes,
  DASHBOARD_EMPLOYEES_DETAILS_PROGRAMS:
    'DASHBOARD_EMPLOYEES_DETAILS_PROGRAM' as Routes,
  DASHBOARD_EMPLOYEES_DETAILS_MEMBERSHIP_SUMMARIES:
    'DASHBOARD_EMPLOYEES_DETAILS_MEMBERSHIP_SUMMARIES' as Routes,
  DASHBOARD_TRANSACTIONS: 'DASHBOARD_TRANSACTIONS' as Routes,
  DASHBOARD_RESTRICTION_GROUPS: 'DASHBOARD_RESTRICTION_GROUPS' as Routes,
  DASHBOARD_MERCHANTS: 'DASHBOARD_MERCHANTS' as Routes,
  DASHBOARD_PROGRAMS: 'DASHBOARD_PROGRAMS' as Routes,
  DASHBOARD_PROGRAMS_DETAILS: 'DASHBOARD_PROGRAMS_DETAILS' as Routes,
  DASHBOARD_PROGRAMS_DETAILS_PREVIEW:
    'DASHBOARD_PROGRAMS_DETAILS_PREVIEW' as Routes,
  DASHBOARD_PROGRAMS_DETAILS_EMPLOYEES:
    'DASHBOARD_PROGRAMS_DETAILS_EMPLOYEES' as Routes,
  DASHBOARD_PROGRAMS_DETAILS_EXPENSES:
    'DASHBOARD_PROGRAMS_DETAILS_EXPENSES' as Routes,
  DASHBOARD_PROGRAMS_DETAILS_PARAMETERS:
    'DASHBOARD_PROGRAMS_DETAILS_PARAMETERS' as Routes,
  DASHBOARD_PROGRAMS_DETAILS_PARAMETERS_BASIC:
    'DASHBOARD_PROGRAMS_DETAILS_PARAMETERS_BASIC' as Routes,
  DASHBOARD_PROGRAMS_DETAILS_PARAMETERS_ENROLMENT:
    'DASHBOARD_PROGRAMS_DETAILS_PARAMETERS_ENROLMENT' as Routes,
  DASHBOARD_PROGRAMS_DETAILS_PARAMETERS_POLICIES:
    'DASHBOARD_PROGRAMS_DETAILS_PARAMETERS_POLICIES' as Routes,
  DASHBOARD_PROGRAMS_DETAILS_SERVICES_DETAILS:
    'DASHBOARD_PROGRAMS_DETAILS_SERVICES_DETAILS' as Routes,
  DASHBOARD_CATALOG_PROGRAMS: 'DASHBOARD_CATALOG_PROGRAMS' as Routes,
  DASHBOARD_USERS: 'DASHBOARD_USERS' as Routes,
  DASHBOARD_USERS_EMPLOYEES: 'DASHBOARD_USERS_EMPLOYEES' as Routes,
  DASHBOARD_USERS_GROUPS: 'DASHBOARD_USERS_GROUPS' as Routes,
  DASHBOARD_CATALOG_SERVICES: 'DASHBOARD_CATALOG_SERVICES' as Routes,
  DASHBOARD_CATALOG: 'DASHBOARD_CATALOG' as Routes,
  DASHBOARD_PROGRAMS_DETAILS_SERVICES_DETAILS_CONFIGURATION:
    'DASHBOARD_PROGRAMS_DETAILS_SERVICES_DETAILS_CONFIGURATION' as Routes,
  DASHBOARD_TRANSACTIONS_DETAILS: 'DASHBOARD_TRANSACTIONS_DETAILS' as Routes,
  DASHBOARD_RULESETS: 'DASHBOARD_RULESETS' as Routes,
  DASHBOARD_RESTRICTIONS: 'DASHBOARD_RESTRICTIONS' as Routes,
};

export const ROUTES = {
  [Routes.SERVICE_UNAVAILABLE]: {
    title: Routes.SERVICE_UNAVAILABLE,
    path: '/service-unavailable',
  },
  [Routes.AUTH]: {
    title: Routes.AUTH,
    path: '/auth',
  },
  [Routes.AUTH_LOGIN]: {
    title: Routes.AUTH_LOGIN,
    path: '/login',
    parent: Routes.AUTH,
  },
  [Routes.AUTH_RESET_PASSWORD]: {
    title: Routes.AUTH_RESET_PASSWORD,
    path: '/reset-password',
    parent: Routes.AUTH,
  },
  [Routes.AUTH_CONFIRM_RESET_PASSWORD]: {
    title: Routes.AUTH_CONFIRM_RESET_PASSWORD,
    path: '/confirm-reset-password',
    parent: Routes.AUTH,
  },
  [Routes.AUTH_SET_PASSWORD]: {
    title: Routes.AUTH_SET_PASSWORD,
    path: '/first-set-password',
    parent: Routes.AUTH,
  },
  [Routes.DASHBOARD]: {
    title: Routes.DASHBOARD,
    path: '/',
    translationKey: i18nKeys.common.dashboard,
  },
  [Routes.ONBOARDING]: {
    title: Routes.ONBOARDING,
    path: '/onboarding',
  },
  [Routes.DASHBOARD_ORGANISATIONS]: {
    title: Routes.DASHBOARD_ORGANISATIONS,
    path: '/organisations',
    parent: Routes.DASHBOARD,
  },
  [Routes.DASHBOARD_SERVICES]: {
    title: Routes.DASHBOARD_SERVICES,
    path: '/services',
    parent: Routes.DASHBOARD,
  },
  [Routes.DASHBOARD_EXPENSES]: {
    title: Routes.DASHBOARD_EXPENSES,
    path: '/expenses',
    parent: Routes.DASHBOARD,
  },
  [Routes.DASHBOARD_ORGANISATIONS_DETAILS]: {
    title: Routes.DASHBOARD_ORGANISATIONS_DETAILS,
    path: '/:organisationId',
    parent: Routes.DASHBOARD_ORGANISATIONS,
  },
  [Routes.DASHBOARD_ORGANISATIONS_DETAILS_ONBOARDING]: {
    title: Routes.DASHBOARD_ORGANISATIONS_DETAILS_ONBOARDING,
    path: '/onboarding',
    parent: Routes.DASHBOARD_ORGANISATIONS_DETAILS,
  },
  [Routes.DASHBOARD_EMPLOYEES]: {
    title: Routes.DASHBOARD_EMPLOYEES,
    path: '/employees',
    parent: Routes.DASHBOARD,
  },
  [Routes.DASHBOARD_EMPLOYEES_DETAILS]: {
    title: Routes.DASHBOARD_EMPLOYEES_DETAILS,
    path: '/:membershipId',
    parent: Routes.DASHBOARD_EMPLOYEES,
  },
  [Routes.DASHBOARD_EMPLOYEES_DETAILS_BUDGETS]: {
    title: Routes.DASHBOARD_EMPLOYEES_DETAILS_BUDGETS,
    path: '/budgets',
    parent: Routes.DASHBOARD_EMPLOYEES_DETAILS,
  },
  [Routes.DASHBOARD_EMPLOYEES_DETAILS_PROGRAMS]: {
    title: Routes.DASHBOARD_EMPLOYEES_DETAILS_PROGRAMS,
    path: '/programs',
    parent: Routes.DASHBOARD_EMPLOYEES_DETAILS,
  },
  [Routes.DASHBOARD_EMPLOYEES_DETAILS_MEMBERSHIP_SUMMARIES]: {
    title: Routes.DASHBOARD_EMPLOYEES_DETAILS_MEMBERSHIP_SUMMARIES,
    path: '/membership-summaries',
    parent: Routes.DASHBOARD_EMPLOYEES_DETAILS,
  },
  [Routes.DASHBOARD_EMPLOYEES_DETAILS_EXPENSES]: {
    title: Routes.DASHBOARD_EMPLOYEES_DETAILS_EXPENSES,
    path: '/expenses',
    parent: Routes.DASHBOARD_EMPLOYEES_DETAILS,
  },
  [Routes.DASHBOARD_EMPLOYEES_DETAILS_CARD]: {
    title: Routes.DASHBOARD_EMPLOYEES_DETAILS_CARD,
    path: '/card',
    parent: Routes.DASHBOARD_EMPLOYEES_DETAILS,
  },
  [Routes.DASHBOARD_TRANSACTIONS]: {
    title: Routes.DASHBOARD_TRANSACTIONS,
    path: '/transactions',
    parent: Routes.DASHBOARD,
    translationKey: i18nKeys.common.transactions,
  },
  [Routes.DASHBOARD_RESTRICTION_GROUPS]: {
    title: Routes.DASHBOARD_RESTRICTION_GROUPS,
    path: '/restriction-groups',
    parent: Routes.DASHBOARD,
  },
  [Routes.DASHBOARD_MERCHANTS]: {
    title: Routes.DASHBOARD_MERCHANTS,
    path: '/merchants',
    parent: Routes.DASHBOARD,
  },
  [Routes.DASHBOARD_PROGRAMS]: {
    title: Routes.DASHBOARD_PROGRAMS,
    path: '/programs',
    parent: Routes.DASHBOARD,
    translationKey: i18nKeys.common.programs,
  },
  [Routes.DASHBOARD_PROGRAMS_DETAILS]: {
    title: Routes.DASHBOARD_PROGRAMS_DETAILS,
    path: '/:programId',
    parent: Routes.DASHBOARD_PROGRAMS,
  },
  [Routes.DASHBOARD_PROGRAMS_DETAILS_PREVIEW]: {
    title: Routes.DASHBOARD_PROGRAMS_DETAILS_PREVIEW,
    path: '/overview',
    parent: Routes.DASHBOARD_PROGRAMS_DETAILS,
    translationKey: i18nKeys.common.preview,
  },
  [Routes.DASHBOARD_PROGRAMS_DETAILS_EMPLOYEES]: {
    title: Routes.DASHBOARD_PROGRAMS_DETAILS_EMPLOYEES,
    path: '/employees',
    parent: Routes.DASHBOARD_PROGRAMS_DETAILS,
    translationKey: i18nKeys.common.employees,
  },
  [Routes.DASHBOARD_PROGRAMS_DETAILS_EXPENSES]: {
    title: Routes.DASHBOARD_PROGRAMS_DETAILS_EXPENSES,
    path: '/transactions',
    parent: Routes.DASHBOARD_PROGRAMS_DETAILS,
    translationKey: i18nKeys.common.expenses,
  },
  [Routes.DASHBOARD_PROGRAMS_DETAILS_PARAMETERS]: {
    title: Routes.DASHBOARD_PROGRAMS_DETAILS_PARAMETERS,
    path: '/settings',
    parent: Routes.DASHBOARD_PROGRAMS_DETAILS,
    translationKey: i18nKeys.common.parameters.title,
  },
  [Routes.DASHBOARD_PROGRAMS_DETAILS_PARAMETERS_BASIC]: {
    title: Routes.DASHBOARD_PROGRAMS_DETAILS_PARAMETERS_BASIC,
    path: '/basic',
    parent: Routes.DASHBOARD_PROGRAMS_DETAILS_PARAMETERS,
    translationKey: i18nKeys.common.basic,
  },
  [Routes.DASHBOARD_PROGRAMS_DETAILS_PARAMETERS_ENROLMENT]: {
    title: Routes.DASHBOARD_PROGRAMS_DETAILS_PARAMETERS_ENROLMENT,
    path: '/enrolment',
    parent: Routes.DASHBOARD_PROGRAMS_DETAILS_PARAMETERS,
    translationKey: i18nKeys.common.enrolment,
  },
  [Routes.DASHBOARD_PROGRAMS_DETAILS_PARAMETERS_POLICIES]: {
    title: Routes.DASHBOARD_PROGRAMS_DETAILS_PARAMETERS_POLICIES,
    path: '/policies',
    parent: Routes.DASHBOARD_PROGRAMS_DETAILS_PARAMETERS,
    translationKey: i18nKeys.common.restriction_policies,
  },
  [Routes.DASHBOARD_PROGRAMS_DETAILS_SERVICES_DETAILS]: {
    title: Routes.DASHBOARD_PROGRAMS_DETAILS_SERVICES_DETAILS,
    path: '/:serviceId',
    parent: Routes.DASHBOARD_PROGRAMS_DETAILS,
  },
  [Routes.DASHBOARD_USERS]: {
    title: Routes.DASHBOARD_USERS,
    path: '/users',
    parent: Routes.DASHBOARD,
    translationKey: i18nKeys.common.users,
  },
  [Routes.DASHBOARD_USERS_EMPLOYEES]: {
    title: Routes.DASHBOARD_USERS_EMPLOYEES,
    path: '/employees',
    parent: Routes.DASHBOARD_USERS,
    translationKey: i18nKeys.common.employees,
  },
  [Routes.DASHBOARD_USERS_GROUPS]: {
    title: Routes.DASHBOARD_USERS_GROUPS,
    path: '/groups',
    parent: Routes.DASHBOARD_USERS,
    translationKey: i18nKeys.common.groups,
  },
  [Routes.DASHBOARD_CATALOG]: {
    title: Routes.DASHBOARD_CATALOG,
    path: '/catalog',
    parent: Routes.DASHBOARD,
    translationKey: i18nKeys.common.catalog,
  },
  [Routes.DASHBOARD_CATALOG_PROGRAMS]: {
    title: Routes.DASHBOARD_CATALOG_PROGRAMS,
    path: '/catalog-programs',
    parent: Routes.DASHBOARD_CATALOG,
    translationKey: i18nKeys.common.programs,
  },
  [Routes.DASHBOARD_CATALOG_SERVICES]: {
    title: Routes.DASHBOARD_CATALOG_SERVICES,
    path: '/catalog-services',
    parent: Routes.DASHBOARD_CATALOG,
    translationKey: i18nKeys.common.services,
  },
  [Routes.DASHBOARD_PROGRAMS_DETAILS_SERVICES_DETAILS_CONFIGURATION]: {
    title: Routes.DASHBOARD_PROGRAMS_DETAILS_SERVICES_DETAILS_CONFIGURATION,
    path: '/configuration',
    parent: Routes.DASHBOARD_PROGRAMS_DETAILS_SERVICES_DETAILS,
    translationKey: i18nKeys.common.service_activation,
  },
  [Routes.DASHBOARD_TRANSACTIONS_DETAILS]: {
    title: Routes.DASHBOARD_TRANSACTIONS_DETAILS,
    path: '/:transactionId',
    parent: Routes.DASHBOARD_TRANSACTIONS,
  },
  [Routes.DASHBOARD_RULESETS]: {
    title: Routes.DASHBOARD_RULESETS,
    path: '/rulesets',
  },
  [Routes.DASHBOARD_RESTRICTIONS]: {
    title: Routes.DASHBOARD_RESTRICTIONS,
    path: '/restrictions',
  },
} as RoutesDefinition<Routes>;

export const getRoute = getRouteForRoutes(ROUTES);
export const getRouterPath = getRouterPathForRoutes(ROUTES);
export const getPath = getPathForRoutes(ROUTES);
export const pickRoute = pickRouteForRoutes(ROUTES);
export { useQueryParam, addQueryParams };
