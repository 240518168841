/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  ExpenseReportsGetResponse,
  GetExpenseReportsParams,
  ExpenseReportsUrlGetResponse,
  GetExpenseReportsUrlParams,
  ReportGraphDataGetResponse,
  ReportGetReportCo2GraphDataParams,
  ReportSummaryDataGetResponse,
  ReportGetReportCo2SummaryDataParams,
  ReportExpensesSummaryDataGetResponse,
  ReportGetReportExpensesSummaryDataParams,
} from '../../models';
import { customInstance } from '../../config';

export const getExpenseReports = (
  params?: GetExpenseReportsParams,
  signal?: AbortSignal,
) => {
  return customInstance<ExpenseReportsGetResponse>({
    url: `/web/v2/expense-reports`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetExpenseReportsQueryKey = (
  params?: GetExpenseReportsParams,
) => [`/web/v2/expense-reports`, ...(params ? [params] : [])] as const;

export const getGetExpenseReportsQueryOptions = <
  TData = Awaited<ReturnType<typeof getExpenseReports>>,
  TError = unknown,
>(
  params?: GetExpenseReportsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getExpenseReports>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getExpenseReports>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetExpenseReportsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getExpenseReports>>
  > = ({ signal }) => getExpenseReports(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetExpenseReportsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getExpenseReports>>
>;
export type GetExpenseReportsQueryError = unknown;

export const useGetExpenseReports = <
  TData = Awaited<ReturnType<typeof getExpenseReports>>,
  TError = unknown,
>(
  params?: GetExpenseReportsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getExpenseReports>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetExpenseReportsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getExpenseReportsUrl = (
  params?: GetExpenseReportsUrlParams,
  signal?: AbortSignal,
) => {
  return customInstance<ExpenseReportsUrlGetResponse>({
    url: `/web/v2/expense-reports-url`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetExpenseReportsUrlQueryKey = (
  params?: GetExpenseReportsUrlParams,
) => [`/web/v2/expense-reports-url`, ...(params ? [params] : [])] as const;

export const getGetExpenseReportsUrlQueryOptions = <
  TData = Awaited<ReturnType<typeof getExpenseReportsUrl>>,
  TError = unknown,
>(
  params?: GetExpenseReportsUrlParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getExpenseReportsUrl>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getExpenseReportsUrl>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetExpenseReportsUrlQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getExpenseReportsUrl>>
  > = ({ signal }) => getExpenseReportsUrl(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetExpenseReportsUrlQueryResult = NonNullable<
  Awaited<ReturnType<typeof getExpenseReportsUrl>>
>;
export type GetExpenseReportsUrlQueryError = unknown;

export const useGetExpenseReportsUrl = <
  TData = Awaited<ReturnType<typeof getExpenseReportsUrl>>,
  TError = unknown,
>(
  params?: GetExpenseReportsUrlParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getExpenseReportsUrl>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetExpenseReportsUrlQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const reportGetReportCo2GraphData = (
  reportType: 'CO2' | 'EXPENSES',
  params?: ReportGetReportCo2GraphDataParams,
  signal?: AbortSignal,
) => {
  return customInstance<ReportGraphDataGetResponse>({
    url: `/web/v2/reports/${reportType}/graphs`,
    method: 'get',
    params,
    signal,
  });
};

export const getReportGetReportCo2GraphDataQueryKey = (
  reportType: 'CO2' | 'EXPENSES',
  params?: ReportGetReportCo2GraphDataParams,
) =>
  [
    `/web/v2/reports/${reportType}/graphs`,
    ...(params ? [params] : []),
  ] as const;

export const getReportGetReportCo2GraphDataQueryOptions = <
  TData = Awaited<ReturnType<typeof reportGetReportCo2GraphData>>,
  TError = unknown,
>(
  reportType: 'CO2' | 'EXPENSES',
  params?: ReportGetReportCo2GraphDataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof reportGetReportCo2GraphData>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof reportGetReportCo2GraphData>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getReportGetReportCo2GraphDataQueryKey(reportType, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof reportGetReportCo2GraphData>>
  > = ({ signal }) => reportGetReportCo2GraphData(reportType, params, signal);

  return { queryKey, queryFn, enabled: !!reportType, ...queryOptions };
};

export type ReportGetReportCo2GraphDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof reportGetReportCo2GraphData>>
>;
export type ReportGetReportCo2GraphDataQueryError = unknown;

export const useReportGetReportCo2GraphData = <
  TData = Awaited<ReturnType<typeof reportGetReportCo2GraphData>>,
  TError = unknown,
>(
  reportType: 'CO2' | 'EXPENSES',
  params?: ReportGetReportCo2GraphDataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof reportGetReportCo2GraphData>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getReportGetReportCo2GraphDataQueryOptions(
    reportType,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const reportGetReportCo2SummaryData = (
  params?: ReportGetReportCo2SummaryDataParams,
  signal?: AbortSignal,
) => {
  return customInstance<ReportSummaryDataGetResponse>({
    url: `/web/v2/reports/co2/summaries`,
    method: 'get',
    params,
    signal,
  });
};

export const getReportGetReportCo2SummaryDataQueryKey = (
  params?: ReportGetReportCo2SummaryDataParams,
) => [`/web/v2/reports/co2/summaries`, ...(params ? [params] : [])] as const;

export const getReportGetReportCo2SummaryDataQueryOptions = <
  TData = Awaited<ReturnType<typeof reportGetReportCo2SummaryData>>,
  TError = unknown,
>(
  params?: ReportGetReportCo2SummaryDataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof reportGetReportCo2SummaryData>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof reportGetReportCo2SummaryData>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getReportGetReportCo2SummaryDataQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof reportGetReportCo2SummaryData>>
  > = ({ signal }) => reportGetReportCo2SummaryData(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ReportGetReportCo2SummaryDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof reportGetReportCo2SummaryData>>
>;
export type ReportGetReportCo2SummaryDataQueryError = unknown;

export const useReportGetReportCo2SummaryData = <
  TData = Awaited<ReturnType<typeof reportGetReportCo2SummaryData>>,
  TError = unknown,
>(
  params?: ReportGetReportCo2SummaryDataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof reportGetReportCo2SummaryData>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getReportGetReportCo2SummaryDataQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const reportGetReportExpensesSummaryData = (
  params?: ReportGetReportExpensesSummaryDataParams,
  signal?: AbortSignal,
) => {
  return customInstance<ReportExpensesSummaryDataGetResponse>({
    url: `/web/v2/reports/expenses/summaries`,
    method: 'get',
    params,
    signal,
  });
};

export const getReportGetReportExpensesSummaryDataQueryKey = (
  params?: ReportGetReportExpensesSummaryDataParams,
) =>
  [`/web/v2/reports/expenses/summaries`, ...(params ? [params] : [])] as const;

export const getReportGetReportExpensesSummaryDataQueryOptions = <
  TData = Awaited<ReturnType<typeof reportGetReportExpensesSummaryData>>,
  TError = unknown,
>(
  params?: ReportGetReportExpensesSummaryDataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof reportGetReportExpensesSummaryData>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof reportGetReportExpensesSummaryData>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getReportGetReportExpensesSummaryDataQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof reportGetReportExpensesSummaryData>>
  > = ({ signal }) => reportGetReportExpensesSummaryData(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ReportGetReportExpensesSummaryDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof reportGetReportExpensesSummaryData>>
>;
export type ReportGetReportExpensesSummaryDataQueryError = unknown;

export const useReportGetReportExpensesSummaryData = <
  TData = Awaited<ReturnType<typeof reportGetReportExpensesSummaryData>>,
  TError = unknown,
>(
  params?: ReportGetReportExpensesSummaryDataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof reportGetReportExpensesSummaryData>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getReportGetReportExpensesSummaryDataQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
