/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type ExpenseInterface =
  typeof ExpenseInterface[keyof typeof ExpenseInterface];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseInterface = {
  APP: 'APP',
  CARD: 'CARD',
  MANUAL: 'MANUAL',
  IBAN: 'IBAN',
} as const;
