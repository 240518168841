/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';

export interface MembershipSummary {
  membership_id?: Uuid;
  program_id?: Uuid;
  group_id?: Uuid;
  group_limit_id?: Uuid;
  program_group_id?: Uuid;
  group_name?: string;
  budget_limit?: number;
  remaining?: number;
  used: number;
  /** total used amount of the budget but in % */
  used_percentage?: number;
  used_pending?: number;
  /** used amount that's still waiting for approval in % */
  used_pending_percentage?: number;
  used_approved?: number;
  /** used amount that's been approved in % */
  used_approved_percentage?: number;
  reserved: number;
  /** % of budget_limit that is reserved */
  reserved_percentage?: number;
  start_date?: string;
  end_date?: string;
  is_unlimited?: boolean;
  program_name?: string;
  /** Computed boolean of current range dates. */
  is_current?: boolean;
  /** Date of the budget start for that summary, which is the date of the first topup for that budget. */
  budget_start_on?: string;
}
