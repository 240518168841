/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { ExpenseAttachment } from './expenseAttachment';
import type { ExpenseAttachmentWithContentAllOf } from './expenseAttachmentWithContentAllOf';

/**
 * Document used as proof to justify an expense including the document encoded in base64
 */
export type ExpenseAttachmentWithContent = ExpenseAttachment &
  ExpenseAttachmentWithContentAllOf;
