import { Flex, Stack, Text } from '@chakra-ui/react';
import {
  getShareholderGetQueryKey,
  organisationUpdate,
  shareholderCreate,
  shareholderUpdate,
  useShareholdersGet,
} from '@libs/api/endpoints';
import {
  DocumentType,
  OnboardingStatus,
  Organisation,
  ShareholdersGetResponse,
  Title,
} from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { validateSchema } from '@libs/core/utils/validation';
import { ONBOARDING_EVENTS } from '@libs/dashboard-core/constants/analytics';
import {
  Assistant,
  FormSubmitData,
  handleFormSubmit,
  Loader,
  useWizard,
} from '@libs/ui/components';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OnboardingLayout } from '../components/onboarding-layout';
import {
  ShareholderTypeForm,
  SHAREHOLDER_DEFAULT,
  SHAREHOLDER_ID_NEW,
} from '../onboarding-wizard.constants';
import { OnboardingShareholderFormData } from '../onboarding-wizard.types';
import { uploadFile } from '../onboarding-wizard.utils';
import { validationShareholderSchema } from '../onboarding-wizard.validations';
import { ShareholderDetails } from './shareholder-details';
import { ShareholderSelection } from './shareholder-selection';

type ShareholderFormProps = {
  selectShareholder: (shareholderId: string) => void;
  shareholderId?: string;
  organisation: Organisation;
};
export const ShareholderForm = ({
  selectShareholder,
  shareholderId,
  organisation,
}: ShareholderFormProps) => {
  const { previousStep, nextStep } = useWizard();
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(false);
  const queryClient = useQueryClient();
  const {
    data: shareholders,
    isLoading,
    queryKey,
  } = useShareholdersGet({
    organisation_id: organisation.id,
  });

  const { mutateAsync: submit } = useMutation(
    async ({ values }: FormSubmitData<OnboardingShareholderFormData>) => {
      const { shareholderId, dao, ...shareholder } = values;
      if (shareholderId === SHAREHOLDER_ID_NEW) {
        const shareholderCreated = await shareholderCreate({
          ...shareholder,
          organisation_id: organisation.id,
        });

        const uploadedDocument = dao.length
          ? await uploadFile({
              file: dao[0],
              document_type: DocumentType.PROXY_GRANTING_EMPLOYEE,
            })
          : null;

        const shareholderUpdated = uploadedDocument
          ? shareholderUpdate(shareholderCreated.id, {
              ...shareholderCreated,
              documents: [uploadedDocument],
            })
          : shareholderCreated;

        return shareholderUpdated;
      }
      return shareholderUpdate(shareholderId, shareholder);
    },
    {
      onSuccess: (shareholder) => {
        selectShareholder(shareholder.id);
        queryClient.setQueryData(
          getShareholderGetQueryKey(shareholder.id),
          shareholder,
        );
        queryClient.setQueryData(queryKey, {
          ...(shareholders as ShareholdersGetResponse),
          data: [
            ...shareholders.data.filter(({ id }) => shareholder.id !== id),
            shareholder,
          ],
        });
        organisationUpdate(shareholder.organisation_id, {
          // no need to pass all org fields only these ones are required
          use_mobility_budget: organisation.use_mobility_budget,
          use_payment_card: organisation.use_payment_card,
          is_freelancer: organisation.is_freelancer,
          onboarding_status: OnboardingStatus.UBO_SELECTED,
          legal_name: organisation.legal_name,
        });
        window.analytics.track(ONBOARDING_EVENTS.UBO_DETAILS_COMPLETED);
        nextStep();
      },
    },
  );

  useEffect(() => {
    if (
      shareholders?.data.find((shareholder) => shareholder.id === shareholderId)
    ) {
      setShowDetails(true);
    }
  }, [shareholders, shareholderId]);

  if (isLoading) {
    return <Loader />;
  }

  const initialShareholderValues = {
    shareholderId: shareholderId || '',
    ...(shareholders.data.find(
      (shareholder) => shareholder.id === shareholderId,
    ) || SHAREHOLDER_DEFAULT),
    dao: [],
    daoUrl: '',
    shareholderKnown: ShareholderTypeForm.UNDEFINED,
    shareholderKnownId: '',
    title: Title.M,
  };

  return (
    <OnboardingLayout
      title={t(i18nKeys.onboarding.review_beneficiary.title)}
      introduction={t(i18nKeys.onboarding.review_beneficiary.select.title)}
    >
      <Flex direction="column">
        <Stack>
          <Formik<OnboardingShareholderFormData>
            initialValues={initialShareholderValues}
            validate={validateSchema(validationShareholderSchema)}
            onSubmit={handleFormSubmit(submit)}
          >
            {({ values, isSubmitting, resetForm, isValid }) => (
              <Form noValidate>
                {!showDetails && (
                  <ShareholderSelection
                    shareholders={shareholders}
                    values={values}
                    previousStep={previousStep}
                    resetForm={resetForm}
                    setShowDetails={setShowDetails}
                  />
                )}
                {showDetails && (
                  <ShareholderDetails
                    values={values}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    resetForm={resetForm}
                    setShowDetails={setShowDetails}
                  />
                )}
              </Form>
            )}
          </Formik>
        </Stack>
      </Flex>

      <Assistant>
        <Assistant.Card
          title={t(i18nKeys.onboarding.review_beneficiary.assistant.title)}
        >
          <Text my={4}>
            {t(i18nKeys.onboarding.review_beneficiary.assistant.content)}
          </Text>
        </Assistant.Card>
      </Assistant>
    </OnboardingLayout>
  );
};
