/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { Status } from './status';

export type OrganisationsStatisticsGetParams = {
  status?: Status;
  period_from?: string;
  period_to?: string;
};
