/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { CostEstimationRequestConfiguration } from './costEstimationRequestConfiguration';

/**
 * for now, it's specific to km_allowance
 */
export interface CostEstimationRequest {
  program_id?: string;
  membership_id?: string;
  configuration?: CostEstimationRequestConfiguration;
}
