/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { FrontendConfigurationCustomApp } from './frontendConfigurationCustomApp';

export interface FrontendConfigurationEnvironmentVariables {
  custom_app: FrontendConfigurationCustomApp;
  firebase_web_api_key: string;
  firebase_auth_domain: string;
  firebase_database_url: string;
  firebase_project_id: string;
  firebase_sender_id: string;
  segment_key: string;
  sentry_dsn: string;
  hipay_username: string;
  hipay_password: string;
  hipay_env: string;
  hipay_language: string;
  /** The version of the app */
  release_version: string;
  google_maps_api_key: string;
}
