/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export interface PayrollReportDetails {
  formatting_rule_id?: string;
  formatting_rule_name?: string;
  expenses_count?: number;
  employees_count?: number;
  aggregated_value?: number;
  aggregate_unit?: string;
  pay_code?: string;
}
