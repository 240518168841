/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export interface Taxes {
  is_us_person?: boolean;
  tax_residence_id?: string;
}
