/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { DocumentV1 } from './documentV1';
import type { OrganisationDocumentAllOf } from './organisationDocumentAllOf';

export type OrganisationDocument = DocumentV1 & OrganisationDocumentAllOf;
