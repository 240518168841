import * as Yup from 'yup';

export const validationMerchantSchema = Yup.object({
  provider_id: Yup.string().required(),
});

export const validationCreateMerchantIdRestrictionSchema = Yup.object({
  name: Yup.string().required(),
  start_at: Yup.string().required(),
});
