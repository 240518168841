/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { AmountOfMoney } from './amountOfMoney';
import type { AuthorizationStatus } from './authorizationStatus';
import type { AuthorizationFailureReason } from './authorizationFailureReason';

export interface PaymentAuthorization {
  id: Uuid;
  amount: AmountOfMoney;
  service_id: Uuid;
  product_id: Uuid;
  payment_method_id: Uuid;
  authorized_at: string;
  /** Status of authorization from HiPay */
  status: AuthorizationStatus;
  /** The reason of the failure */
  failure_reason?: AuthorizationFailureReason;
  /** URL to complete the transaction */
  forward_url?: string;
}
