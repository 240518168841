/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

/**
 * Outcome of 3DS challenge
 */
export type ScaAuthenticationStatus =
  typeof ScaAuthenticationStatus[keyof typeof ScaAuthenticationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScaAuthenticationStatus = {
  AUTHORIZED: 'AUTHORIZED',
  FAILED: 'FAILED',
} as const;
