import { useOrganisationGet } from '@libs/api/endpoints';
import { Status } from '@libs/api/models';
import { Loader, useToast } from '@libs/ui/components';
import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { AvailableSteps } from '../components/onboarding-wizard/onboarding-wizard.types';
import { useAuthState } from '../context';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { getPath, Routes } from '../routes';
import { useFleetManagerMembership } from '../utils/membership';

type OnboardedRouteProps = {
  children: ReactNode;
};

const statusRedirectToOnboarding: Status[] = [
  Status.VALIDATED,
  Status.CANCELED,
];

export const OnboardedRoute = ({ children }: OnboardedRouteProps) => {
  const membership = useFleetManagerMembership();
  const { signout } = useAuthState();
  const { t } = useTranslation();

  const toast = useToast();

  const { data: organisation, isLoading } = useOrganisationGet(
    membership?.organisation_id,
  );

  useEffect(() => {
    if (!membership) {
      signout();
      toast({
        title: 'UNAUTHORIZED',
        content: t(i18nKeys.common.errors.unauthorized.employee),
        status: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membership]);

  if (isLoading || !membership) {
    return <Loader />;
  }

  if (organisation.status === Status.IN_REVIEW) {
    const path =
      getPath(Routes.ONBOARDING) + '#' + AvailableSteps.UPLOAD_EMPLOYEES;
    return <Navigate replace to={path} />;
  }

  if (!statusRedirectToOnboarding.includes(organisation.status)) {
    return <Navigate replace to={getPath(Routes.ONBOARDING)} />;
  }
  return children as React.ReactElement;
};
