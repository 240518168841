/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { KmAllowanceType } from './kmAllowanceType';

export interface KmAllowance {
  enabled?: boolean;
  bike_fee_per_km?: number;
  car_fee_per_km?: number;
  bike_allowance_type?: KmAllowanceType;
  car_allowance_type?: KmAllowanceType;
}
