/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { MerchantRestrictionGroup } from './merchantRestrictionGroup';
import type { MerchantIdRestrictionGroupGet200AllOf } from './merchantIdRestrictionGroupGet200AllOf';

export type MerchantIdRestrictionGroupGet200 = MerchantRestrictionGroup &
  MerchantIdRestrictionGroupGet200AllOf;
