/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type AuthorizationFailureReason =
  typeof AuthorizationFailureReason[keyof typeof AuthorizationFailureReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuthorizationFailureReason = {
  SCA_AUTHENTICATION_FAILED: 'SCA_AUTHENTICATION_FAILED',
  CAPTURE_FAILED: 'CAPTURE_FAILED',
  AUTHORIZATION_RENEWAL_FAILED: 'AUTHORIZATION_RENEWAL_FAILED',
} as const;
