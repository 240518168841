import { Flex, Text } from '@chakra-ui/layout';
import {
  useCardsGet,
  useImcclRestrictionCardsGet,
  useListPaymentMethod,
} from '@libs/api-v2/endpoints';
import {
  ActivationStatus,
  CardStatus,
  ImcclRestrictionCardStatus,
  PaymentMethodStatus,
  QueryPaymentMethodTypeParameter,
} from '@libs/api-v2/models';
import { PAGE_SIZE } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { DrawerType } from '@libs/dashboard-core/pages/dashboard/dashboard.utils';
import { Currency, Pager, Table, Tag } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useDrawer, useTable } from '@libs/ui/components/table/table.utils';
import { useIcon } from '@libs/core/theme/utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RestrictionGroupType } from '../../../restriction-group/restriction-group.type';
import {
  CardStatusToTagStatus,
  ProgramStatusToTagStatus,
} from '../../employee.utils';
import { CardBlockDrawer } from './card-block-drawer';
import { CardEditDrawer } from './card-edit-drawer';
import { CardRestrictionGroupName } from './card-restriction-group-name';
import { ConvertPhysicalCardDrawer } from './convert-physical-card-drawer';
import { Registration } from '@libs/api-v2/models';
import { ImcclRestrictionName } from '../../../restriction/components/imccl-restriction-name';

type CardTableProps = {
  employee: Registration;
  hasImccl: boolean;
};

export const CardTable = ({ employee, hasImccl }: CardTableProps) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const EditIcon = useIcon('Edit');
  const { t } = useTranslation();
  const CreditCardIcon = useIcon('CreditCard');
  const BlockIcon = useIcon('Blocked');
  const {
    activeDrawerIndex,
    drawerHandler,
    isOpen,
    onClose,
    selectedDrawerType,
  } = useDrawer();
  const { useQueryParams } = useTable({
    setPageNumber,
    setPageSize,
  });

  const params = {
    membership_ids: [employee.membership_id],
    page_number: pageNumber,
    page_size: pageSize,
  };
  const { data, isLoading, isFetching, refetch } = useCardsGet(params, {
    query: { keepPreviousData: true },
  });

  const { data: personalCards, isLoading: isLoadingPersonalCards } =
    useListPaymentMethod({
      user_id: employee.user_id,
      payment_method_type: QueryPaymentMethodTypeParameter.CREDIT_CARD,
    });

  useQueryParams({
    totalPages: data?.meta?.total_pages,
    pageSize,
    pageNumber,
    setPageSize,
    refetch,
  });

  const { data: cards, meta } = data || {};

  // sort cards by status
  // first in the list are unlocked
  const sortedCards = cards?.sort(({ card_status }) =>
    card_status === CardStatus.UNLOCK ? -1 : 1,
  );
  const firstCard =
    !!sortedCards && sortedCards.length > 0 ? sortedCards[0] : null;
  const { data: imcclRestrictions } = useImcclRestrictionCardsGet(
    firstCard?.card_reference,
    { query: { enabled: !!sortedCards } },
  );
  const activeImcclRestriction = imcclRestrictions?.data?.find(
    (e) => e.status === ImcclRestrictionCardStatus.CURRENT,
  );

  const headers: TableHeaders = [
    t(i18nKeys.operator.cards.card_reference),
    t(i18nKeys.operator.cards.monthly_limit),
    t(i18nKeys.operator.cards.is_physical),
    t(i18nKeys.common.employee.card.eligibility.title),
    t(i18nKeys.operator.cards.mcc_restriction_group),
    t(i18nKeys.operator.cards.mid_restriction_group),
    'IMCCL restriction',
    t(i18nKeys.common.expire_at),
    t(i18nKeys.common.updated_at),
    { name: 'card_status', title: t(i18nKeys.common.status) },
    { name: 'pin_status', title: t(i18nKeys.common.pin_status) },
    t(i18nKeys.operator.cards.card_coupled),
    '',
  ];

  const editDrawerHandler = (index) =>
    drawerHandler({
      index,
      drawerType: DrawerType.edit,
    });

  const hasPersonalCard = !!personalCards?.data?.find(
    (card) => !card.deleted_at && card.status === PaymentMethodStatus.VALIDATED,
  );
  return (
    <>
      <Table headers={headers}>
        <Table.TBody
          colSpan={headers.length}
          isLoading={
            (isLoading || isLoadingPersonalCards || isFetching) && !cards
          }
          isEmpty={!isLoading && !isFetching && (!cards || cards?.length === 0)}
        >
          {sortedCards?.map((card, index) => {
            return (
              <Table.TR
                key={card.id}
                selectable
                index={card.id}
                onClick={() => editDrawerHandler(index)}
              >
                <Table.TD>{card.card_reference}</Table.TD>
                <Table.TD>
                  <Currency size="Body2Bold">{card.monthly_limit}</Currency>
                </Table.TD>
                <Table.TD>
                  {card.is_physical
                    ? t(i18nKeys.common.yes)
                    : t(i18nKeys.common.no)}
                </Table.TD>
                <Table.TD>
                  {employee.use_physical_card
                    ? t(i18nKeys.common.employee.card.eligible)
                    : t(i18nKeys.common.employee.card.not_eligible)}
                </Table.TD>
                <Table.TD>
                  <CardRestrictionGroupName
                    type={RestrictionGroupType.MCC}
                    reference={card.mcc_restriction_group_reference}
                  />
                </Table.TD>
                <Table.TD>
                  <CardRestrictionGroupName
                    type={RestrictionGroupType.MID}
                    reference={card.mid_restriction_group_reference}
                  />
                </Table.TD>
                <Table.TD onClick={() => editDrawerHandler(index)}>
                  <ImcclRestrictionName
                    imccl_restriction_id={
                      activeImcclRestriction?.imccl_restriction_id
                    }
                  />
                </Table.TD>
                <Table.Date onClick={() => editDrawerHandler(index)}>
                  {card.expiry_date}
                </Table.Date>
                <Table.Date onClick={() => editDrawerHandler(index)}>
                  {card.updated_at}
                </Table.Date>
                <Table.TD onClick={() => editDrawerHandler(index)}>
                  <Tag
                    hasIcon
                    tooltipLabel={
                      <Text size="Small">
                        {t(
                          i18nKeys.operator.cards.CardStatus[card.card_status],
                        )}
                      </Text>
                    }
                    status={CardStatusToTagStatus[card.card_status]}
                  />
                </Table.TD>
                <Table.TD>
                  <Tag
                    hasIcon
                    tooltipLabel={
                      <Text size="Small">
                        {card.pin_try_exceeded
                          ? t(i18nKeys.operator.cards.CardStatus['LOCK'])
                          : t(i18nKeys.operator.cards.CardStatus['UNLOCK'])}
                      </Text>
                    }
                    status={
                      card.pin_try_exceeded
                        ? CardStatusToTagStatus['LOCK']
                        : CardStatusToTagStatus['UNLOCK']
                    }
                  />
                </Table.TD>
                <Table.TD>
                  <Tag
                    hasIcon
                    status={
                      hasPersonalCard
                        ? ProgramStatusToTagStatus[ActivationStatus.ACTIVE]
                        : ProgramStatusToTagStatus[ActivationStatus.INACTIVE]
                    }
                  />
                </Table.TD>
                <Table.Action
                  actions={[
                    {
                      icon: <EditIcon w="2rem" h="2rem" />,
                      onClick: () => editDrawerHandler(index),
                      buttonProps: {
                        disabled: card.card_status === CardStatus.LOST,
                      },
                    },
                    {
                      icon: <BlockIcon w="2rem" h="2rem" />,
                      onClick: () =>
                        drawerHandler({
                          index,
                          drawerType: DrawerType.block,
                        }),
                      buttonProps: {
                        disabled: card.card_status === CardStatus.LOST,
                      },
                    },
                    {
                      tooltipLabel: (
                        <Text size="small">
                          {card.is_physical
                            ? t(
                                i18nKeys.operator.cards.conversion
                                  .tooltip_is_converted,
                              )
                            : t(i18nKeys.operator.cards.conversion.title)}
                        </Text>
                      ),
                      icon: (
                        <Flex align="center" p="0" w="2rem" h="2rem">
                          <CreditCardIcon />
                        </Flex>
                      ),
                      onClick: () =>
                        drawerHandler({
                          index,
                          drawerType: DrawerType.conversion,
                        }),
                      buttonProps: {
                        disabled:
                          card.is_physical ||
                          card.card_status === CardStatus.LOST,
                      },
                    },
                  ]}
                />
              </Table.TR>
            );
          })}
        </Table.TBody>
      </Table>
      {isOpen &&
        cards?.length > 0 &&
        ((selectedDrawerType === DrawerType.block && (
          <CardBlockDrawer
            card={cards[activeDrawerIndex]}
            isOpen={isOpen}
            onClose={onClose}
            cardsGetQueryParams={params}
          />
        )) ||
          (selectedDrawerType === DrawerType.conversion && (
            <ConvertPhysicalCardDrawer
              card={cards[activeDrawerIndex]}
              isOpen={isOpen}
              onClose={onClose}
            />
          )) || (
            <CardEditDrawer
              card={cards[activeDrawerIndex]}
              isOpen={isOpen}
              onClose={onClose}
              cardsGetQueryParams={params}
              hasImccl={hasImccl}
              imcclRestrictionId={activeImcclRestriction?.imccl_restriction_id}
            />
          ))}
      {cards && meta?.total_pages > 1 && (
        <Pager
          setPageNumber={setPageNumber}
          total_items={meta.total_items}
          page_index={meta.page_index}
          total_pages={meta.total_pages}
          page_size={pageSize}
          setPageSize={setPageSize}
          mt="2rem"
          isFetching={isFetching}
          pageNumber={pageNumber}
        />
      )}
    </>
  );
};
