/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type ExpenseRefundIssuer =
  typeof ExpenseRefundIssuer[keyof typeof ExpenseRefundIssuer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseRefundIssuer = {
  NONE: 'NONE',
  ORGANISATION: 'ORGANISATION',
  EMPLOYEE: 'EMPLOYEE',
  RESELLER: 'RESELLER',
  PROVIDER: 'PROVIDER',
} as const;
