/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { PolicyType } from './policyType';
import type { PolicyLevel } from './policyLevel';
import type { PolicyRequiredField } from './policyRequiredField';
import type { PolicyCondition } from './policyCondition';
import type { PolicyAction } from './policyAction';

export interface PolicyData {
  program_id?: Uuid;
  group_id?: Uuid;
  type?: PolicyType;
  readonly level?: PolicyLevel;
  required_fields?: PolicyRequiredField[];
  conditions: PolicyCondition[];
  actions: PolicyAction[];
}
