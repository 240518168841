/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  Membership,
  AddMembershipRole,
  MembershipProductsGetResponse,
  MembershipProductsGetParams,
  ProgramsGetResponse,
  MembershipProgramsGetParams,
  Program,
  DefaultProgramPatch,
  KmAllowanceConfiguration,
  MembershipKmAllowanceProductCreateRequest,
  ProgramGroup,
  Registration,
  MembershipRegistrationGetParams,
  RegistrationPatch,
  ServicesGetResponse,
  MembershipServicesGetParams,
  MembershipSummariesGetResponse,
  MembershipSummariesGetParams,
  UpdateMembershipProgramsStartOnRequest,
  MembershipProgramsStartOnUpdateParams,
  Invitation,
  CreateInvitationRequest,
  UpdateInvitationRequest,
  MembershipSuspendPostParams,
  MembershipSuspendPutParams,
  MembershipReactivatePostParams,
  MembershipReactivatePutParams,
  MembershipSummaryRecalculateParams,
  MembershipSwornStatementsGetResponse,
  MembershipSwornStatementsGetParams,
  ProviderListResponse,
  MembershipProvidersGetParams,
  SearchProviderResponse,
  MembershipSearchProvidersGetParams,
} from '../../models';
import type { Error } from '../../models/component-common';
import { customInstance } from '../../config';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
  T,
>() => T extends Y ? 1 : 2
  ? A
  : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<
    { [Q in P]: T[P] },
    { -readonly [Q in P]: T[P] },
    P
  >;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object
        ? NonReadonly<NonNullable<T[P]>>
        : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

/**
 * This call is used to convert an existing employee to a manager or administrator.
It cannot be used to created a new employee/manager/administrator from scratch.
In this case POST /registrations on v1 should be used as additional actions
on serviced like Treezor might be needed.

- User is employee and higher role is added => New membership is created with higher role
- User has higher role and another higher role is added => Existing higher role membership is updated
- Adding role "EMPLOYEE" => Error 400
- Create memberships for child organisations

 */
export const membershipCreate = (addMembershipRole: AddMembershipRole) => {
  return customInstance<Membership[]>({
    url: `/web/v2/memberships`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: addMembershipRole,
  });
};

export const getMembershipCreateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipCreate>>,
    TError,
    { data: AddMembershipRole },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof membershipCreate>>,
  TError,
  { data: AddMembershipRole },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof membershipCreate>>,
    { data: AddMembershipRole }
  > = (props) => {
    const { data } = props ?? {};

    return membershipCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type MembershipCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof membershipCreate>>
>;
export type MembershipCreateMutationBody = AddMembershipRole;
export type MembershipCreateMutationError = unknown;

export const useMembershipCreate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipCreate>>,
    TError,
    { data: AddMembershipRole },
    TContext
  >;
}) => {
  const mutationOptions = getMembershipCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * This call can be used to convert a manager or administrator back to a
normal employee. It can't be used to delete a employee or to convert a manager/administrator
which was never a normal employee in the first place.

- Remove higher role => Membership of higher role removed
- Remove higher role of a user which was never an employee => Error 400
- Remove membership of role "EMPLOYEE" => Error 400

 */
export const membershipDelete = (membershipId: string) => {
  return customInstance<void>({
    url: `/web/v2/memberships/${membershipId}`,
    method: 'delete',
  });
};

export const getMembershipDeleteMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipDelete>>,
    TError,
    { membershipId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof membershipDelete>>,
  TError,
  { membershipId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof membershipDelete>>,
    { membershipId: string }
  > = (props) => {
    const { membershipId } = props ?? {};

    return membershipDelete(membershipId);
  };

  return { mutationFn, ...mutationOptions };
};

export type MembershipDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof membershipDelete>>
>;

export type MembershipDeleteMutationError = unknown;

export const useMembershipDelete = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipDelete>>,
    TError,
    { membershipId: string },
    TContext
  >;
}) => {
  const mutationOptions = getMembershipDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};
export const membershipProductsGet = (
  membershipId: string,
  params?: MembershipProductsGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<MembershipProductsGetResponse>({
    url: `/web/v2/memberships/${membershipId}/products`,
    method: 'get',
    params,
    signal,
  });
};

export const getMembershipProductsGetQueryKey = (
  membershipId: string,
  params?: MembershipProductsGetParams,
) =>
  [
    `/web/v2/memberships/${membershipId}/products`,
    ...(params ? [params] : []),
  ] as const;

export const getMembershipProductsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof membershipProductsGet>>,
  TError = unknown,
>(
  membershipId: string,
  params?: MembershipProductsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof membershipProductsGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof membershipProductsGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMembershipProductsGetQueryKey(membershipId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof membershipProductsGet>>
  > = ({ signal }) => membershipProductsGet(membershipId, params, signal);

  return { queryKey, queryFn, enabled: !!membershipId, ...queryOptions };
};

export type MembershipProductsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof membershipProductsGet>>
>;
export type MembershipProductsGetQueryError = unknown;

export const useMembershipProductsGet = <
  TData = Awaited<ReturnType<typeof membershipProductsGet>>,
  TError = unknown,
>(
  membershipId: string,
  params?: MembershipProductsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof membershipProductsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getMembershipProductsGetQueryOptions(
    membershipId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const membershipProgramsGet = (
  membershipId: string,
  params?: MembershipProgramsGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<ProgramsGetResponse>({
    url: `/web/v2/memberships/${membershipId}/programs`,
    method: 'get',
    params,
    signal,
  });
};

export const getMembershipProgramsGetQueryKey = (
  membershipId: string,
  params?: MembershipProgramsGetParams,
) =>
  [
    `/web/v2/memberships/${membershipId}/programs`,
    ...(params ? [params] : []),
  ] as const;

export const getMembershipProgramsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof membershipProgramsGet>>,
  TError = unknown,
>(
  membershipId: string,
  params?: MembershipProgramsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof membershipProgramsGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof membershipProgramsGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMembershipProgramsGetQueryKey(membershipId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof membershipProgramsGet>>
  > = ({ signal }) => membershipProgramsGet(membershipId, params, signal);

  return { queryKey, queryFn, enabled: !!membershipId, ...queryOptions };
};

export type MembershipProgramsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof membershipProgramsGet>>
>;
export type MembershipProgramsGetQueryError = unknown;

export const useMembershipProgramsGet = <
  TData = Awaited<ReturnType<typeof membershipProgramsGet>>,
  TError = unknown,
>(
  membershipId: string,
  params?: MembershipProgramsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof membershipProgramsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getMembershipProgramsGetQueryOptions(
    membershipId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const membershipDefaultProgramGet = (
  membershipId: string,
  signal?: AbortSignal,
) => {
  return customInstance<Program>({
    url: `/web/v2/memberships/${membershipId}/default_program`,
    method: 'get',
    signal,
  });
};

export const getMembershipDefaultProgramGetQueryKey = (membershipId: string) =>
  [`/web/v2/memberships/${membershipId}/default_program`] as const;

export const getMembershipDefaultProgramGetQueryOptions = <
  TData = Awaited<ReturnType<typeof membershipDefaultProgramGet>>,
  TError = unknown,
>(
  membershipId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof membershipDefaultProgramGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof membershipDefaultProgramGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMembershipDefaultProgramGetQueryKey(membershipId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof membershipDefaultProgramGet>>
  > = ({ signal }) => membershipDefaultProgramGet(membershipId, signal);

  return { queryKey, queryFn, enabled: !!membershipId, ...queryOptions };
};

export type MembershipDefaultProgramGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof membershipDefaultProgramGet>>
>;
export type MembershipDefaultProgramGetQueryError = unknown;

export const useMembershipDefaultProgramGet = <
  TData = Awaited<ReturnType<typeof membershipDefaultProgramGet>>,
  TError = unknown,
>(
  membershipId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof membershipDefaultProgramGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getMembershipDefaultProgramGetQueryOptions(
    membershipId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Patch default program for the membership

 */
export const membershipDefaultProgramPatch = (
  membershipId: string,
  defaultProgramPatch: DefaultProgramPatch,
) => {
  return customInstance<void>({
    url: `/web/v2/memberships/${membershipId}/default_program`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: defaultProgramPatch,
  });
};

export const getMembershipDefaultProgramPatchMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipDefaultProgramPatch>>,
    TError,
    { membershipId: string; data: DefaultProgramPatch },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof membershipDefaultProgramPatch>>,
  TError,
  { membershipId: string; data: DefaultProgramPatch },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof membershipDefaultProgramPatch>>,
    { membershipId: string; data: DefaultProgramPatch }
  > = (props) => {
    const { membershipId, data } = props ?? {};

    return membershipDefaultProgramPatch(membershipId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type MembershipDefaultProgramPatchMutationResult = NonNullable<
  Awaited<ReturnType<typeof membershipDefaultProgramPatch>>
>;
export type MembershipDefaultProgramPatchMutationBody = DefaultProgramPatch;
export type MembershipDefaultProgramPatchMutationError = unknown;

export const useMembershipDefaultProgramPatch = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipDefaultProgramPatch>>,
    TError,
    { membershipId: string; data: DefaultProgramPatch },
    TContext
  >;
}) => {
  const mutationOptions =
    getMembershipDefaultProgramPatchMutationOptions(options);

  return useMutation(mutationOptions);
};
export const membershipKmAllowanceProductCreate = (
  membershipId: string,
  membershipKmAllowanceProductCreateRequest: MembershipKmAllowanceProductCreateRequest,
) => {
  return customInstance<KmAllowanceConfiguration>({
    url: `/web/v2/memberships/${membershipId}/products/km_allowance`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: membershipKmAllowanceProductCreateRequest,
  });
};

export const getMembershipKmAllowanceProductCreateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipKmAllowanceProductCreate>>,
    TError,
    { membershipId: string; data: MembershipKmAllowanceProductCreateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof membershipKmAllowanceProductCreate>>,
  TError,
  { membershipId: string; data: MembershipKmAllowanceProductCreateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof membershipKmAllowanceProductCreate>>,
    { membershipId: string; data: MembershipKmAllowanceProductCreateRequest }
  > = (props) => {
    const { membershipId, data } = props ?? {};

    return membershipKmAllowanceProductCreate(membershipId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type MembershipKmAllowanceProductCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof membershipKmAllowanceProductCreate>>
>;
export type MembershipKmAllowanceProductCreateMutationBody =
  MembershipKmAllowanceProductCreateRequest;
export type MembershipKmAllowanceProductCreateMutationError = unknown;

export const useMembershipKmAllowanceProductCreate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipKmAllowanceProductCreate>>,
    TError,
    { membershipId: string; data: MembershipKmAllowanceProductCreateRequest },
    TContext
  >;
}) => {
  const mutationOptions =
    getMembershipKmAllowanceProductCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const membershipProgramGroupGet = (
  membershipId: string,
  programId: string,
  signal?: AbortSignal,
) => {
  return customInstance<ProgramGroup>({
    url: `/web/v2/memberships/${membershipId}/programs/${programId}/group`,
    method: 'get',
    signal,
  });
};

export const getMembershipProgramGroupGetQueryKey = (
  membershipId: string,
  programId: string,
) =>
  [`/web/v2/memberships/${membershipId}/programs/${programId}/group`] as const;

export const getMembershipProgramGroupGetQueryOptions = <
  TData = Awaited<ReturnType<typeof membershipProgramGroupGet>>,
  TError = unknown,
>(
  membershipId: string,
  programId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof membershipProgramGroupGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof membershipProgramGroupGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMembershipProgramGroupGetQueryKey(membershipId, programId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof membershipProgramGroupGet>>
  > = ({ signal }) =>
    membershipProgramGroupGet(membershipId, programId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(membershipId && programId),
    ...queryOptions,
  };
};

export type MembershipProgramGroupGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof membershipProgramGroupGet>>
>;
export type MembershipProgramGroupGetQueryError = unknown;

export const useMembershipProgramGroupGet = <
  TData = Awaited<ReturnType<typeof membershipProgramGroupGet>>,
  TError = unknown,
>(
  membershipId: string,
  programId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof membershipProgramGroupGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getMembershipProgramGroupGetQueryOptions(
    membershipId,
    programId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const membershipRegistrationGet = (
  membershipId: string,
  params?: MembershipRegistrationGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<Registration>({
    url: `/web/v2/memberships/${membershipId}/registration`,
    method: 'get',
    params,
    signal,
  });
};

export const getMembershipRegistrationGetQueryKey = (
  membershipId: string,
  params?: MembershipRegistrationGetParams,
) =>
  [
    `/web/v2/memberships/${membershipId}/registration`,
    ...(params ? [params] : []),
  ] as const;

export const getMembershipRegistrationGetQueryOptions = <
  TData = Awaited<ReturnType<typeof membershipRegistrationGet>>,
  TError = unknown,
>(
  membershipId: string,
  params?: MembershipRegistrationGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof membershipRegistrationGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof membershipRegistrationGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMembershipRegistrationGetQueryKey(membershipId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof membershipRegistrationGet>>
  > = ({ signal }) => membershipRegistrationGet(membershipId, params, signal);

  return { queryKey, queryFn, enabled: !!membershipId, ...queryOptions };
};

export type MembershipRegistrationGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof membershipRegistrationGet>>
>;
export type MembershipRegistrationGetQueryError = unknown;

export const useMembershipRegistrationGet = <
  TData = Awaited<ReturnType<typeof membershipRegistrationGet>>,
  TError = unknown,
>(
  membershipId: string,
  params?: MembershipRegistrationGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof membershipRegistrationGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getMembershipRegistrationGetQueryOptions(
    membershipId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const membershipRegistrationPut = (
  membershipId: string,
  registration: NonReadonly<Registration>,
) => {
  return customInstance<Registration>({
    url: `/web/v2/memberships/${membershipId}/registration`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: registration,
  });
};

export const getMembershipRegistrationPutMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipRegistrationPut>>,
    TError,
    { membershipId: string; data: NonReadonly<Registration> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof membershipRegistrationPut>>,
  TError,
  { membershipId: string; data: NonReadonly<Registration> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof membershipRegistrationPut>>,
    { membershipId: string; data: NonReadonly<Registration> }
  > = (props) => {
    const { membershipId, data } = props ?? {};

    return membershipRegistrationPut(membershipId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type MembershipRegistrationPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof membershipRegistrationPut>>
>;
export type MembershipRegistrationPutMutationBody = NonReadonly<Registration>;
export type MembershipRegistrationPutMutationError = unknown;

export const useMembershipRegistrationPut = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipRegistrationPut>>,
    TError,
    { membershipId: string; data: NonReadonly<Registration> },
    TContext
  >;
}) => {
  const mutationOptions = getMembershipRegistrationPutMutationOptions(options);

  return useMutation(mutationOptions);
};
export const membershipRegistrationPatch = (
  membershipId: string,
  registrationPatch: RegistrationPatch,
) => {
  return customInstance<Registration>({
    url: `/web/v2/memberships/${membershipId}/registration`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: registrationPatch,
  });
};

export const getMembershipRegistrationPatchMutationOptions = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipRegistrationPatch>>,
    TError,
    { membershipId: string; data: RegistrationPatch },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof membershipRegistrationPatch>>,
  TError,
  { membershipId: string; data: RegistrationPatch },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof membershipRegistrationPatch>>,
    { membershipId: string; data: RegistrationPatch }
  > = (props) => {
    const { membershipId, data } = props ?? {};

    return membershipRegistrationPatch(membershipId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type MembershipRegistrationPatchMutationResult = NonNullable<
  Awaited<ReturnType<typeof membershipRegistrationPatch>>
>;
export type MembershipRegistrationPatchMutationBody = RegistrationPatch;
export type MembershipRegistrationPatchMutationError = Error;

export const useMembershipRegistrationPatch = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipRegistrationPatch>>,
    TError,
    { membershipId: string; data: RegistrationPatch },
    TContext
  >;
}) => {
  const mutationOptions =
    getMembershipRegistrationPatchMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Fetch all services which are available to the membership. Note we don't support pagination on this call. For the meta we always assume a single page with all results.
 */
export const membershipServicesGet = (
  membershipId: string,
  params?: MembershipServicesGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<ServicesGetResponse>({
    url: `/web/v2/memberships/${membershipId}/services`,
    method: 'get',
    params,
    signal,
  });
};

export const getMembershipServicesGetQueryKey = (
  membershipId: string,
  params?: MembershipServicesGetParams,
) =>
  [
    `/web/v2/memberships/${membershipId}/services`,
    ...(params ? [params] : []),
  ] as const;

export const getMembershipServicesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof membershipServicesGet>>,
  TError = unknown,
>(
  membershipId: string,
  params?: MembershipServicesGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof membershipServicesGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof membershipServicesGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMembershipServicesGetQueryKey(membershipId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof membershipServicesGet>>
  > = ({ signal }) => membershipServicesGet(membershipId, params, signal);

  return { queryKey, queryFn, enabled: !!membershipId, ...queryOptions };
};

export type MembershipServicesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof membershipServicesGet>>
>;
export type MembershipServicesGetQueryError = unknown;

export const useMembershipServicesGet = <
  TData = Awaited<ReturnType<typeof membershipServicesGet>>,
  TError = unknown,
>(
  membershipId: string,
  params?: MembershipServicesGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof membershipServicesGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getMembershipServicesGetQueryOptions(
    membershipId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const membershipSummariesGet = (
  membershipId: string,
  params?: MembershipSummariesGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<MembershipSummariesGetResponse>({
    url: `/web/v2/memberships/${membershipId}/summaries`,
    method: 'get',
    params,
    signal,
  });
};

export const getMembershipSummariesGetQueryKey = (
  membershipId: string,
  params?: MembershipSummariesGetParams,
) =>
  [
    `/web/v2/memberships/${membershipId}/summaries`,
    ...(params ? [params] : []),
  ] as const;

export const getMembershipSummariesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof membershipSummariesGet>>,
  TError = unknown,
>(
  membershipId: string,
  params?: MembershipSummariesGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof membershipSummariesGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof membershipSummariesGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMembershipSummariesGetQueryKey(membershipId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof membershipSummariesGet>>
  > = ({ signal }) => membershipSummariesGet(membershipId, params, signal);

  return { queryKey, queryFn, enabled: !!membershipId, ...queryOptions };
};

export type MembershipSummariesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof membershipSummariesGet>>
>;
export type MembershipSummariesGetQueryError = unknown;

export const useMembershipSummariesGet = <
  TData = Awaited<ReturnType<typeof membershipSummariesGet>>,
  TError = unknown,
>(
  membershipId: string,
  params?: MembershipSummariesGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof membershipSummariesGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getMembershipSummariesGetQueryOptions(
    membershipId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update the program start_on date of a membership

 */
export const membershipProgramsStartOnUpdate = (
  membershipId: string,
  updateMembershipProgramsStartOnRequest: UpdateMembershipProgramsStartOnRequest,
  params?: MembershipProgramsStartOnUpdateParams,
) => {
  return customInstance<void>({
    url: `/web/v2/memberships/${membershipId}/summaries`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: updateMembershipProgramsStartOnRequest,
    params,
  });
};

export const getMembershipProgramsStartOnUpdateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipProgramsStartOnUpdate>>,
    TError,
    {
      membershipId: string;
      data: UpdateMembershipProgramsStartOnRequest;
      params?: MembershipProgramsStartOnUpdateParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof membershipProgramsStartOnUpdate>>,
  TError,
  {
    membershipId: string;
    data: UpdateMembershipProgramsStartOnRequest;
    params?: MembershipProgramsStartOnUpdateParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof membershipProgramsStartOnUpdate>>,
    {
      membershipId: string;
      data: UpdateMembershipProgramsStartOnRequest;
      params?: MembershipProgramsStartOnUpdateParams;
    }
  > = (props) => {
    const { membershipId, data, params } = props ?? {};

    return membershipProgramsStartOnUpdate(membershipId, data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type MembershipProgramsStartOnUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof membershipProgramsStartOnUpdate>>
>;
export type MembershipProgramsStartOnUpdateMutationBody =
  UpdateMembershipProgramsStartOnRequest;
export type MembershipProgramsStartOnUpdateMutationError = unknown;

export const useMembershipProgramsStartOnUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipProgramsStartOnUpdate>>,
    TError,
    {
      membershipId: string;
      data: UpdateMembershipProgramsStartOnRequest;
      params?: MembershipProgramsStartOnUpdateParams;
    },
    TContext
  >;
}) => {
  const mutationOptions =
    getMembershipProgramsStartOnUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const membershipInvitationPost = (
  membershipId: string,
  createInvitationRequest: CreateInvitationRequest,
) => {
  return customInstance<Invitation>({
    url: `/web/v2/memberships/${membershipId}/invitation`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: createInvitationRequest,
  });
};

export const getMembershipInvitationPostMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipInvitationPost>>,
    TError,
    { membershipId: string; data: CreateInvitationRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof membershipInvitationPost>>,
  TError,
  { membershipId: string; data: CreateInvitationRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof membershipInvitationPost>>,
    { membershipId: string; data: CreateInvitationRequest }
  > = (props) => {
    const { membershipId, data } = props ?? {};

    return membershipInvitationPost(membershipId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type MembershipInvitationPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof membershipInvitationPost>>
>;
export type MembershipInvitationPostMutationBody = CreateInvitationRequest;
export type MembershipInvitationPostMutationError = unknown;

export const useMembershipInvitationPost = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipInvitationPost>>,
    TError,
    { membershipId: string; data: CreateInvitationRequest },
    TContext
  >;
}) => {
  const mutationOptions = getMembershipInvitationPostMutationOptions(options);

  return useMutation(mutationOptions);
};
export const membershipInvitationPut = (
  membershipId: string,
  invitationId: string,
  updateInvitationRequest: UpdateInvitationRequest,
) => {
  return customInstance<Invitation>({
    url: `/web/v2/memberships/${membershipId}/invitation/${invitationId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: updateInvitationRequest,
  });
};

export const getMembershipInvitationPutMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipInvitationPut>>,
    TError,
    {
      membershipId: string;
      invitationId: string;
      data: UpdateInvitationRequest;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof membershipInvitationPut>>,
  TError,
  { membershipId: string; invitationId: string; data: UpdateInvitationRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof membershipInvitationPut>>,
    {
      membershipId: string;
      invitationId: string;
      data: UpdateInvitationRequest;
    }
  > = (props) => {
    const { membershipId, invitationId, data } = props ?? {};

    return membershipInvitationPut(membershipId, invitationId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type MembershipInvitationPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof membershipInvitationPut>>
>;
export type MembershipInvitationPutMutationBody = UpdateInvitationRequest;
export type MembershipInvitationPutMutationError = unknown;

export const useMembershipInvitationPut = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipInvitationPut>>,
    TError,
    {
      membershipId: string;
      invitationId: string;
      data: UpdateInvitationRequest;
    },
    TContext
  >;
}) => {
  const mutationOptions = getMembershipInvitationPutMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Update the status of a registration as VALIDATED

 */
export const membershipValidateRegistration = (membershipId: string) => {
  return customInstance<Registration>({
    url: `/web/v2/memberships/${membershipId}/validate_registration`,
    method: 'post',
  });
};

export const getMembershipValidateRegistrationMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipValidateRegistration>>,
    TError,
    { membershipId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof membershipValidateRegistration>>,
  TError,
  { membershipId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof membershipValidateRegistration>>,
    { membershipId: string }
  > = (props) => {
    const { membershipId } = props ?? {};

    return membershipValidateRegistration(membershipId);
  };

  return { mutationFn, ...mutationOptions };
};

export type MembershipValidateRegistrationMutationResult = NonNullable<
  Awaited<ReturnType<typeof membershipValidateRegistration>>
>;

export type MembershipValidateRegistrationMutationError = unknown;

export const useMembershipValidateRegistration = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipValidateRegistration>>,
    TError,
    { membershipId: string },
    TContext
  >;
}) => {
  const mutationOptions =
    getMembershipValidateRegistrationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const membershipSuspendPost = (
  membershipId: string,
  params: MembershipSuspendPostParams,
) => {
  return customInstance<void>({
    url: `/web/v2/memberships/${membershipId}/suspend`,
    method: 'post',
    params,
  });
};

export const getMembershipSuspendPostMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipSuspendPost>>,
    TError,
    { membershipId: string; params: MembershipSuspendPostParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof membershipSuspendPost>>,
  TError,
  { membershipId: string; params: MembershipSuspendPostParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof membershipSuspendPost>>,
    { membershipId: string; params: MembershipSuspendPostParams }
  > = (props) => {
    const { membershipId, params } = props ?? {};

    return membershipSuspendPost(membershipId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type MembershipSuspendPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof membershipSuspendPost>>
>;

export type MembershipSuspendPostMutationError = unknown;

export const useMembershipSuspendPost = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipSuspendPost>>,
    TError,
    { membershipId: string; params: MembershipSuspendPostParams },
    TContext
  >;
}) => {
  const mutationOptions = getMembershipSuspendPostMutationOptions(options);

  return useMutation(mutationOptions);
};
export const membershipSuspendPut = (
  membershipId: string,
  params: MembershipSuspendPutParams,
) => {
  return customInstance<void>({
    url: `/web/v2/memberships/${membershipId}/suspend`,
    method: 'put',
    params,
  });
};

export const getMembershipSuspendPutMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipSuspendPut>>,
    TError,
    { membershipId: string; params: MembershipSuspendPutParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof membershipSuspendPut>>,
  TError,
  { membershipId: string; params: MembershipSuspendPutParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof membershipSuspendPut>>,
    { membershipId: string; params: MembershipSuspendPutParams }
  > = (props) => {
    const { membershipId, params } = props ?? {};

    return membershipSuspendPut(membershipId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type MembershipSuspendPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof membershipSuspendPut>>
>;

export type MembershipSuspendPutMutationError = unknown;

export const useMembershipSuspendPut = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipSuspendPut>>,
    TError,
    { membershipId: string; params: MembershipSuspendPutParams },
    TContext
  >;
}) => {
  const mutationOptions = getMembershipSuspendPutMutationOptions(options);

  return useMutation(mutationOptions);
};
export const membershipSuspendDelete = (membershipId: string) => {
  return customInstance<void>({
    url: `/web/v2/memberships/${membershipId}/suspend`,
    method: 'delete',
  });
};

export const getMembershipSuspendDeleteMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipSuspendDelete>>,
    TError,
    { membershipId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof membershipSuspendDelete>>,
  TError,
  { membershipId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof membershipSuspendDelete>>,
    { membershipId: string }
  > = (props) => {
    const { membershipId } = props ?? {};

    return membershipSuspendDelete(membershipId);
  };

  return { mutationFn, ...mutationOptions };
};

export type MembershipSuspendDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof membershipSuspendDelete>>
>;

export type MembershipSuspendDeleteMutationError = unknown;

export const useMembershipSuspendDelete = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipSuspendDelete>>,
    TError,
    { membershipId: string },
    TContext
  >;
}) => {
  const mutationOptions = getMembershipSuspendDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};
export const membershipReactivatePost = (
  membershipId: string,
  params: MembershipReactivatePostParams,
) => {
  return customInstance<void>({
    url: `/web/v2/memberships/${membershipId}/reactivate`,
    method: 'post',
    params,
  });
};

export const getMembershipReactivatePostMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipReactivatePost>>,
    TError,
    { membershipId: string; params: MembershipReactivatePostParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof membershipReactivatePost>>,
  TError,
  { membershipId: string; params: MembershipReactivatePostParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof membershipReactivatePost>>,
    { membershipId: string; params: MembershipReactivatePostParams }
  > = (props) => {
    const { membershipId, params } = props ?? {};

    return membershipReactivatePost(membershipId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type MembershipReactivatePostMutationResult = NonNullable<
  Awaited<ReturnType<typeof membershipReactivatePost>>
>;

export type MembershipReactivatePostMutationError = unknown;

export const useMembershipReactivatePost = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipReactivatePost>>,
    TError,
    { membershipId: string; params: MembershipReactivatePostParams },
    TContext
  >;
}) => {
  const mutationOptions = getMembershipReactivatePostMutationOptions(options);

  return useMutation(mutationOptions);
};
export const membershipReactivatePut = (
  membershipId: string,
  params: MembershipReactivatePutParams,
) => {
  return customInstance<void>({
    url: `/web/v2/memberships/${membershipId}/reactivate`,
    method: 'put',
    params,
  });
};

export const getMembershipReactivatePutMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipReactivatePut>>,
    TError,
    { membershipId: string; params: MembershipReactivatePutParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof membershipReactivatePut>>,
  TError,
  { membershipId: string; params: MembershipReactivatePutParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof membershipReactivatePut>>,
    { membershipId: string; params: MembershipReactivatePutParams }
  > = (props) => {
    const { membershipId, params } = props ?? {};

    return membershipReactivatePut(membershipId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type MembershipReactivatePutMutationResult = NonNullable<
  Awaited<ReturnType<typeof membershipReactivatePut>>
>;

export type MembershipReactivatePutMutationError = unknown;

export const useMembershipReactivatePut = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipReactivatePut>>,
    TError,
    { membershipId: string; params: MembershipReactivatePutParams },
    TContext
  >;
}) => {
  const mutationOptions = getMembershipReactivatePutMutationOptions(options);

  return useMutation(mutationOptions);
};
export const membershipReactivateDelete = (membershipId: string) => {
  return customInstance<void>({
    url: `/web/v2/memberships/${membershipId}/reactivate`,
    method: 'delete',
  });
};

export const getMembershipReactivateDeleteMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipReactivateDelete>>,
    TError,
    { membershipId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof membershipReactivateDelete>>,
  TError,
  { membershipId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof membershipReactivateDelete>>,
    { membershipId: string }
  > = (props) => {
    const { membershipId } = props ?? {};

    return membershipReactivateDelete(membershipId);
  };

  return { mutationFn, ...mutationOptions };
};

export type MembershipReactivateDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof membershipReactivateDelete>>
>;

export type MembershipReactivateDeleteMutationError = unknown;

export const useMembershipReactivateDelete = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipReactivateDelete>>,
    TError,
    { membershipId: string },
    TContext
  >;
}) => {
  const mutationOptions = getMembershipReactivateDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Allows to recalculate membership summary on demand.
It may be used to solve inconsistency between expenses, topups, authorized transactions and summary

 */
export const membershipSummaryRecalculate = (
  membershipId: string,
  params?: MembershipSummaryRecalculateParams,
) => {
  return customInstance<MembershipSummariesGetResponse>({
    url: `/web/v2/memberships/${membershipId}/recalculate_summary`,
    method: 'post',
    params,
  });
};

export const getMembershipSummaryRecalculateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipSummaryRecalculate>>,
    TError,
    { membershipId: string; params?: MembershipSummaryRecalculateParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof membershipSummaryRecalculate>>,
  TError,
  { membershipId: string; params?: MembershipSummaryRecalculateParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof membershipSummaryRecalculate>>,
    { membershipId: string; params?: MembershipSummaryRecalculateParams }
  > = (props) => {
    const { membershipId, params } = props ?? {};

    return membershipSummaryRecalculate(membershipId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type MembershipSummaryRecalculateMutationResult = NonNullable<
  Awaited<ReturnType<typeof membershipSummaryRecalculate>>
>;

export type MembershipSummaryRecalculateMutationError = unknown;

export const useMembershipSummaryRecalculate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipSummaryRecalculate>>,
    TError,
    { membershipId: string; params?: MembershipSummaryRecalculateParams },
    TContext
  >;
}) => {
  const mutationOptions =
    getMembershipSummaryRecalculateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const membershipSwornStatementsGet = (
  membershipId: string,
  params?: MembershipSwornStatementsGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<MembershipSwornStatementsGetResponse>({
    url: `/web/v2/memberships/${membershipId}/sworn_statements`,
    method: 'get',
    params,
    signal,
  });
};

export const getMembershipSwornStatementsGetQueryKey = (
  membershipId: string,
  params?: MembershipSwornStatementsGetParams,
) =>
  [
    `/web/v2/memberships/${membershipId}/sworn_statements`,
    ...(params ? [params] : []),
  ] as const;

export const getMembershipSwornStatementsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof membershipSwornStatementsGet>>,
  TError = unknown,
>(
  membershipId: string,
  params?: MembershipSwornStatementsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof membershipSwornStatementsGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof membershipSwornStatementsGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMembershipSwornStatementsGetQueryKey(membershipId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof membershipSwornStatementsGet>>
  > = ({ signal }) =>
    membershipSwornStatementsGet(membershipId, params, signal);

  return { queryKey, queryFn, enabled: !!membershipId, ...queryOptions };
};

export type MembershipSwornStatementsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof membershipSwornStatementsGet>>
>;
export type MembershipSwornStatementsGetQueryError = unknown;

export const useMembershipSwornStatementsGet = <
  TData = Awaited<ReturnType<typeof membershipSwornStatementsGet>>,
  TError = unknown,
>(
  membershipId: string,
  params?: MembershipSwornStatementsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof membershipSwornStatementsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getMembershipSwornStatementsGetQueryOptions(
    membershipId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const membershipSignSwornStatements = (membershipId: string) => {
  return customInstance<void>({
    url: `/web/v2/memberships/${membershipId}/sign_sworn_statements`,
    method: 'put',
  });
};

export const getMembershipSignSwornStatementsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipSignSwornStatements>>,
    TError,
    { membershipId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof membershipSignSwornStatements>>,
  TError,
  { membershipId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof membershipSignSwornStatements>>,
    { membershipId: string }
  > = (props) => {
    const { membershipId } = props ?? {};

    return membershipSignSwornStatements(membershipId);
  };

  return { mutationFn, ...mutationOptions };
};

export type MembershipSignSwornStatementsMutationResult = NonNullable<
  Awaited<ReturnType<typeof membershipSignSwornStatements>>
>;

export type MembershipSignSwornStatementsMutationError = unknown;

export const useMembershipSignSwornStatements = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membershipSignSwornStatements>>,
    TError,
    { membershipId: string },
    TContext
  >;
}) => {
  const mutationOptions =
    getMembershipSignSwornStatementsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const membershipProvidersGet = (
  membershipId: string,
  params?: MembershipProvidersGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<ProviderListResponse>({
    url: `/web/v2/memberships/${membershipId}/providers`,
    method: 'get',
    params,
    signal,
  });
};

export const getMembershipProvidersGetQueryKey = (
  membershipId: string,
  params?: MembershipProvidersGetParams,
) =>
  [
    `/web/v2/memberships/${membershipId}/providers`,
    ...(params ? [params] : []),
  ] as const;

export const getMembershipProvidersGetQueryOptions = <
  TData = Awaited<ReturnType<typeof membershipProvidersGet>>,
  TError = unknown,
>(
  membershipId: string,
  params?: MembershipProvidersGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof membershipProvidersGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof membershipProvidersGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMembershipProvidersGetQueryKey(membershipId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof membershipProvidersGet>>
  > = ({ signal }) => membershipProvidersGet(membershipId, params, signal);

  return { queryKey, queryFn, enabled: !!membershipId, ...queryOptions };
};

export type MembershipProvidersGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof membershipProvidersGet>>
>;
export type MembershipProvidersGetQueryError = unknown;

export const useMembershipProvidersGet = <
  TData = Awaited<ReturnType<typeof membershipProvidersGet>>,
  TError = unknown,
>(
  membershipId: string,
  params?: MembershipProvidersGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof membershipProvidersGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getMembershipProvidersGetQueryOptions(
    membershipId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const membershipSearchProvidersGet = (
  membershipId: string,
  params?: MembershipSearchProvidersGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<SearchProviderResponse>({
    url: `/web/v2/memberships/${membershipId}/providers/search`,
    method: 'get',
    params,
    signal,
  });
};

export const getMembershipSearchProvidersGetQueryKey = (
  membershipId: string,
  params?: MembershipSearchProvidersGetParams,
) =>
  [
    `/web/v2/memberships/${membershipId}/providers/search`,
    ...(params ? [params] : []),
  ] as const;

export const getMembershipSearchProvidersGetQueryOptions = <
  TData = Awaited<ReturnType<typeof membershipSearchProvidersGet>>,
  TError = unknown,
>(
  membershipId: string,
  params?: MembershipSearchProvidersGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof membershipSearchProvidersGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof membershipSearchProvidersGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMembershipSearchProvidersGetQueryKey(membershipId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof membershipSearchProvidersGet>>
  > = ({ signal }) =>
    membershipSearchProvidersGet(membershipId, params, signal);

  return { queryKey, queryFn, enabled: !!membershipId, ...queryOptions };
};

export type MembershipSearchProvidersGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof membershipSearchProvidersGet>>
>;
export type MembershipSearchProvidersGetQueryError = unknown;

export const useMembershipSearchProvidersGet = <
  TData = Awaited<ReturnType<typeof membershipSearchProvidersGet>>,
  TError = unknown,
>(
  membershipId: string,
  params?: MembershipSearchProvidersGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof membershipSearchProvidersGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getMembershipSearchProvidersGetQueryOptions(
    membershipId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
