/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { ExportFormat } from './exportFormat';
import type { ExpenseExportFilter } from './expenseExportFilter';

export interface ExpenseExportRequest {
  format?: ExportFormat;
  filters?: ExpenseExportFilter;
}
