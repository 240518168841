/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { SwornStatement } from './swornStatement';

/**
 * List of sworn statements that are related to a membership
 */
export interface MembershipSwornStatementsGetResponse {
  sworn_statements: SwornStatement[];
  has_unsigned_updates: boolean;
}
