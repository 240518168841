/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

/**
 * Carries details of payment method's T&C acceptance

 */
export interface SignPaymentMethodTermsAndConditionsRequest {
  /** Should be populated with a T&C content that was displayed to the user
   */
  terms_content: string;
  signed_at: string;
}
