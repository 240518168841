import { AvailableLanguage } from '@libs/core/i18n';
import { BuildConfig } from '@libs/core/types';

export const BUILD_CONFIG: BuildConfig = {
  appId: 'dashboard-delbe',
  i18n: {
    defaultLanguage: AvailableLanguage.EN,
    fallbackLanguage: AvailableLanguage.EN,
    languages: [
      AvailableLanguage.EN,
      AvailableLanguage.FR,
      AvailableLanguage.NL,
    ],
  },
};
