/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { Registration } from './registration';
import type { Meta } from './meta';

export interface RegistrationsGetResponse {
  data?: Registration[];
  meta?: Meta;
}
