/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type SocialSecretarySocialSecretaryName =
  typeof SocialSecretarySocialSecretaryName[keyof typeof SocialSecretarySocialSecretaryName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocialSecretarySocialSecretaryName = {
  PARTENA: 'PARTENA',
  ATTENTIA: 'ATTENTIA',
} as const;
