/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type CardTransactionAllOfStatus =
  typeof CardTransactionAllOfStatus[keyof typeof CardTransactionAllOfStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardTransactionAllOfStatus = {
  SETTLED: 'SETTLED',
  ACCEPTED: 'ACCEPTED',
  REFUSED: 'REFUSED',
  REVERSED: 'REVERSED',
  REFUND: 'REFUND',
  CLEARED: 'CLEARED',
} as const;
