/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type { LoggedUserV1 } from '../../models';
import { customInstance } from '../../config';

export const getCurrentUser = (signal?: AbortSignal) => {
  return customInstance<LoggedUserV1>({
    url: `/web/me`,
    method: 'get',
    signal,
  });
};

export const getGetCurrentUserQueryKey = () => [`/web/me`];

export type GetCurrentUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCurrentUser>>
>;
export type GetCurrentUserQueryError = unknown;

export const useGetCurrentUser = <
  TData = Awaited<ReturnType<typeof getCurrentUser>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getCurrentUser>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCurrentUserQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrentUser>>> = ({
    signal,
  }) => getCurrentUser(signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getCurrentUser>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};
