/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryReactivationDateParameter } from './queryReactivationDateParameter';

export type MembershipReactivatePutParams = {
  /**
   * date of reactivation
   */
  reactivation_date: QueryReactivationDateParameter;
};
