/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type UseCard = typeof UseCard[keyof typeof UseCard];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UseCard = {
  VIRTUAL: 'VIRTUAL',
  PHYSICAL: 'PHYSICAL',
  NONE: 'NONE',
} as const;
