/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { CreateImcclRestrictionPayload } from './createImcclRestrictionPayload';

/**
 * Defines restrictions attached to a program. For non PTO program only primary restriction is needed For PTO enabled programs secondary restriction should be defined as well (restrictions that allows PTO subscription purchase). Note that only 1 of the restrictions is activated on a card at a time, depending whether criteria to unblock PTO subscription purchase are met
 */
export interface CreateImcclRestrictionRequest {
  /** Primary restriction applied to linked cards It is either the only restriction defined or the restriction that excludes PTO subscription purchase (for PTO enabled programs) */
  primary_restriction: CreateImcclRestrictionPayload;
  /** Secondary restriction applied to linked cards It is relevant to PTO enabled programs only It is related to restriction that allows PTO subscription purchase */
  secondary_restriction?: CreateImcclRestrictionPayload;
}
