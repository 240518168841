/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { ChildOrganisation } from './childOrganisation';
import type { MetaV1 } from './metaV1';

export interface ChildOrganisationsGetResponse {
  data: ChildOrganisation[];
  meta: MetaV1;
}
