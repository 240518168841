/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { DocumentType } from './documentType';

export type DocumentCreateBody = {
  file: string;
  document_type?: DocumentType;
};
