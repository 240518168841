/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryServiceIdsParameter } from './component-parameters/queryServiceIdsParameter';
import type { QueryFeaturedProvidersParameter } from './component-parameters/queryFeaturedProvidersParameter';

export type MembershipProvidersGetParams = {
  /**
   * ID of the services
   */
  service_ids?: QueryServiceIdsParameter;
  /**
   * flag to query featured providers only
   */
  featured_providers?: QueryFeaturedProvidersParameter;
};
