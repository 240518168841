/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryPrimaryImcclRestrictionIdParameter } from './queryPrimaryImcclRestrictionIdParameter';
import type { QuerySecondaryImcclRestrictionIdParameter } from './querySecondaryImcclRestrictionIdParameter';

export type ImcclRestrictionActivateParams = {
  /**
   * ID of the primary IMCCL restriction
   */
  primary_restriction_id: QueryPrimaryImcclRestrictionIdParameter;
  /**
   * ID of the primary IMCCL restriction
   */
  secondary_restriction_id: QuerySecondaryImcclRestrictionIdParameter;
};
