/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { ExpenseStatuses } from './expenseStatuses';
import type { ExpenseRefundStatuses } from './expenseRefundStatuses';
import type { ExpenseRefundIssuer } from './expenseRefundIssuer';
import type { ExpenseType } from './expenseType';
import type { ExpenseInterface } from './expenseInterface';
import type { ExpenseLabel } from './expenseLabel';

export interface ExpenseExportFilter {
  review_statuses?: ExpenseStatuses;
  refund_statuses?: ExpenseRefundStatuses;
  refund_issuer?: ExpenseRefundIssuer;
  /** search on employe first and last_name */
  employee_name?: string;
  /** Start of date range (including) */
  datetime_from?: string;
  /** End of date range (including) */
  datetime_to?: string;
  expense_ids?: string[];
  /** get expense for your teams only */
  only_managed_teams?: boolean;
  /** ID of the programs */
  program_ids?: string[];
  /** ID of the memberships */
  membership_ids?: string[];
  /** ID of the services */
  service_ids?: string[];
  /** Result sort order */
  sort?: string;
  /** Name key of the category */
  category_name_key?: string;
  expense_type?: ExpenseType;
  /** Interfaces of expense */
  expense_interfaces?: ExpenseInterface[];
  /** filter by specific field (for the range date) */
  sort_by?: string;
  /** Labels of expense */
  expense_labels?: ExpenseLabel[];
}
