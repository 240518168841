/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export interface LimitMeta {
  /** Indicates number of employees impacted by program group limit
   */
  impacted_employees_count?: number;
  /** Indicates total number of employees belonging to program group
   */
  employees_in_group_count?: number;
}
