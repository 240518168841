/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { TeamMeta } from './teamMeta';

export type TeamWithMetaAllOf = {
  meta?: TeamMeta;
};
