/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type NumberOfEmployeeRange =
  typeof NumberOfEmployeeRange[keyof typeof NumberOfEmployeeRange];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NumberOfEmployeeRange = {
  less_ten: 'less_ten',
  less_hundred: 'less_hundred',
  less_two_hundred_fifty: 'less_two_hundred_fifty',
  over_two_hundred_fifty: 'over_two_hundred_fifty',
} as const;
