/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type MembershipAction =
  typeof MembershipAction[keyof typeof MembershipAction];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipAction = {
  SUSPENSION: 'SUSPENSION',
  REACTIVATION: 'REACTIVATION',
} as const;
