import React from 'react';
import {
  getImcclRestrictionGetQueryKey,
  imcclRestrictionGet,
} from '@libs/api-v2/endpoints';
import { Loader } from '@libs/ui/components';
import { Box, Text } from '@chakra-ui/layout';
import { capitalize } from 'lodash';
import { useQuery } from '@tanstack/react-query';

type ImcclRestrictionNameProps = {
  imccl_restriction_id: string;
};

export const ImcclRestrictionName = ({
  imccl_restriction_id,
}: ImcclRestrictionNameProps) => {
  // Attributes
  const { data, isLoading } = useQuery(
    getImcclRestrictionGetQueryKey(imccl_restriction_id),
    () => imcclRestrictionGet(imccl_restriction_id),
    {
      keepPreviousData: true,
      enabled: !!imccl_restriction_id,
    },
  );

  // Render
  if (!!imccl_restriction_id && isLoading) {
    return (
      <Box position="relative" h="2.5rem">
        <Loader />
      </Box>
    );
  }

  if (!data) {
    return null;
  }

  return <Text size="Body2">{capitalize(data.name)}</Text>;
};
