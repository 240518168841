/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { Organisation } from './organisation';
import type { OrganisationUpdateBodyAllOf } from './organisationUpdateBodyAllOf';

export type OrganisationUpdateBody = Organisation & OrganisationUpdateBodyAllOf;
