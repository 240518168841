/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { JourneyTripType } from './journeyTripType';
import type { JourneyType } from './journeyType';
import type { Address } from './address';

export type CostEstimationRequestConfiguration = {
  trip_type: JourneyTripType;
  type: JourneyType;
  home_address: Address;
  office_address: Address;
  start_station_address?: Address;
  end_station_address?: Address;
};
