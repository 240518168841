/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  LoggedUser,
  Program,
  DeviceUpdateRequest,
  Error400InvalidLanguageSelectionResponse,
  Error403ForbiddenResponse,
  Error404NotFoundResponse,
  LanguageUpdateRequest,
} from '../../models';
import type { Error } from '../../models/component-common';
import { customInstance } from '../../config';

export const getCurrentUser = (signal?: AbortSignal) => {
  return customInstance<LoggedUser>({
    url: `/web/v2/me`,
    method: 'get',
    signal,
  });
};

export const getGetCurrentUserQueryKey = () => [`/web/v2/me`] as const;

export const getGetCurrentUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getCurrentUser>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getCurrentUser>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getCurrentUser>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCurrentUserQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrentUser>>> = ({
    signal,
  }) => getCurrentUser(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetCurrentUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCurrentUser>>
>;
export type GetCurrentUserQueryError = unknown;

export const useGetCurrentUser = <
  TData = Awaited<ReturnType<typeof getCurrentUser>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getCurrentUser>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCurrentUserQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const usersUpdateDevices = (
  userId: string,
  deviceUpdateRequest: DeviceUpdateRequest,
) => {
  return customInstance<Program>({
    url: `/web/v2/users/${userId}/device`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: deviceUpdateRequest,
  });
};

export const getUsersUpdateDevicesMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersUpdateDevices>>,
    TError,
    { userId: string; data: DeviceUpdateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof usersUpdateDevices>>,
  TError,
  { userId: string; data: DeviceUpdateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof usersUpdateDevices>>,
    { userId: string; data: DeviceUpdateRequest }
  > = (props) => {
    const { userId, data } = props ?? {};

    return usersUpdateDevices(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UsersUpdateDevicesMutationResult = NonNullable<
  Awaited<ReturnType<typeof usersUpdateDevices>>
>;
export type UsersUpdateDevicesMutationBody = DeviceUpdateRequest;
export type UsersUpdateDevicesMutationError = unknown;

export const useUsersUpdateDevices = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersUpdateDevices>>,
    TError,
    { userId: string; data: DeviceUpdateRequest },
    TContext
  >;
}) => {
  const mutationOptions = getUsersUpdateDevicesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const usersUpdateLanguage = (
  userId: string,
  languageUpdateRequest: LanguageUpdateRequest,
) => {
  return customInstance<void>({
    url: `/web/v2/users/${userId}/language`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: languageUpdateRequest,
  });
};

export const getUsersUpdateLanguageMutationOptions = <
  TError =
    | Error400InvalidLanguageSelectionResponse
    | Error403ForbiddenResponse
    | Error404NotFoundResponse
    | Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersUpdateLanguage>>,
    TError,
    { userId: string; data: LanguageUpdateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof usersUpdateLanguage>>,
  TError,
  { userId: string; data: LanguageUpdateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof usersUpdateLanguage>>,
    { userId: string; data: LanguageUpdateRequest }
  > = (props) => {
    const { userId, data } = props ?? {};

    return usersUpdateLanguage(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UsersUpdateLanguageMutationResult = NonNullable<
  Awaited<ReturnType<typeof usersUpdateLanguage>>
>;
export type UsersUpdateLanguageMutationBody = LanguageUpdateRequest;
export type UsersUpdateLanguageMutationError =
  | Error400InvalidLanguageSelectionResponse
  | Error403ForbiddenResponse
  | Error404NotFoundResponse
  | Error;

export const useUsersUpdateLanguage = <
  TError =
    | Error400InvalidLanguageSelectionResponse
    | Error403ForbiddenResponse
    | Error404NotFoundResponse
    | Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersUpdateLanguage>>,
    TError,
    { userId: string; data: LanguageUpdateRequest },
    TContext
  >;
}) => {
  const mutationOptions = getUsersUpdateLanguageMutationOptions(options);

  return useMutation(mutationOptions);
};
