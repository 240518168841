/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type AnnualTurnOver = typeof AnnualTurnOver[keyof typeof AnnualTurnOver];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AnnualTurnOver = {
  less_forty: 'less_forty',
  less_hundred: 'less_hundred',
  less_two_hundred_fifty: 'less_two_hundred_fifty',
  less_one_k: 'less_one_k',
  less_three_k: 'less_three_k',
  less_ten_k: 'less_ten_k',
  less_hundred_k: 'less_hundred_k',
  over_hundred_k: 'over_hundred_k',
} as const;
