/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { RestrictionGroupStatus } from './restrictionGroupStatus';

export type MerchantRestrictionGroupAllOf = {
  id?: string;
  status?: RestrictionGroupStatus;
  total_merchants?: number;
  name?: string;
  is_whitelist?: boolean;
  /** Treezor's ID */
  reference?: number;
};
