/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Email } from './component-common/email';
import type { Language } from './component-common/language';
import type { Role } from './role';
import type { LoggedUserMembership } from './loggedUserMembership';

export interface LoggedUser {
  /** user ID */
  id: string;
  first_name?: string;
  last_name?: string;
  email?: Email;
  language?: Language;
  roles: Role[];
  memberships?: LoggedUserMembership[];
}
