/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  ShareholdersGetResponse,
  ShareholdersGetParams,
  Shareholder,
} from '../../models';
import { customInstance } from '../../config';

export const shareholdersGet = (
  params?: ShareholdersGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<ShareholdersGetResponse>({
    url: `/web/shareholders`,
    method: 'get',
    params,
    signal,
  });
};

export const getShareholdersGetQueryKey = (params?: ShareholdersGetParams) => [
  `/web/shareholders`,
  ...(params ? [params] : []),
];

export type ShareholdersGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof shareholdersGet>>
>;
export type ShareholdersGetQueryError = unknown;

export const useShareholdersGet = <
  TData = Awaited<ReturnType<typeof shareholdersGet>>,
  TError = unknown,
>(
  params?: ShareholdersGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof shareholdersGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getShareholdersGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shareholdersGet>>> = ({
    signal,
  }) => shareholdersGet(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof shareholdersGet>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const shareholderCreate = (shareholder: Shareholder) => {
  return customInstance<Shareholder>({
    url: `/web/shareholders`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: shareholder,
  });
};

export type ShareholderCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof shareholderCreate>>
>;
export type ShareholderCreateMutationBody = Shareholder;
export type ShareholderCreateMutationError = unknown;

export const useShareholderCreate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shareholderCreate>>,
    TError,
    { data: Shareholder },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shareholderCreate>>,
    { data: Shareholder }
  > = (props) => {
    const { data } = props ?? {};

    return shareholderCreate(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof shareholderCreate>>,
    TError,
    { data: Shareholder },
    TContext
  >(mutationFn, mutationOptions);
};
export const shareholderGet = (shareholderId: string, signal?: AbortSignal) => {
  return customInstance<Shareholder>({
    url: `/web/shareholders/${shareholderId}`,
    method: 'get',
    signal,
  });
};

export const getShareholderGetQueryKey = (shareholderId: string) => [
  `/web/shareholders/${shareholderId}`,
];

export type ShareholderGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof shareholderGet>>
>;
export type ShareholderGetQueryError = unknown;

export const useShareholderGet = <
  TData = Awaited<ReturnType<typeof shareholderGet>>,
  TError = unknown,
>(
  shareholderId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof shareholderGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getShareholderGetQueryKey(shareholderId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shareholderGet>>> = ({
    signal,
  }) => shareholderGet(shareholderId, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof shareholderGet>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!shareholderId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const shareholderUpdate = (
  shareholderId: string,
  shareholder: Shareholder,
) => {
  return customInstance<Shareholder>({
    url: `/web/shareholders/${shareholderId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: shareholder,
  });
};

export type ShareholderUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof shareholderUpdate>>
>;
export type ShareholderUpdateMutationBody = Shareholder;
export type ShareholderUpdateMutationError = unknown;

export const useShareholderUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shareholderUpdate>>,
    TError,
    { shareholderId: string; data: Shareholder },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shareholderUpdate>>,
    { shareholderId: string; data: Shareholder }
  > = (props) => {
    const { shareholderId, data } = props ?? {};

    return shareholderUpdate(shareholderId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof shareholderUpdate>>,
    TError,
    { shareholderId: string; data: Shareholder },
    TContext
  >(mutationFn, mutationOptions);
};
export const shareholderDelete = (shareholderId: string) => {
  return customInstance<void>({
    url: `/web/shareholders/${shareholderId}`,
    method: 'delete',
  });
};

export type ShareholderDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof shareholderDelete>>
>;

export type ShareholderDeleteMutationError = unknown;

export const useShareholderDelete = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shareholderDelete>>,
    TError,
    { shareholderId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shareholderDelete>>,
    { shareholderId: string }
  > = (props) => {
    const { shareholderId } = props ?? {};

    return shareholderDelete(shareholderId);
  };

  return useMutation<
    Awaited<ReturnType<typeof shareholderDelete>>,
    TError,
    { shareholderId: string },
    TContext
  >(mutationFn, mutationOptions);
};
