import { useCallback, useEffect } from 'react';

type InfiniteScrollProps = {
  callback: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loaderRef?: any;
  hasMore?: boolean;
};

export const useInfiniteScroll = ({
  callback,
  loaderRef,
  hasMore = true,
}: InfiniteScrollProps) => {
  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if ((target.isIntersecting || target.isVisible) && hasMore) {
        callback();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasMore],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '30px',
      threshold: 0,
    });

    if (loaderRef.current && hasMore) {
      observer.observe(loaderRef.current);
    }
  }, [handleObserver, loaderRef, hasMore]);
};
