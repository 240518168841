/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type RegistrationAllOfStatus =
  typeof RegistrationAllOfStatus[keyof typeof RegistrationAllOfStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RegistrationAllOfStatus = {
  CREATED: 'CREATED',
  VALIDATED: 'VALIDATED',
  CANCELED: 'CANCELED',
} as const;
