/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { CardV1AllOf } from './cardV1AllOf';
import type { Audit } from './audit';

export type CardV1 = CardV1AllOf & Audit;
