/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type Title = typeof Title[keyof typeof Title];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Title = {
  M: 'M',
  MME: 'MME',
} as const;
