/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import type { AddressV1 } from './addressV1';
import type { Gender } from './component-common/gender';
import type { RegistrationV1AllOfRole } from './registrationV1AllOfRole';
import type { ProviderV1 } from './providerV1';
import type { IdentityCard } from './identityCard';
import type { DrivingLicence } from './drivingLicence';
import type { Selfie } from './selfie';
import type { BankService } from './bankService';
import type { Status } from './status';
import type { SocialSecretary } from './socialSecretary';

export type RegistrationV1AllOf = {
  membership_id?: string;
  readonly user_id?: string;
  readonly organisation_id?: string;
  email?: string;
  address?: AddressV1;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  gender?: Gender;
  date_of_birth?: string;
  is_us_person?: boolean;
  nationality?: string;
  place_of_birth?: string;
  country_of_birth?: string;
  start_on?: string;
  role?: RegistrationV1AllOfRole;
  job_title?: string;
  organisation_name?: string;
  payment_profiles?: string[];
  providers?: ProviderV1[];
  identity_card?: IdentityCard;
  driving_licence?: DrivingLicence;
  selfie?: Selfie;
  bank?: BankService;
  status?: Status;
  internal_payroll_id?: string;
  social_secretary?: SocialSecretary;
};
