/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  RegistrationsGetResponse,
  RegistrationsGetParams,
  Registration,
  RegistrationCreateRequest,
  ExportResponse,
  RegistrationsExportParams,
  InvitationsExportParams,
  Address,
  ResetRegistrationMembershipRequest,
} from '../../models';
import type { Error } from '../../models/component-common';
import { customInstance } from '../../config';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
  T,
>() => T extends Y ? 1 : 2
  ? A
  : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<
    { [Q in P]: T[P] },
    { -readonly [Q in P]: T[P] },
    P
  >;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object
        ? NonReadonly<NonNullable<T[P]>>
        : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

export const registrationsGet = (
  params?: RegistrationsGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<RegistrationsGetResponse>({
    url: `/web/v2/registrations`,
    method: 'get',
    params,
    signal,
  });
};

export const getRegistrationsGetQueryKey = (params?: RegistrationsGetParams) =>
  [`/web/v2/registrations`, ...(params ? [params] : [])] as const;

export const getRegistrationsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof registrationsGet>>,
  TError = unknown,
>(
  params?: RegistrationsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof registrationsGet>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof registrationsGet>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRegistrationsGetQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof registrationsGet>>
  > = ({ signal }) => registrationsGet(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type RegistrationsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof registrationsGet>>
>;
export type RegistrationsGetQueryError = unknown;

export const useRegistrationsGet = <
  TData = Awaited<ReturnType<typeof registrationsGet>>,
  TError = unknown,
>(
  params?: RegistrationsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof registrationsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRegistrationsGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const registrationsCreate = (
  registrationCreateRequest: RegistrationCreateRequest,
) => {
  return customInstance<Registration>({
    url: `/web/v2/registrations`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: registrationCreateRequest,
  });
};

export const getRegistrationsCreateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registrationsCreate>>,
    TError,
    { data: RegistrationCreateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof registrationsCreate>>,
  TError,
  { data: RegistrationCreateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registrationsCreate>>,
    { data: RegistrationCreateRequest }
  > = (props) => {
    const { data } = props ?? {};

    return registrationsCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RegistrationsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof registrationsCreate>>
>;
export type RegistrationsCreateMutationBody = RegistrationCreateRequest;
export type RegistrationsCreateMutationError = unknown;

export const useRegistrationsCreate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registrationsCreate>>,
    TError,
    { data: RegistrationCreateRequest },
    TContext
  >;
}) => {
  const mutationOptions = getRegistrationsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const registrationsExport = (
  params?: RegistrationsExportParams,
  signal?: AbortSignal,
) => {
  return customInstance<ExportResponse>({
    url: `/web/v2/registrations/export`,
    method: 'get',
    params,
    signal,
  });
};

export const getRegistrationsExportQueryKey = (
  params?: RegistrationsExportParams,
) => [`/web/v2/registrations/export`, ...(params ? [params] : [])] as const;

export const getRegistrationsExportQueryOptions = <
  TData = Awaited<ReturnType<typeof registrationsExport>>,
  TError = unknown,
>(
  params?: RegistrationsExportParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof registrationsExport>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof registrationsExport>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRegistrationsExportQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof registrationsExport>>
  > = ({ signal }) => registrationsExport(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type RegistrationsExportQueryResult = NonNullable<
  Awaited<ReturnType<typeof registrationsExport>>
>;
export type RegistrationsExportQueryError = unknown;

export const useRegistrationsExport = <
  TData = Awaited<ReturnType<typeof registrationsExport>>,
  TError = unknown,
>(
  params?: RegistrationsExportParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof registrationsExport>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRegistrationsExportQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const invitationsExport = (
  params?: InvitationsExportParams,
  signal?: AbortSignal,
) => {
  return customInstance<ExportResponse>({
    url: `/web/v2/invitations/export`,
    method: 'get',
    params,
    signal,
  });
};

export const getInvitationsExportQueryKey = (
  params?: InvitationsExportParams,
) => [`/web/v2/invitations/export`, ...(params ? [params] : [])] as const;

export const getInvitationsExportQueryOptions = <
  TData = Awaited<ReturnType<typeof invitationsExport>>,
  TError = unknown,
>(
  params?: InvitationsExportParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof invitationsExport>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof invitationsExport>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getInvitationsExportQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof invitationsExport>>
  > = ({ signal }) => invitationsExport(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type InvitationsExportQueryResult = NonNullable<
  Awaited<ReturnType<typeof invitationsExport>>
>;
export type InvitationsExportQueryError = unknown;

export const useInvitationsExport = <
  TData = Awaited<ReturnType<typeof invitationsExport>>,
  TError = unknown,
>(
  params?: InvitationsExportParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof invitationsExport>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getInvitationsExportQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * This call will be proxied to `PUT /web/memberships/{membership_id}/registration`. Please check that call for more info.
 */
export const registrationUpdate = (
  membershipId: string,
  registration: NonReadonly<Registration>,
) => {
  return customInstance<Registration>({
    url: `/web/v2/registrations/${membershipId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: registration,
  });
};

export const getRegistrationUpdateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registrationUpdate>>,
    TError,
    { membershipId: string; data: NonReadonly<Registration> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof registrationUpdate>>,
  TError,
  { membershipId: string; data: NonReadonly<Registration> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registrationUpdate>>,
    { membershipId: string; data: NonReadonly<Registration> }
  > = (props) => {
    const { membershipId, data } = props ?? {};

    return registrationUpdate(membershipId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RegistrationUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof registrationUpdate>>
>;
export type RegistrationUpdateMutationBody = NonReadonly<Registration>;
export type RegistrationUpdateMutationError = unknown;

export const useRegistrationUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registrationUpdate>>,
    TError,
    { membershipId: string; data: NonReadonly<Registration> },
    TContext
  >;
}) => {
  const mutationOptions = getRegistrationUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * This call will disable the account of an employee permanently Following actions will be performed: - Disable his card - Disable provider credentials such as qpark - block budgets and remove payment profiles
 */
export const registrationDelete = (membershipId: string) => {
  return customInstance<void>({
    url: `/web/v2/registrations/${membershipId}`,
    method: 'delete',
  });
};

export const getRegistrationDeleteMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registrationDelete>>,
    TError,
    { membershipId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof registrationDelete>>,
  TError,
  { membershipId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registrationDelete>>,
    { membershipId: string }
  > = (props) => {
    const { membershipId } = props ?? {};

    return registrationDelete(membershipId);
  };

  return { mutationFn, ...mutationOptions };
};

export type RegistrationDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof registrationDelete>>
>;

export type RegistrationDeleteMutationError = unknown;

export const useRegistrationDelete = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registrationDelete>>,
    TError,
    { membershipId: string },
    TContext
  >;
}) => {
  const mutationOptions = getRegistrationDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * This call will fully archive the account of a user permanently Following actions will be performed: - Suspend the user - Disable their account - Disable their card - Archive their email and phone number
 */
export const registrationFullArchive = (membershipId: string) => {
  return customInstance<void>({
    url: `/web/v2/registrations/${membershipId}/full_archive`,
    method: 'delete',
  });
};

export const getRegistrationFullArchiveMutationOptions = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registrationFullArchive>>,
    TError,
    { membershipId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof registrationFullArchive>>,
  TError,
  { membershipId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registrationFullArchive>>,
    { membershipId: string }
  > = (props) => {
    const { membershipId } = props ?? {};

    return registrationFullArchive(membershipId);
  };

  return { mutationFn, ...mutationOptions };
};

export type RegistrationFullArchiveMutationResult = NonNullable<
  Awaited<ReturnType<typeof registrationFullArchive>>
>;

export type RegistrationFullArchiveMutationError = Error;

export const useRegistrationFullArchive = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registrationFullArchive>>,
    TError,
    { membershipId: string },
    TContext
  >;
}) => {
  const mutationOptions = getRegistrationFullArchiveMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Update an address of a registration

 */
export const registrationAddressUpdate = (
  membershipId: string,
  address: Address,
) => {
  return customInstance<Registration>({
    url: `/web/v2/registrations/${membershipId}/address`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: address,
  });
};

export const getRegistrationAddressUpdateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registrationAddressUpdate>>,
    TError,
    { membershipId: string; data: Address },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof registrationAddressUpdate>>,
  TError,
  { membershipId: string; data: Address },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registrationAddressUpdate>>,
    { membershipId: string; data: Address }
  > = (props) => {
    const { membershipId, data } = props ?? {};

    return registrationAddressUpdate(membershipId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RegistrationAddressUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof registrationAddressUpdate>>
>;
export type RegistrationAddressUpdateMutationBody = Address;
export type RegistrationAddressUpdateMutationError = unknown;

export const useRegistrationAddressUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registrationAddressUpdate>>,
    TError,
    { membershipId: string; data: Address },
    TContext
  >;
}) => {
  const mutationOptions = getRegistrationAddressUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Resets a registration membership and sends an invitation email if specified. - Removes any SSO authentication mapping - Updates the membership status to CREATED. This feature is mainly used to fix SSO loops.
 */
export const registrationMembershipResetPost = (
  membershipId: string,
  resetRegistrationMembershipRequest: NonReadonly<ResetRegistrationMembershipRequest>,
) => {
  return customInstance<void>({
    url: `/web/v2/registrations/${membershipId}/reset`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: resetRegistrationMembershipRequest,
  });
};

export const getRegistrationMembershipResetPostMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registrationMembershipResetPost>>,
    TError,
    {
      membershipId: string;
      data: NonReadonly<ResetRegistrationMembershipRequest>;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof registrationMembershipResetPost>>,
  TError,
  {
    membershipId: string;
    data: NonReadonly<ResetRegistrationMembershipRequest>;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registrationMembershipResetPost>>,
    {
      membershipId: string;
      data: NonReadonly<ResetRegistrationMembershipRequest>;
    }
  > = (props) => {
    const { membershipId, data } = props ?? {};

    return registrationMembershipResetPost(membershipId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RegistrationMembershipResetPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof registrationMembershipResetPost>>
>;
export type RegistrationMembershipResetPostMutationBody =
  NonReadonly<ResetRegistrationMembershipRequest>;
export type RegistrationMembershipResetPostMutationError = unknown;

export const useRegistrationMembershipResetPost = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registrationMembershipResetPost>>,
    TError,
    {
      membershipId: string;
      data: NonReadonly<ResetRegistrationMembershipRequest>;
    },
    TContext
  >;
}) => {
  const mutationOptions =
    getRegistrationMembershipResetPostMutationOptions(options);

  return useMutation(mutationOptions);
};
