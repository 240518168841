import {
  Box,
  Heading,
  Link as ChakraLink,
  Link,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { useGetCurrentUser, useOrganisationGet } from '@libs/api/endpoints';
import { useLanguage } from '@libs/core/i18n';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { getEnvByLanguage } from '@libs/dashboard-core/utils/environment';
import { Loader, PageHeader } from '@libs/ui/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingSuccessWallet } from './onboarding-success-wallet';

type OnboardingSuccessProps = {
  organisationId: string;
};

export const OnboardingSuccess = ({
  organisationId,
}: OnboardingSuccessProps) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const faqUrl = getEnvByLanguage(language, 'NX_FAQ_URL');

  const { data: organisation, isLoading: organisationLoading } =
    useOrganisationGet(organisationId);
  const { data: user, isLoading } = useGetCurrentUser();
  if (isLoading || organisationLoading) {
    return (
      <>
        <PageHeader
          size="Large"
          title={
            <>
              {t(i18nKeys.onboarding.success.title, {
                username: '',
              })}
            </>
          }
          introduction={t(
            i18nKeys.onboarding.success.complete_steps.introduction,
          )}
        />
        <Box py="10rem" maxW="69rem" position="relative">
          <Loader spinnerProps={{ size: 'md' }} modRight>
            {t(i18nKeys.common.loading)}
          </Loader>
        </Box>
      </>
    );
  }
  if (organisation && user) {
    return (
      <>
        <PageHeader
          size="Large"
          title={
            <>
              {t(i18nKeys.onboarding.success.title, {
                username: user.first_name,
              })}
            </>
          }
          introduction={t(
            i18nKeys.onboarding.success.complete_steps.introduction,
          )}
        />
        <Heading size="Title3" mt="4rem">
          {t(i18nKeys.onboarding.success.complete_steps.title)}
        </Heading>

        <SimpleGrid columns={{ md: 2, sm: 1 }} spacing="2rem">
          <OnboardingSuccessWallet walletIban={organisation.wallet_iban} />
        </SimpleGrid>
        <Box mt="4rem">
          <Heading>{t(i18nKeys.onboarding.success.question)}</Heading>
          <Text color="ink.dark">
            {t(i18nKeys.common.consult)}{' '}
            <Link href={faqUrl} as="a" target="_blank">
              {t(i18nKeys.common.faq)}
            </Link>{' '}
            {t(i18nKeys.onboarding.success.contact)}{' '}
            <ChakraLink href={`mailto:${process.env.NX_EMAIL_SUPPORT}`}>
              {t(i18nKeys.common.support)}
            </ChakraLink>
          </Text>
        </Box>
      </>
    );
  }
};
