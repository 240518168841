/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { Audit } from './audit';
import type { RegistrationAllOf } from './registrationAllOf';

export type Registration = Audit & RegistrationAllOf;
