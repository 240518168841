/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */

export type NotionBlockType =
  typeof NotionBlockType[keyof typeof NotionBlockType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotionBlockType = {
  paragraph: 'paragraph',
  heading_1: 'heading_1',
  heading_2: 'heading_2',
  heading_3: 'heading_3',
  bulleted_list_item: 'bulleted_list_item',
  video: 'video',
  image: 'image',
} as const;
