/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryPaymentMethodsParameter } from './queryPaymentMethodsParameter';

export type ProgramServicesGetParams = {
  /**
   * Repeat "payment_method" param multiple times to include multiple payment methods
   */
  payment_method?: QueryPaymentMethodsParameter;
};
