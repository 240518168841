/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Web Policy API
 * Web policies
 * OpenAPI spec version: 1.0
 */
import type { QueryOrganisationIdParameter } from './component-parameters/queryOrganisationIdParameter';
import type { QueryPageNumberParameter } from './component-parameters/queryPageNumberParameter';
import type { QueryPageSizeParameter } from './component-parameters/queryPageSizeParameter';

export type GetWalletParams = {
  /**
   * ID of the organisation
   */
  organisation_id?: QueryOrganisationIdParameter;
  /**
   * Number of the page to fetch
   */
  page_number?: QueryPageNumberParameter;
  /**
   * Size of the page to fetch
   */
  page_size?: QueryPageSizeParameter;
};
