/* eslint-disable @typescript-eslint/ban-types */
import { useMemo } from 'react';

type IDefaultForm = {
  submitForm: () => void;
};

interface MetaForm {
  touched: boolean;
  error?: string;
}
interface InputForm<T> {
  name: keyof T;
  value?;
  meta?: MetaForm;
  handleSelect: (value) => void;
}

export type IForm<T extends {}> = IDefaultForm & {
  [key in keyof T]: InputForm<T>;
};

export function extraValueFromEvent(e) {
  return e?.target?.value ?? e;
}

// Generates the generic form with the keys of the field used
export function useCreateForm<T extends {}>(
  values: T,
  rest: { touched; errors; setFieldValue; submitForm },
): IForm<T> & IDefaultForm {
  // All keys of values
  const keys = Object.keys(values);

  // Destruct
  const { touched, errors, setFieldValue, submitForm } = rest;

  // Generate Form

  const form = useMemo(() => {
    const form = { submitForm };

    keys.forEach((key) => {
      form[key] = createFormField(values, touched, errors, setFieldValue, key);
    });

    return form;
  }, [errors, keys, setFieldValue, submitForm, touched, values]);

  return form as IForm<T> & IDefaultForm;
}

// Generates inputSelectForm
function createFormField<T>(
  values,
  touched,
  errors,
  setFieldValue,
  name,
): InputForm<T> {
  // Generate field
  const field = {
    name: name,
    value: values[name],
    meta: {
      touched: touched[name],
      error: errors[name],
    },
    handleSelect: (value: unknown) => setFieldValue(name, value),
  };

  return field;
}
