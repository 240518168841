import { Role } from '@libs/api/models';
import React, { PropsWithChildren } from 'react';
import { Routes } from 'react-router-dom';
import { useRoleState } from '../context';

type RoleRouteProps = PropsWithChildren<{
  role: Role;
}>;

export const RoleRoute = ({ role, children }: RoleRouteProps) => {
  const { role: actualRole } = useRoleState();

  if (role !== actualRole) {
    return null;
  }
  return <Routes>{children}</Routes>;
};
