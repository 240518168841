import { Text } from '@chakra-ui/layout';
import { Progress } from '@chakra-ui/progress';
import { BudgetsGetParams, BudgetStatus, BudgetType } from '@libs/api/models';
import { PAGE_SIZE } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useIcon } from '@libs/core/theme/utils';
import { DrawerType } from '@libs/dashboard-core/pages/dashboard/dashboard.utils';
import { Currency, Pager, Table, Tag } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useDrawer, useTable } from '@libs/ui/components/table/table.utils';
import dayjs from 'dayjs';
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BudgetStatusToTagStatus } from '../../employee.utils';
import { BudgetCreateDrawer } from './budget-create-drawer';
import { BudgetDetailsDrawer } from './budget-details-drawer';
import { ExpenseCreateDrawer } from './expense-create-drawer';
import { useBudgetsGet } from '@libs/api/endpoints';
import { Registration } from '@libs/api-v2/models';

type BudgetTableProps = {
  employee: Registration;

  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  setPageSize?: React.Dispatch<React.SetStateAction<number>>;
  pageSize?: number;
  budgetsGetParams: BudgetsGetParams;
};

export const BudgetTable = ({
  employee,

  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
  budgetsGetParams,
}: BudgetTableProps) => {
  const { t } = useTranslation();
  const EyeIcon = useIcon('Eye');
  const EditIcon = useIcon('Edit');
  const AddIcon = useIcon('Add');
  const {
    drawerHandler,
    isOpen,
    onClose,
    activeDrawerIndex,
    selectedDrawerType,
  } = useDrawer();

  const params = {
    membership_id: employee.membership_id,
    page_number: pageNumber,
    page_size: pageSize,
    // TODO enable sort & filters when endpoints are updated
    // sort: currentSortQuery.length > 0 ? currentSortQuery.toString() : null,
    // is_carry_over_enabled:
    //   (filterQuery.length > 0 &&
    //     filterQuery.find('is_carry_over_enabled').split(':')[1]) ||
    //   null,
    // type:
    //   (filterQuery.length > 0 && filterQuery.find('type').split(':')[1]) ||
    //   null,
  };
  const {
    isLoading,
    isFetching,
    refetch,
    data: budgets,
  } = useBudgetsGet(params, {
    query: { keepPreviousData: true },
  });

  const { useQueryParams } = useTable({
    setPageNumber,
    setPageSize,
  });

  useQueryParams({
    totalPages: budgets?.meta.total_pages,
    pageSize,
    pageNumber,
    setPageSize,
    refetch,
  });

  useEffect(() => {
    refetch();
  }, [refetch, pageSize]);

  const headers: TableHeaders = [
    {
      name: 'type',
      title: t(i18nKeys.operator.employees.budgets.type.title),
      filter: true,
      filterValues: Object.values(BudgetType),
      filterValuesI18nKey: i18nKeys.operator.employees.budgets.type,
    },
    {
      name: 'start_at',
      title: t(i18nKeys.common.start_at),
    },
    {
      name: 'end_at',
      title: t(i18nKeys.common.end_at),
    },
    {
      name: 'is_carry_over_enabled',
      title: t(i18nKeys.operator.employees.budgets.is_carry_over_enabled.title),
      filter: true,
      filterValues: [true.toString(), false.toString()],
      filterValuesI18nKey:
        i18nKeys.operator.employees.budgets.is_carry_over_enabled,
    },
    {
      name: 'remaining',
      title: t(i18nKeys.operator.employees.budgets.remaining),
    },
    {
      name: 'used',
      title: t(i18nKeys.common.used),
    },
    {
      name: 'total',
      title: t(i18nKeys.operator.employees.budgets.total),
    },
    { name: 'status', title: t(i18nKeys.common.status) },
    '',
  ];

  return (
    <>
      <Table headers={headers}>
        <Table.TBody
          isLoading={(isLoading || isFetching) && !budgets}
          colSpan={headers.length}
          isEmpty={
            !isLoading &&
            !isFetching &&
            (budgets?.data.length === 0 || !budgets)
          }
        >
          {budgets?.data.map((budget, index) => {
            const progressValue = (budget.remaining / budget.total) * 100 || 0;

            return (
              <Fragment key={`${budget.id}-${budget.remaining}-${index}`}>
                <Table.TR
                  selectable
                  position="relative"
                  transform="scale(1,1)"
                  onClick={() => drawerHandler(index)}
                  index={budget.id}
                >
                  <Table.TD>
                    <Text>
                      {t(i18nKeys.operator.employees.budgets.type[budget.type])}
                    </Text>
                  </Table.TD>

                  <Table.Date>{budget.start_at}</Table.Date>
                  <Table.Date>{budget.end_at}</Table.Date>
                  <Table.TD>
                    {t(
                      i18nKeys.operator.employees.budgets.is_carry_over_enabled[
                        budget.is_carry_over_enabled
                          ? true.toString()
                          : false.toString()
                      ],
                    )}
                  </Table.TD>
                  <Table.TD>
                    <Currency
                      size="Body2Bold"
                      textOptions={{
                        color:
                          progressValue > 0
                            ? 'status.positive.type'
                            : 'ink.dark',
                      }}
                    >
                      {budget.remaining}
                    </Currency>
                    <Progress
                      colorScheme="status.positive"
                      rounded="full"
                      size="lg"
                      value={progressValue}
                    />
                  </Table.TD>
                  <Table.TD>
                    <Currency size="Body2Bold">{budget.used}</Currency>
                  </Table.TD>
                  <Table.TD>
                    <Currency size="Body2Bold">{budget.initial_total}</Currency>
                  </Table.TD>
                  <Table.TD>
                    <Tag
                      hasIcon
                      tooltipLabel={
                        <Text size="Small">
                          {t(
                            i18nKeys.operator.employees.budgets.status[
                              budget.status
                            ],
                          )}
                        </Text>
                      }
                      status={BudgetStatusToTagStatus[budget.status]}
                    />
                  </Table.TD>
                  <Table.Action
                    actions={[
                      {
                        onClick: () => drawerHandler(index),
                        icon: <EyeIcon width="2rem" height="2rem" />,
                      },
                      {
                        onClick: () =>
                          drawerHandler({ index, drawerType: DrawerType.edit }),
                        icon: <EditIcon width="2rem" height="2rem" />,
                      },
                      {
                        onClick: () =>
                          drawerHandler({
                            index,
                            drawerType: DrawerType.create,
                          }),
                        icon: <AddIcon width="2rem" height="2rem" />,
                        tooltipLabel: t(
                          i18nKeys.operator.employees.expenses.swapfiets
                            .add_expense,
                        ),
                        buttonProps: {
                          disabled:
                            budget.status === BudgetStatus.BLOCKED ||
                            dayjs(budget.end_at) < dayjs() ||
                            dayjs(budget.start_at) > dayjs(),
                        },
                      },
                    ]}
                  />
                </Table.TR>
              </Fragment>
            );
          })}
        </Table.TBody>
      </Table>
      {isOpen &&
        ((selectedDrawerType === DrawerType.edit && (
          <BudgetCreateDrawer
            budget={budgets.data[activeDrawerIndex]}
            isOpen={isOpen}
            onClose={onClose}
            budgetsGetParams={budgetsGetParams}
          />
        )) ||
          (selectedDrawerType === DrawerType.create && (
            <ExpenseCreateDrawer
              isOpen={isOpen}
              onClose={onClose}
              employee={employee}
              expensesGetParams={{
                membership_id: employee.membership_id,
                page_number: 1,
                page_size: PAGE_SIZE,
              }}
              budget={budgets.data[activeDrawerIndex]}
            />
          )) || (
            <BudgetDetailsDrawer
              budget={budgets.data[activeDrawerIndex]}
              isOpen={isOpen}
              onClose={onClose}
            />
          ))}
      {budgets && budgets.meta.total_pages > 1 && (
        <Pager
          setPageNumber={setPageNumber}
          total_items={budgets.meta.total_items}
          page_index={budgets.meta.page_index}
          total_pages={budgets.meta.total_pages}
          page_size={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          mt="2rem"
          isFetching={isFetching}
        />
      )}
    </>
  );
};
